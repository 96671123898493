import * as React from "react";
const AdminUsersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 25 25",
      marginRight: "2%",
    }}
    viewBox="0 0 25 25"
    {...props}
  >
    <style>{".st0{fill:}" + props.color + "}"}</style>
    <path
      d="M12.5 9.8c1.6 0 3-1.4 3-3s-1.4-3-3-3-3 1.4-3 3c0 1.7 1.4 3 3 3z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M12.5 11.6c2.6 0 4.8-2.1 4.8-4.8 0-2.6-2.1-4.8-4.8-4.8S7.7 4.2 7.7 6.8c0 2.7 2.2 4.8 4.8 4.8zm0-7.8c1.6 0 3 1.4 3 3s-1.4 3-3 3-3-1.4-3-3 1.4-3 3-3zM13.4 11.9h-1.7C8 11.9 5 14.3 5 17.3V22c0 .5.4.9.9.9s.9-.4.9-.9v-4.7c0-2 2.2-3.6 5-3.6h1.7c2.7 0 5 1.6 5 3.6V22c0 .5.4.9.9.9s.9-.4.9-.9v-4.8c-.2-2.9-3.2-5.3-6.9-5.3z"
      className="st0"
    />
  </svg>
);
export default AdminUsersIcon;
