import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useIntl } from "react-intl";
import moment from "moment-timezone";
import "../../oneOnOne/one-on-one-progress-bar.css";

export default function MyTrainingTimeline({ trainings }) {
  let steps;
  const intl = useIntl();
  if (trainings && trainings.length >= 1) {
    steps = trainings.map((training) => {
      return {
        ...training,
        active: true,
        title: intl.formatMessage({
          id: `TrainingStatus.${training.status.replace(/ /g, ".")}`,
        }),
        topText: moment(training.createdAt).format("DD.MM.YYYY HH:mm"),
      };
    });
  }

  return (
    <div className="one-on-one-progressbar">
      {steps &&
        steps.map((step, index) => (
          <div
            className={`step ${step.active ? "done" : ""} ${
              index === trainings.length - 1 ? "active" : ""
            }`}
            key={index}
          >
            <p className="label top">
              {step.topText &&
                ReactHtmlParser(step.topText.split(" ").join("<br/>"))}
            </p>
            <div className={`drawing ${index === 0 ? "first" : ""}`}>
              <div className="circle"></div>
            </div>
            <p className="label bottom">{step.title}</p>
          </div>
        ))}
    </div>
  );
}
