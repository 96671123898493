import React, { useState, useEffect } from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { Doughnut, Bar, Pie } from "react-chartjs-2";
import TrainingsService from "../../../services/TrainingsService";
import PerformanceReviewService from "../../../services/PerformanceReviewService";
import Loading from "../../common/display/Loading";
import "chartjs-plugin-piechart-outlabels";
import "./ReportingStatistics.css";

let barChart;
let pieChart;
const ReportingStatistics = () => {
  const intl = useIntl();
  const oneHunderePercent = 100;
  const pointAccuracy = 2;
  const { performanceReviewId } = useParams();
  const [tabKey, setTabKey] = useState("barChart");
  const [performanceReviewYear, setPerformanceReviewYear] = useState();
  const [hiddenDatasets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [wasLoaded, setWasLoaded] = useState(false);
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [],
  });
  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [],
  });
  const [doughnutData, setDoughnutData] = useState({
    labels: [],
    datasets: [],
  });

  const providedVsNotProvidedLabels = [
    "TrainingStatus.Provided",
    "TrainingStatus.not.Provided",
  ];

  const [trainingStatuses] = useState([
    {
      labelId: "TrainingStatus.In.Progress",
      index: 0,
      value: "In Progress",
      count: 0,
      color: "#C6D4EC",
    },
    {
      labelId: "TrainingStatus.Remain",
      index: 1,
      value: "Requested",
      count: 0,
      color: "#18B3B3",
    },
    {
      labelId: "TrainingStatus.Vendor.Selected",
      index: 2,
      value: "Vendor Selected",
      count: 0,
      color: "#DD1273",
    },
    {
      labelId: "TrainingStatus.Cancelled.by.P.and.C",
      index: 3,
      value: "Cancelled by P and C",
      count: 0,
      color: "#BFBFBF",
    },
    {
      labelId: "TrainingStatus.Provided",
      index: 4,
      value: "Provided",
      count: 0,
      color: "#24274C",
    },
    {
      labelId: "TrainingStatus.Cancelled.by.Employee",
      index: 5,
      value: "Cancelled by Employee",
      count: 0,
      color: "#1F4E79",
    },
    {
      labelId: "TrainingStatus.Internal.Done",
      index: 6,
      value: "Internal Done",
      count: 0,
      color: "#2E75B6",
    },
    {
      labelId: "TrainingStatus.Left.the.Company",
      index: 7,
      value: "Left the Company",
      count: 0,
      color: "#98C0E4",
    },
  ]);
  const [monthsOfTheYear] = useState([
    {
      labelId: "Year.months.january",
      monthNumber: 1,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.february",
      monthNumber: 2,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.march",
      monthNumber: 3,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.april",
      monthNumber: 4,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.may",
      monthNumber: 5,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.june",
      monthNumber: 6,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.july",
      monthNumber: 7,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.august",
      monthNumber: 8,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.september",
      monthNumber: 9,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.october",
      monthNumber: 10,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.november",
      monthNumber: 11,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
    {
      labelId: "Year.months.december",
      monthNumber: 12,
      count: 0,
      color: "rgb(75, 81, 159)",
    },
  ]);

  const doughnutOptions = {
    tooltips: {
      callbacks: {
        label: (ctx, data) =>
          `${intl.formatMessage({ id: data.labels[ctx.index] })}: ${
            data.datasets[ctx.datasetIndex].data[ctx.index]
          }%`,
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 100,
        left: 200,
        right: 250,
      },
    },
    responsive: false,
    legend: { display: false },
    plugins: {
      outlabels: {
        text: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          if (value === "0.00" || value === "") return "";
          return `${value}%`;
        },
        textAlign: "start",
        color: "white",
        stretch: 15,
        font: {
          resizable: true,
          minSize: 12,
          maxSize: 18,
        },
      },
      datalabels: {
        formatter: function (value) {
          return "";
        },
      },
    },
  };

  const barOptions = {
    tooltips: {
      callbacks: {
        label: (ctx, data) =>
          `${intl.formatMessage({ id: data.labels[ctx.index] })}: ${
            data.datasets[ctx.datasetIndex].data[ctx.index]
          }%`,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0,
      },
    },
    legend: { display: false },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: Math.round,
        font: {
          weight: "bold",
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            fontSize: 18,
            maxRotation: 40,
            minRotation: 40,
            fontColor: "#000",
          },
          barThickness: 13,
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            display: false,
          },
          ticks: {
            userCallback: function (value, index, values) {
              return "";
            },
          },
        },
      ],
    },
  };

  const pieOptions = {
    tooltips: {
      callbacks: {
        label: (ctx, data) =>
          `${intl.formatMessage({ id: data.labels[ctx.index] })}: ${
            data.datasets[ctx.datasetIndex].data[ctx.index]
          }`,
      },
    },
    layout: {
      padding: {
        top: 25,
        bottom: 25,
      },
    },
    legend: { display: false },
    plugins: {
      outlabels: {
        textAlign: "start",
        color: "white",
        stretch: 15,
        font: {
          resizable: true,
          minSize: 12,
          maxSize: 18,
        },
      },
      datalabels: {
        formatter: function () {
          return "";
        },
      },
    },
  };

  const filteredData = {
    ...doughnutData,
    datasets: doughnutData.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((value, dataIndex) =>
        hiddenDatasets.includes(dataIndex) ? "" : value
      ),
    })),
  };

  useEffect(() => {
    if (isLoading && performanceReviewYear && !wasLoaded) {
      setWasLoaded(true);
      const providedCounts = monthsOfTheYear.map((month) => {
        return TrainingsService.getProvidedTrainingsCount(
          performanceReviewId,
          month.monthNumber
        );
      });

      const allStatusesCounts = trainingStatuses.map((status) => {
        return TrainingsService.getTrainingsCount(
          performanceReviewId,
          status.value
        );
      });

      Promise.all(providedCounts).then((counts) => {
        setBarData({
          labels: monthsOfTheYear.flat().map((status) => status.labelId),
          datasets: [
            {
              data: counts,
              backgroundColor: monthsOfTheYear.map((month) => month.color),
              borderWidth: 1,
            },
          ],
        });
      });

      Promise.all(allStatusesCounts).then((statusCounts) => {
        statusCounts.forEach((status) => {
          const match = trainingStatuses.find(
            (training) => training.value === status.status
          );
          if (match) {
            match.count = status.count;
          }
        });
        const filteredTrainingStatus = trainingStatuses.filter(
          (training) => training.count > 0
        );
        const allTrainingsCount = filteredTrainingStatus.reduce(
          (total, training) => total + training.count,
          0
        );
        setDoughnutData({
          labels: filteredTrainingStatus.flat().map((status) => status.labelId),
          datasets: [
            {
              data: filteredTrainingStatus.map((trainingStatus) =>
                (
                  (trainingStatus.count / allTrainingsCount) *
                  oneHunderePercent
                ).toFixed(pointAccuracy)
              ),
              borderWidth: 1,
              backgroundColor: filteredTrainingStatus.map(
                (status) => status.color
              ),
            },
          ],
        });
        setIsLoading(false);
      });

      TrainingsService.getProvidedAndNotProvidedTrainingsCount(
        performanceReviewId
      ).then((response) => {
        setPieData({
          labels: [
            providedVsNotProvidedLabels.map((labelId) => {
              return labelId;
            }),
          ],
          datasets: [
            {
              data: [response.countProvided, response.countNotProvided],
              backgroundColor: ["#DD1273", "#24274C"],
              hoverOffset: 4,
            },
          ],
        });
      });
    }
  }, [
    isLoading,
    wasLoaded,
    performanceReviewYear,
    performanceReviewId,
    monthsOfTheYear,
    trainingStatuses,
    providedVsNotProvidedLabels,
    intl,
  ]);

  useEffect(() => {
    if (!isLoading) {
      barChart.chartInstance.data.labels = monthsOfTheYear.map((status) => {
        return intl.formatMessage({ id: status.labelId });
      });
      barChart.chartInstance.update();
      pieChart.chartInstance.data.labels = providedVsNotProvidedLabels.map(
        (labelId) => {
          return intl.formatMessage({ id: labelId });
        }
      );
      pieChart.chartInstance.update();
    }
  }, [
    isLoading,
    monthsOfTheYear,
    trainingStatuses,
    providedVsNotProvidedLabels,
    intl,
    intl.locale,
  ]);

  useEffect(() => {
    PerformanceReviewService.getPerformanceReviewById(performanceReviewId).then(
      (performanceReview) => {
        setPerformanceReviewYear(
          performanceReview.title.substring(performanceReview.title.length - 4)
        );
      }
    );
  }, [performanceReviewId]);

  return isLoading ? (
    <Loading />
  ) : (
    <Row className="main-row">
      <Col sm={12} md={12} lg={12} xl={5}>
        {" "}
        <div className="pl-1 doughnut-column">
          <div className="training-title-container">
            <h1 className="trainings-title">
              <FormattedMessage id={"Statistics.trainings.label"} />{" "}
              {` ${performanceReviewYear}`}
            </h1>{" "}
          </div>
          <div className="doughnut-canvas">
            <Doughnut
              data={filteredData}
              width={"750px"}
              height={"750px"}
              options={doughnutOptions}
            />
          </div>
        </div>
        <div className="training-statuses">
          <Row className="justify-content-center">
            {trainingStatuses.map((status) => (
              <Col key={status.index} xs={6} md={6} className="px-1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    opacity:
                      status.count !== 0
                        ? hiddenDatasets.includes(status.index)
                          ? 0.5
                          : 1
                        : 0.5,
                    marginRight: "10px",
                  }}
                >
                  <span
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: status.color,
                      marginRight: "5px",
                    }}
                  ></span>
                  <span>{intl.formatMessage({ id: status.labelId })}</span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Col>
      <Col sm={12} md={12} lg={12} xl={3} className=" training-status-column">
        {" "}
        <Row className="status-container">
          {trainingStatuses.map((status) => (
            <Col key={status.index} sm={6} md={5}>
              <div className={"px-1 training-status-rect "}>
                {" "}
                <p className="training-status-label">
                  {" "}
                  {intl.formatMessage({ id: status.labelId })}
                  <h4 className="training-status-count">{status.count}</h4>
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
      <Col sm={12} md={12} lg={12} xl={4} className="bar-column">
        <Tabs
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
          id="graphics-tabs"
          className="mb-3"
        >
          <Tab
            eventKey={"barChart"}
            title={intl.formatMessage({
              id: "Statistics.provided.by.months.label",
            })}
          >
            <h4 className="months-title">
              <FormattedMessage id="Statistics.provided.by.months.label" />
            </h4>
            <div className="bar-chart">
              <Bar
                ref={(reference) => {
                  barChart = reference;
                }}
                width={"450px"}
                height={"430px"}
                options={barOptions}
                data={barData}
              ></Bar>
            </div>
          </Tab>
          <Tab
            eventKey={"pieChart"}
            title={intl.formatMessage({
              id: "Statistics.provided.vs.not.provided.label",
            })}
          >
            <h4 className="months-title">
              <FormattedMessage id="Statistics.provided.vs.not.provided.label" />
            </h4>
            <div className="pie-chart">
              <Pie
                height={"200px"}
                ref={(reference) => {
                  pieChart = reference;
                }}
                data={pieData}
                options={pieOptions}
              />
            </div>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};

export default ReportingStatistics;
