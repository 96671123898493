import React, { useState } from "react";
import { Table } from "react-bootstrap";

import RoleDataRow from "./RoleDataRow";
import "./roles.css";
import { FormattedMessage } from "react-intl";

const Roles = ({ roles }) => {
  const [hoveredRole, setHoveredRole] = useState();

  const onMouseOver = (role) => setHoveredRole(role);

  const onMouseLeave = () => setHoveredRole();

  return (
    <Table className="roles" responsive borderless>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="Roles.role" />
          </th>
          <th>
            <FormattedMessage id="Roles.description" />
          </th>
        </tr>
      </thead>
      <tbody>
        {roles.map((role, index) => (
          <RoleDataRow
            key={`role-row-${index}`}
            hoveredRole={hoveredRole}
            role={role}
            onMouseOver={() => onMouseOver(role)}
            onMouseLeave={onMouseLeave}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default Roles;
