import React, { useState, useEffect } from "react";
import { Formik, FieldArray, ErrorMessage } from "formik";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import Select, { components } from "react-select";
import * as Yup from "yup";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import Image from "../common/Image";
import { getRoleName } from "../../util/roleName";
import AdminService from "../../services/AdminService";
import "./edit-user.css";
import Loading from "../common/display/Loading";
import { getUniqueArray } from "../../util/arrayUtils";
import ErrorIcon from "../../icons/ErrorIcon";

const { Group, Label, Control, Check } = Form;
const { Dialog, Body, Title } = Modal;
const SELECT_MANAGER = "Select manager";
const NO_MANAGER = "No manager";
const NO_TEAM = "No team";

const selectStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
  }),
  multiValueRemove: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    display: data.isTeamViewer ? "none" : "block",
  }),
};

const MultiValueRemove = (props) => {
  if (props.data.isTeamViewer) {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-disabled">
            <FormattedMessage id="EditUserForm.teamViewer" />
          </Tooltip>
        }
        trigger="hover"
        placement="right"
      >
        {/*  don't delete the span - OverlayTrigger expects to have at least 1 child component */}
        <span style={{ opacity: "0" }}>tata</span>
      </OverlayTrigger>
    );
  } else {
    return <components.MultiValueRemove {...props} />;
  }
};
const EditUserForm = ({
  user,
  handleClose,
  handleFormSubmit,
  users,
  roles,
  teams,
  loading,
}) => {
  const [managers, setManagers] = useState([]);
  const [viewers, setViewers] = useState([]);
  const [selectedViewers, setSelectedViewers] = useState(
    user?.viewers?.map((element) => {
      return {
        value: element.id,
        label: element.firstName + " " + element.lastName,
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.email,
        username: element.username,
        isTeamViewer: element.isTeamViewer ? element.isTeamViewer : false,
        isDisabled: element.isTeamViewer ? element.isTeamViewer : false,
      };
    })
  );
  const [selectedTeam, setSelectedTeam] = useState(
    user?.team ? user?.team?.id : NO_TEAM
  );
  const [selectedManager, setSelectedManager] = useState(
    user?.managerId ? user?.managerId : NO_MANAGER
  );

  const intl = useIntl();
  const editPlaceholder = intl.formatMessage({
    id: "Reviews.tabs.edit",
  });
  const addPlaceholder = intl.formatMessage({
    id: "ProcessParticipantsFilter.add",
  });
  const userPlaceholder = intl.formatMessage({
    id: "Audit.user",
  });
  const noViewersPlaceholder = intl.formatMessage({
    id: "EditUserForm.noViewers",
  });

  useEffect(() => {
    AdminService.getAvaibleManagers(user?.id).then((managers) => {
      setManagers(managers);
      setViewers(managers);
    });
  }, [user]);

  function handleSelect(data) {
    setSelectedViewers(data);
  }

  function handleSelectedManager(data) {
    setSelectedManager(data);
  }

  function handleSelectedTeam(data) {
    setSelectedTeam(data);
    const oldSelectedViews = selectedViewers ? selectedViewers : [];
    const removeOldTeamViewers = oldSelectedViews
      ? oldSelectedViews.filter((el) => el.isTeamViewer === false)
      : [];
    const teamViewers =
      (user && user.team && data !== user.team.id) || data !== NO_TEAM
        ? teams
            .filter((e) => e.id === Number(data))
            .flatMap((el) =>
              el.viewers.map((element) => {
                return {
                  value: element.id,
                  label: element.firstName + " " + element.lastName,
                  firstName: element.firstName,
                  lastName: element.lastName,
                  email: element.email,
                  username: element.username,
                  isTeamViewer: true,
                  isDisabled: true,
                };
              })
            )
        : [];
    const newViewers = [...removeOldTeamViewers, ...teamViewers];
    setSelectedViewers(getUniqueArray(newViewers));
    if (user && user.team && data !== user.team.id) {
      setViewers([]);
    }
    if (user) {
      if (user.manager) {
        setSelectedManager(user.managerId);
      } else if (user.team && user.team.manager) {
        setSelectedManager(user.team.manager.id);
      } else {
        const managerId = teams
          .filter((e) => e.id === Number(data))
          .flatMap((el) => el?.manager?.id)[0];
        setSelectedManager(managerId);
      }
    } else if (data !== NO_TEAM) {
      const managerId = teams
        .filter((e) => e.id === Number(data))
        .flatMap((el) => el?.manager?.id)[0];
      setSelectedManager(managerId);
    }
    if ((user && user.team && data !== user.team.id) || data !== NO_TEAM) {
      const managerId = teams
        .filter((e) => e.id === Number(data))
        .flatMap((el) => el?.manager?.id)[0];
      setSelectedManager(managerId !== undefined ? managerId : NO_MANAGER);
    }
    if (data === NO_TEAM) {
      setSelectedManager(NO_MANAGER);
    }
  }

  const selectedRoles = user && user.roles.map((r) => r.name);

  const initializeValues = (internalUserManagement, user, managers) => {
    if (internalUserManagement) {
      if (user) {
        return {
          firstName: user.firstName,
          lastName: user.lastName,
          title: user.title ? user.title : "",
          username: user.username,
          email: user.email,
          password: "",
          confirmPassword: "",
          managerId: selectedManager,
          /*  user.managerId &&
            managers.some((manager) => manager.id === user.managerId)
              ? user.managerId
              : user?.team && user?.team?.manager?.id
              ? user?.team?.manager?.id
              : SELECT_MANAGER, */
          userRoles: user.roles,
          active: user.active,
          limitedVisibility: user.limitedVisibility,
          teamId: user.team ? user.team.id : NO_TEAM,
          viewers: user.viewers ? user.viewers : user?.team?.viewers,
        };
      }
      return {
        firstName: "",
        lastName: "",
        title: "",
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        managerId: selectedManager ? selectedManager : SELECT_MANAGER,
        userRoles: [],
        active: true,
        limitedVisibility: false,
        teamId: NO_TEAM,
        viewers: [],
      };
    }
    return {
      managerId: selectedManager,
      /* user.managerId &&
        managers.some((manager) => manager.id === user.managerId)
          ? user.managerId
          : user?.team && user?.team?.manager?.id
          ? user?.team?.manager?.id
          : SELECT_MANAGER, */
      userRoles: user.roles,
      active: user.active,
      limitedVisibility: user.limitedVisibility,
      teamId: user.team ? user.team.id : NO_TEAM,
      viewers: user.viewers ? user.viewers : user?.team?.viewers,
    };
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <ConfigurationPropertiesContext.Consumer>
        {({ internalUserManagement }) => (
          <Formik
            enableReinitialize={true}
            initialValues={initializeValues(
              internalUserManagement,
              user,
              managers,
              { viewers: selectedViewers ? selectedViewers : [] }
            )}
            validationSchema={userValidationSchema(
              internalUserManagement,
              user,
              intl
            )}
            onSubmit={(values) => {
              if (!selectedManager) {
                values.managerId = null;
              } else {
                values.managerId =
                  selectedManager !== user?.id ? selectedManager : null;
              }
              if (!selectedTeam) {
                values.teamId = null;
              } else {
                values.teamId =
                  selectedTeam !== user?.team?.id ? selectedTeam : null;
              }
              if (
                values.managerId === SELECT_MANAGER ||
                values.managerId === NO_MANAGER
              )
                values.managerId = null;
              if (values.teamId === NO_TEAM) {
                values.teamId = null;
                // values.managerId = null;
              }

              if (!selectedViewers) values.viewers = [];
              else {
                values.viewers = selectedViewers?.map((element) => {
                  return {
                    id: element.value,
                    username: element.username,
                    email: element.email,
                    firstName: element.firstName,
                    lastName: element.lastName,
                    isTeamViewer: element.isTeamViewer
                      ? element.isTeamViewer
                      : false,
                    disabled: element.isTeamViewer
                      ? element.isTeamViewer
                      : false,
                    isDisabled: element.isTeamViewer
                      ? element.isTeamViewer
                      : false,
                  };
                });
              }

              handleFormSubmit(values);
              handleClose();
            }}
          >
            {({ values, handleBlur, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton />
                <Dialog style={{ width: "90%", maxWidth: "100%" }}>
                  <Body style={{ paddingBottom: "0" }}>
                    <Title>{`${
                      user ? editPlaceholder : addPlaceholder
                    } ${userPlaceholder}`}</Title>
                    <hr />
                    <Row>
                      <Col className="profile-picture-column" md={4}>
                        {user && (
                          <Image
                            className="profile-picture"
                            email={user.email}
                            rounded
                          />
                        )}
                      </Col>
                      <Col md={8}>
                        <Group as={Row}>
                          <Col>
                            <Label>
                              <FormattedMessage id="EditUserForm.firstName" />
                            </Label>
                            <Control
                              name="firstName"
                              type="text"
                              value={
                                internalUserManagement
                                  ? values.firstName
                                  : user.firstName
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={!internalUserManagement}
                            />
                            <ErrorMessage
                              render={(msg) => (
                                <div className="validation-error-msg">
                                  {" "}
                                  <ErrorIcon height="16px" width="16px" />{" "}
                                  <span style={{ marginLeft: "1%" }}>
                                    {" "}
                                    {msg}{" "}
                                  </span>
                                </div>
                              )}
                              name="firstName"
                            />
                          </Col>
                          <Col>
                            <Label>
                              <FormattedMessage id="EditUserForm.lastName" />
                            </Label>
                            <Control
                              name="lastName"
                              type="text"
                              value={
                                internalUserManagement
                                  ? values.lastName
                                  : user.lastName
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={!internalUserManagement}
                            />
                            <ErrorMessage
                              render={(msg) => (
                                <div className="validation-error-msg">
                                  {" "}
                                  <ErrorIcon height="16px" width="16px" />{" "}
                                  <span style={{ marginLeft: "1%" }}>
                                    {" "}
                                    {msg}{" "}
                                  </span>
                                </div>
                              )}
                              name="lastName"
                            />
                          </Col>
                        </Group>
                        {internalUserManagement && (
                          <Group as={Row}>
                            <Col>
                              <Label>
                                <FormattedMessage id="EditUserForm.positionTitle" />
                              </Label>
                              <Control
                                name="title"
                                type="text"
                                value={values.title}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col>
                          </Group>
                        )}
                        <Group as={Row}>
                          <Col md={5}>
                            <Label>
                              <FormattedMessage id="EditUserForm.username" />
                            </Label>
                            <Control
                              name="username"
                              type="text"
                              value={
                                internalUserManagement
                                  ? values.username
                                  : user.username
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={!internalUserManagement}
                            />
                            <ErrorMessage
                              render={(msg) => (
                                <div className="validation-error-msg">
                                  {" "}
                                  <ErrorIcon height="16px" width="16px" />{" "}
                                  <span style={{ marginLeft: "1%" }}>
                                    {" "}
                                    {msg}{" "}
                                  </span>
                                </div>
                              )}
                              name="username"
                            />
                          </Col>
                          <Col md={7}>
                            <Label>
                              <FormattedMessage id="EditUserForm.email" />
                            </Label>
                            <Control
                              name="email"
                              type="email"
                              value={
                                internalUserManagement
                                  ? values.email
                                  : user.email
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={!internalUserManagement}
                            />
                            <ErrorMessage
                              render={(msg) => (
                                <div className="validation-error-msg">
                                  {" "}
                                  <ErrorIcon height="16px" width="16px" />{" "}
                                  <span style={{ marginLeft: "1%" }}>
                                    {" "}
                                    {msg}{" "}
                                  </span>
                                </div>
                              )}
                              name="email"
                            />
                          </Col>
                        </Group>
                        {internalUserManagement && (
                          <Group as={Row}>
                            <Col>
                              <Label>
                                <FormattedMessage id="EditUserForm.password" />
                              </Label>
                              <Control
                                name="password"
                                type="password"
                                value={values.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="validation-error-msg">
                                    {" "}
                                    <ErrorIcon
                                      height="16px"
                                      width="16px"
                                    />{" "}
                                    <span style={{ marginLeft: "1%" }}>
                                      {" "}
                                      {msg}{" "}
                                    </span>
                                  </div>
                                )}
                                name="password"
                              />
                            </Col>
                            <Col>
                              <Label>
                                <FormattedMessage id="EditUserForm.confirmPassword" />
                              </Label>
                              <Control
                                name="confirmPassword"
                                type="password"
                                value={values.confirmPassword}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={values.password.length === 0}
                              />
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="validation-error-msg">
                                    {" "}
                                    <ErrorIcon
                                      height="16px"
                                      width="16px"
                                    />{" "}
                                    <span style={{ marginLeft: "1%" }}>
                                      {" "}
                                      {msg}{" "}
                                    </span>
                                  </div>
                                )}
                                name="confirmPassword"
                              />
                            </Col>
                          </Group>
                        )}
                        <Group>
                          <Label>
                            <FormattedMessage id="EditUserForm.teams" />
                          </Label>

                          <Control
                            name="teamId"
                            as="select"
                            value={selectedTeam}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              handleSelectedTeam(e.target.value);
                            }}
                          >
                            <option>{NO_TEAM}</option>
                            {teams.map((t) => (
                              <option key={t.id} value={t.id}>
                                {t.name}
                              </option>
                            ))}
                          </Control>
                        </Group>
                        <Group>
                          <Label>
                            <FormattedMessage id="EditUserForm.manager" />
                          </Label>
                          <Control
                            name="managerId"
                            as="select"
                            value={values.managerId}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              handleSelectedManager(e.target.value);
                            }}
                          >
                            <option disabled>{SELECT_MANAGER}</option>
                            {selectedManager !== SELECT_MANAGER && (
                              <option>{NO_MANAGER}</option>
                            )}

                            {managers.map((manager) => (
                              <option key={manager.id} value={manager.id}>
                                {`${manager.firstName} ${manager.lastName}`}
                              </option>
                            ))}
                          </Control>
                        </Group>

                        <Group>
                          <Label>
                            <FormattedMessage id="EditUserForm.viewer" />
                          </Label>
                          <Select
                            defaultValue={noViewersPlaceholder}
                            isMulti
                            name="viewers"
                            options={viewers.map((element) => {
                              return {
                                value: element.id,
                                label:
                                  element.firstName + " " + element.lastName,
                                firstName: element.firstName,
                                lastName: element.lastName,
                                email: element.email,
                                username: element.username,
                                isTeamViewer: element.isTeamViewer
                                  ? element.isTeamViewer
                                  : false,
                                disabled: element.isTeamViewer
                                  ? element.isTeamViewer
                                  : false,
                                isDisabled: element.isTeamViewer
                                  ? element.isTeamViewer
                                  : false,
                              };
                            })}
                            className="viewers-multi-select"
                            classNamePrefix="select"
                            value={selectedViewers}
                            onChange={handleSelect}
                            isClearable={
                              !selectedViewers?.find((v) => v?.isTeamViewer)
                            }
                            isOptionDisabled={(option) =>
                              option?.isTeamViewer === true
                            }
                            styles={selectStyles}
                            components={{
                              MultiValueRemove,
                            }}
                          />
                        </Group>
                      </Col>
                    </Row>
                    <Group as={Row} className="roles">
                      <Col>
                        <Label className="role-label">
                          <FormattedMessage id="EditUserForm.roles" />
                        </Label>
                        <FieldArray
                          name="userRoles"
                          render={(arrayHelpers) => {
                            let activeRoles = values.userRoles.map(
                              (r) => r.name
                            );
                            return (
                              <div className="roles-scrolable">
                                {Object.values(roles)
                                  .sort((r1, r2) => {
                                    if (
                                      selectedRoles &&
                                      selectedRoles.includes(r1.name) &&
                                      !selectedRoles.includes(r2.name)
                                    ) {
                                      return -1;
                                    } else if (
                                      selectedRoles &&
                                      !selectedRoles.includes(r1.name) &&
                                      selectedRoles.includes(r2.name)
                                    ) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                  .map((role) => (
                                    <Check
                                      id={role.name}
                                      key={role.name}
                                      type="switch"
                                      label={getRoleName(role)}
                                      value={role.name}
                                      checked={activeRoles.includes(role.name)}
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          arrayHelpers.push({
                                            name: role.name,
                                          });
                                        } else {
                                          const idx = activeRoles.indexOf(
                                            role.name
                                          );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />
                                  ))}
                              </div>
                            );
                          }}
                        />
                      </Col>
                    </Group>
                  </Body>
                </Dialog>
                <Row>
                  <Group
                    as={Col}
                    style={{ marginLeft: "5%", textAlign: "left" }}
                  >
                    <Check
                      id="active"
                      type="switch"
                      label={<FormattedMessage id="EditUserForm.active" />}
                      checked={values.active}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Group>
                  <Group as={Col} style={{ textAlign: "left" }}>
                    <Check
                      id="limitedVisibility"
                      type="switch"
                      label={
                        <FormattedMessage id="EditUserForm.limitedVisibility" />
                      }
                      checked={values.limitedVisibility}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Group>
                  <Group
                    as={Col}
                    style={{ marginRight: "5%", textAlign: "right" }}
                  >
                    <Button
                      variant="secondary"
                      className="mr-1"
                      onClick={handleClose}
                    >
                      <FormattedMessage id="EditUserForm.cancel" />
                    </Button>
                    <Button type="submit" variant="primary">
                      <FormattedMessage id="EditUserForm.submit" />
                    </Button>
                  </Group>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </ConfigurationPropertiesContext.Consumer>
    </>
  );
};

const userValidationSchema = (internalUserManagement, user, intl) =>
  internalUserManagement
    ? Yup.object({
        firstName: Yup.string().required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
        lastName: Yup.string().required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
        username: Yup.string().required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
        email: Yup.string().required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
        password: user
          ? Yup.string().matches(
              /^(|\w{4,})$/,
              intl.formatMessage({
                id: "ForgotPassword.password.length",
              })
            )
          : Yup.string()
              .required(
                intl.formatMessage({
                  id: "UserAuthenticationForm.validation.error",
                })
              )
              .min(
                4,
                intl.formatMessage({
                  id: "ForgotPassword.password.length",
                })
              ),
        confirmPassword: Yup.string().when("password", {
          is: (value) => (value && value.length > 0 ? true : false),
          then: Yup.string()
            .required(
              intl.formatMessage({
                id: "UserAuthenticationForm.validation.error",
              })
            )
            .oneOf(
              [Yup.ref("password")],
              intl.formatMessage({
                id: "ForgotPassword.password.match",
              })
            ),
        }),
      })
    : null;

export default EditUserForm;
