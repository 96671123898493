import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/manager";

const ManagerService = {
  getManagers: () =>
    authFetchJSON(`${BASE_URL}/all`).then((response) => response.json()),

  getActiveManagers: () =>
    authFetchJSON(`${BASE_URL}/all?active=1`).then((response) =>
      response.json()
    ),

  getManagersOverview: (managerId, active) =>
    authFetchJSON(`${BASE_URL}/${managerId}/managers?active=${active}`).then(
      (response) => response.json()
    ),

  getUsersByManagerId: (managerId, query, performanceReviewId) =>
    authFetchJSON(
      `${BASE_URL}/${managerId}/users-for-review?${
        query && performanceReviewId
          ? `queryString=${query}&performance_review_id=${performanceReviewId}`
          : ``
      }`
    ).then((response) => response.json()),

  getProcessPerUserByManagerIdAndPerformanceReviewId: (
    managerId,
    performanceReviewId
  ) =>
    authFetchJSON(
      `${BASE_URL}/${managerId}/process-users?performance_review_id=${performanceReviewId}`
    ).then((response) => response.json()),
};

export default ManagerService;
