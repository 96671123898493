import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
// import { FormattedMessage } from "react-intl";
// import ManagerGradeSelector from "../manager/feedback/ManagerGradeSelector";
import OneOnOneFeedbackWithScoreRow from "./OneOnOneFeedbackWithScoreRow";

const OneOnOneFeedbackQuestionRow = ({
  question,
  setOneOnOneFeedback,
  feedback,
  viewOnly,
  grades,
}) => {
  const [comment, setComment] = useState(feedback ? feedback?.answer : "");

  const onUpdate = (comment) => {
    if (question?.hasScore) {
      setComment("");
    } else {
      setComment(comment);
    }
    setOneOnOneFeedback(question.id, comment, question.mandatory);
  };

  return (
    <>
      <>
        {question?.hasScore ? (
          <OneOnOneFeedbackWithScoreRow
            question={question}
            setOneOnOneFeedback={setOneOnOneFeedback}
            feedback={feedback}
            viewOnly={viewOnly}
            grades={grades}
          />
        ) : (
          <>
            <Row
              className="appraisal-preview-row appraisal-row content"
              style={{
                display: "flex",
                margin: viewOnly && "1%",
                flexDirection: "column",
              }}
            >
              <Col>
                <h5>
                  {question?.content} {question?.mandatory && "*"}
                </h5>
              </Col>
              <br />
              <Col>
                <Form.Control
                  className="text-area"
                  as="textarea"
                  rows={comment?.length > 250 ? 5 : 1}
                  maxLength={4000}
                  value={comment || ""}
                  onChange={(event) => {
                    setComment(comment);
                    onUpdate(event.target.value);
                  }}
                  disabled={viewOnly}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    </>
  );
};

export default OneOnOneFeedbackQuestionRow;
