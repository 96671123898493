import React from "react";
import { Modal, Row } from "react-bootstrap";
import {
  PROCESS_CLOSE_MODE,
  PROCESS_OPEN_MODE,
} from "../../constants/processModes";
import YesModalBtn from "../common/buttons/YesModalBtn";
import NoModalBtn from "../common/buttons/NoModalBtn";
import { useIntl } from "react-intl";
const CloseAndReopenProcessModal = (props) => {
  const intl = useIntl();

  const areYouSurePlaceholder = intl.formatMessage({
    id: "CloseReopen.areYouSure",
  });
  const reopenPlaceholder = intl.formatMessage({
    id: "CloseReopen.reopen",
  });
  const closePlaceholder = intl.formatMessage({
    id: "CloseReopen.close",
  });
  const processPlaceholder = intl.formatMessage({
    id: "Processes.process",
  });
  return (
    <Modal show={props.showModal} onHide={props.handleCloseModal} centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <Row className="justify-content-center my-4">
          <h5
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "center",
            }}
          >
            {`${areYouSurePlaceholder} ${
              props.mode === PROCESS_CLOSE_MODE
                ? closePlaceholder
                : props.mode === PROCESS_OPEN_MODE
                ? reopenPlaceholder
                : "-"
            } ${processPlaceholder} '${props.process?.title}'?`}
          </h5>
        </Row>
        <Row className="justify-content-center">
          <YesModalBtn
            variant="secondary"
            onClick={() => {
              props.handleSubmit(props.process);
            }}
          />
          <NoModalBtn variant="primary" onClick={props.handleCloseModal} />
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CloseAndReopenProcessModal;
