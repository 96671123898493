import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { ToastContainer } from "react-toastr";
import Select from "react-select";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BAD_REQUEST_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../../constants/errorStatusCodes";
import {
  ONE_ON_ONE_QUESTION_CREATE_MODE,
  ONE_ON_ONE_QUESTION_EDIT_MODE,
  ONE_ON_ONE_QUESTION_DELETE_MODE,
} from "../../../constants/oneOnOneQuestionsModes";
import Icon, { ICONS } from "../../../icons/Icon";
import OneOnOnesService from "../../../services/OneOnOnesService";
import OneOnOneFeedbackQuestionsService from "../../../services/OneOnOneFeedbackQuestionsService";
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../util/toastUtils";
import Loading from "../../common/display/Loading";
import Pagination from "../Pagination";

import "./one-on-one-questions.css";
import OneOnOneQuestionsModal from "./OneOnOneQuestionsModal";
import OneOnOneQuestionsTable from "./OneOnOneQuestionsTable";

let toastContainer;

const OneOnOneFeedbackQuestionsContainer = ({ user }) => {
  const intl = useIntl();

  const [types, setTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [oneOnOneFeedbackQuestionss, setOneOnOneFeedbackQuestions] = useState(
    []
  );
  const [pages, setPages] = useState();

  // eslint-disable-next-line no-unused-vars
  const [sortBy, setSortBy] = useState("id");
  // eslint-disable-next-line no-unused-vars
  const [sortDir, setSortDir] = useState("desc");
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [type, setType] = useState();
  // eslint-disable-next-line no-unused-vars
  const [content, setContent] = useState("");
  const [hasScore, setHasScore] = useState();
  const [active, setActive] = useState(true);
  const [mandatory, setMandatory] = useState();
  const [resetAvailable, setResetAvailable] = useState(false);

  const [mode, setMode] = useState(undefined);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);

  const paginationClickHandler = (event) => {
    if (event.target.text === undefined) {
      return;
    } else {
      setCurrentPage(parseInt(event.target.text) - 1);
    }
  };

  const handleOpenModal = (mode, question) => {
    setMode(mode);
    setSelectedQuestion(question);
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setMode(undefined);
    setSelectedQuestion(undefined);
    setShowCreateModal(false);
  };

  const fetchTypes = () => {
    Promise.all([OneOnOnesService.getTypes()]).then(([types]) => {
      setTypes(types);
    });
  };

  const fetchQuestions = (
    page,
    sortBy,
    sortDir,
    pageSize,
    type,
    content,
    hasScore,
    active,
    mandatory
  ) => {
    Promise.all([
      OneOnOneFeedbackQuestionsService.searchQuestions(
        page,
        sortBy,
        sortDir,
        pageSize,
        type,
        content,
        hasScore,
        active,
        mandatory
      ),
    ]).then(([oneOnOneFeedbackQuestions]) => {
      setOneOnOneFeedbackQuestions(
        oneOnOneFeedbackQuestions?.oneOnOneFeedbackQuestions
      );
      setPages(oneOnOneFeedbackQuestions?.pages);
    });
  };

  useEffect(() => {
    // if (isLoading) {
    Promise.all([
      fetchTypes(),
      fetchQuestions(
        currentPage,
        sortBy,
        sortDir,
        pageSize,
        type,
        content,
        hasScore,
        active,
        mandatory
      ),
    ]).then(() => setIsLoading(false));
    //  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    sortBy,
    sortDir,
    pageSize,
    type,
    content,
    hasScore,
    active,
    mandatory,
    isLoading,
  ]);

  const handleSubmit = async (question) => {
    setIsLoading(true);
    if (mode === ONE_ON_ONE_QUESTION_CREATE_MODE) {
      await OneOnOneFeedbackQuestionsService.addQuestion(question).then(
        (response) => {
          if (response.ok) {
            response.json().then(() => {
              fetchQuestions(
                currentPage,
                sortBy,
                sortDir,
                pageSize,
                type,
                content,
                hasScore,
                active,
                mandatory
              );
              showSuccessNotification(
                toastContainer,
                <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.success.saved" />
              );
            });
          } else if (response.status === FORBIDDEN_ERROR_CODE) {
            showErrorNotification(
              toastContainer,
              <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.noRights" />
            );
          } else if (response.status === BAD_REQUEST_ERROR_CODE) {
            response
              .json()
              .then((error) => showErrorNotification(`${error.message}`));
          }
          setIsLoading(false);
        }
      );
    } else if (mode === ONE_ON_ONE_QUESTION_EDIT_MODE) {
      await OneOnOneFeedbackQuestionsService.updateQuestion(
        selectedQuestion?.id,
        question
      ).then((response) => {
        if (response.ok) {
          response.json().then(() => {
            fetchQuestions(
              currentPage,
              sortBy,
              sortDir,
              pageSize,
              type,
              content,
              hasScore,
              active,
              mandatory
            );
            showSuccessNotification(
              toastContainer,
              <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.update.saved" />
            );
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          showErrorNotification(
            toastContainer,
            <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.noRights" />
          );
        } else if (response.status === BAD_REQUEST_ERROR_CODE) {
          response
            .json()
            .then((error) => showErrorNotification(`${error.message}`));
        }
        setIsLoading(false);
      });
    } else if (mode === ONE_ON_ONE_QUESTION_DELETE_MODE) {
      await OneOnOneFeedbackQuestionsService.deleteQuestion(
        selectedQuestion?.id
      ).then((response) => {
        if (response.ok) {
          // response.json().then(() => {
          fetchQuestions(
            currentPage,
            sortBy,
            sortDir,
            pageSize,
            type,
            content,
            hasScore,
            active,
            mandatory
          );
          showSuccessNotification(
            toastContainer,
            <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.delete.saved" />
          );
          // });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          showErrorNotification(
            toastContainer,
            <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.noRights" />
          );
        } else if (response.status === BAD_REQUEST_ERROR_CODE) {
          response
            .json()
            .then((error) => showErrorNotification(`${error.message}`));
        }
        setIsLoading(false);
      });
    }
  };

  const handleReset = () => {
    setResetAvailable(false);
    setActive(true);
    setMandatory();
    setHasScore();
    setType(null);
    setIsLoading(true);
  };

  const setTypeHandler = (value) => {
    setCurrentPage(0);
    setType(value);
    setResetAvailable(true);
    setIsLoading(true);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Container fluid>
        <ToastContainer
          ref={(ref) => (toastContainer = ref)}
          className="toast-top-right"
        />
        <Row style={{ padding: 0, marginTop: "2%" }}>
          <Form.Group as={Col} className="col-md-2">
            <Select
              value={type}
              options={types?.map((element) => {
                return {
                  value: element.id,
                  label: element.type,
                };
              })}
              onChange={setTypeHandler}
              className="type-select"
              classNamePrefix="select"
              placeholder={intl.formatMessage({
                id: "SetOneOnOneForm.type.default",
              })}
              name="type"
              isClearable
            />
          </Form.Group>
          <Form.Group
            as={Col}
            className="col-md-2"
            style={{ marginLeft: "5%", textAlign: "left" }}
          >
            <Form.Check
              id="active"
              type="switch"
              label={
                <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.active" />
              }
              checked={active}
              onBlur={(event) => event.target.blur()}
              onChange={(e) => {
                setActive(e.target.checked);
                setResetAvailable(true);
                setIsLoading(true);
              }}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            className="col-md-2"
            style={{ textAlign: "left" }}
          >
            <Form.Check
              id="mandatory"
              type="switch"
              label={
                <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.mandatory" />
              }
              checked={mandatory}
              onBlur={(event) => event.target.blur()}
              onChange={(e) => {
                setMandatory(e.target.checked);
                setResetAvailable(true);
                setIsLoading(true);
              }}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            className="col-md-2"
            style={{ textAlign: "left" }}
          >
            <Form.Check
              id="hasScore"
              type="switch"
              label={
                <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.hasScore" />
              }
              checked={hasScore}
              onBlur={(event) => event.target.blur()}
              onChange={(e) => {
                setHasScore(e.target.checked);
                setResetAvailable(true);
                setIsLoading(true);
              }}
              title={intl.formatMessage({
                id: "OneOnOneFeedbackQuestionsContainer.hasScore.tooltip",
              })}
            />
          </Form.Group>
          <Button
            className="reset"
            variant="link"
            size="sm"
            onClick={handleReset}
            disabled={!resetAvailable}
            style={{ height: "30px", fontSize: "1.1rem", paddingTop: 0 }}
          >
            <Icon icon={ICONS.RESET} />
            <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.button.reset" />
          </Button>
          <Button
            variant="link"
            size="sm"
            style={{
              height: "30px",
              fontSize: "1.1rem",
              paddingTop: 0,
              marginLeft: "2%",
            }}
            onClick={(event) => {
              handleOpenModal(ONE_ON_ONE_QUESTION_CREATE_MODE);
              event.target.blur();
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            {<FormattedMessage id="Button.add" />}
          </Button>
        </Row>
        <Row className="mt-4">
          <Col>
            <header className="title text-center">
              <h4 className="processes-header">
                <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.title" />
              </h4>
            </header>
          </Col>
        </Row>
        {oneOnOneFeedbackQuestionss.length === 0 ? (
          <h4 className="title text-center">
            <FormattedMessage id="OneOnOneContainer.notFound" />
          </h4>
        ) : (
          <>
            <Row>
              <OneOnOneQuestionsTable
                questions={oneOnOneFeedbackQuestionss}
                handleOpenModal={handleOpenModal}
              />
            </Row>
            {pages > 0 && (
              <Pagination
                style={{ marginTop: "2%", position: "relative" }}
                currentPage={currentPage}
                paginationClickHandler={paginationClickHandler}
                setCurrentPage={setCurrentPage}
                pages={pages}
              />
            )}
          </>
        )}
      </Container>
      <OneOnOneQuestionsModal
        showModal={showCreateModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        mode={mode}
        types={types}
        loading={isLoading}
        question={selectedQuestion}
      />
    </>
  );
};

export default OneOnOneFeedbackQuestionsContainer;
