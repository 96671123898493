import React, { useEffect, useState } from "react";
import AppraisalService from "../../services/AppraisalService";
import AuthenticationService from "../../services/AuthenticationService";
import Loading from "./display/Loading";
import ErrorComponent from "./ErrorComponent";
import {
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from "../../constants/errorStatusCodes";

const WithAssessorProvedAndPermission = (props) => {
  const [appraisal, setAppraisal] = useState();
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState(true);

  useEffect(() => {
    AppraisalService.getAppraisalById(props.match.params.id)
      .then((appraisal) => {
        setAppraisal(appraisal);
        setFound(true);
        setLoading(false);
        setAllowed(
          AuthenticationService.hasPermission(props.user, props.permission) &&
            props.user.isManager
        );
      })
      .catch(() => setLoading(false));
  }, [props]);

  if (loading) {
    return <Loading />;
  } else if (props.user && !found) {
    return (
      <ErrorComponent statusCode={NOT_FOUND_ERROR_CODE} user={props.user} />
    );
  } else if (
    props.user &&
    props.user.id !== appraisal.managerId &&
    props.user.id !== appraisal.targetEmployeeId
  ) {
    return (
      <ErrorComponent statusCode={FORBIDDEN_ERROR_CODE} user={props.user} />
    );
  } else if (
    props.user &&
    props.user.id !== appraisal.managerId &&
    props.user.id !== appraisal.targetEmployeeId
  ) {
    return (
      <ErrorComponent statusCode={FORBIDDEN_ERROR_CODE} user={props.user} />
    );
  } else if (allowed === false) {
    return (
      <ErrorComponent statusCode={FORBIDDEN_ERROR_CODE} user={props.user} />
    );
  }
  return props.children;
};

export default WithAssessorProvedAndPermission;
