import React, { useState } from "react";
import { Table } from "react-bootstrap";
import TeamDataRow from "./TeamDataRow";
import "./teams.css";
import { FormattedMessage } from "react-intl";
const Teams = ({ teams }) => {
  const [hoveredTeam, setHoveredTeam] = useState();

  const onMouseOver = (team) => setHoveredTeam(team);

  const onMouseLeave = () => setHoveredTeam();

  return (
    <Table className="teams" borderless>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="Teams.team" />
          </th>
        </tr>
      </thead>
      <tbody>
        {teams.map((team, index) => (
          <TeamDataRow
            key={`team-row-${index}`}
            hoveredTeam={hoveredTeam}
            team={team}
            onMouseOver={() => onMouseOver(team)}
            onMouseLeave={onMouseLeave}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default Teams;
