import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { PERMISSION_SELF_ASSESS } from "../../constants/permissions";
import {
  MANAGER as MANAGER_ROLE,
  HR as HR_ROLE,
  EMPLOYEE as EMPLOYEE_ROLE,
} from "../../constants/roles";

import OneOnOnesService from "../../services/OneOnOnesService";
import AuthenticationService from "../../services/AuthenticationService";
import Loading from "../common/display/Loading";
import Filter from "../common/Filter";
import "react-datepicker/dist/react-datepicker.css";
import "./one-on-one.css";
import OneOnOneContainer from "./OneOnOneContainer";

const DEFAULT_FILTER_ITEMS = (user) => [
  (AuthenticationService.hasRole(user, EMPLOYEE_ROLE) ||
    AuthenticationService.hasPermission(user, PERMISSION_SELF_ASSESS)) && {
    value: "employee",
    title: <FormattedMessage id="OneOnOneContainer.employee" />,
  },
  AuthenticationService.hasRole(user, MANAGER_ROLE) && {
    value: "manager",
    title: <FormattedMessage id="OneOnOneContainer.manager" />,
  },
  AuthenticationService.hasRole(user, HR_ROLE) && {
    value: "hr",
    title: <FormattedMessage id="OneOnOneContainer.hr" />,
  },
];

const OneOnOneByRoleContainer = ({ user }) => {
  const history = useHistory();
  const location = useLocation();

  const [filterItems, setFilterItems] = useState(DEFAULT_FILTER_ITEMS(user));
  const [selectedFilterItem, setSelectedFilterItem] = useState(
    location.state?.selectedFilterItem || DEFAULT_FILTER_ITEMS(user)[0]
  );
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [sortBy, setSortBy] = useState("id");
  // eslint-disable-next-line no-unused-vars
  const [sortDir, setSortDir] = useState("desc");
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [name, setName] = useState("");
  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [meetingDate, setMeetingDate] = useState(null);

  const [byEmployee, setByEmployee] = useState(true);

  const handleChange = (value) => {
    setSelectedFilterItem(filterItems.find((item) => item.value === value));
    setCurrentPage(0);
    setName("");
    setType(null);
    setStatus(null);
    setMeetingDate(null);
    setIsLoading(true);
  };

  const fetchOneOnOnes = (
    userId,
    closeable,
    page,
    sortBy,
    sortDir,
    pageSize,
    name,
    type,
    status,
    meetingDate,
    byEmployee,
    activeTab
  ) => {
    Promise.all([
      OneOnOnesService.searchOneOnOnes(
        userId,
        closeable,
        page,
        sortBy,
        sortDir,
        pageSize,
        name,
        type,
        status,
        meetingDate,
        byEmployee,
        activeTab
      ),
    ]).then(([oneOnOnes]) => {
      setFilterItems([
        (AuthenticationService.hasRole(user, EMPLOYEE_ROLE) ||
          AuthenticationService.hasPermission(
            user,
            PERMISSION_SELF_ASSESS
          )) && {
          value: "employee",
          title: <FormattedMessage id="OneOnOneContainer.employee" />,
        },
        AuthenticationService.hasRole(user, MANAGER_ROLE) && {
          value: "manager",
          title: <FormattedMessage id="OneOnOneContainer.manager" />,
        },
        AuthenticationService.hasRole(user, HR_ROLE) && {
          value: "hr",
          title: <FormattedMessage id="OneOnOneContainer.hr" />,
        },
      ]);
    });
  };

  useEffect(() => {
    if (selectedFilterItem?.value) {
      saveFilterValue(selectedFilterItem.value);
      setIsLoading(false);
    }

    Promise.all([
      fetchOneOnOnes(
        user.id,
        true,
        currentPage,
        sortBy,
        sortDir,
        pageSize,
        name,
        type,
        status,
        meetingDate,
        byEmployee,
        selectedFilterItem?.value
      ),
    ]).then(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    currentPage,
    sortBy,
    sortDir,
    pageSize,
    name,
    type,
    status,
    meetingDate,
    isLoading,
    selectedFilterItem,
    byEmployee,
  ]);

  const saveFilterValue = (filterValue) => {
    setByEmployee(filterValue === "employee");
    setIsLoading(true);
    history.replace(history.location.pathname, {
      selectedFilterItem: { value: filterValue ? filterValue : 0 },
    });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Filter
        className="oneOnOneByRole"
        items={filterItems}
        value={selectedFilterItem?.value}
        onChange={handleChange}
      />
      <OneOnOneContainer
        user={user}
        selectedFilterItem={selectedFilterItem}
        byEmployee={byEmployee}
        activeTab={selectedFilterItem?.value}
      />
    </>
  );
};

export default OneOnOneByRoleContainer;
