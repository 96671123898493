import React from "react";
import { Col, Table } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { FormattedMessage } from "react-intl";
import { getRoleName } from "../../util/roleName";
import Image from "../common/Image";

import "./team-employees.css";

const TeamEmployees = ({ employees }) => {
  return employees.length > 0 ? (
    <Table className="team-employees" borderless>
      <thead>
        <tr>
          <th colSpan={2}>
            <FormattedMessage id="UsersTable.name" />
          </th>
          <th>
            <FormattedMessage id="UsersTable.role" />
          </th>
          <th>
            <FormattedMessage id="UsersTable.manager" />
          </th>
        </tr>
      </thead>
      <tbody>
        {employees.map((employee, index) => (
          <tr
            key={`employee-row-${index}`}
            className={!employee.active && "inactive"}
          >
            <td className="profile-img">
              <LazyLoad height={50} offset={100}>
                <Image email={employee.email} roundedCircle />
              </LazyLoad>
            </td>
            <td>
              <div className="employee-name">{`${employee.firstName} ${employee.lastName}`}</div>
              <div className="employee-position">{employee.title}</div>
              <div className="employee-email">{employee.email}</div>
            </td>
            <td>
              {employee.roles.map((role, index) => (
                <div key={index}>{getRoleName(role)}</div>
              ))}
            </td>
            <td>
              {employee.managerName ? (
                <div>{employee.managerName}</div>
              ) : (
                <div> --</div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <Col className="text-center ">
      <h4>
        <FormattedMessage id="Manager.noEmployeesToShow" />
      </h4>
    </Col>
  );
};

export default TeamEmployees;
