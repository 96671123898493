import React from "react";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import { CircularProgressbar } from "react-circular-progressbar";
import { GOAL_GRADE } from "../../constants/gradeDictionary";

const ScoreCircle = ({ score, date }) => {
  const [color] = React.useState(() => {
    if (score >= 4) {
      return "var(--primary-dark)";
    }
    if (score >= 3 && score < 4) {
      return "var(--primary-blue-circle)";
    } else {
      return "var(--primary-light)";
    }
  });

  return (
    <ConfigurationPropertiesContext.Consumer>
      {({ formatDate }) => (
        <div
          style={{
            width: "3.75rem",
            margin: "auto",
          }}
        >
          {score ? (
            <CircularProgressbar
              className=""
              value={(score / GOAL_GRADE) * 100}
              text={score}
              strokeWidth={7}
              styles={{
                path: {
                  stroke: color,
                },
              }}
            />
          ) : (
            "--"
          )}
          {date && (
            <span
              style={{
                display: "block",
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {formatDate(date)}
            </span>
          )}
        </div>
      )}
    </ConfigurationPropertiesContext.Consumer>
  );
};

export default ScoreCircle;
