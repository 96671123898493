import React from "react";
import UserService from "../../services/UserService";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import { Button, Col, Container, Row } from "react-bootstrap";
import { PROCESS_USER_ACTION_APPRAISAL } from "../../constants/processUserAction";
import GoalTemplateGroupService from "../../services/GoalTemplateGroupService";
import { APPRAISAL_STATUS_CLOSED } from "../../constants/appraisalStatus";
import Loading from "../common/display/Loading";
import {
  APPRAISAL_TYPE_INTERMEDIATE,
  APPRAISAL_TYPE_FINAL,
} from "../../constants/appraisalType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import ErrorComponent from "../common/ErrorComponent";
import { BAD_REQUEST_ERROR_CODE } from "../../constants/errorStatusCodes";
import { capitalizeWord } from "../../util/stringUtils";
import getHomeLink from "../../util/homeLink";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import { withRouter } from "react-router-dom";
import Image from "../common/Image";
import "./postFinalAssessment.css";
import { FormattedMessage } from "react-intl";
class PostFinalAssessment extends React.Component {
  state = {
    processUser: undefined,
    appraisalType: undefined,
    goalTemplateGroupTopics: [],
    organizationalGoalTemplates: [],
    showModal: false,
    found: false,
    loading: true,
  };

  componentDidMount() {
    UserService.getCurrentProcessUserByUserId(this.props.match.params.id)
      .then((processUser) =>
        processUser.action === PROCESS_USER_ACTION_APPRAISAL
          ? Promise.all([
              PerformanceReviewGoalService.getCurrentPerformanceReviewGoalByUserId(
                processUser.user.id
              ),
              GoalTemplateGroupService.getTopicsByOwnerId(this.props.user.id),
              GoalTemplateGroupService.getTopicsWithoutOwner(),
            ]).then(
              ([
                performanceReviewGoal,
                topics,
                organizationalGoalTemplates,
              ]) => {
                processUser.performanceReviewGoal = performanceReviewGoal;
                this.setState({
                  processUser,
                  appraisalType: processUser.appraisal.type,
                  goalTemplateGroupTopics: topics,
                  organizationalGoalTemplates,
                  found: true,
                  loading: false,
                });
              }
            )
          : this.setState({ found: false, loading: false })
      )
      .catch(() => this.setState({ found: false, loading: false }));
  }

  goToManagerView = () => this.props.history.push(getHomeLink(this.props.user));

  render() {
    return this.state.loading ? (
      <Loading />
    ) : this.state.found &&
      this.state.processUser.appraisal.status === APPRAISAL_STATUS_CLOSED ? (
      <ConfigurationPropertiesContext.Consumer>
        {({ formatDate }) => (
          <Container className="post-final" fluid>
            <Row className="mb-5">
              <Col>
                <header className="text-center">
                  <h4>
                    <FormattedMessage id="Appraisal.status" />
                  </h4>
                </header>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col md={4}>
                    <Image
                      email={this.state.processUser.user.email}
                      thumbnail
                    />
                  </Col>
                  <Col md={8}>
                    <h4>{`${this.state.processUser.user.firstName} ${this.state.processUser.user.lastName}`}</h4>
                    <h5>{this.state.processUser.user.title}</h5>
                    <h5>{`${capitalizeWord(
                      this.state.appraisalType
                    )} review`}</h5>
                    <h5>
                      {<FormattedMessage id="Reviews.period" />}
                      {`: ${formatDate(
                        this.state.processUser.process.performanceReview.start
                      )} - ${formatDate(
                        this.state.processUser.process.performanceReview.end
                      )}`}
                    </h5>
                    <h5>
                      {<FormattedMessage id="Appraisal.by" />}
                      {`${this.state.processUser.appraisal.managerName}`}
                    </h5>
                  </Col>
                </Row>
              </Col>
              <Col className="right">
                <Row className="mb-3">
                  <Col md={1} className="centered">
                    <FontAwesomeIcon icon={faCheck} size="2x" />
                  </Col>
                  <Col md={11} className="text">
                    <p>
                      <FormattedMessage id="Appraisal.finalAssesment2" />
                    </p>
                    {this.state.appraisalType === APPRAISAL_TYPE_FINAL && (
                      <article>
                        <p className="mt-3">
                          <FormattedMessage id="Appraisal.perfomnaceReviewProcessPeriod" />
                        </p>
                        <p>
                          {`${formatDate(
                            this.state.processUser.process.performanceReview
                              .start
                          )} - ${formatDate(
                            this.state.processUser.process.performanceReview.end
                          )} is over.`}
                        </p>
                      </article>
                    )}
                  </Col>
                </Row>
                {this.state.appraisalType === APPRAISAL_TYPE_INTERMEDIATE && (
                  <Row>
                    <Col md={1} className="centered">
                      <FontAwesomeIcon icon={faQuestionCircle} size="2x" />
                    </Col>
                    <Col md={11} className="text">
                      <p className="my-auto">
                        <FormattedMessage id="Appraisal.tweakGoals" />
                      </p>
                    </Col>
                  </Row>
                )}
                <Row className="mt-3 mb-4">
                  {(this.state.appraisalType ===
                    APPRAISAL_TYPE_INTERMEDIATE && (
                    <>
                      <Button
                        variant="success"
                        className="ml-auto"
                        onClick={() =>
                          this.props.history.push(
                            `/prg/${this.state.processUser.performanceReviewGoal.performanceReviewGoalId}/goals`
                          )
                        }
                      >
                        <p>
                          <FormattedMessage id="Appraisal.yes" />
                        </p>
                        <small>
                          <FormattedMessage id="Appraisal.goalEditor" />
                        </small>
                      </Button>
                      <Button
                        variant="outline-dark"
                        className="mr-auto"
                        onClick={this.goToManagerView}
                      >
                        <p className="my-auto">
                          <FormattedMessage id="Appraisal.no" />
                        </p>
                        <small>
                          <FormattedMessage id="Appraisal.sameGoals" />
                        </small>
                      </Button>
                    </>
                  )) ||
                    (this.state.appraisalType === APPRAISAL_TYPE_FINAL && (
                      <Col className="text-center">
                        <Button
                          variant="primary"
                          onClick={this.goToManagerView}
                        >
                          <p className="my-auto">
                            <FormattedMessage id="Appraisal.okay" />
                          </p>
                          <small>
                            {" "}
                            <FormattedMessage id="Appraisal.homePage" />
                          </small>
                        </Button>
                      </Col>
                    ))}
                </Row>
                {this.state.appraisalType === APPRAISAL_TYPE_INTERMEDIATE && (
                  <Row>
                    <Col md={1} className="centered">
                      <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                    </Col>
                    <Col md={11} className="text">
                      <small className="my-auto">
                        Please, note: if you choose not to modify the goals you
                        will not be able to do that later, by yourself. If you
                        change your mind later, please contact the People Team
                        staff in order to make the goals modifications
                      </small>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </ConfigurationPropertiesContext.Consumer>
    ) : (
      <ErrorComponent
        statusCode={BAD_REQUEST_ERROR_CODE}
        user={this.props.user}
      />
    );
  }
}

export default withRouter(PostFinalAssessment);
