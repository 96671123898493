import React from "react";
import { Col, Container, Row, Card, ListGroup } from "react-bootstrap";
import FeedbackGraph from "./FeedbackGraph";
import FeedbackCommentListV2 from "./FeedbackCommentListV2";
import "./FeedbackQuestionStatistic.css";

const FeedbackQuestionV2 = ({
  question,
  statistic,
  responses,
  anonymous,
  hr,
}) => {
  return (
    <Container
      style={{ minHeight: "0%", paddingTop: "0%", paddingBottom: "3%" }}
    >
      <Row>
        <Col md={12}>
          <Row>
            <Card>
              <Card.Body>
                <Card.Text>
                  <h4>{question}</h4>
                </Card.Text>
                <ListGroup
                  variant="flush"
                  style={{
                    borderRadius: "0%",
                    width: "50%",
                    float: "right",
                  }}
                >
                  <ListGroup.Item
                    style={{
                      borderBottom: "0",
                    }}
                  >
                    <FeedbackGraph type="bar" statistic={statistic} />
                  </ListGroup.Item>
                </ListGroup>
                <FeedbackCommentListV2
                  responses={responses}
                  anonymous={anonymous}
                  hr={hr}
                />
              </Card.Body>
            </Card>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FeedbackQuestionV2;
