import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Modal,
  Row,
} from "react-bootstrap";
import { ToastContainer } from "react-toastr";
import {
  faCheckCircle,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFileExport,
  faPlusCircle,
  faTrashAlt,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl, FormattedMessage } from "react-intl";
import PerformanceReviewService from "../../services/PerformanceReviewService";
import ProcessService from "../../services/ProcessService";
import ProcessServiceV2 from "../../services/ProcessServiceV2";
import Loading from "../common/display/Loading";
import ErrorComponent from "../common/ErrorComponent";
import {
  BAD_REQUEST_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import PROCESS_MODES, {
  PROCESS_ADD_PARTICIPANTS_MODE,
  PROCESS_CLOSE_MODE,
  PROCESS_CREATE_MODE,
  PROCESS_DELETE_MODE,
  PROCESS_OPEN_MODE,
  PROCESS_UPDATE_MODE,
} from "../../constants/processModes";
import {
  PROCESS_STATUS_CLOSED,
  PROCESS_STATUS_OPEN,
  PROCESS_STATUS_PENDING,
} from "../../constants/processStatus";
import { PERFORMANCE_REVIEW_COMPLETED } from "../../constants/performanceReviewStatus";
import Filter from "../common/Filter";
import Processes from "./Processes";
import ProcessModal from "./ProcessModal";
import CloseAndReopenProcessModal from "./CloseAndReopenProcessModal";
import DeleteConfirmation from "../common/DeleteConfirmation";
import AddOrRemoveUserModal from "../admin/users/AddOrRemoveUserModal";

const DEFAULT_SELECTED_FILTER_ITEM = {
  value: "open",
  title: <FormattedMessage id="Processes.tabs.open" />,
  count: 0,
};

const DEFAULT_FILTER_ITEMS = [
  {
    value: "any",
    title: <FormattedMessage id="Processes.tabs.any" />,
    count: 0,
  },
  {
    value: "pending",
    title: <FormattedMessage id="Processes.tabs.pending" />,
    count: 0,
  },
  DEFAULT_SELECTED_FILTER_ITEM,
  {
    value: "closed",
    title: <FormattedMessage id="Processes.tabs.closed" />,
    count: 0,
  },
];

let toastContainer;

const ProcessesContainer = ({ user }) => {
  const intl = useIntl();
  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();

  const [performanceReview, setPerformanceReview] = useState();
  const [period, setPeriod] = useState();
  // const [managers, setManagers] = useState([]);
  const [filterItems, setFilterItems] = useState(DEFAULT_FILTER_ITEMS);
  const [selectedFilterItem, setSelectedFilterItem] = useState(
    state?.selectedFilterItem || DEFAULT_SELECTED_FILTER_ITEM
  );
  const [processes, setProcesses] = useState([]);
  const [displayableProcesses, setDisplayableProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState();
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [showProcessDeleteModal, setShowProcessDeleteModal] = useState(false);
  const [showCloseOrReopenModal, setShowCloseOrReopenModal] = useState(false);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [mode, setMode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  /*   const titlePlaceholder = intl.formatMessage({
    id: "Roles.members",
  }); */

  useEffect(() => {
    saveFilterValue(selectedFilterItem.value);

    if (isLoading)
      Promise.all([
        PerformanceReviewService.getPerformanceReviewById(id),
        ProcessService.getPeriod(),
        // ManagerService.getActiveManagers(),
      ])
        .then(([fetchedPerformanceReview, fetchedPeriod, fetchedManagers]) => {
          setPerformanceReview(fetchedPerformanceReview);
          setPeriod(fetchedPeriod);
          // setManagers(fetchedManagers);

          fetchProcesses(fetchedPerformanceReview.id);
        })
        .catch(() => {
          setNotFound(true);
          setIsLoading(false);
        });
    else
      setDisplayableProcesses(
        filterProcesses(selectedFilterItem.value, processes)
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedFilterItem]);

  const saveFilterValue = (filterValue) =>
    history.replace(history.location.pathname, {
      selectedFilterItem: { value: filterValue },
    });

  const fetchProcesses = (performanceReviewId) =>
    ProcessServiceV2.getProcessesByPerformanceReviewId(
      performanceReviewId
    ).then((fetchedProcesses) =>
      Promise.all(fetchedProcesses.map(fetchProcessStats)).then(() => {
        DEFAULT_FILTER_ITEMS.forEach((item) =>
          setFilterItems(createFilterItems(item.value, fetchedProcesses))
        );
        fetchedProcesses.forEach(addActions);

        setProcesses(fetchedProcesses);
        setDisplayableProcesses(
          filterProcesses(selectedFilterItem.value, fetchedProcesses)
        );
        setIsLoading(false);
      })
    );

  const createFilterItems = (filterValue, fetchedProcesses) =>
    filterItems.map((item) => {
      if (item.value === filterValue)
        item.count =
          filterProcesses(filterValue, fetchedProcesses).length < 999
            ? filterProcesses(filterValue, fetchedProcesses).length
            : "1k+";
      return item;
    });

  const filterProcesses = (filterValue, fetchedProcesses) =>
    filterValue === "any"
      ? fetchedProcesses
      : fetchedProcesses.filter(
          (process) => process.status === filterValue.toUpperCase()
        );

  const fetchProcessStats = (process) =>
    ProcessService.getProcessStatsByProcessId(process.id).then(
      (stats) => (process.stats = stats)
    );

  const addActions = (process) =>
    Object.assign(process, {
      leftButtons: [
        process?.status !== PROCESS_STATUS_CLOSED && {
          icon: faEdit,
          label: <FormattedMessage id="Processes.tabs.edit" />,
          action: () => handleOpenModal(PROCESS_UPDATE_MODE, process),
        },
        process?.status !== PROCESS_STATUS_CLOSED && {
          icon: faTrashAlt,
          label: <FormattedMessage id="Processes.tabs.delete" />,
          action: () => handleOpenModal(PROCESS_DELETE_MODE, process),
        },
        process?.status === PROCESS_STATUS_OPEN && {
          icon: faTimesCircle,
          label: <FormattedMessage id="Processes.tabs.close" />,
          action: () => handleOpenModal(PROCESS_CLOSE_MODE, process),
        },
        process?.status === PROCESS_STATUS_CLOSED &&
          process?.performanceReview?.status !==
            PERFORMANCE_REVIEW_COMPLETED && {
            icon: faCheckCircle,
            label: <FormattedMessage id="Processes.tabs.reopen" />,
            action: () => handleOpenModal(PROCESS_OPEN_MODE, process),
          },
      ].filter((button) => button),
      rightButtons: [
        process.status !== PROCESS_STATUS_PENDING && {
          icon: faFileExport,
          label: <FormattedMessage id="Processes.tabs.export" />,
          action: () => handleExport(process),
        },
        {
          icon: faUsersCog,
          label: <FormattedMessage id="Processes.tabs.participants" />,
          action: () =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              state: { participants: "processes" },
            }),
        },
      ].filter((button) => button),
      addButton: process.status !== PROCESS_STATUS_CLOSED &&
        process?.performanceReview?.status !== PERFORMANCE_REVIEW_COMPLETED && {
          action: () => handleOpenModal(PROCESS_ADD_PARTICIPANTS_MODE, process),
        },
    });

  const handleChange = (value) =>
    setSelectedFilterItem(filterItems.find((item) => item.value === value));

  const handleOpenModal = (mode, process) => {
    if (PROCESS_MODES.includes(mode)) {
      setMode(mode);
      setSelectedProcess(process);

      switch (mode) {
        case PROCESS_CREATE_MODE:
        case PROCESS_UPDATE_MODE:
          setShowProcessModal(true);
          break;
        case PROCESS_DELETE_MODE:
          setShowProcessDeleteModal(true);
          break;
        case PROCESS_OPEN_MODE:
        case PROCESS_CLOSE_MODE:
          setShowCloseOrReopenModal(true);
          break;
        case PROCESS_ADD_PARTICIPANTS_MODE:
          setShowParticipantsModal(true);
          break;
        default:
          break;
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedProcess();
    setMode();
    setShowProcessModal(false);
    setShowProcessDeleteModal(false);
    setShowCloseOrReopenModal(false);
    setShowParticipantsModal(false);
  };

  const handleSubmitProcess = async (process, participants = []) => {
    switch (mode) {
      case PROCESS_CREATE_MODE:
        await createProcess(process);
        break;
      case PROCESS_UPDATE_MODE:
        await updateProcess(process);
        break;
      case PROCESS_DELETE_MODE:
        await deleteProcess(process);
        break;
      case PROCESS_OPEN_MODE:
        await updateProcessStatus(process, PROCESS_STATUS_OPEN);
        break;
      case PROCESS_CLOSE_MODE:
        await updateProcessStatus(process, PROCESS_STATUS_CLOSED);
        break;
      case PROCESS_ADD_PARTICIPANTS_MODE:
        await addParticipants(process, participants);
        break;
      default:
        break;
    }

    handleCloseModal();
    fetchProcesses(performanceReview.id);
  };

  const createProcess = (process) =>
    ProcessService.attemptCreatingNewProcess(process).then((response) => {
      if (response.ok)
        showSuccessNotification(
          intl.formatMessage({
            id: "ProcessContainer.process.created.ok",
          })
        );
      else
        response
          .json()
          .then((error) => showErrorNotification(`${error.message}`));
    });

  const updateProcess = (process) =>
    ProcessService.attemptSavingProcessById(process.id, {
      title: process.title,
      startDate: process.startDate,
      endDate: process.endDate,
    }).then((response) => {
      if (response.ok)
        showSuccessNotification(
          intl.formatMessage({
            id: "ProcessContainer.process.updated.ok",
          })
        );
      else
        response.json().then((error) => showErrorNotification(error.message));
    });

  const deleteProcess = (process) =>
    ProcessService.attemptDeletingProcessById(process.id).then((response) => {
      if (response.status === BAD_REQUEST_ERROR_CODE)
        showErrorNotification(
          intl.formatMessage({
            id: "ProcessContainer.process.exist.participant",
          })
        );
    });

  const updateProcessStatus = (process, newProcessStatus) =>
    ProcessService.updateProcessStatus(process.id, newProcessStatus).then(
      (response) => {
        if (response.ok)
          showSuccessNotification(
            intl.formatMessage({
              id: "ProcessContainer.process.status.update.ok",
            })
          );
        else if (response.status === BAD_REQUEST_ERROR_CODE)
          response.json().then((error) => showErrorNotification(error.message));
      }
    );

  const handleExport = (process) =>
    ProcessService.exportDashboardAsExcel({
      processId: process.id,
      participants: process.participants,
      filter: {
        name: "",
        status: [],
        goals: [],
        action: [],
        manager: "",
      },
    });

  const addParticipants = (process, participants) =>
    ProcessService.addParticipantsToProcess(process.id, participants).then(
      (response) => {
        if (response.ok) {
          response.text().then((message) => {
            showSuccessNotification(message);
          });
        } else if (response.status === BAD_REQUEST_ERROR_CODE) {
          response.text().then((message) => {
            showErrorNotification(message);
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          showErrorNotification(
            "You don't have permission to add participants to process."
          );
        }
      }
    );

  const showSuccessNotification = (message) =>
    toastContainer.success(message, "", { closeButton: true });

  const showErrorNotification = (message) =>
    toastContainer.error(message, "", { closeButton: true });

  return isLoading ? (
    <Loading />
  ) : notFound ? (
    <ErrorComponent statusCode={BAD_REQUEST_ERROR_CODE} user={user} />
  ) : (
    <Container>
      <ToastContainer
        ref={(ref) => (toastContainer = ref)}
        className="toast-top-right"
      />
      <Row>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => history.go(-1)}>
            <FormattedMessage id="Breadcrumb.review" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {performanceReview.title.toUpperCase()}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col>
          <Filter
            items={filterItems}
            value={selectedFilterItem?.value}
            onChange={handleChange}
          />
        </Col>
        {selectedFilterItem?.value !== "closed" &&
          performanceReview.status !== PERFORMANCE_REVIEW_COMPLETED && (
            <Col style={{ textAlign: "right" }}>
              <Button
                variant="link"
                onClick={() => handleOpenModal(PROCESS_CREATE_MODE)}
                style={{ fontSize: "1.1rem" }}
              >
                <FontAwesomeIcon icon={faPlusCircle} />
                {<FormattedMessage id="Reviews.add" />}
              </Button>
            </Col>
          )}
      </Row>
      <Row className="mt-3">
        <Processes processes={displayableProcesses} />
      </Row>

      <ProcessModal
        showModal={showProcessModal}
        handleCloseModal={handleCloseModal}
        submit={handleSubmitProcess}
        mode={mode}
        process={selectedProcess}
        user={user}
        period={period}
        performanceReview={performanceReview}
      />

      <Modal show={showProcessDeleteModal} onHide={handleCloseModal} centered>
        <DeleteConfirmation
          process={selectedProcess}
          handleClose={handleCloseModal}
          handleSubmit={handleSubmitProcess}
        />
      </Modal>

      <CloseAndReopenProcessModal
        showModal={showCloseOrReopenModal}
        handleCloseModal={handleCloseModal}
        process={selectedProcess}
        mode={mode}
        handleSubmit={handleSubmitProcess}
      />
      <Modal
        show={showParticipantsModal}
        onHide={handleCloseModal}
        size="lg"
        centered
      >
        <AddOrRemoveUserModal
          handleClose={handleCloseModal}
          handleSubmit={handleSubmitProcess}
          title={selectedProcess?.title}
          members={
            selectedProcess?.participants ? selectedProcess?.participants : []
          }
          role={selectedProcess}
          isProcess
        />
      </Modal>
    </Container>
  );
};

export default ProcessesContainer;
