import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import "./controlled-tabs.css";

const ControlledTabs = ({ children, defaultKey, tabs, ...rest }) => {
  const [key, setKey] = useState(defaultKey);
  const location = useLocation();
  const history = useHistory();

  const isSelected = (suffix) => location.pathname.includes(suffix);

  return (
    <Tabs
      activeKey={key}
      onSelect={(k) => {
        setKey(k);
        history.push(k);
      }}
      className={"controlled-tabs"}
      {...rest}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={`tab-${index}`}
          eventKey={tab.eventKey}
          title={tab.title}
          tabClassName={isSelected(tab.eventKey) ? "active" : ""}
        >
          {children}
        </Tab>
      ))}
    </Tabs>
  );
};

export default ControlledTabs;
