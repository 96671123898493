import * as React from "react";

const OneOnOneMenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 50 50",
      marginRight: "4%",
    }}
    viewBox="0 0 50 50"
    {...props}
  >
    <style>{".st0{fill:}" + props.color + "}"}</style>
    <path
      d="M16.4 49.4c-.9 0-1.7-.7-1.7-1.7v-4.1c0-4.6-2.9-8.4-6.5-8.4-1.6 0-2.8.4-3.6 1.1-.7.6-1.8.5-2.3-.2s-.5-1.8.2-2.3C3.9 32.6 5.8 32 8.2 32c5.4 0 9.9 5.2 9.9 11.7v4.1c0 .8-.8 1.6-1.7 1.6zM34.4 49.5c-.9 0-1.7-.7-1.7-1.7 0-6.2 4.3-11.2 9.5-11.2 2.3 0 4.2.6 5.6 1.8.7.6.8 1.6.2 2.3-.6.7-1.6.8-2.3.2-.8-.7-1.9-1-3.4-1-3.4 0-6.1 3.5-6.1 7.9-.1 1-.8 1.7-1.8 1.7z"
      className="st0"
    />
    <path
      d="M10.2 33c-4.1 0-7.4-3.3-7.4-7.4s3.3-7.4 7.4-7.4 7.4 3.3 7.4 7.4-3.3 7.4-7.4 7.4zm0-11.5c-2.2 0-4.1 1.9-4.1 4.1s1.9 4.1 4.1 4.1 4.1-1.9 4.1-4.1-1.9-4.1-4.1-4.1zM40.2 37.8c-4 0-7.2-3.2-7.2-7.1s3.2-7.1 7.2-7.1 7.2 3.2 7.2 7.1-3.3 7.1-7.2 7.1zm0-10.9c-2.1 0-3.8 1.7-3.8 3.8s1.8 3.8 3.8 3.8 3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8z"
      className="st0"
    />
    <path
      d="M28.3 5.8h.6c-.2-.1-.5-.3-.8-.3H12.8c-.9 0-1.6.7-1.6 1.6v5.6c0 .9.7 1.6 1.6 1.6h10.5v-3.6c.1-2.7 2.3-4.9 5-4.9z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M28.1 2.2H12.8c-2.7 0-4.9 2.2-4.9 4.9v5.6c0 2.7 2.2 4.9 4.9 4.9h5l-.4 4.3 5.3-4.3h.6v-3.3H12.8c-.9 0-1.6-.7-1.6-1.6V7.1c0-.9.7-1.6 1.6-1.6H28c.3 0 .6.1.8.3h3.9c-.5-2.1-2.4-3.6-4.6-3.6z"
      className="st0"
    />
    <path
      d="M42.9 5.8H28.3c-2.7 0-4.9 2.2-4.9 4.9v7.4c0 2.7 2.2 4.9 4.9 4.9h.7l4.2 3.4-.3-3.4h9.9c2.7 0 4.9-2.2 4.9-4.9v-7.4c.1-2.7-2.1-5-4.8-4.9zm1.5 12.3c0 .9-.7 1.6-1.6 1.6H28.3c-.9 0-1.6-.7-1.6-1.6v-7.4c0-.9.7-1.6 1.6-1.6h14.6c.9 0 1.6.7 1.6 1.6v7.4z"
      className="st0"
    />
  </svg>
);
export default OneOnOneMenuIcon;
