/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  MANAGER_ASSESSOR_TYPE,
  EMPLOYEE_ASESSOR_TYPE,
  NO_ASSESSOR_TYPE,
  FINAL_ASSESSOR_TYPE,
} from "../../constants/assessorTypes";
import {
  ASSESS_MODE,
  REVIEW_MODE,
  VIEW_ONLY_MODE,
} from "../../constants/appraisalModes";
import {
  GRADES_DICTIONARY_NUM,
  GOAL_GRADE,
} from "../../constants/gradeDictionary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import UserService from "../../services/UserService";
import GradeSelector from "./GradeSelector";
import CustomPopover from "../common/display/CustomPopover";
import { APPRAISAL_STATUS_CLOSED } from "../../constants/appraisalStatus";
import CommentDisplay from "./CommentDisplay";
import { FormattedMessage } from "react-intl";
import { ORGANIZATIONAL_GOAL_TYPE_ID } from "../../constants/goalTypes";
export const GoalTable = (props) => {
  const {
    extendedAssessmentGoals,
    type,
    mode,
    assessorType,
    assessment,
    grades,
    addAssessment,
    manager,
    targetUser,
    appraisalStatus,
  } = props;

  let currentTypeGoals = extendedAssessmentGoals.filter(
    (eas) => eas.goal && eas.goal.goalTypeId === type.id
  );

  const getTitle = (type) => (
    <>
      <FormattedMessage id={`GoalType.${type.id}`} />
      <span> {type.weight}%</span>
    </>
  );

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <GoalHeaderRenderer
        mode={mode}
        assessorType={assessorType}
        title={getTitle(type)}
      />

      <Form onChange={(event) => assessment(event)}>
        {currentTypeGoals.length === 0 ? (
          <Row className="appraisal-preview-row content pt-3 pb-2">
            <Col lg={12} align="center">
              <h4>
                <FormattedMessage id="Goals.noGoalsOfThisType" />
              </h4>
            </Col>
          </Row>
        ) : (
          currentTypeGoals.map((eas, index) => (
            <GoalRowRenderer
              key={index}
              extendedGoalAssessment={eas}
              active={eas.goal.active}
              grades={grades}
              mode={mode}
              assessorType={assessorType}
              assessment={assessment}
              addAssessment={addAssessment}
              manager={manager}
              targetUser={targetUser}
              appraisalStatus={appraisalStatus}
              type={type}
            />
          ))
        )}
      </Form>
    </div>
  );
};

/**
 * Helper functional component for rendering the table header row
 * @param props
 * @return {*}
 * @constructor
 */
const GoalHeaderRenderer = (props) => {
  const { mode, title } = props;

  return (
    <Row className="header">
      {(mode === ASSESS_MODE || mode === REVIEW_MODE) && (
        <>
          <Col md={8}>{title}</Col>
          <Col md={1} className="gray-heading">
            <FormattedMessage id="Goals.weight" />
          </Col>
          <Col md={3} className="gray-heading">
            {`${mode === REVIEW_MODE ? "Final " : ""}`}
            <FormattedMessage id="Processes.tabs.stats.assessment" />
          </Col>
        </>
      )}
      {mode === VIEW_ONLY_MODE && (
        <>
          <Col md={9}>{title}</Col>
          <Col md={1} className="gray-heading">
            <FormattedMessage id="Goals.weight" />
          </Col>
          <Col md={2} className="gray-heading">
            <FormattedMessage id="Goals.finalAssesment" />
          </Col>
        </>
      )}
    </Row>
  );
};

/**
 * Helper functional component for rendering a goal with its assessment
 * @param props
 * @return {*}
 * @constructor
 */
const GoalRowRenderer = (props) => {
  const {
    mode,
    assessorType,
    extendedGoalAssessment,
    addAssessment,
    active,
    grades,
    assessment,
    manager,
    targetUser,
    appraisalStatus,
    type,
  } = props;

  const initSelectStateValue = () => {
    if (
      mode === ASSESS_MODE &&
      assessorType === EMPLOYEE_ASESSOR_TYPE &&
      extendedGoalAssessment.employeeAssessment
    ) {
      return extendedGoalAssessment.employeeAssessment.goalTypeGrade.title;
    } else if (
      mode === ASSESS_MODE &&
      assessorType === MANAGER_ASSESSOR_TYPE &&
      extendedGoalAssessment.managerAssessment
    ) {
      return extendedGoalAssessment.managerAssessment.goalTypeGrade.title;
    } else if (
      mode === REVIEW_MODE &&
      extendedGoalAssessment.managerAssessment
    ) {
      addAssessment(
        `${extendedGoalAssessment.goal.id}`,
        extendedGoalAssessment.managerAssessment.goalTypeGrade.title
      );
      return extendedGoalAssessment.managerAssessment.goalTypeGrade.title;
    }
    return "Select grade";
  };

  const initCommentStateValue = () => {
    if (
      mode === ASSESS_MODE &&
      assessorType === EMPLOYEE_ASESSOR_TYPE &&
      extendedGoalAssessment.employeeAssessment
    ) {
      return extendedGoalAssessment.employeeAssessment.comment || "";
    } else if (
      mode === ASSESS_MODE &&
      assessorType === MANAGER_ASSESSOR_TYPE &&
      extendedGoalAssessment.managerAssessment
    ) {
      return extendedGoalAssessment.managerAssessment.comment || "";
    }
    return "";
  };

  const [selectedOption, setSelectedOption] = useState(initSelectStateValue);
  const [commentContent, setCommentContent] = useState(initCommentStateValue());
  const [setBy, setSetBy] = useState();

  const handleGradeChange = (grade) => setSelectedOption(grade);

  useEffect(() => {
    let mounted = true;
    UserService.getUserById(extendedGoalAssessment.goal.managerCreatorId).then(
      (manager) => {
        if (mounted) setSetBy(manager);
      }
    );
    return function cleanup() {
      mounted = false;
    };
  }, [extendedGoalAssessment.goal.managerCreatorId]);

  const getGoalColumnWidth = () => {
    if (mode === ASSESS_MODE || mode === REVIEW_MODE) return 8;
    if (mode === VIEW_ONLY_MODE) return 9;
    return 7;
  };

  return (
    <>
      <Row
        className="appraisal-preview-row appraisal-row content"
        active={active?.toString()}
      >
        <Col md={getGoalColumnWidth()}>
          <h5>{extendedGoalAssessment.goal.title}</h5>
          {extendedGoalAssessment.goal.description && (
            <p className="goal-desc">
              {extendedGoalAssessment.goal.description}
            </p>
          )}
          <Row className="mt-2">
            <Col>
              <span style={{ fontStyle: "italic" }}>
                <FormattedMessage id="Goals.kpi" />
                :&nbsp;
                {extendedGoalAssessment.goal.kpi ? (
                  <>
                    {extendedGoalAssessment.goal.kpiDescription ? (
                      <CustomPopover
                        placement="right"
                        text={ReactHtmlParser(
                          extendedGoalAssessment.goal.kpiDescription
                        )}
                      >
                        <span>
                          {extendedGoalAssessment.goal.kpi}
                          <FontAwesomeIcon
                            style={{ marginLeft: "5px" }}
                            icon={faInfoCircle}
                          />
                        </span>
                      </CustomPopover>
                    ) : (
                      extendedGoalAssessment.goal.kpi
                    )}
                  </>
                ) : (
                  "--"
                )}
              </span>
            </Col>
          </Row>
          {setBy && (
            <Row>
              <Col>
                <i style={{ fontSize: "0.8125rem" }}>
                  {<FormattedMessage id="Goals.setBy" />} {":"}{" "}
                  {type.id === ORGANIZATIONAL_GOAL_TYPE_ID
                    ? "Proxiad SEE"
                    : `${setBy.firstName} ${setBy.lastName}`}
                </i>
              </Col>
            </Row>
          )}
        </Col>
        <Col md={1} className="weight-column full-height-centered">
          {Number(extendedGoalAssessment.goal.weight).toFixed(2)}
        </Col>
        {mode === ASSESS_MODE ? (
          <Col md={3} className="full-height-centered">
            <GradeSelector
              id={extendedGoalAssessment.goal.id}
              value={selectedOption}
              grades={grades}
              assessment={assessment}
              onChange={handleGradeChange}
            />
          </Col>
        ) : (
          <>
            {mode === REVIEW_MODE ? (
              <Col md={3} className="full-height-centered">
                <GradeSelector
                  id={extendedGoalAssessment.goal.id}
                  value={selectedOption}
                  grades={grades}
                  assessment={assessment}
                  onChange={handleGradeChange}
                />
              </Col>
            ) : (
              [extendedGoalAssessment.finalAssessment].map(
                (assessment, index) => {
                  if (assessment === null)
                    return <React.Fragment key={index}></React.Fragment>;
                  if (!assessment)
                    return (
                      <Col key={index} md={2} className="full-height-centered">
                        --
                      </Col>
                    );
                  const currentGrade =
                    GRADES_DICTIONARY_NUM[assessment.goalTypeGrade.title];
                  let circleColor = active ? "var(--primary-light)" : "#c1c1c1";
                  if (currentGrade === 4 && active)
                    circleColor = "var(--primary-dark)";
                  if (currentGrade === 5 && active)
                    circleColor = "--primary-darkest-blue";
                  return (
                    <Col key={index} md={2} className="full-height-centered">
                      <div>
                        <div style={{ width: "50px", margin: "auto" }}>
                          <CircularProgressbar
                            value={(currentGrade / GOAL_GRADE) * 100}
                            text={currentGrade !== -1 ? currentGrade : "N/A"}
                            styles={{ path: { stroke: circleColor } }}
                          />
                        </div>
                        <p style={{ textAlign: "center" }}>
                          {assessment.goalTypeGrade.title}
                        </p>
                      </div>
                    </Col>
                  );
                }
              )
            )}
          </>
        )}
      </Row>
      {(mode === REVIEW_MODE || mode === VIEW_ONLY_MODE) && (
        <Row className="content comment-row">
          <Col>
            <Row>
              <Col>
                <h6>
                  <FormattedMessage id="Appraisal.comments" />
                </h6>
              </Col>
            </Row>
            {appraisalStatus === APPRAISAL_STATUS_CLOSED
              ? [
                  {
                    user: targetUser,
                    assessment: extendedGoalAssessment.employeeAssessment,
                  },
                  {
                    user: manager,
                    assessment: extendedGoalAssessment.managerAssessment,
                  },
                ].map((gradeData, index) => {
                  if (!gradeData.assessment)
                    return <React.Fragment key={index} />;

                  return (
                    <Row key={index}>
                      <Col>
                        <CommentDisplay
                          user={gradeData.user}
                          gradeTitle={gradeData.assessment.goalTypeGrade.title}
                          assessment={gradeData.assessment}
                          hasGrade={mode !== ASSESS_MODE}
                          isFinal={
                            gradeData.assessment.assessorType ===
                            FINAL_ASSESSOR_TYPE
                          }
                          marginRight={"3.5rem"}
                        />
                      </Col>
                    </Row>
                  );
                })
              : [
                  assessorType === MANAGER_ASSESSOR_TYPE
                    ? {
                        user: manager,
                        assessment: extendedGoalAssessment.managerAssessment,
                      }
                    : {
                        user: targetUser,
                        assessment: extendedGoalAssessment.employeeAssessment,
                      },
                  {
                    user: manager,
                    assessment:
                      assessorType === NO_ASSESSOR_TYPE
                        ? extendedGoalAssessment.managerAssessment
                        : assessorType === FINAL_ASSESSOR_TYPE &&
                          mode === REVIEW_MODE
                        ? extendedGoalAssessment.managerAssessment
                        : undefined,
                  },
                ].map((gradeData, index) => {
                  if (!gradeData.assessment)
                    return <React.Fragment key={index} />;

                  return (
                    <Row key={index}>
                      <Col>
                        <CommentDisplay
                          user={gradeData.user}
                          gradeTitle={gradeData.assessment.goalTypeGrade.title}
                          assessment={gradeData.assessment}
                          hasGrade={mode !== ASSESS_MODE}
                          isFinal={
                            gradeData.assessment.assessorType ===
                            FINAL_ASSESSOR_TYPE
                          }
                          marginRight={"6.25rem"}
                        />
                      </Col>
                    </Row>
                  );
                })}
          </Col>
        </Row>
      )}
      {mode !== VIEW_ONLY_MODE && (
        <Row className="content comment-row">
          <Col>
            <Row>
              <Col>
                <h6>
                  {" "}
                  <FormattedMessage id="Goal.comment" />
                </h6>
              </Col>
            </Row>
            <Form.Control
              id={extendedGoalAssessment.goal.id}
              value={commentContent ? commentContent : ""}
              onChange={(event) => setCommentContent(event.target.value)}
              as="textarea"
              rows={1}
              disabled={selectedOption === "Select grade"}
              placeholder={
                selectedOption === "Select grade"
                  ? "Select grade to enter comment"
                  : ""
              }
            />
          </Col>
        </Row>
      )}
      {mode === VIEW_ONLY_MODE && extendedGoalAssessment.finalAssessment && (
        <Row className="content comment-row">
          <Col>
            <Row>
              <Col>
                <h6>
                  <FormattedMessage id="Appraisal.finalAgreement" />
                </h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <CommentDisplay
                  user={manager}
                  gradeTitle={
                    extendedGoalAssessment.finalAssessment.goalTypeGrade.title
                  }
                  assessment={extendedGoalAssessment.finalAssessment}
                  hasGrade={true}
                  isFinal={
                    extendedGoalAssessment.finalAssessment.assessorType ===
                    FINAL_ASSESSOR_TYPE
                  }
                  marginRight={"3.5rem"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
