import React from "react";
import {
  PROCESS_USER_ACTION_APPRAISAL,
  PROCESS_USER_ACTION_GOALS,
} from "../../constants/processUserAction";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import { Col, Figure, Modal, ProgressBar, Row } from "react-bootstrap";
import Image from "../common/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

import { getAssessorStatus } from "../../util/getAssessorStatus";
import { FormattedMessage } from "react-intl";
import "../common/timeline.css";

const StatusTimelineModal = ({ showModal, handleClose, user, audits }) => {
  const maxCountAudits =
    user?.processAction === PROCESS_USER_ACTION_GOALS
      ? 3
      : user?.processAction === PROCESS_USER_ACTION_APPRAISAL
      ? 6
      : 0;

  return (
    <Modal show={showModal} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton />
      <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
        <Modal.Body>
          {user && (
            <Modal.Title>
              {user.processAction === PROCESS_USER_ACTION_GOALS
                ? `Goals Setting Status History of ${user.firstName} ${user.lastName}`
                : user.processAction === PROCESS_USER_ACTION_APPRAISAL &&
                  `Appraisal Status History of ${user.firstName} ${user.lastName}`}
            </Modal.Title>
          )}
          <hr />
          {audits.length > 0 ? (
            <div className="timeline-layout">
              <Row
                xs={maxCountAudits}
                sm={maxCountAudits}
                md={maxCountAudits}
                lg={maxCountAudits}
                xl={maxCountAudits}
              >
                {audits.map((audit) => (
                  <Col key={audit.id}>
                    <Figure>
                      {audit.createdBy ? (
                        <Image
                          className={
                            audit.impersonated
                              ? `timeline-item-image impersonated`
                              : `timeline-item-image`
                          }
                          email={audit.createdBy.email}
                          roundedCircle
                        />
                      ) : (
                        <FontAwesomeIcon icon={faCog} />
                      )}
                      <Figure.Caption
                        className={
                          maxCountAudits === 3
                            ? "timeline-item bigger"
                            : "timeline-item"
                        }
                      >
                        <p>
                          {audit.createdBy
                            ? `${audit.createdBy.firstName} ${audit.createdBy.lastName}`
                            : "System Process"}
                        </p>
                        <p>
                          <ConfigurationPropertiesContext.Consumer>
                            {({ formatDateTime }) =>
                              formatDateTime(audit.createdAt)
                            }
                          </ConfigurationPropertiesContext.Consumer>
                        </p>
                        <p>
                          {audit.status ? (
                            <FormattedMessage id={`Status.${audit.status}`} />
                          ) : (
                            audit.type && getAssessorStatus(audit)
                          )}
                        </p>
                      </Figure.Caption>
                    </Figure>
                  </Col>
                ))}
              </Row>
              <ProgressBar
                animated
                now={(100 / maxCountAudits) * audits.length}
              />
            </div>
          ) : (
            <p>No appraisal history found</p>
          )}
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default StatusTimelineModal;
