import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const ManagerFeedbackHeader = ({ managerName }) => {
  return (
    <>
      <Row className="header" style={{ paddingTop: "30px" }}>
        <Col md={8}>
          <FormattedMessage id="ManagerFeedback.managerFeedback" />
          <br></br>
          <span style={{ color: "grey" }}>
            <FormattedMessage id="ManagerFeedback.for" /> {managerName}
          </span>
        </Col>
        <Col md={1} className="gray-heading"></Col>
        <Col md={3} className="gray-heading">
          <FormattedMessage id="Processes.tabs.stats.assessment" />
        </Col>
      </Row>
    </>
  );
};

export default ManagerFeedbackHeader;
