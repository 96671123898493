import React from "react";
import { useLocation } from "react-router";
import { Redirect, Route } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";

const ProtectedRoute = ({
  component: Component,
  path,
  exact,
  user,
  permission,
  readOnly,
}) => {
  const location = useLocation();

  return (
    <Route
      exact={exact}
      path={path}
      render={() =>
        AuthenticationService.hasPermission(user, permission) ? (
          <Component key={location.pathname} user={user} readOnly={readOnly} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default ProtectedRoute;
