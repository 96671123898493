import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { ORGANIZATIONAL_GOAL_TYPE_ID } from "../../constants/goalTypes";
import { GOAL_UPDATE_MODE } from "../../constants/goalModes";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import TrainingsService from "../../services/TrainingsService";
import ErrorIcon from "../../icons/ErrorIcon";

const AddTrainingForm = ({
  mode,
  user,
  goal,
  performanceReviewGoal,
  goalTypeId,
  goalTemplateGroup,
  manager,
  handleClose,
  handleSubmit,
  setting,
  allUsers,
}) => {
  const intl = useIntl();
  const [categories, setCategories] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedTraining, setSelectedTraining] = useState();
  const [selectedUserId, setSelectedUserId] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    TrainingsService.getCategories().then((categories) => {
      setCategories(categories);
    });

    if (selectedCategory) {
      /*       if (goal?.refTrainingId && )  {
        TrainingsService.searchRefTraining(
          true,
          selectedCategory?.value ? selectedCategory?.value : goal?.categoryId,
          goal?.refTrainingId
        ).then((trainings) => {
          setTrainings(trainings);
        });
      } else { */
      TrainingsService.searchRefTraining(
        true,
        selectedCategory?.value ? selectedCategory?.value : goal?.categoryId
      ).then((trainings) => {
        setTrainings(trainings);
      });
      // }
    } else {
      TrainingsService.searchRefTraining(true, goal?.categoryId).then(
        (trainings) => {
          setTrainings(trainings);
        }
      );
    }
    /*     if (goal?.refTrainingId) {
      setSelectedTraining(trainings.filter((t) => t.is === goal.refTrainingId));
    } */
  }, [selectedCategory, goal]);
  const editPlaceholder = intl.formatMessage({
    id: "Reviews.tabs.edit",
  });
  const newPlaceholder = intl.formatMessage({
    id: "Goals.new",
  });
  const goalPlaceholder = intl.formatMessage({
    id: "Goals.goal",
  });
  const goalsActivePlaceholder = intl.formatMessage({
    id: "EditUserForm.active",
  });
  const goalsPeriod = intl.formatMessage({
    id: "Reviews.period",
  });

  const goalValidationSchema = Yup.object({
    goal: Yup.object({
      categoryId: Yup.number()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        )
        .typeError(
          intl.formatMessage({
            id: "Validation.numberType",
          })
        )
        .nullable(),
      refTrainingId: Yup.number()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        )

        .typeError(
          intl.formatMessage({
            id: "Validation.numberType",
          })
        )
        .nullable(),
      selectedUserId: allUsers
        ? Yup.string().required(
            intl.formatMessage({
              id: "UserAuthenticationForm.validation.error",
            })
          )
        : null,
      weight: Yup.number()
        .moreThan(0, <FormattedMessage id="Validation.weightMoreThan" />)
        .max(1, <FormattedMessage id="Validation.weightMax" />)
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    }),
  });

  const goalValidationSchema2 = Yup.object({
    goal: Yup.object({
      categoryId: Yup.number()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        )
        .typeError(
          intl.formatMessage({
            id: "Validation.numberType",
          })
        )
        .nullable(),
      training: Yup.string().required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      ),
      selectedUserId: allUsers
        ? Yup.string().required(
            intl.formatMessage({
              id: "UserAuthenticationForm.validation.error",
            })
          )
        : null,
      weight: Yup.number()
        .moreThan(0, <FormattedMessage id="Validation.weightMoreThan" />)
        .max(1, <FormattedMessage id="Validation.weightMax" />)
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    }),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        goal:
          user && performanceReviewGoal && manager
            ? goal || {
                id: 0,
                categoryId: selectedCategory?.value
                  ? selectedCategory?.value
                  : categories
                      .map((element) => {
                        return {
                          value: element.id,
                          label: element.category,
                        };
                      })
                      .filter((el) => el.value === goal?.categoryId)?.value,
                refTrainingId: selectedTraining?.value
                  ? selectedTraining?.value
                  : trainings
                      .map((element) => {
                        return {
                          value: element.id,
                          label: element.label,
                        };
                      })
                      .filter((el) => el.value === goal?.refTrainingId)?.value,
                kpi: "",
                kpiDescription: "",
                weight: "",
                goalTypeId,
                active: true,
                performanceReviewGoalId:
                  performanceReviewGoal.performanceReviewGoalId,
                managerCreatorId: manager.id,
                userId: user.id,
                selectedUserId: selectedUserId.value,
              }
            : goal || {
                id: 0,
                categoryId: selectedCategory?.value
                  ? selectedCategory?.value
                  : categories
                      .map((element) => {
                        return {
                          value: element.id,
                          label: element.category,
                        };
                      })
                      .filter((el) => el.value === goal?.categoryId)?.value,
                refTrainingId: selectedTraining?.value
                  ? selectedTraining?.value
                  : trainings
                      .map((element) => {
                        return {
                          value: element.id,
                          label: element.label,
                        };
                      })
                      .filter((el) => el.value === goal?.refTrainingId)?.value,
                kpi: "",
                kpiDescription: "",
                weight: "",
                goalTypeId,
                active: true,
                organizational: goalTypeId === ORGANIZATIONAL_GOAL_TYPE_ID,
                ownerId:
                  goalTypeId !== ORGANIZATIONAL_GOAL_TYPE_ID
                    ? manager.id
                    : undefined,
                goalTemplateGroupId: goalTemplateGroup?.id,
                userId: user.id,
                selectedUserId: selectedUserId.value,
              },
      }}
      validationSchema={
        selectedCategory?.label === "Other"
          ? goalValidationSchema2
          : goalValidationSchema
      }
      onSubmit={(values) => {
        if (values.goal.userId === null) {
          values.goal.userId = user.id;
        }
        handleSubmit(values.goal);
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>
                <Row>
                  {allUsers ? (
                    <>
                      <div className="pl-3">
                        <FormattedMessage id="New.training.label" />
                      </div>
                    </>
                  ) : (
                    <Col>
                      {`${
                        goal ? editPlaceholder : newPlaceholder
                      } ${goalPlaceholder}`}
                      {user && ` ${user.firstName} ${user.lastName}`}
                      {performanceReviewGoal && (
                        <>
                          <br />
                          <small>
                            <ConfigurationPropertiesContext.Consumer>
                              {({ formatDate }) =>
                                `${goalsPeriod}: ${formatDate(
                                  performanceReviewGoal.performanceReviewStart
                                )} - ${formatDate(
                                  performanceReviewGoal.performanceReviewEnd
                                )}`
                              }
                            </ConfigurationPropertiesContext.Consumer>
                          </small>
                        </>
                      )}
                    </Col>
                  )}
                </Row>
              </Modal.Title>
              <hr />
              {allUsers && (
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      <FormattedMessage id="Create.training.employee.label" />
                    </Form.Label>
                    <Select
                      name="goal.selectedUserId"
                      placeholder=""
                      options={allUsers.map((user) => {
                        return {
                          value: user.id,
                          label: `${user.firstName} ${user.lastName} - ${user.email}`,
                        };
                      })}
                      value={selectedUserId}
                      onChange={(selectedOption) => {
                        setSelectedUserId(selectedOption);
                      }}
                      className="viewers-multi-select"
                      classNamePrefix="select"
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="validation-error-msg">
                          {" "}
                          <ErrorIcon height="16px" width="16px" />{" "}
                          <span style={{ marginLeft: "1%" }}> {msg} </span>
                        </div>
                      )}
                      name="goal.selectedUserId"
                    />
                  </Form.Group>
                </Row>
              )}
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="Goals.category" />
                  </Form.Label>
                  <Select
                    name="goal.categoryId"
                    placeholder=""
                    options={categories.map((element) => {
                      return {
                        value: element.id,
                        label: element.category,
                      };
                    })}
                    className="viewers-multi-select"
                    classNamePrefix="select"
                    value={
                      selectedCategory
                        ? selectedCategory
                        : categories
                            .map((element) => {
                              return {
                                value: element.id,
                                label: element.category,
                              };
                            })
                            .filter((el) => el.value === goal?.categoryId)
                    }
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        "goal.categoryId",
                        goal?.categoryId || mode === GOAL_UPDATE_MODE
                          ? selectedOption?.value
                          : selectedOption
                      );
                      setSelectedCategory(selectedOption);
                      formik.setFieldValue("goal.refTrainingId", "");
                      setSelectedTraining("");
                    }}
                    isClearable={!goal}
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span style={{ marginLeft: "1%" }}> {msg} </span>
                      </div>
                    )}
                    name="goal.categoryId"
                  />
                </Form.Group>
              </Row>
              <Row>
                {selectedCategory?.label !== "Other" ? (
                  <Form.Group as={Col}>
                    <Form.Label>
                      <FormattedMessage id="Goals.training" />
                    </Form.Label>
                    <Select
                      name="goal.refTrainingId"
                      options={
                        !selectedCategory
                          ? []
                          : trainings.map((element) => {
                              return {
                                value: element.id,
                                label: element.label,
                              };
                            })
                      }
                      placeholder=""
                      className="viewers-multi-select"
                      classNamePrefix="select"
                      value={
                        selectedTraining
                          ? selectedTraining
                          : trainings
                              .map((element) => {
                                return {
                                  value: element.id,
                                  label: element.label,
                                };
                              })
                              .filter((el) => el.value === goal?.refTrainingId)
                      }
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          "goal.refTrainingId",
                          goal?.refTrainingId || mode === GOAL_UPDATE_MODE
                            ? selectedOption?.value
                            : selectedOption
                        );
                        setSelectedTraining(selectedOption);
                      }}
                      isClearable={!goal}
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="validation-error-msg">
                          {" "}
                          <ErrorIcon height="16px" width="16px" />{" "}
                          <span style={{ marginLeft: "1%" }}> {msg} </span>
                        </div>
                      )}
                      name="goal.refTrainingId"
                    />
                  </Form.Group>
                ) : (
                  <Form.Group as={Col}>
                    {" "}
                    <Form.Label>
                      <FormattedMessage id="Goals.training" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="goal.training"
                      value={
                        formik.values.goal.training
                          ? formik.values.goal.training
                          : ""
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                )}
              </Row>

              <Row>
                <Form.Group as={Col} md={{ span: 8 }}>
                  <Form.Label>
                    <FormattedMessage id="Goals.kpi" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="goal.kpi"
                    value={formik.values.goal.kpi ? formik.values.goal.kpi : ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
                <Form.Group as={Col} md={{ span: 4 }}>
                  <Form.Label>
                    <FormattedMessage id="Goals.weight.defitionion" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="goal.weight"
                    value={formik.values.goal.weight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span style={{ marginLeft: "1%" }}> {msg} </span>
                      </div>
                    )}
                    name="goal.weight"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="Goals.kpiDescription" />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="goal.kpiDescription"
                    value={
                      formik.values.goal.kpiDescription
                        ? formik.values.goal.kpiDescription
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
          <Row>
            <Form.Group
              as={Col}
              style={{ marginLeft: "5%", textAlign: "left" }}
            >
              {setting ? (
                <Form.Check
                  type="switch"
                  name="goal.active"
                  label={goalsActivePlaceholder}
                  checked={formik.values.goal.active}
                  disabled
                />
              ) : (
                <Form.Check
                  id="goal.active"
                  type="switch"
                  name="goal.active"
                  label={goalsActivePlaceholder}
                  checked={formik.values.goal.active}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              style={{ marginRight: "5%", textAlign: "right" }}
            >
              <Button
                variant="secondary"
                className="mr-1"
                onClick={handleClose}
              >
                <FormattedMessage id="EditForm.cancel" />
              </Button>
              <Button type="submit" variant="primary">
                <FormattedMessage id="EditForm.submit" />
              </Button>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AddTrainingForm;
