import * as React from "react";

const ErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 12 12",
      marginTop: "-1%",
    }}
    viewBox="0 0 12 12"
    {...props}
  >
    <circle
      cx={6}
      cy={6}
      r={6}
      style={{
        fill: "#ff3e3e",
      }}
    />
    <path
      d="M5.3 9.6c0-.4.3-.7.7-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7zm.3-1.7-.1-5.8h1.2l-.3 5.8h-.8z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default ErrorIcon;
