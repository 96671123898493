import React from "react";
import { Modal } from "react-bootstrap";
import {
  PR_CREATE_MODE,
  PR_DELETE_MODE,
  PR_EDIT_MODE,
} from "../../constants/performanceReviewModes";
import PerformanceReviewForm from "./PerformanceReviewForm";
import DeleteConfirmation from "../common/DeleteConfirmation";

const PerformanceReviewModal = ({
  showModal,
  handleClose,
  handleSubmit,
  mode,
  performanceReview,
  period,
}) => {
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      {(mode === PR_CREATE_MODE || mode === PR_EDIT_MODE) && (
        <PerformanceReviewForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          performanceReview={performanceReview}
          period={period}
        />
      )}
      {mode === PR_DELETE_MODE && (
        <DeleteConfirmation
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          performanceReview={performanceReview}
        />
      )}
    </Modal>
  );
};

export default PerformanceReviewModal;
