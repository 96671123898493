import React, { useEffect, useState } from "react";
import { ListGroup, Card, Container, Row, Col, Form } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import Select from "react-select";
import ManagerService from "../../../services/ManagerService";
import PerformanceReviewService from "../../../services/PerformanceReviewService";
import ProcessServiceV3 from "../../../services/ProcessServiceV3";
import Loading from "../../common/display/Loading";
import CustomSelect from "../../common/form/CustomSelect";
import EmployeeManagerFeedbackPageV2 from "./EmployeeManagerFeedbackPageV2";
import "./AllManagersFeedbackGroup.css";

const { Label } = Form;

const AllManagersFeedbackGroup = ({ user }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);

  const [performanceReviews, setPerformanceReviews] = useState([]);
  const [selectedPerformanceReview, setSelectedPerformanceReview] = useState();
  const [processes, setProcesses] = useState([]);
  const [selectedProcesses, setSelectedProcesses] = useState([]);
  const [managers, setAllManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);

  useEffect(() => {
    if (isLoading) {
      Promise.all([fetchManagers(), fetchPerformanceReviews()]).then(() =>
        setIsLoading(false)
      );
    }

    if (selectedPerformanceReview) {
      fetchProcesses(selectedPerformanceReview.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPerformanceReview, isLoading]);

  const fetchManagers = () => {
    Promise.all([ManagerService.getActiveManagers()]).then(([managers]) => {
      const m = [];
      managers.forEach((manager) => {
        if (manager.id !== user.id) {
          m.push({
            value: manager,
            label: `${manager.firstName} ${manager.lastName}`,
          });
        }
      });

      setAllManagers(m);
    });
  };

  const fetchPerformanceReviews = () => {
    Promise.all([PerformanceReviewService.getPerformanceReviews()]).then(
      ([reviews]) => {
        setPerformanceReviews(reviews);
      }
    );
  };

  const fetchProcesses = (performanceReviewId) => {
    Promise.all([
      ProcessServiceV3.getProcessesByPerformanceReviewId(performanceReviewId),
    ]).then(([proccesses]) => {
      setProcesses(proccesses.filter((p) => p.status === "CLOSED"));
      setIsLoading(false);
    });
  };
  const handleChange = (input) => {
    const arr = [];
    if (input !== null) input.forEach((e) => arr.push(e.value));
    setSelectedManagers(arr);
  };

  const handlePerformanceReviewSelect = (performanceReviewId) => {
    setSelectedPerformanceReview(performanceReviewId);
    setSelectedProcesses([]);
  };

  const handleProcessSelect = (process) => {
    setSelectedProcesses(process);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Row className="mt-3">
        <Label>
          <FormattedMessage id="ManagerFeedback.performanceReview.label" />
        </Label>

        <Col>
          <Select
            name="performanceReviewId"
            options={performanceReviews.map((element) => {
              return {
                value: element.id,
                label: element.title,
              };
            })}
            className="perfreviews-select"
            classNamePrefix="select"
            value={selectedPerformanceReview}
            onChange={handlePerformanceReviewSelect}
            isClearable
            placeholder=""
          />
        </Col>

        <Label>
          <FormattedMessage
            id="ManagerFeedback.process.label"
            defaultMessage="Process"
          />
        </Label>

        <Col>
          <CustomSelect
            name="processReviewId"
            options={processes.map((element) => {
              return {
                value: element.id,
                label: element.title,
              };
            })}
            className="processes-select"
            classNamePrefix="select"
            value={selectedProcesses}
            onChange={handleProcessSelect}
            isClearable
            placeholder=""
            isMulti
            allowSelectAll
          />
        </Col>
      </Row>
      <Select
        style={{ marginTop: "2%" }}
        options={managers}
        onChange={(e) => {
          handleChange(e);
        }}
        className="manager-select"
        classNamePrefix="select"
        isMulti
        placeholder={intl.formatMessage({
          id: "ManagerFeedback.select",
        })}
      />
      {!selectedManagers ? null : (
        <ListGroup style={{ paddingTop: "3%" }}>
          {selectedManagers.map((e) =>
            selectedProcesses?.map((sp) => {
              return (
                <ListGroup.Item>
                  <Card>
                    <Card.Header>{`${e.firstName} ${e.lastName} - ${sp.label}`}</Card.Header>
                    <Card.Body>
                      <EmployeeManagerFeedbackPageV2
                        manager={e}
                        processId={sp?.value}
                        performanceReviewId={selectedPerformanceReview?.value}
                        hr={true}
                        loading={isLoading}
                      />
                    </Card.Body>
                  </Card>
                </ListGroup.Item>
              );
            })
          )}
        </ListGroup>
      )}
    </Container>
  );
};

export default AllManagersFeedbackGroup;
