import React, { useState } from "react";
import {
  PROCESS_USER_ACTION_APPRAISAL,
  PROCESS_USER_ACTION_GOALS,
} from "../../constants/processUserAction";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import { Button, Col, Table } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import Image from "../common/Image";
import { getAppraisalTitle } from "../../util/getAppraisalTitle";
import {
  APPRAISAL_STATUS_HISTORY_MODE,
  GOALS_SETTING_STATUS_HISTORY_MODE,
} from "../../constants/managerViewModes";
import "./employees.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHistory } from "@fortawesome/free-solid-svg-icons";
import GoalIcon from "../common/buttons/GoalIcon";
import { FormattedMessage } from "react-intl";
const getStatusTimelineMode = (employee) => {
  if (employee?.processAction === PROCESS_USER_ACTION_APPRAISAL)
    return APPRAISAL_STATUS_HISTORY_MODE;

  if (employee?.processAction === PROCESS_USER_ACTION_GOALS)
    return GOALS_SETTING_STATUS_HISTORY_MODE;

  return;
};

const ManagerEmployees = ({ employees }) => {
  const [hoveredEmployee, setHoveredEmployee] = useState();

  const onMouseOver = (employee) => setHoveredEmployee(employee);

  const onMouseLeave = () => setHoveredEmployee();

  return employees.length > 0 ? (
    <ConfigurationPropertiesContext.Consumer>
      {({ formatDate }) => (
        <Table className="employees" borderless>
          <thead>
            <tr>
              <th colSpan={2}>
                <FormattedMessage id="Manager.employee" />
              </th>
              <th>
                <FormattedMessage id="Manager.assessmentPeriod" />
              </th>
              <th>
                <FormattedMessage id="Manager.status" />
              </th>
              <th>
                <FormattedMessage id="Manager.action" />
              </th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => (
              <tr
                key={`employee-row-${index}`}
                className={!employee.active && "inactive"}
                onMouseOver={() => onMouseOver(employee)}
                onMouseLeave={onMouseLeave}
              >
                <td className="profile-img">
                  <LazyLoad height={50} offset={100}>
                    <Image email={employee.email} roundedCircle />
                  </LazyLoad>
                </td>
                <td>
                  <div className="employee-name">{`${employee.firstName} ${employee.lastName}`}</div>
                  <div className="employee-position">{employee.title}</div>
                  <div className="employee-email">{employee.email}</div>
                  {employee.id === hoveredEmployee?.id && (
                    <div className="actions">
                      {employee?.buttons?.map((button, index) => (
                        <Button
                          key={`button-employee-action-${index}`}
                          variant="link"
                          onClick={button.action}
                        >
                          <div
                            style={{ alignItems: "center", display: "flex" }}
                          >
                            {button.icon === faHistory && (
                              <FontAwesomeIcon
                                icon={button.icon}
                                style={{ paddingRight: "3px" }}
                              />
                            )}

                            {!button.icon && (
                              <GoalIcon style={{ paddingRight: "6px" }} />
                            )}

                            {button.label}
                          </div>
                        </Button>
                      ))}
                    </div>
                  )}
                </td>
                <td>
                  {employee.appraisal?.type ? (
                    <>
                      <div className="appraisal-title">{`${getAppraisalTitle(
                        employee.appraisal.type
                      )} assessment`}</div>
                      <small>
                        {employee.performanceReview
                          ? `${formatDate(
                              employee.performanceReview.start
                            )} - ${formatDate(employee.performanceReview.end)}`
                          : "--"}
                      </small>
                    </>
                  ) : employee.performanceReview ? (
                    `${formatDate(
                      employee.performanceReview.start
                    )} - ${formatDate(employee.performanceReview.end)}`
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  {employee.status ? (
                    <Button
                      variant="link"
                      onClick={(event) => {
                        event.preventDefault();
                        employee.handleStatusClick(
                          getStatusTimelineMode(employee),
                          employee
                        );
                      }}
                    >
                      {<FormattedMessage id={`Status.${employee.status}`} />}
                    </Button>
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  <div className="main-action">
                    {employee.actionButton && (
                      <>
                        {employee.actionButton}
                        <small>
                          {<FormattedMessage id="Manager.dueDate" />}
                          {`: ${formatDate(
                            employee.process?.endDate
                              ? employee.process.endDate
                              : employee.appraisal.dueDate
                          )}`}
                        </small>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </ConfigurationPropertiesContext.Consumer>
  ) : (
    <Col className="text-center ">
      <h4>
        <FormattedMessage id="Manager.noEmployeesToShow" />
      </h4>
    </Col>
  );
};

export default ManagerEmployees;
