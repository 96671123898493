import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import authFetchJSON from "../../../components/security/fetchClient";
import {
  ROLE_CREATE_MODE,
  ROLE_EDIT_MODE,
  ROLE_DELETE_MODE,
  ROLE_SHOW_MEMBERS,
} from "../../../constants/roleModes";
// import RoleService from "../../../services/RoleService";
import Loading from "../../common/display/Loading";
import ErrorComponent from "../../common/ErrorComponent";
import EditRoleForm from "./EditRoleForm";
import DeleteConfirmation from "../../common/DeleteConfirmation";
//import RoleInfiniteScroll from "./RoleInfiniteScroll";
import AddOrRemoveUserModal from "../users/AddOrRemoveUserModal";

const RoleModal = ({ showModal, handleClose, handleSubmit, mode, role }) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size={
        (mode === ROLE_CREATE_MODE ||
          mode === ROLE_EDIT_MODE ||
          mode === ROLE_SHOW_MEMBERS) &&
        "lg"
      }
      centered
    >
      {(mode === ROLE_CREATE_MODE || mode === ROLE_EDIT_MODE) && (
        <EditRoleForm
          role={role}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
      {mode === ROLE_SHOW_MEMBERS && (
        /*  <RoleInfiniteScroll mode={mode} role={role} /> */
        <RoleMembers
          role={role}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
      {mode === ROLE_DELETE_MODE && (
        <DeleteConfirmation
          role={role}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

/**
 * Helper functional component for rendering a modal with selected role members
 * @param props
 * @return {*}
 * @constructor
 */
const RoleMembers = ({ role, handleClose, handleSubmit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [roleMembers, setRoleMembers] = useState([]);

  useEffect(() => {
    if (role && isLoading) {
      authFetchJSON(`/api/v1/roles/${role.id}/users`)
        .then((response) => response.ok && response.json())
        .then((data) => {
          setRoleMembers(data);
          setIsLoading(false);
        })
        .catch((error) => setError(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return isLoading ? (
    <Loading />
  ) : error ? (
    <ErrorComponent message={error.message} />
  ) : (
    <AddOrRemoveUserModal
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title={role?.name}
      members={roleMembers}
      role={role}
    />
  );
};

export default RoleModal;
