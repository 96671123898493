import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import MultiselectTwoSides from "react-multiselect-two-sides";
import UserService from "../../../services/UserService";
import ManagerService from "../../../services/ManagerService";
import Loading from "../../common/display/Loading";

import "./styles.css";

const AddOrRemoveUserModal = ({
  handleClose,
  handleSubmit,
  title,
  members,
  role,
  isProcess,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [allEmployees, setAllEmployess] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(undefined);
  const [selectedEmployees, setSelectedEmployees] = useState(
    isProcess ? [] : members.map((el) => el.id)
  );

  const titlePlaceholder = intl.formatMessage({
    id: "Roles.members",
  });

  const availableEmployees = intl.formatMessage({
    id: "Roles.availableEmployees",
  });
  const chosenEmployees = intl.formatMessage({
    id: "Roles.selectedEmployees",
  });
  const addAll = intl.formatMessage({
    id: "Roles.addAll",
  });
  const removeAll = intl.formatMessage({
    id: "Roles.removeAll",
  });

  useEffect(() => {
    if (isLoading) {
      Promise.all([
        UserService.getAllActiveUsers(),
        ManagerService.getActiveManagers(),
      ]).then(([users, managers]) => {
        if (isProcess) {
          const filteredEmp = users.filter((el) => !members.includes(el));
          setEmployees(filteredEmp);
          setAllEmployess(filteredEmp);
        } else {
          setEmployees(users);
          setAllEmployess(users);
        }
        setManagers(managers);
        setAllMembers(members);
      });
      setIsLoading(false);
    }

    if (selectedManager) {
      const newEmployees = employees.filter(
        (employee) => employee.managerId === selectedManager.id
      );
      const newMembers = members.filter(
        (employee) => employee.managerId === selectedManager.id
      );
      setEmployees(newEmployees !== [] ? newEmployees : allEmployees);

      setSelectedEmployees(
        newMembers !== []
          ? newMembers.map((el) => el.id)
          : allMembers.map((el) => el.id)
      );
    } else {
      setIsLoading(true);
      Promise.all([UserService.getAllActiveUsers()]).then(([users]) => {
        if (isProcess) {
          setSelectedEmployees([]);
          const filteredEmp = users.filter((el) => !members.includes(el));
          setEmployees(filteredEmp);
          setAllEmployess(filteredEmp);
        } else {
          setEmployees(users);
          setSelectedEmployees(members.map((el) => el.id));
        }
      });
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedManager]);

  const handleChange = (value) => {
    setSelectedEmployees(value);
  };

  function filterBy(option, filter, labelKey) {
    return option[labelKey].toLowerCase().indexOf(filter.toLowerCase()) > -1;
  }

  const blurElement = (event) => event.currentTarget.blur();

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{`${titlePlaceholder} '${title}'`}</Modal.Title>
      </Modal.Header>
      <Modal.Dialog style={{ width: "100%", padding: 0, margin: 0 }} size="xl">
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group style={{ width: "30%" }}>
                <Form.Control
                  size="sm"
                  as="select"
                  value={selectedManager && selectedManager.id}
                  onChange={(event) => {
                    setEmployees(allEmployees);
                    setSelectedEmployees(allMembers.map((el) => el.id));
                    setSelectedManager(
                      managers.find(
                        (manager) => manager.id === Number(event.target.value)
                      )
                    );
                    blurElement(event);
                  }}
                >
                  <option value={undefined}>
                    {intl.formatMessage({
                      id: "Users.anyManager",
                    })}
                  </option>
                  {managers &&
                    managers.map((manager) => (
                      <option key={manager.id} value={manager.id}>
                        {`${manager.firstName}  ${manager.lastName}`}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <MultiselectTwoSides
            className="msts_theme_example"
            onChange={handleChange}
            availableHeader={availableEmployees}
            selectedHeader={chosenEmployees}
            selectAllText={addAll}
            deselectAllText={removeAll}
            value={selectedEmployees}
            filterBy={filterBy}
            options={employees}
            labelKey="fullName"
            valueKey="id"
            showControls
            searchable
            clearable
          />
        </Modal.Body>
      </Modal.Dialog>
      <Form.Group style={{ width: "95%", textAlign: "right", marginTop: "2%" }}>
        <Button
          variant="outline-secondary"
          className="mr-1"
          onClick={handleClose}
        >
          <FormattedMessage id="EditForm.cancel" />
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleSubmit(role, selectedEmployees);
            handleClose();
          }}
        >
          <FormattedMessage id="Users.save" />
        </Button>
      </Form.Group>
    </>
  );
};

export default AddOrRemoveUserModal;
