/**
 * This is a helper function which has to be used for all types of requests to external APIs.
 *
 * @param url is the URL of the API
 * @param options the options of the request
 * @return Fetched JSON response
 */
import { authConfig, loginRequest } from "../../msal/authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export async function getAccessToken() {
  const { msalInstance, internalUserManagement, oauth2ImplicitGrant } =
    authConfig;

  if (!internalUserManagement && !oauth2ImplicitGrant) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        ">>>>No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const accessTokenRequest = {
      ...loginRequest(),
      account: account,
    };

    // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react
    const response = await msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msalInstance
            .acquireTokenPopup(accessTokenRequest)
            .catch((error) => {
              // Acquire token interactive failure
              console.log(error);
              msalInstance.logoutRedirect();
            });
        }
        console.log(error);
        msalInstance.logoutRedirect();
      });

    return response.accessToken;
  }

  return null;
}

export async function setAuthHeader(options) {
  const accessToken = await getAccessToken();
  let optionResult = options;
  let headers;
  if (optionResult) {
    headers = new Headers(optionResult.headers);
  } else {
    headers = new Headers();
    optionResult = {};
  }
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  optionResult.headers = headers;
  return optionResult;
}

const authFetchJSON = async (url, options = {}) => {
  try {
    const { internalUserManagement, oauth2ImplicitGrant } = authConfig;

    if (
      !internalUserManagement &&
      !oauth2ImplicitGrant &&
      url !== "/actuator/configprops"
    ) {
      options = await setAuthHeader(options);
    }

    return await fetch(url, options);
  } catch (error) {
    await refreshToken();
    return await fetch(url, options);
  }
};

export const refreshToken = async () => {
  if (document.getElementById("iframe-aad") === null) {
    var iframe = document.createElement("iframe");

    iframe.setAttribute("id", "iframe-aad");
    iframe.setAttribute("class", "iframe-aad"); // stylization of iframe is at global stylization css file
    iframe.setAttribute("width", "480px");
    iframe.setAttribute("height", "600px");
    iframe.setAttribute(
      "sandbox",
      "allow-scripts allow-same-origin allow-forms"
    );

    document.body.appendChild(iframe);
  }

  const aadIframe = document.getElementById("iframe-aad");
  aadIframe.setAttribute("src", "/auto-closable");
  await timeout(1000);
};

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default authFetchJSON;
