import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  ASSESS_MODE,
  REVIEW_MODE,
  VIEW_ONLY_MODE,
} from "../../constants/appraisalModes";
import {
  EMPLOYEE_ASESSOR_TYPE,
  FINAL_ASSESSOR_TYPE,
} from "../../constants/assessorTypes";
import { FormattedMessage } from "react-intl";
import ManagerFeedbackHeader from "../managerfeedback/ManagerFeedbackHeader";
import ManagerFeedbackContainer from "../managerfeedback/ManagerFeedbackContainer";

const PersonalDevelopmntCommentSection = ({
  mode,
  assessorType,
  assessors,
  comment,
  setCommentContent,
  managerFeedback,
  managerName,
}) => {
  const employeeAssessorComment = assessors.find(
    (a) => a.type === EMPLOYEE_ASESSOR_TYPE
  ).personalDevelopmentComment;
  const finalAssessorComment = assessors.find(
    (a) => a.type === FINAL_ASSESSOR_TYPE
  ).personalDevelopmentComment;

  const getManagerFeedback = () =>
    managerFeedback
      .sort((a, b) => a.questionId - b.questionId)
      .map((feedback) => (
        <ManagerFeedbackContainer
          key={feedback.questionId}
          questionId={feedback.questionId}
          question={feedback.questionContent}
          feedback={feedback}
          viewOnly={mode === VIEW_ONLY_MODE}
        />
      ));

  return (
    <>
      {mode === ASSESS_MODE && assessorType === EMPLOYEE_ASESSOR_TYPE && (
        <>
          <Row className="header">
            <Col md={12}>
              <FormattedMessage id="Appraisal.devView" />
            </Col>
          </Row>
          <Row className="content">
            <Col>
              <Form.Control
                as="textarea"
                rows={4}
                maxLength={4000}
                value={comment ? comment : ""}
                onChange={(event) => setCommentContent(event.target.value)}
              />
            </Col>
          </Row>
        </>
      )}
      {mode === REVIEW_MODE && (
        <>
          <Row className="header">
            <Col md={12}>
              <FormattedMessage id="Appraisal.employeeDevView" />
            </Col>
          </Row>
          <Row className="content">
            <Col>
              <Form.Control
                as="textarea"
                rows={4}
                value={employeeAssessorComment ? employeeAssessorComment : ""}
                readOnly
              />
            </Col>
          </Row>
          <Row className="header">
            <Col md={12}>
              <FormattedMessage id="Appraisal.decision" />
            </Col>
          </Row>
          <Row className="content">
            <Col>
              <Form.Control
                as="textarea"
                rows={4}
                maxLength={4000}
                value={comment ? comment : ""}
                onChange={(event) => setCommentContent(event.target.value)}
              />
            </Col>
          </Row>
        </>
      )}
      {mode === VIEW_ONLY_MODE && (
        <>
          <Row className="header">
            <Col md={12}>
              <FormattedMessage id="Appraisal.employeeDevView" />
            </Col>
          </Row>
          <Row className="content">
            <Col>
              <p className="comment_pd">
                {employeeAssessorComment ? employeeAssessorComment : "--"}
              </p>
            </Col>
          </Row>
          <Row className="header">
            <Col md={12}>
              <FormattedMessage id="Appraisal.decision" />
            </Col>
          </Row>
          <Row className="content">
            <Col>
              <p className="comment_pd">
                {finalAssessorComment ? finalAssessorComment : "--"}
              </p>
            </Col>
          </Row>
        </>
      )}

      {assessorType === EMPLOYEE_ASESSOR_TYPE &&
        mode === VIEW_ONLY_MODE &&
        managerFeedback?.length > 0 && (
          <>
            <ManagerFeedbackHeader managerName={managerName} />
            {getManagerFeedback()}
          </>
        )}
    </>
  );
};

export default PersonalDevelopmntCommentSection;
