import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { useIntl, FormattedMessage } from "react-intl";
import {
  APPRAISAL_TYPE_FINAL,
  APPRAISAL_TYPE_INTERMEDIATE,
} from "../../constants/appraisalType";
import DatePickerField from "../common/DatePickerField";
import ErrorIcon from "../../icons/ErrorIcon";

const { Group, Label, Control } = Form;

const ProcessModal = (props) => {
  const [processStart] = useState(new Date(props.period.startDate));
  const [processEnd] = useState(new Date(props.period.endDate));
  const intl = useIntl();
  const placeholderProcess = intl.formatMessage({
    id: "Processes.process",
  });
  const placeholderUpdate = intl.formatMessage({
    id: "Processes.update",
  });
  const placeholderNew = intl.formatMessage({
    id: "Processes.createNew",
  });
  return (
    <Modal show={props.showModal} onHide={props.handleCloseModal} centered>
      <Modal.Header closeButton />
      {props.mode && (
        <Formik
          enableReinitialize
          initialValues={initializeValues(props, processStart, processEnd)}
          validationSchema={createValidationSchema(intl)}
          onSubmit={(values) => props.submit(values)}
        >
          {({ values, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Dialog style={{ width: "90%" }}>
                <Modal.Body>
                  <Modal.Title>{`${
                    props.process ? placeholderUpdate : placeholderNew
                  } ${placeholderProcess}`}</Modal.Title>
                  <hr />
                  <Row className="mt-3">
                    <Group as={Col}>
                      <Label>
                        <FormattedMessage id="ProcessesModal.title" />
                      </Label>
                      <Control
                        type="text"
                        name="title"
                        id="title"
                        value={values.title}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        render={(msg) => (
                          <div className="validation-error-msg">
                            {" "}
                            <ErrorIcon height="16px" width="16px" />{" "}
                            <span style={{ marginLeft: "1%" }}> {msg} </span>
                          </div>
                        )}
                        name="title"
                      />
                    </Group>
                  </Row>
                  <Row>
                    <Group as={Col}>
                      <Label>
                        {" "}
                        <FormattedMessage id="ProcessesModal.type" />
                      </Label>
                      <Control
                        as="select"
                        name="type"
                        id="type"
                        value={values.type}
                        onChange={handleChange}
                        disabled={props.process !== undefined}
                      >
                        <option>{APPRAISAL_TYPE_INTERMEDIATE}</option>
                        <option>{APPRAISAL_TYPE_FINAL}</option>
                      </Control>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="validation-error-msg">
                            {" "}
                            <ErrorIcon height="16px" width="16px" />{" "}
                            <span style={{ marginLeft: "1%" }}> {msg} </span>
                          </div>
                        )}
                        name="type"
                      />
                    </Group>
                  </Row>
                  <Row>
                    <Group as={Col} md={{ span: 6 }}>
                      <Label>
                        {" "}
                        <FormattedMessage id="ProcessesModal.startDate" />
                      </Label>
                      <DatePickerField
                        name="startDate"
                        selectsStart
                        startDate={values.startDate}
                        endDate={values.endDate}
                        disabled={
                          props.process ? Date.now() >= values.startDate : false
                        }
                      />
                      <ErrorMessage
                        render={(msg) => (
                          <div className="validation-error-msg">
                            {" "}
                            <ErrorIcon height="16px" width="16px" />{" "}
                            <span style={{ marginLeft: "1%" }}> {msg} </span>
                          </div>
                        )}
                        name="startDate"
                      />
                    </Group>
                    <Group as={Col} md={{ span: 6 }}>
                      <Label>
                        <FormattedMessage id="ProcessesModal.endDate" />
                      </Label>
                      <DatePickerField
                        name="endDate"
                        selectsEnd
                        startDate={values.startDate}
                        endDate={values.endDate}
                        minDate={values.startDate}
                      />
                      <ErrorMessage
                        render={(msg) => (
                          <div className="validation-error-msg">
                            {" "}
                            <ErrorIcon height="16px" width="16px" />{" "}
                            <span style={{ marginLeft: "1%" }}> {msg} </span>
                          </div>
                        )}
                        name="endDate"
                      />
                    </Group>
                  </Row>
                  <Row>
                    <Group as={Col} md={{ span: 12 }}>
                      <Label>
                        {" "}
                        <FormattedMessage id="ProcessesModal.managedBy" />
                      </Label>
                      <Control
                        type="text"
                        name="managedBy"
                        id="managedBy"
                        value={`${props.user.firstName} ${props.user.lastName}`}
                        disabled
                      />
                    </Group>
                  </Row>
                  <Row>
                    <Group as={Col} md={{ span: 12 }}>
                      <Label>
                        <FormattedMessage id="ProcessesModal.performanceReview" />
                      </Label>
                      <Control
                        type="text"
                        value={
                          props.performanceReview &&
                          props.performanceReview.title
                        }
                        disabled
                      />
                    </Group>
                  </Row>
                </Modal.Body>
              </Modal.Dialog>
              <Row>
                <Group style={{ marginRight: "41px", marginLeft: "auto" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="float-right"
                  >
                    <FormattedMessage id="ProcessesModal.submit" />
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={props.handleCloseModal}
                    className="mr-2 float-right"
                  >
                    <FormattedMessage id="ProcessesModal.cancel" />
                  </Button>
                </Group>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

const initializeValues = (props, processStart, processEnd) => {
  if (props.process) {
    props.process.startDate = new Date(props.process.startDate);
    props.process.endDate = new Date(props.process.endDate);

    return props.process;
  }
  return {
    title: "",
    type: APPRAISAL_TYPE_INTERMEDIATE,
    startDate: processStart,
    endDate: processEnd,
    performanceReviewId: props.performanceReview
      ? props.performanceReview.id
      : undefined,
  };
};

const createValidationSchema = (intl) => {
  let minDate = new Date();

  return Yup.object({
    title: Yup.string().required(
      intl.formatMessage({
        id: "UserAuthenticationForm.validation.error",
      })
    ),
    startDate: Yup.date()
      .required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      )
      .nullable()
      .default(undefined),
    endDate: Yup.date()
      .min(
        minDate,
        intl.formatMessage({
          id: "PerformanceReviewForm.endDate.error",
        })
      )
      .required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      )
      .nullable()
      .default(undefined),
  });
};

export default ProcessModal;
