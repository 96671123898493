export const GOAL_CREATE_MODE = "create_goal";
export const GOAL_COPY_MODE = "copy_goal";
export const GOAL_UPDATE_MODE = "update_goal";
export const GOAL_DELETE_MODE = "delete_goal";

export const GOAL_MODES = [
  GOAL_CREATE_MODE,
  GOAL_COPY_MODE,
  GOAL_UPDATE_MODE,
  GOAL_DELETE_MODE,
];
