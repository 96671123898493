import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import authFetchJSON from "../../../components/security/fetchClient";
import Loading from "../../common/display/Loading";
import QuestionStatisticListV2 from "./QuestionStatisticListV2";

const EmployeeManagerFeedbackPageV2 = ({
  manager,
  processId,
  performanceReviewId,
  hr,
  loading,
}) => {
  const [data, setData] = useState();
  useEffect(() => {
    if (processId && performanceReviewId) {
      authFetchJSON(
        `/api/v1/manager-feedback/${manager.id}/?processId=${processId}&performanceReviewId=${performanceReviewId}`
      )
        .then((response) => response.ok && response.json())
        .then((data) => setData(data));
    }
  }, [manager, processId, performanceReviewId]);

  // implication needed as a logical operation !(anonymous->hr)
  return loading ? (
    <Loading />
  ) : !data?.questions || data?.questions?.length === 0 ? (
    <>
      <Row style={{ marginTop: "2%" }}>
        <Col className="text-center ">
          <h4>
            <FormattedMessage id="ManagerFeedback.noFeedbacks" />
          </h4>
        </Col>
      </Row>
    </>
  ) : hr === true ? (
    <div style={{ justifyContent: "normal" }}>
      <QuestionStatisticListV2
        questions={data.questions}
        anonymous={false}
        hr
      />
    </div>
  ) : (
    <div style={{ justifyContent: "normal" }}>
      <QuestionStatisticListV2
        questions={data.questions}
        anonymous={data.anonymous}
      />
    </div>
  );
};

export default injectIntl(EmployeeManagerFeedbackPageV2);
