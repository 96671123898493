/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastr";
import { FormattedMessage } from "react-intl";
import { faEdit, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { SORT_NONE, SORT_ASC, SORT_DESC } from "../../constants/resultsSort";
import {
  PROCESS_CREATE_MODE,
  PROCESS_UPDATE_MODE,
} from "../../constants/processModes";
import {
  PR_CREATE_MODE,
  PR_DELETE_MODE,
  PR_EDIT_MODE,
} from "../../constants/performanceReviewModes";
import {
  BAD_REQUEST_ERROR_CODE,
  CONFLICT_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import Loading from "../common/display/Loading";
import FAButtonAndTooltip from "../common/buttons/FAButtonAndTooltip";
import SortButton from "../common/buttons/SortButton";
import PerformanceReviewService from "../../services/PerformanceReviewService";
import ProcessService from "../../services/ProcessService";
import ManagerService from "../../services/ManagerService";
import ProcessModal from "./ProcessModal";
import ProcessCard from "../common/ProcessCard";
import ProcessStatusTimelineModal from "./ProcessStatusTimelineModal";
import PerformanceReviewsFilter from "../common/PerformanceReviewsFilter";
import PerformanceReviewModal from "./PerformanceReviewModal";

import "../appraisal/appraisal-preview.css";
import "./process.css";

class Process extends React.Component {
  state = {
    processes: [],
    processesToDisplay: [],
    managers: [],
    selectedProcess: undefined,
    period: undefined,
    showProcessModal: false,
    showAuditModal: false,
    showPRModal: false,
    processMode: undefined,
    prMode: undefined,
    audits: [],
    performanceReviews: [],
    selectedPerformanceReview: undefined,
    loading: true,
    processesLoading: true,
  };

  toastContainer;

  componentDidMount() {
    this.fetchResources();
  }

  fetchResources = (performanceReview) =>
    Promise.all([
      PerformanceReviewService.getPerformanceReviews(),
      ProcessService.getPeriod(),
      ManagerService.getActiveManagers(),
    ])
      .then(([performanceReviews, period, managers]) => {
        this.setState({ managers });
        if (performanceReview)
          return [performanceReviews, period, performanceReview];
        else
          return PerformanceReviewService.getActivePerformanceReviewId()
            .then((activePerformanceReviewId) => [
              performanceReviews,
              period,
              performanceReviews.find(
                (pr) => pr.id === activePerformanceReviewId
              ),
            ])
            .catch(() => [performanceReviews, period]);
      })
      .then(([performanceReviews, period, selectedPerformanceReview]) => {
        if (selectedPerformanceReview) {
          this.setSelectedPerformanceReview(selectedPerformanceReview);
          this.setProcessesToDisplay(selectedPerformanceReview.id);
        }
        this.setState({
          performanceReviews,
          period,
          sortByPeriod: SORT_NONE,
          sortByType: SORT_NONE,
          sortByStatus: SORT_NONE,
          loading: selectedPerformanceReview ? true : false,
        });
      });

  setSelectedPerformanceReview = (performanceReview) =>
    this.setState({ selectedPerformanceReview: performanceReview });

  setProcessesToDisplay = (performanceReviewId) => {
    this.setState({ processesLoading: true, processesToDisplay: [] });
    return ProcessService.getProcessesByPerformanceReviewId(performanceReviewId)
      .then((processes) => {
        this.setState({
          processes,
          processesToDisplay: processes,
          loading: false,
          processesLoading: false,
        });
        return Promise.all(
          processes.map((p) => ProcessService.getProcessAuditsByProcessId(p.id))
        );
      })
      .then((audits) => {
        this.setState({ audits: audits });
      });
  };

  getProcessesDataAsTable = () => (
    <Row>
      <Col>
        <Table responsive bordered className="process-table">
          <thead>
            <tr>
              <th>Process</th>
              <th className="text-center">
                <SortButton
                  style={{ color: "inherit" }}
                  label="Period"
                  sort={this.state.sortByPeriod}
                  onClick={() => this.sortResults("sortByPeriod")}
                />
              </th>
              <th className="text-center">
                <SortButton
                  style={{ color: "inherit" }}
                  label="Type"
                  sort={this.state.sortByType}
                  onClick={() => this.sortResults("sortByType")}
                />
              </th>
              <th className="text-center">Completion</th>
              <th className="text-center">
                <SortButton
                  style={{ color: "inherit" }}
                  label="Status"
                  sort={this.state.sortByStatus}
                  onClick={() => this.sortResults("sortByStatus")}
                />
              </th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.processesToDisplay.length > 0 ? (
              this.state.processesToDisplay.map((process) => (
                <tr key={process.id}>
                  <td>
                    <Col xs={12} className="title">
                      <h5>{process.title}</h5>
                    </Col>
                    <Row className="mt-2 description">
                      <Col md={3}>
                        <span>
                          <i>Participants</i>:&nbsp;
                          {process.participants.length}
                        </span>
                      </Col>
                      <Col md={9} className="text-right">
                        <span>
                          <i>{`Managed by: ${process.managedBy.firstName} ${process.managedBy.lastName}`}</i>
                        </span>
                      </Col>
                    </Row>
                  </td>
                  <td className="text-center">
                    <ConfigurationPropertiesContext.Consumer>
                      {({ formatDate }) =>
                        `${formatDate(process.startDate)} - ${formatDate(
                          process.endDate
                        )}`
                      }
                    </ConfigurationPropertiesContext.Consumer>
                  </td>
                  <td className="text-center">
                    {this.getTypeAndStatusTitle(process.type)}
                  </td>
                  <td className="text-center">{`${process.completion}%`}</td>
                  <td className="text-center">
                    <a
                      href=""
                      onClick={(event) =>
                        this.handleStatusClick(event, process)
                      }
                    >
                      {this.getTypeAndStatusTitle(process.status)}
                    </a>
                  </td>
                  <td className="text-center">
                    {this.getDashboardButton(process.id)}
                    {this.getEditButton(process)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan={7}>
                  <h3>No processes found.</h3>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );

  getDashboardButton = (id) => (
    <Button
      size="sm"
      variant="primary"
      onClick={() => this.props.history.push(`/processes/${id}/dashboard`)}
    >
      Dashboard
    </Button>
  );

  getEditButton = (process) => (
    <FAButtonAndTooltip
      id={`edit-${process.id}`}
      icon={faEdit}
      tooltipMessage="Edit Process"
      className="ml-1"
      onClick={() => this.handleOpenProcessModal(PROCESS_UPDATE_MODE, process)}
    />
  );

  handleOpenProcessModal = (mode, process) =>
    this.setState({
      processMode: mode,
      selectedProcess: process,
      showProcessModal: true,
    });

  handleCloseModal = () =>
    this.setState({
      showProcessModal: false,
      showAuditModal: false,
      showPRModal: false,
      processMode: undefined,
      selectedProcess: undefined,
    });

  handleSubmitProcess = (values) => {
    if (this.state.processMode === PROCESS_CREATE_MODE) {
      ProcessService.attemptCreatingNewProcess(values).then((response) => {
        if (response.ok) {
          this.showSuccessNotification("The Process is created successfully.");
        } else {
          response
            .json()
            .then((error) => this.showErrorNotification(`${error.message}`));
        }
      });
    } else if (this.state.processMode === PROCESS_UPDATE_MODE) {
      ProcessService.attemptSavingProcessById(values.id, {
        title: values.title,
        startDate: values.startDate,
        endDate: values.endDate,
      }).then((response) => {
        if (response.ok) {
          this.showSuccessNotification("The Process is updated successfully.");
        } else {
          response
            .json()
            .then((error) => this.showErrorNotification(`${error.message}`));
        }
      });
    }
    this.handleCloseModal();
    setTimeout(
      () => this.fetchResources(this.state.selectedPerformanceReview),
      1000
    );
  };

  sortResults = (sortOption) =>
    this.setState(
      (prevState) => ({
        [sortOption]: this.getNextSortOption(prevState[sortOption]),
      }),
      () => this.sortProcessesToReview()
    );

  getNextSortOption = (sortOption) => {
    if (sortOption === SORT_NONE) {
      return SORT_ASC;
    } else if (sortOption === SORT_ASC) {
      return SORT_DESC;
    }
    return SORT_NONE;
  };

  sortProcessesToReview = () => {
    const currentProcessList = [].concat(this.state.processesToDisplay);
    let sortedResults = currentProcessList.sort((a, b) => {
      if (this.state.sortByPeriod !== SORT_NONE && a.startDate > b.startDate) {
        return this.state.sortByPeriod === SORT_ASC ? 1 : -1;
      } else if (
        this.state.sortByPeriod !== SORT_NONE &&
        a.startDate < b.startDate
      ) {
        return this.state.sortByPeriod === SORT_ASC ? -1 : 1;
      } else {
        if (this.state.sortByType !== SORT_NONE && a.type > b.type) {
          return this.state.sortByType === SORT_ASC ? 1 : -1;
        } else if (this.state.sortByType !== SORT_NONE && a.type < b.type) {
          return this.state.sortByType === SORT_ASC ? -1 : 1;
        } else {
          if (this.state.sortByStatus !== SORT_NONE && a.status > b.status) {
            return this.state.sortByStatus === SORT_ASC ? 1 : -1;
          } else if (
            this.state.sortByStatus !== SORT_NONE &&
            a.status < b.status
          ) {
            return this.state.sortByStatus === SORT_ASC ? -1 : 1;
          } else {
            return 0;
          }
        }
      }
    });
    this.setState((prevState) => ({
      processesToDisplay:
        prevState.sortByPeriod === SORT_NONE &&
        prevState.sortByType === SORT_NONE &&
        prevState.sortByStatus === SORT_NONE
          ? prevState.processes
          : sortedResults,
    }));
  };

  getTypeAndStatusTitle = (title) =>
    title.charAt(0) + title.slice(1).toLowerCase();

  handleStatusClick = (event, process) => {
    event.preventDefault();
    ProcessService.getProcessAuditsByProcessId(process.id).then((data) =>
      this.setState({
        showAuditModal: true,
        selectedProcess: process,
        audits: data,
      })
    );
  };

  handleChangePR = (event) => {
    const prId = Number(event.target.value);
    this.setSelectedPerformanceReview(
      prId
        ? this.state.performanceReviews.find(
            (performanceReview) => performanceReview.id === prId
          )
        : undefined
    );
    this.setProcessesToDisplay(prId);
  };

  handleOpenPRModal = (mode) =>
    this.setState({
      prMode: mode,
      showPRModal: true,
    });

  handleSubmitPerformanceReview = async (performanceReview) => {
    if (
      this.state.prMode === PR_CREATE_MODE ||
      this.state.prMode === PR_EDIT_MODE
    ) {
      await PerformanceReviewService.attemptSavingPerformanceReview(
        performanceReview
      ).then((response) => {
        if (response.ok) {
          response.json().then((performanceReview) => {
            this.fetchResources(performanceReview);
            this.showSuccessNotification(
              <FormattedMessage id="PerfomanceReview.success.saved" />
            );
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          this.showErrorNotification(
            <FormattedMessage id="PerfomanceReview.noRights" />
          );
        } else if (response.status === BAD_REQUEST_ERROR_CODE) {
          response
            .json()
            .then((error) => this.showErrorNotification(`${error.message}`));
        }
      });
    } else if (this.state.prMode === PR_DELETE_MODE) {
      await PerformanceReviewService.attemptDeletingPerformanceReviewById(
        performanceReview
      ).then((response) => {
        if (response.ok) {
          this.fetchResources();
          this.showSuccessNotification(
            <FormattedMessage id="PerfomanceReview.success.delete" />
          );
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          this.showErrorNotification(
            <FormattedMessage id="PerfomanceReview.noRights.delete" />
          );
        } else if (response.status === CONFLICT_ERROR_CODE) {
          this.showErrorNotification(
            <FormattedMessage id="PerfomanceReview.assignedProcesses.delete" />
          );
        }
      });
    }
  };

  showSuccessNotification = (message) =>
    this.toastContainer.success(message, "", {
      closeButton: true,
    });

  showErrorNotification = (message) =>
    this.toastContainer.error(message, "", {
      closeButton: true,
    });

  render() {
    return this.state.loading ? (
      <Loading />
    ) : (
      <Container className="animated mb-5">
        <ToastContainer
          ref={(ref) => (this.toastContainer = ref)}
          className="toast-top-right"
        />
        <Row className="mb-4">
          <Col>
            <header className="title text-center">
              <h4 className="processes-header">
                <FormattedMessage id="Processes.perfomanceReviews" />
              </h4>
            </header>
          </Col>
        </Row>
        <PerformanceReviewsFilter
          className="mb-2"
          handleChange={this.handleChangePR}
          selectedPerformanceReview={this.state.selectedPerformanceReview}
          performanceReviews={this.state.performanceReviews}
          handleOpenModal={this.handleOpenPRModal}
          noHeading
        />
        <Row className="mt-4">
          <Col>
            <header className="title text-center">
              <h4 className="processes-header">
                <FormattedMessage id="Processes.processes" />
                {this.state.selectedPerformanceReview && (
                  <FAButtonAndTooltip
                    id="add-process-btn"
                    icon={faPlusSquare}
                    buttonSize="2x"
                    tooltipMessage="Create new process"
                    onClick={() =>
                      this.handleOpenProcessModal(PROCESS_CREATE_MODE)
                    }
                  />
                )}
              </h4>
            </header>
          </Col>
        </Row>
        {this.state.processesToDisplay.length > 0
          ? this.state.processesToDisplay.map((process, index) => {
              let audits;
              if (this.state.audits) audits = this.state.audits[index];
              return (
                <ProcessCard
                  key={index}
                  process={process}
                  managers={this.state.managers}
                  audits={audits}
                  onEditClick={() =>
                    this.handleOpenProcessModal(PROCESS_UPDATE_MODE, process)
                  }
                  showSuccessNotification={this.showSuccessNotification}
                  showErrorNotification={this.showErrorNotification}
                />
              );
            })
          : !this.state.processesLoading && (
              <h3>
                <FormattedMessage id="Processes.noFound" />
              </h3>
            )}
        <ProcessModal
          showModal={this.state.showProcessModal}
          handleCloseModal={this.handleCloseModal}
          submit={this.handleSubmitProcess}
          mode={this.state.processMode}
          process={this.state.selectedProcess}
          user={this.props.user}
          period={this.state.period}
          performanceReview={this.state.selectedPerformanceReview}
        />
        <ProcessStatusTimelineModal
          showModal={this.state.showAuditModal}
          handleClose={this.handleCloseModal}
          process={this.state.selectedProcess}
          processAudits={this.state.audits}
          getStatusTitle={this.getTypeAndStatusTitle}
        />
        <PerformanceReviewModal
          showModal={this.state.showPRModal}
          handleClose={this.handleCloseModal}
          handleSubmit={this.handleSubmitPerformanceReview}
          mode={this.state.prMode}
          performanceReview={
            this.state.prMode !== PR_CREATE_MODE &&
            this.state.selectedPerformanceReview
          }
          period={this.state.period}
        />
      </Container>
    );
  }
}

export default withRouter(Process);
