import React from "react";
import { Modal } from "react-bootstrap";
import {
  ONE_ON_ONE_QUESTION_CREATE_MODE,
  ONE_ON_ONE_QUESTION_EDIT_MODE,
  ONE_ON_ONE_QUESTION_DELETE_MODE,
} from "../../../constants/oneOnOneQuestionsModes";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import AddOneOnOneQuestions from "./AddOneOnOneQuestions";
import EditOneOnOneQuestions from "./EditOneOnOneQuestions";

const OneOnOneQuestionsModal = ({
  showModal,
  handleClose,
  handleSubmit,
  mode,
  types,
  loading,
  question,
}) => {
  return (
    <Modal
      size={
        (mode === ONE_ON_ONE_QUESTION_CREATE_MODE ||
          mode === ONE_ON_ONE_QUESTION_EDIT_MODE) &&
        "lg"
      }
      show={showModal}
      onHide={handleClose}
      centered
    >
      {mode === ONE_ON_ONE_QUESTION_CREATE_MODE && (
        <AddOneOnOneQuestions
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          types={types}
          loading={loading}
        />
      )}
      {mode === ONE_ON_ONE_QUESTION_EDIT_MODE && (
        <EditOneOnOneQuestions
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          types={types}
          loading={loading}
          question={question}
        />
      )}
      {mode === ONE_ON_ONE_QUESTION_DELETE_MODE && (
        <DeleteConfirmation
          question={question}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

export default OneOnOneQuestionsModal;
