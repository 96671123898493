import authFetchJSON from "../components/security/fetchClient";
import { _download } from "./fileSaverService";

const BASE_URL = "/api/v1/performance-reviews";
const BASE_URL_V2 = "/api/v2/performance-reviews";

const PerformanceReviewService = {
  getPerformanceReviews: () =>
    authFetchJSON(BASE_URL).then((response) => response.json()),

  getPerformanceReviewById: (id) =>
    authFetchJSON(`${BASE_URL}/${id}`).then((response) => response.json()),

  getPerformanceReviewsByStatus: (status) =>
    authFetchJSON(
      `${BASE_URL_V2}${status === "any" ? "" : `?status=${status}`}`
    ).then((response) => response.json()),

  getPerformanceReviewPeriodById: (id) =>
    authFetchJSON(`${BASE_URL}/${id}/period`).then((response) =>
      response.json()
    ),

  getActivePerformanceReviewId: () =>
    authFetchJSON(`${BASE_URL}/active/id`).then((response) => response.json()),

  getPersonalTrainingReviews: (userId, page) => {
    let URL = `${BASE_URL}/my-training?userId=${userId}&&page=${page}`;
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },
  getPerformanceReviewUsers: (performanceReviewId) => {
    let URL = `${BASE_URL}/users/${performanceReviewId}`;
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },

  attemptSavingPerformanceReview: (performanceReview) =>
    authFetchJSON(BASE_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(performanceReview),
    }),

  attemptDeletingPerformanceReviewById: (id) =>
    authFetchJSON(`${BASE_URL}/${id}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),

  getPerformanceReviewsStatusCount: () =>
    authFetchJSON(`${BASE_URL_V2}/status-count`).then((response) =>
      response.json()
    ),

  exportPersonalDevelopmentGoals: (performanceReviewId) =>
    _download(
      "POST",
      `${BASE_URL_V2}/export/${performanceReviewId}/development-goals`
    ),
};

export default PerformanceReviewService;
