import React from "react";
import { Modal } from "react-bootstrap";
import {
  USER_CREATE_MODE,
  USER_EDIT_MODE,
  USER_DELETE_MODE,
} from "../../constants/userModes";
import DeleteConfirmation from "../common/DeleteConfirmation";
import EditUserForm from "./EditUserForm";

const UserModal = ({
  showModal,
  handleClose,
  handleSubmit,
  mode,
  user,
  users,
  roles,
  teams,
  loading,
}) => {
  return (
    <Modal
      size={(mode === USER_CREATE_MODE || mode === USER_EDIT_MODE) && "lg"}
      show={showModal}
      onHide={handleClose}
      centered
    >
      {(mode === USER_CREATE_MODE || mode === USER_EDIT_MODE) && (
        <EditUserForm
          user={user}
          handleClose={handleClose}
          handleFormSubmit={handleSubmit}
          users={users}
          roles={roles}
          teams={teams}
          loading={loading}
        />
      )}
      {mode === USER_DELETE_MODE && (
        <DeleteConfirmation
          user={user}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

export default UserModal;
