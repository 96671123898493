import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import UserService from "../../services/UserService";
import ProcessUserService from "../../services/ProcessUserService";
import AuthenticationService from "../../services/AuthenticationService";
import Loading from "./display/Loading";
import ErrorComponent from "./ErrorComponent";
import {
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import { PERMISSION_VIEW_LIMITED } from "../../constants/permissions";

const WithManagerAndPermission = (props) => {
  const [found, setFound] = useState(false);
  const [allowed, setAllowed] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    UserService.getUserById(props.match.params.id)
      .then((user) => {
        ProcessUserService.existsByUserId(props.match.params.id).then(
          (isExisting) => {
            setFound(true);
            setAllowed(
              isExisting ||
                (user.limitedVisibility &&
                  AuthenticationService.hasPermission(
                    props.user,
                    PERMISSION_VIEW_LIMITED
                  )) ||
                (AuthenticationService.hasPermission(
                  props.user,
                  props.permission
                ) &&
                  (user.managerId === props.user.id ||
                    (props.teamAccessView &&
                      props.user.teamId === user.team.id)))
            );
            setLoading(false);
          }
        );
      })

      .catch(() => setLoading(false));
  }, [props]);

  if (loading) {
    return <Loading />;
  } else if (!found) {
    return (
      <ErrorComponent statusCode={NOT_FOUND_ERROR_CODE} user={props.user} />
    );
  } else if (!allowed) {
    return (
      <ErrorComponent statusCode={FORBIDDEN_ERROR_CODE} user={props.user} />
    );
  }
  return props.children;
};

export default withRouter(WithManagerAndPermission);
