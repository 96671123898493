import React, { useState } from "react";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
// import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Button, Col, Form } from "react-bootstrap";
import AuthenticationService from "../../services/AuthenticationService";
import Icon, { ICONS } from "../../icons/Icon";
import ErrorIcon from "../../icons/ErrorIcon";
import TextInput from "./form/TextField";
import SendForgotPassEmail from "../../security/SendForgotPassEmail";
import { USER_CHANGE_PASSWORD_MODE } from "../../constants/userModes";

import "./user-authentication-form.css";

const UserAuthenticationForm = () => {
  const intl = useIntl();
  const [mode, setMode] = useState();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(undefined);

  const handleOpenModal = (mode) => {
    setMode(mode);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setMode();
    setShowModal(false);
  };

  const submitLogin = (credentials) =>
    AuthenticationService.attemptLogIn(credentials).then((response) => {
      if (response.ok) {
        window.location.href = "/";
      } else {
        response.json().then((error) => setError(error));
      }
    });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={Yup.object({
          username: Yup.string().required(
            intl.formatMessage({
              id: "UserAuthenticationForm.validation.error",
            })
          ),
          password: Yup.string().required(
            intl.formatMessage({
              id: "UserAuthenticationForm.validation.error",
            })
          ),
        })}
        onSubmit={(values) => submitLogin(values)}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
        }) => (
          <>
            <Icon
              icon={ICONS.LOGO}
              height="200"
              width="400"
              color="white"
              stroke="white"
              strokeWidth="1"
              fillRule="evenodd"
              style={{ marginTop: "14vmax" }}
            />
            <Form
              className="UserAuthenticationForm-form authentication-form"
              onSubmit={handleSubmit}
            >
              <TextInput
                formGroupClass="UserAuthenticationForm-form"
                formControlClass="UserAuthenticationForm"
                /*                 formControlClass={`UserAuthenticationForm ${
                  isValid ? "" : "notAuthenticated"
                }`} */
                formControlType="text"
                formControlName="username"
                name="username"
                type="text"
                placeholder={intl.formatMessage({
                  id: "UserAuthenticationForm.username",
                })}
                value={values.username}
                handleChange={handleChange}
                onChange={(value) => {
                  setFieldValue("username", value);
                }}
                onBlur={handleBlur}
              />

              <TextInput
                formGroupClass="UserAuthenticationForm-form"
                formControlClass="UserAuthenticationForm"
                /* formControlClass={`UserAuthenticationForm ${
                  error ? "notAuthenticated" : ""
                }`} */
                formControlType="password"
                formControlName="password"
                name="password"
                type="password"
                placeholder={intl.formatMessage({
                  id: "UserAuthenticationForm.password",
                })}
                value={values.password}
                onChange={(value) => {
                  setFieldValue("password", value);
                }}
                onBlur={handleBlur}
              />

              <Form.Group as={Col}>
                <Button
                  variant="link"
                  onClick={() => {
                    handleOpenModal(USER_CHANGE_PASSWORD_MODE);
                  }}
                >
                  <FormattedMessage id="UserAuthenticationForm.forgotPassword" />
                </Button>
              </Form.Group>

              {error && (
                <Form.Group as={Col} className="UserAuthenticationForm-form">
                  <div className="validation-error-msg">
                    {" "}
                    <ErrorIcon height="16px" width="16px" />{" "}
                    <span style={{ marginLeft: "1%" }}> {error.type} </span>
                  </div>
                </Form.Group>
              )}

              <Form.Group
                as={Col}
                style={{ marginTop: "10%" }}
                className="UserAuthenticationForm-form"
              >
                <Button type="submit" variant="primary">
                  <FormattedMessage id="UserAuthenticationForm.login" />
                </Button>
              </Form.Group>
              <Icon
                icon={ICONS.POWERED_BY}
                height="100"
                width="150"
                color="white"
                style={{ marginLeft: "33%", opacity: "50%" }}
              />
            </Form>
          </>
        )}
      </Formik>

      {mode === USER_CHANGE_PASSWORD_MODE && (
        <SendForgotPassEmail onHide={handleCloseModal} showModal={showModal} />
      )}
    </>
  );
};

export default UserAuthenticationForm;
