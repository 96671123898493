import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { ToastContainer } from "react-toastr";
// import moment from "moment";
import moment from "moment-timezone";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SET_ONE_ON_ONE } from "../../constants/oneOnOneModes";
import {
  BAD_REQUEST_ERROR_CODE,
  INTERNAL_SERVER_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import {
  DIRECT_REPORT,
  HR,
  HR_EMPLOYEE,
  MANAGER,
  WE_LISTEN,
} from "../../constants/oneOnOneRefType";
import { INPUT_DATE_FORMAT } from "../../constants/dateTimeFormatterTypes";

import OneOnOnesService from "../../services/OneOnOnesService";
import Loading from "../common/display/Loading";
import Filter from "../common/Filter";
import Pagination from "../admin/Pagination";
import OneOnOneModal from "./OneOnOneModal";
import OneOnOneCard from "./OneOnOneCard";
import AccordionComponent from "../common/AccordionComponent";
import DebouncedSearchInput from "../common/form/DebouncedSearchInput";

import "react-datepicker/dist/react-datepicker.css";
import "./one-on-one.css";

const DEFAULT_FILTER_ITEMS = (user) => [
  {
    value: "upcomming",
    title: <FormattedMessage id="OneOnOneContainer.upcomming" />,
    count: 0,
  },
  {
    value: "history",
    title: <FormattedMessage id="OneOnOneContainer.history" />,
    count: 0,
  },
];

let toastContainer;

const OneOnOneContainer = ({ user, selectedFilter, byEmployee, activeTab }) => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const [types, setTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [filterItems, setFilterItems] = useState(DEFAULT_FILTER_ITEMS(user));
  const [selectedFilterItem, setSelectedFilterItem] = useState(
    location.state?.selectedFilter || DEFAULT_FILTER_ITEMS(user)[0]
  );
  const [closeable, setCloseable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [notFound, setNotFound] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [oneOnOnes, setOneOnOnes] = useState([]);
  const [pages, setPages] = useState();

  // eslint-disable-next-line no-unused-vars
  const [sortBy, setSortBy] = useState("id");
  // eslint-disable-next-line no-unused-vars
  const [sortDir, setSortDir] = useState("desc");
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(10);
  const [name, setName] = useState("");
  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [meetingDate, setMeetingDate] = useState(null);

  const [mode, setMode] = useState(undefined);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const showSuccessNotification = (message) =>
    toastContainer.success(message, "", {
      closeButton: true,
    });
  const showErrorNotification = (message) =>
    toastContainer.error(message, "", {
      closeButton: true,
    });

  const paginationClickHandler = (event) => {
    if (event.target.text === undefined) {
      return;
    } else {
      setCurrentPage(parseInt(event.target.text) - 1);
    }
  };

  const fetchStatuses = (closeable) => {
    Promise.all([OneOnOnesService.getStatuses(closeable)]).then(
      ([statuses]) => {
        setStatuses(statuses);
      }
    );
  };

  const fetchTypes = () => {
    Promise.all([OneOnOnesService.getTypes()]).then(([types]) => {
      setTypes(types);
    });
  };

  const fetchOneOnOnes = (
    userId,
    closeable,
    page,
    sortBy,
    sortDir,
    pageSize,
    name,
    type,
    status,
    meetingDate,
    byEmployee,
    activeTab,
    timezone
  ) => {
    Promise.all([
      OneOnOnesService.searchOneOnOnes(
        userId,
        closeable,
        page,
        sortBy,
        sortDir,
        pageSize,
        name,
        type,
        status,
        meetingDate,
        byEmployee,
        activeTab,
        timezone
      ),
    ]).then(([oneOnOnes]) => {
      setOneOnOnes(oneOnOnes?.oneOnOnes);
      setPages(oneOnOnes?.pages);
      setFilterItems([
        {
          value: "upcomming",
          title: <FormattedMessage id="OneOnOneContainer.upcomming" />,
          count:
            oneOnOnes?.stats?.upcommingCount < 999
              ? oneOnOnes?.stats?.upcommingCount
              : "1k+",
        },
        {
          value: "history",
          title: <FormattedMessage id="OneOnOneContainer.history" />,
          count:
            oneOnOnes?.stats?.closedCount < 999
              ? oneOnOnes?.stats?.closedCount
              : "1k+",
        },
      ]);
    });
  };

  useEffect(() => {
    if (selectedFilterItem?.value) {
      saveFilterValue(selectedFilterItem.value);
      setIsLoading(false);
    }

    Promise.all([
      fetchTypes(),
      fetchStatuses(closeable),
      fetchOneOnOnes(
        user.id,
        closeable,
        currentPage,
        sortBy,
        sortDir,
        pageSize,
        name,
        type,
        status,
        meetingDate,
        byEmployee,
        activeTab,
        moment.tz.guess(true)
      ),
    ]).then(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    closeable,
    currentPage,
    sortBy,
    sortDir,
    pageSize,
    name,
    type,
    status,
    meetingDate,
    isLoading,
    selectedFilterItem,
    byEmployee,
    selectedFilter,
    activeTab,
  ]);

  const saveFilterValue = (filterValue) => {
    setCloseable(filterValue === "history");
    setIsLoading(true);
    history.replace(history.location.pathname, {
      selectedFilter: { value: filterValue ? filterValue : 0 },
    });
  };

  const handleSubmit = async (oneOnOne, user) => {
    if (mode === SET_ONE_ON_ONE) {
      setIsLoading(true);
      await OneOnOnesService.setOneOnOne(oneOnOne, user).then((response) => {
        if (response.ok) {
          response.json().then(() => {
            fetchOneOnOnes(
              user.id,
              closeable,
              currentPage,
              sortBy,
              sortDir,
              pageSize,
              name,
              type,
              status,
              meetingDate,
              byEmployee,
              activeTab,
              moment.tz.guess(true)
            );
            showSuccessNotification(
              <FormattedMessage id="SetOneOnOneForm.success.saved" />
            );
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          showErrorNotification(
            <FormattedMessage id="SetOneOnOneForm.noRights" />
          );
        } else if (
          response.status === BAD_REQUEST_ERROR_CODE ||
          response.status === INTERNAL_SERVER_ERROR_CODE
        ) {
          response
            .json()
            .then((error) => showErrorNotification(`${error.message}`));
        }
        setIsLoading(false);
      });
    }
  };

  const handleChange = (value) => {
    setSelectedFilterItem(filterItems.find((item) => item.value === value));
    setCloseable(value === "history");
    setCurrentPage(0);
    setName("");
    setType(null);
    setStatus(null);
    setMeetingDate(null);
    setIsLoading(true);
  };

  const handleOpenModal = (mode) => {
    setMode(mode);
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setMode(undefined);
    setShowCreateModal(false);
  };

  const getMeetingDate = (meetingDate) => {
    if (meetingDate) {
      return moment(meetingDate).format("DD.MM.YYYY HH:mm");
    } else {
      return "";
    }
  };

  const getHeaderNames = (type, oneOnOne) => {
    switch (type) {
      case HR_EMPLOYEE:
      case DIRECT_REPORT:
      case WE_LISTEN:
        return `${oneOnOne?.person?.firstName} ${oneOnOne?.person?.lastName} > ${oneOnOne?.employee?.firstName} ${oneOnOne?.employee?.lastName}`;

      case HR:
        return oneOnOne?.person
          ? `${oneOnOne?.employee?.firstName} ${oneOnOne?.employee?.lastName} > ${oneOnOne?.person?.firstName} ${oneOnOne?.person?.lastName}`
          : `${oneOnOne?.employee?.firstName} ${oneOnOne?.employee?.lastName}`;
      case MANAGER:
        return oneOnOne?.person
          ? `${oneOnOne?.employee?.firstName} ${oneOnOne?.employee?.lastName} > ${oneOnOne?.person?.firstName} ${oneOnOne?.person?.lastName}`
          : "";
      default:
        return "";
    }
  };

  const getOneOnOneHeader = (type, status, date) => {
    let title = null;
    if (type) {
      title = `${type}`;
    }
    if (status) {
      title += ` / ${status}`;
    }
    if (date) {
      title += ` / ${date}`;
    }
    return title;
  };

  const setNameHandler = (value) => {
    setName(value);
  };

  const setTypeHandler = (value) => {
    setCurrentPage(0);
    setType(value);
  };

  const setStatusHandler = (value) => {
    setCurrentPage(0);
    setStatus(value);
  };

  const setMeetingDateHandler = (data) => {
    setCurrentPage(0);
    setMeetingDate(data);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container fluid style={{ width: "82%" }}>
      <ToastContainer
        ref={(ref) => (toastContainer = ref)}
        className="toast-top-right"
      />
      <Row
        style={{
          width: "14%",
          float: "right",
          marginRight: "-1%",
        }}
      >
        <Col>
          <Button
            className="float-right setOneOnOne"
            variant="link"
            size="sm"
            style={{ fontSize: "1.1rem" }}
            onClick={(event) => {
              handleOpenModal(SET_ONE_ON_ONE);
              event.target.blur();
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            <FormattedMessage id="OneOnOneContainer.create" />
          </Button>
        </Col>
      </Row>
      {!notFound && (
        <>
          <Filter
            className="oneOnOne"
            items={filterItems}
            value={selectedFilterItem?.value}
            onChange={handleChange}
          />

          <Row style={{ padding: 0, marginTop: "2%" }}>
            <Col md={4}>
              <DebouncedSearchInput
                style={{ height: "calc(1.5em + 0.75rem + 5px)" }}
                onChange={setNameHandler}
                placeholder={intl.formatMessage({
                  id: "ProcessParticipants.name",
                })}
                name="name"
                id="name-control"
                size="sm"
                type="text"
              />
            </Col>
            <Form.Group as={Col} className="col-md-4">
              <Select
                value={type}
                options={types?.map((element) => {
                  return {
                    value: element.id,
                    label: element.type,
                  };
                })}
                onChange={setTypeHandler}
                className="type-select"
                classNamePrefix="select"
                placeholder={intl.formatMessage({
                  id: "SetOneOnOneForm.type.default",
                })}
                name="type"
                isClearable
              />
            </Form.Group>
            <Form.Group as={Col} className="col-md-4">
              <Select
                value={status}
                options={statuses?.map((element) => {
                  return {
                    value: element.id,
                    label: element.status,
                  };
                })}
                onChange={setStatusHandler}
                className="status-select"
                classNamePrefix="select"
                placeholder={intl.formatMessage({
                  id: "SetOneOnOneForm.status.default",
                })}
                name="status"
                isClearable
              />
            </Form.Group>
            <Col md={2}>
              <DatePicker
                placeholderText={intl.formatMessage({
                  id: "SetOneOnOneForm.date.default",
                })}
                className="date-picker"
                dateFormat={INPUT_DATE_FORMAT}
                selected={meetingDate}
                size="sm"
                onChange={(date) => setMeetingDateHandler(date)}
              ></DatePicker>
            </Col>
          </Row>

          {oneOnOnes.length === 0 ? (
            <h4 className="title text-center">
              <FormattedMessage id="OneOnOneContainer.notFound" />
            </h4>
          ) : (
            <Container
              style={{
                float: "right",
                width: "69%",
                margin: 0,
                paddingTop: "2%",
                paddingLeft: 0,
              }}
            >
              {oneOnOnes.map((oneOnOne, index) => {
                const meetingDate = getMeetingDate(oneOnOne?.meetingDate);
                const statusLabel = intl.formatMessage({
                  id: `OneOnOneStatus.${oneOnOne?.status?.status}`,
                });
                const typeLabel = intl.formatMessage({
                  id: `OneOnOneType.${oneOnOne?.type?.type}`,
                });
                const title = getOneOnOneHeader(
                  getHeaderNames(oneOnOne?.type?.type, oneOnOne) !== ""
                    ? typeLabel +
                        " / " +
                        getHeaderNames(oneOnOne?.type?.type, oneOnOne)
                    : typeLabel,
                  statusLabel,
                  meetingDate
                );
                return (
                  <AccordionComponent
                    key={index}
                    title={title}
                    textArray={
                      <OneOnOneCard
                        key={index}
                        oneOnOne={oneOnOne}
                        user={user}
                        showSuccessNotification={showSuccessNotification}
                        showErrorNotification={showErrorNotification}
                        reload={setIsLoading}
                        isReload={isLoading}
                        selectedFilterItem={selectedFilterItem}
                        activeTab={activeTab}
                      />
                    }
                  />
                );
              })}
              {pages > 0 && (
                <Pagination
                  style={{ marginTop: "2%", position: "relative" }}
                  currentPage={currentPage}
                  paginationClickHandler={paginationClickHandler}
                  setCurrentPage={setCurrentPage}
                  pages={pages}
                />
              )}
            </Container>
          )}
        </>
      )}
      <OneOnOneModal
        showModal={showCreateModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        mode={mode}
        user={user}
      />
    </Container>
  );
};

export default OneOnOneContainer;
