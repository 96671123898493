import authFetchJSON from "../components/security/fetchClient";
import { _download } from "./fileSaverService";

const BASE_URL = "/api/v1/trainings";

const TrainingsService = {
  getCategories: () =>
    authFetchJSON(`${BASE_URL}/categories`).then((response) => response.json()),

  searchRefTraining: (active, categoryId, refTrainingId) => {
    let URL = `${BASE_URL}/catalog?active=${active}`;
    if (categoryId) {
      URL += `&categoryId=${categoryId}`;
    }
    if (refTrainingId) {
      URL += `&refTrainingId=${refTrainingId}`;
    }
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },
  getPersonalTraining: (userId, performanceReviewId) => {
    let URL = `${BASE_URL}/my-training?userId=${userId}&&performanceReviewId=${performanceReviewId}`;
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },
  getTrainingsCount: (performanceReviewId, status) => {
    let URL = `${BASE_URL}/count-all?performanceReviewId=${performanceReviewId}&status=${status}`;
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },
  getProvidedTrainingsCount: (performanceReviewId, month) => {
    let URL = `${BASE_URL}/count-provided?performanceReviewId=${performanceReviewId}&month=${month}`;
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },
  getProvidedAndNotProvidedTrainingsCount: (performanceReviewId) => {
    let URL = `${BASE_URL}/${performanceReviewId}/training-counts`;
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },
  getTeamTrainings: (managerId, performanceReviewId, page, filters) =>
    authFetchJSON(
      `${BASE_URL}/team?managerId=${managerId}&latestPerformanceReviewId=${performanceReviewId}&page=${page}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(filters),
      }
    ).then((response) => response.json()),
  getUsersTrainings: (performanceReviewId, page, filters) =>
    authFetchJSON(`${BASE_URL}/all/${performanceReviewId}?page=${page}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filters),
    }),
  updateTraining: (trainingId, training) =>
    authFetchJSON(`${BASE_URL}/${trainingId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(training),
    }),
  exportAsExcel: (page, performanceReviewId, data) =>
    _download(
      "POST",
      `${BASE_URL}/export/${performanceReviewId}?page=${page}`,
      data
    ),
};

export default TrainingsService;
