import moment from "moment";
import authFetchJSON from "../components/security/fetchClient";
import { CREATED, SCHEDULED } from "../constants/oneOnOneRefStatus";
import {
  DIRECT_REPORT,
  WE_LISTEN,
  HR_EMPLOYEE,
} from "../constants/oneOnOneRefType";

const BASE_URL = "/api/v1/oneOnOnes";

const OneOnOnesService = {
  getTypes: () =>
    authFetchJSON(`${BASE_URL}/types`).then((response) => response.json()),

  getStatuses: (closeable) => {
    let URL = `${BASE_URL}/statuses`;
    if (closeable !== undefined) {
      URL += `?closeable=${closeable}`;
    }
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },
  setOneOnOne: (data, user) => {
    return authFetchJSON(`${BASE_URL}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        type: data.type.label,
        comment: data.reason,
        employeeId: user.id,
        status:
          data.type.label === DIRECT_REPORT ||
          data.type.label === WE_LISTEN ||
          data.type.label === HR_EMPLOYEE
            ? SCHEDULED
            : CREATED,
        meetingDate:
          (data.type.label === DIRECT_REPORT ||
            data.type.label === WE_LISTEN ||
            data.type.label === HR_EMPLOYEE) &&
          data?.meetingDate
            ? new Date(
                new Date(data?.meetingDate).getTime() -
                  new Date(data?.meetingDate).getTimezoneOffset() * 60000
              ).toJSON()
            : null,
        chosenEmployeeId: data?.employee?.value,
      }),
    });
  },

  updateOneOnOne: (id, values) =>
    authFetchJSON(`${BASE_URL}/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }),

  searchOneOnOnes: (
    userId,
    closeable,
    page,
    sortBy,
    sortDir,
    pageSize,
    name,
    type,
    status,
    date,
    byEmployee,
    activeTab,
    timezone
  ) => {
    let URL = `${BASE_URL}?closeableStats=${closeable}&page=${page}&sortBy=${sortBy}&sortDir=${sortDir}&pageSize=${pageSize}`;
    if (name) {
      URL += `&name=${name}`;
    }
    if (type) {
      URL += `&type=${type?.value}`;
    }
    if (status) {
      URL += `&status=${status?.value}`;
    }
    if (date) {
      URL += `&meetingDate=${moment(date).format("DD.MM.YYYY")}`;
    }
    if (byEmployee) {
      URL += `&employeeId=${userId}`;
    } else {
      URL += `&personId=${userId}`;
    }
    if (activeTab) {
      URL += `&activeTab=${activeTab}`;
    }
    if (timezone) {
      URL += `&timezone=${timezone}`;
    }
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },

  getOneOnOnesAuditsByOneOnOneId: (id, timezone) =>
    authFetchJSON(`${BASE_URL}/${id}/audits?timezone=${timezone}`).then(
      (response) => response.json()
    ),
};

export default OneOnOnesService;
