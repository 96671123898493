import React, { useEffect, useRef, useState } from "react";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import { ToastContainer } from "react-toastr";
import { useMsal } from "@azure/msal-react";
import { Form, Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  USER_CHANGE_PASSWORD_MODE,
  USER_CHANGE_PROFILE_PICTURE_MODE,
} from "../../constants/userModes";
import { authConfig } from "../../msal/authConfig";
import Icon, { ICONS } from "../../icons/Icon";
import UserChangePasswordForm from "./UserChangePasswordForm";
import UserChangeProfilePictureForm from "./UserChangeProfilePictureForm";

import "./sidebar-profile-menu.css";

let toastContainer;

const SIDEBAR_MODAL_CLASS_NAME = "sidebar-modal";

const SideBarProfileMenu = ({
  user,
  profilePicture,
  updateProfilePicture,
  closeUserProfile,
  runAsMeAvailable,
}) => {
  const [mode, setMode] = useState();
  const [showModal, setShowModal] = useState(false);

  const sideBarProfileRef = useRef();

  const { instance } = useMsal();

  useEffect(() => {
    const handleClickOutside = (event) => {
      const path = event.path || event.composedPath();

      if (!path.includes(sideBarProfileRef.current) && !containsModal(path))
        closeUserProfile();
    };

    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [closeUserProfile, sideBarProfileRef]);

  const containsModal = (path) =>
    path.find(
      (p) =>
        typeof p.className === "string" &&
        p.className?.includes(SIDEBAR_MODAL_CLASS_NAME)
    );

  const handleOpenModal = (mode) => {
    setMode(mode);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setMode();
    setShowModal(false);
  };

  const showSuccessNotification = (message) =>
    toastContainer.success(message, "", { closeButton: true });

  const showErrorNotification = (message) =>
    toastContainer.error(message, "", { closeButton: true });

  return (
    <ConfigurationPropertiesContext.Consumer>
      {({ internalUserManagement }) => (
        <>
          <ToastContainer
            ref={(ref) => (toastContainer = ref)}
            className="toast-top-right"
          />
          <div ref={sideBarProfileRef} className="sidebar-right">
            {user && (
              <>
                {runAsMeAvailable && (
                  <>
                    <Form
                      method="post"
                      action="/run-as-me"
                      style={{ display: "inline-block" }}
                    >
                      <Button variant="link" type="submit">
                        <FormattedMessage id="Profile.runAsMe" />
                      </Button>
                    </Form>
                    <br />
                  </>
                )}

                {internalUserManagement && (
                  <>
                    <Icon
                      icon={ICONS.CHANGE_PHOTO}
                      style={{ marginLeft: "4%" }}
                    />
                    <Button
                      variant="link"
                      onClick={() =>
                        handleOpenModal(USER_CHANGE_PROFILE_PICTURE_MODE)
                      }
                    >
                      <FormattedMessage id="Profile.profilePic" />
                    </Button>
                    <br />
                    <Icon
                      icon={ICONS.CHANGE_PASS}
                      style={{ marginLeft: "4%" }}
                    />
                    <Button
                      variant="link"
                      onClick={() => handleOpenModal(USER_CHANGE_PASSWORD_MODE)}
                    >
                      <FormattedMessage id="Profile.passwordChange" />
                    </Button>
                    <br />
                  </>
                )}

                <Icon
                  icon={ICONS.LOGOUT}
                  style={{ marginRight: "7%", marginLeft: "4%" }}
                  height="14"
                  width="14"
                />
                {authConfig.internalUserManagement ? (
                  <a href="/logout">
                    <FormattedMessage id="Profile.Logout" />
                  </a>
                ) : (
                  <Button variant="link" onClick={() => instance.logout()}>
                    <FormattedMessage id="Profile.Logout" />
                  </Button>
                )}
              </>
            )}
          </div>

          {internalUserManagement && (
            <Modal
              className="sidebar-modal"
              show={showModal}
              onHide={handleCloseModal}
              centered
            >
              {mode === USER_CHANGE_PASSWORD_MODE ? (
                <UserChangePasswordForm
                  handleClose={handleCloseModal}
                  showSuccessNotification={showSuccessNotification}
                  showErrorNotification={showErrorNotification}
                />
              ) : (
                mode === USER_CHANGE_PROFILE_PICTURE_MODE && (
                  <UserChangeProfilePictureForm
                    email={user.email}
                    updateProfilePicture={updateProfilePicture}
                    handleClose={handleCloseModal}
                    showSuccessNotification={showSuccessNotification}
                    showErrorNotification={showErrorNotification}
                  />
                )
              )}
            </Modal>
          )}
        </>
      )}
    </ConfigurationPropertiesContext.Consumer>
  );
};

export default SideBarProfileMenu;
