import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/goal-template-groups";

const GoalTemplateGroupService = {
  getTopicsWithoutOwner: () =>
    authFetchJSON(`${BASE_URL}`).then((response) => response.json()),

  getTopicsByOwnerId: (id) =>
    authFetchJSON(`${BASE_URL}?user=${id}`).then((response) => response.json()),

  attemptSavingTopic: (topic) =>
    authFetchJSON(`${BASE_URL}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(topic),
    }),

  attemptDeletingTopicById: (topicId) =>
    authFetchJSON(`${BASE_URL}/${topicId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),
};

export default GoalTemplateGroupService;
