import React from "react";
import { Formik, ErrorMessage } from "formik";
import { Col, Form, Modal, Row, Button } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import Select from "react-select";
import * as Yup from "yup";
import Loading from "../../common/display/Loading";
import ErrorIcon from "../../../icons/ErrorIcon";

const { Group, Label, Control, Check } = Form;
const { Dialog, Body, Title, Header } = Modal;

const AddOneOnOneQuestions = ({
  handleClose,
  handleSubmit,
  types,
  loading,
}) => {
  const intl = useIntl();

  const initializeValues = () => {
    return {
      content: "",
      type: "",
      hasScore: false,
      active: true,
      mandatory: true,
    };
  };

  const validationSchema = (intl) =>
    Yup.object({
      type: Yup.string()
        .ensure()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
      content: Yup.string()
        .max(
          4000,
          <FormattedMessage id="AddOneOnOneQuestions.content.length" />
        )
        .ensure()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    });

  return loading ? (
    <Loading />
  ) : (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initializeValues()}
        validationSchema={validationSchema(intl)}
        onSubmit={(values) => {
          handleSubmit(values);
          handleClose();
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header closeButton />
            <Dialog style={{ width: "90%", maxWidth: "100%" }}>
              <Body style={{ paddingBottom: "0" }}>
                <Title>
                  {" "}
                  <FormattedMessage id="AddOneOnOneQuestions.title" />
                </Title>
                <hr />
                <Row>
                  <Group as={Col} className="col-md-4">
                    <Select
                      value={values.type}
                      options={types?.map((element) => {
                        return {
                          value: element.id,
                          label: element.type,
                        };
                      })}
                      onChange={(selectedOption) => {
                        setFieldValue("type", selectedOption, true);
                      }}
                      className="type-select"
                      classNamePrefix="select"
                      placeholder={intl.formatMessage({
                        id: "SetOneOnOneForm.type.default",
                      })}
                      name="type"
                      isClearable
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="validation-error-msg">
                          {" "}
                          <ErrorIcon height="16px" width="16px" />{" "}
                          <span> {msg} </span>
                        </div>
                      )}
                      name="type"
                    />
                  </Group>
                </Row>
                <Row style={{ marginLeft: "0%", marginRight: "0%" }}>
                  <Label>
                    <FormattedMessage id="AddOneOnOneQuestions.content.label" />
                  </Label>
                  <Control
                    className="text-area"
                    as="textarea"
                    // maxLength={4000}
                    rows={values.content?.length > 250 ? 5 : 1}
                    name="content"
                    value={values.content || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span> {msg} </span>
                      </div>
                    )}
                    name="content"
                  />
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Group
                    as={Col}
                    className="col-md-2"
                    style={{ textAlign: "left" }}
                  >
                    <Check
                      id="question-active"
                      name="active"
                      type="switch"
                      label={
                        <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.active" />
                      }
                      checked={values.active}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Group>
                  <Group
                    as={Col}
                    className="col-md-2"
                    style={{ textAlign: "left" }}
                  >
                    <Check
                      id="question-mandatory"
                      name="mandatory"
                      type="switch"
                      label={
                        <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.mandatory" />
                      }
                      checked={values.mandatory}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Group>
                  <Group
                    as={Col}
                    className="col-md-4"
                    style={{ textAlign: "left", marginLeft: "5%" }}
                  >
                    <Check
                      id="question-hasScore"
                      name="hasScore"
                      type="switch"
                      label={
                        <FormattedMessage id="OneOnOneFeedbackQuestionsContainer.hasScore" />
                      }
                      checked={values.hasScore}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      title={intl.formatMessage({
                        id: "OneOnOneFeedbackQuestionsContainer.hasScore.tooltip",
                      })}
                    />
                  </Group>
                </Row>
              </Body>
            </Dialog>
            <Row>
              <Group as={Col} style={{ marginRight: "5%", textAlign: "right" }}>
                <Button
                  variant="secondary"
                  className="mr-1"
                  onClick={handleClose}
                >
                  <FormattedMessage id="EditUserForm.cancel" />
                </Button>
                <Button type="submit" variant="primary">
                  <FormattedMessage id="EditUserForm.submit" />
                </Button>
              </Group>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddOneOnOneQuestions;
