import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import Select from "react-select";
import { PERMISSION_ASSESS_EMPLOYEES } from "../../constants/permissions";
import {
  DIRECT_REPORT,
  WE_LISTEN,
  HR_EMPLOYEE,
} from "../../constants/oneOnOneRefType";
import { HR, MANAGER as MANAGER_ROLE } from "../../constants/roles";
import ErrorIcon from "../../icons/ErrorIcon";
import Loading from "../common/display/Loading";
import CustomDatePicker from "../common/form/CustomDatePicker";
import AuthenticationService from "../../services/AuthenticationService";
import OneOnOnesService from "../../services/OneOnOnesService";
import ManagerService from "../../services/ManagerService";
import UserService from "../../services/UserService";

const SetOneOnOneForm = ({ handleClose, handleSubmit, user }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const [employees, setEmployees] = useState([]);

  const initializeValues = () => {
    return {
      reason: "",
      type: "",
      employee: { value: null, label: "" },
      meetingDate: null,
    };
  };

  const oneOnOneValidationSchema = (intl) =>
    Yup.object({
      type: Yup.string()
        .ensure()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
      reason: Yup.string()
        .max(50, <FormattedMessage id="SetOneOnOneForm.reason.length" />)
        .ensure()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    });

  const employeeValidationSchema = (intl) =>
    Yup.object({
      employee: Yup.object()
        .nullable()
        .default(undefined)
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
      meetingDate: Yup.date()
        .nullable()
        .default(undefined)
        .notRequired()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    });
  useEffect(() => {
    if (isLoading) {
      OneOnOnesService.getTypes().then((types) => {
        if (
          !AuthenticationService.hasPermission(
            user,
            PERMISSION_ASSESS_EMPLOYEES
          )
        ) {
          setTypes(types.filter((t) => t.type !== DIRECT_REPORT));
          if (!AuthenticationService.hasRole(user, HR)) {
            setTypes(
              types.filter(
                (t) =>
                  t.type !== WE_LISTEN &&
                  t.type !== DIRECT_REPORT &&
                  t.type !== HR_EMPLOYEE
              )
            );
          } else {
            Promise.all([UserService.getAllActiveUsers()]).then(([users]) => {
              setEmployees(users.filter((u) => u.id !== user.id));
            });
          }
          return;
        } else {
          Promise.all([ManagerService.getUsersByManagerId(user.id)]).then(
            ([directReports]) => {
              setEmployees(directReports);
            }
          );

          setTypes(types);
        }
        if (
          AuthenticationService.hasRole(user, HR) &&
          !AuthenticationService.hasRole(user, MANAGER_ROLE)
        ) {
          Promise.all([UserService.getAllActiveUsers()]).then(([users]) => {
            setEmployees(users.filter((u) => u.id !== user.id));
          });
        } else {
          setTypes(
            types.filter((t) => t.type !== WE_LISTEN && t.type !== HR_EMPLOYEE)
          );
        }
      });
      setIsLoading(false);
    }
  }, [isLoading, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <Formik
      enableReinitialize
      initialValues={initializeValues}
      validationSchema={() =>
        Yup.lazy((values) => {
          if (
            values?.type?.label !== DIRECT_REPORT &&
            values?.type?.label !== WE_LISTEN &&
            values?.type?.label !== HR_EMPLOYEE
          ) {
            return oneOnOneValidationSchema(intl);
          } else {
            return oneOnOneValidationSchema(intl).concat(
              employeeValidationSchema(intl)
            );
          }
        })
      }
      onSubmit={(values) => {
        handleSubmit(values, user);
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>
                <FormattedMessage id="SetOneOnOneForm.title" />
              </Modal.Title>
              <hr />
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="SetOneOnOneForm.type" />
                  </Form.Label>
                  <Select
                    value={formik.values.type}
                    style={{ marginTop: "2%" }}
                    options={types?.map((element) => {
                      return {
                        value: element.id,
                        label: element.type,
                      };
                    })}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("type", selectedOption, true);
                    }}
                    className="type-select"
                    classNamePrefix="select"
                    placeholder={intl.formatMessage({
                      id: "SetOneOnOneForm.type.default",
                    })}
                    name="type"
                    isClearable
                  />
                  {formik?.errors?.type && (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}>
                        {" "}
                        {formik?.errors?.type}{" "}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Row>
              {(formik?.values?.type?.label === DIRECT_REPORT ||
                formik?.values?.type?.label === WE_LISTEN ||
                formik?.values?.type?.label === HR_EMPLOYEE) &&
                employees?.length > 0 && (
                  <>
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>
                          <FormattedMessage id="SetOneOnOneForm.employee" />
                        </Form.Label>
                        <Select
                          value={formik.values.employee}
                          style={{ marginTop: "2%" }}
                          options={employees?.map((element) => {
                            return {
                              value: element.id,
                              label: element.fullName,
                            };
                          })}
                          onChange={(selectedOption) => {
                            formik.setFieldValue("employee", selectedOption);
                          }}
                          placeholder={intl.formatMessage({
                            id: "SetOneOnOneForm.employee.default",
                          })}
                          className="type-select"
                          classNamePrefix="select"
                          name="employee"
                          isClearable
                        />
                        {formik?.errors?.employee && (
                          <div className="validation-error-msg">
                            {" "}
                            <ErrorIcon height="16px" width="16px" />{" "}
                            <span style={{ marginLeft: "1%" }}>
                              {" "}
                              {formik?.errors?.employee}{" "}
                            </span>
                          </div>
                        )}
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>
                          <FormattedMessage id="ScheduleOrRescheduleOneOnOneForm.meetingDate.label" />
                        </Form.Label>
                        <CustomDatePicker
                          value={formik.values.meetingDate}
                          name="meetingDate"
                          shouldCloseOnSelect
                          showTimeSelect
                          dateFormat="dd/MM/yyyy HH:mm"
                          timeFormat="HH:mm"
                          timeCaption={intl.formatMessage({
                            id: "ScheduleOrRescheduleOneOnOneForm.datepicker.time.label",
                          })}
                          minDate={new Date().setHours(9, 30)}
                          minTime={new Date().setHours(9, 30)}
                          maxTime={new Date().setHours(18, 30)}
                          excludeDates={[Date.now()]}
                          prevMonthButtonDisabled
                          prevYearButtonDisabled
                        />
                        {formik?.errors?.meetingDate && (
                          <div className="validation-error-msg">
                            {" "}
                            <ErrorIcon height="16px" width="16px" />{" "}
                            <span style={{ marginLeft: "1%" }}>
                              {" "}
                              {formik?.errors?.meetingDate}{" "}
                            </span>
                          </div>
                        )}
                      </Form.Group>
                    </Row>
                  </>
                )}
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="SetOneOnOneForm.reason" />
                  </Form.Label>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Control
                    className="SetOneOnOneForm"
                    type="text"
                    name="reason"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.reason && (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}>
                        {" "}
                        {formik?.errors?.reason}{" "}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="PerformanceReviewForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="SetOneOnOneForm.submit" />
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default SetOneOnOneForm;
