import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import GoalTypeService from "../../services/GoalTypeService";
import Loading from "../common/display/Loading";
import ErrorComponent from "../common/ErrorComponent";
import { NOT_FOUND_ERROR_CODE } from "../../constants/errorStatusCodes";
import Goals from "./Goals";

import "./my-goals.css";

const MyGoalsContainer = ({ user }) => {
  const [performanceReviewGoal, setPerformanceReviewGoal] = useState();
  const [goals, setGoals] = useState([]);
  const [goalTypes, setGoalTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState();

  const { formatDate } = useContext(ConfigurationPropertiesContext);

  useEffect(() => {
    if (user?.id) {
      Promise.all([
        PerformanceReviewGoalService.getCurrentPerformanceReviewGoalByUserId(
          user.id
        ),
        GoalTypeService.getAllGoalTypes(),
      ])
        .then(([performanceReviewGoal, goalTypes]) =>
          PerformanceReviewGoalService.getGoalsByPerformanceReviewGoalId(
            performanceReviewGoal.performanceReviewGoalId
          )
            .then((goals) => {
              setPerformanceReviewGoal(performanceReviewGoal);
              setGoals(goals);
              setGoalTypes(goalTypes);
              setIsLoading(false);
            })
            .catch(handleError)
        )
        .catch(handleError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleError = (error) => {
    if (error.message.includes("not found")) setNotFound(true);
    else setError(error);
    setIsLoading(false);
  };

  return isLoading ? (
    <Loading />
  ) : error ? (
    <ErrorComponent
      statusCode={NOT_FOUND_ERROR_CODE}
      user={user}
      message={error.message}
    />
  ) : (
    <Container className="my-goals" fluid>
      <Row className="mb-5" style={{ marginTop: "3%" }}>
        <Col>
          <header className="text-center">
            {notFound ? (
              <h4>{<FormattedMessage id="MyGoalsContainer.noGoals" />}</h4>
            ) : (
              <>
                <h4> {<FormattedMessage id="Goals.myGoals" />}</h4>
                {/* <h5>{performanceReviewGoal.targetEmployeeTitle}</h5> */}
                <p style={{ color: "#838383" }}>{`${formatDate(
                  performanceReviewGoal.performanceReviewStart
                )} - ${formatDate(
                  performanceReviewGoal.performanceReviewEnd
                )}`}</p>

                {performanceReviewGoal.performanceReviewGoalStatus ===
                  "IN_PROGRESS" &&
                  goals.length > 0 && (
                    <p style={{ marginTop: "10px" }}>
                      <strong
                        style={{
                          fontSize: "20px",
                          border: "2px solid #696784",
                          color: "white",
                          backgroundColor: "#696784",
                          paddingTop: "6px",
                          paddingBottom: "2px",
                          paddingLeft: "18px",
                          paddingRight: "18px",
                        }}
                      >
                        {<FormattedMessage id="MyGoalsContainer.draft" />}
                      </strong>
                    </p>
                  )}
              </>
            )}
          </header>
        </Col>
      </Row>
      <div className="my-goals-table">
        {goalTypes.map((goalType, index) => (
          <Goals
            key={`goals-container-${index}`}
            goals={goals}
            goalType={goalType}
            readOnly={true}
            isMyGoalsView={true}
          />
        ))}
      </div>
    </Container>
  );
};

export default MyGoalsContainer;
