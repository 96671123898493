/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const FAButtonAndTooltip = ({
  id,
  icon,
  disabled = false,
  tooltipType = "dark",
  buttonSize = "lg",
  tooltipPlace = "top",
  tooltipOffset = {},
  tooltipMessage,
  className = "",
  onClick,
}) => {
  return disabled ? (
    <FontAwesomeIcon
      icon={icon}
      size={buttonSize}
      color="#808285"
      className={`${className} disabled`}
    />
  ) : (
    <>
      <a data-tip data-for={id} className={className}>
        <FontAwesomeIcon
          icon={icon}
          size={buttonSize}
          className={className}
          onClick={onClick}
        />
      </a>
      <ReactTooltip
        id={id}
        type={tooltipType}
        place={tooltipPlace}
        offset={tooltipOffset}
        effect="solid"
      >
        {tooltipMessage}
      </ReactTooltip>
    </>
  );
};

export default FAButtonAndTooltip;
