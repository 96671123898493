import * as React from "react";
const AdminTeamsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 25 25",
      marginRight: "2%",
    }}
    viewBox="0 0 25 25"
    {...props}
  >
    <style>{".st0{fill:" + props.color + "}"}</style>
    <path
      d="M17.6 10.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 1 2 2 2zM8.8 9.8c1.6 0 3-1.4 3-3s-1.4-3-3-3-3 1.4-3 3c0 1.7 1.4 3 3 3z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M8.8 11.6c2.6 0 4.8-2.1 4.8-4.8 0-2.6-2.1-4.8-4.8-4.8S4.1 4.2 4.1 6.8c0 2.7 2.1 4.8 4.7 4.8zm0-7.8c1.6 0 3 1.4 3 3s-1.4 3-3 3-3-1.4-3-3 1.4-3 3-3zM9.6 11.9H8.1c-3.2 0-5.8 2.4-5.8 5.4V22c0 .5.4.9.9.9s.9-.4.9-.9v-4.7c0-2 1.8-3.6 4-3.6h1.5c2.2 0 4 1.6 4 3.6V22c0 .5.4.9.9.9s.9-.4.9-.9v-4.8c-.1-2.9-2.7-5.3-5.8-5.3zM17.6 12.3c2.1 0 3.8-1.7 3.8-3.8 0-2.1-1.7-3.8-3.8-3.8-2.1 0-3.8 1.7-3.8 3.8.1 2.1 1.8 3.8 3.8 3.8zm0-5.8c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 1-2 2-2zM18.2 13h-1.1c-.5 0-.9.4-.9.9s.4.9.9.9h1.1c1.5 0 2.8 1.1 2.8 2.5v3.6c0 .5.4.9.9.9s.9-.4.9-.9v-3.6c-.1-2.4-2.1-4.3-4.6-4.3z"
      className="st0"
    />
  </svg>
);
export default AdminTeamsIcon;
