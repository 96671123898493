import React from "react";
import { Col, Figure, Modal, ProgressBar, Row } from "react-bootstrap";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import "../common/timeline.css";

const ProcessStatusTimelineModal = (props) => (
  <Modal show={props.showModal} onHide={props.handleClose} size="lg" centered>
    <Modal.Header closeButton />
    <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
      <Modal.Body>
        <Modal.Title>
          {props.process && `Status for Process '${props.process.title}'`}
        </Modal.Title>
        <hr />
        {props.processAudits.length > 0 ? (
          <div style={{ paddingTop: "130px" }}>
            <Row xs={3} sm={3} md={3} lg={3} xl={3}>
              {props.processAudits.map((audit) => (
                <Col key={audit.id}>
                  <Figure>
                    <Figure.Caption className="timeline-item bigger">
                      <p>
                        <ConfigurationPropertiesContext.Consumer>
                          {({ formatDateTime }) =>
                            formatDateTime(audit.createdAt)
                          }
                        </ConfigurationPropertiesContext.Consumer>
                      </p>
                      <p>
                        {audit.status && props.getStatusTitle(audit.status)}
                      </p>
                    </Figure.Caption>
                  </Figure>
                </Col>
              ))}
            </Row>
            <ProgressBar
              animated
              now={(100 / 3) * props.processAudits.length}
            />
          </div>
        ) : (
          <p>No process history found</p>
        )}
      </Modal.Body>
    </Modal.Dialog>
  </Modal>
);

export default ProcessStatusTimelineModal;
