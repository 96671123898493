import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import Icon, { ICONS } from "../icons/Icon";
import { useMsal } from "@azure/msal-react";
import "./lobby.css";

const Lobby = (props) => {
  let history = useHistory();

  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      !props.appProps.internalUserManagement &&
      !props.appProps.oauth2ImplicitGrant
    ) {
      initializeSignIn();
    } else {
      history.push("/login");
    }
  };

  useEffect(() => {
    document.body.className = "login";
  }, []);

  return (
    <Container
      className="animated slideInLeft"
      style={{ alignItems: "center", paddingTop: "0" }}
    >
      <Row>
        <Col style={{ marginTop: "14vmax" }}>
          <p
            style={{
              fontSize: "40px",
              textAlign: "center",
              marginBottom: "0",
              color: "white",
            }}
          >
            <FormattedMessage id="Lobby.title" />
          </p>
          <Icon
            icon={ICONS.LOGO}
            height="200"
            width="400"
            color="white"
            stroke="white"
            strokeWidth="1"
            fillRule="evenodd"
          />
          <Form className="Lobby-form lobby-form" onClick={handleSubmit}>
            <Form.Group as={Col}>
              <Button type="button" variant="primary" className="Lobby lobby">
                <FormattedMessage id="Lobby.signin" />
              </Button>
            </Form.Group>
          </Form>
          <Icon
            icon={ICONS.POWERED_BY}
            height="100"
            width="150"
            color="white"
            style={{ marginLeft: "32%", opacity: "50%" }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Lobby);
