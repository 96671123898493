import React from "react";
import { Card, Image } from "react-bootstrap";
import GradeSelector from "./GradeSelector";
import "./comment-display.css";

const FINAL_COMMENT_IMAGE_URL = "/images/final-agreement.svg";

export default function CommentDisplay(props) {
  const { user, gradeTitle, assessment, hasGrade, isFinal, marginRight } =
    props;

  return (
    <div className="comment-display-wrapper">
      <div className="image-wrapper">
        <Image
          src={isFinal ? FINAL_COMMENT_IMAGE_URL : user.imageUrl}
          roundedCircle
        />
      </div>
      <Card>
        <Card.Body style={{ flex: "1 1 auto" }}>
          <Card.Title style={!assessment.comment ? { margin: "0" } : {}}>
            {`${user.firstName} ${user.lastName}: `}
            <span className="grade">{gradeTitle}</span>
          </Card.Title>
          <Card.Text>{assessment.comment}</Card.Text>
        </Card.Body>
        {hasGrade && (
          <div style={{ marginRight }}>
            <GradeSelector value={gradeTitle} circleWidth="2.75rem" readOnly />
          </div>
        )}
      </Card>
    </div>
  );
}
