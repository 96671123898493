import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { Formik } from "formik";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { ASSIGNED, REASSIGNED } from "../../constants/oneOnOneRefStatus";
import {
  ASSIGN_ONE_ON_ONE,
  REASSIGN_ONE_ON_ONE,
} from "../../constants/oneOnOneModes";
import { HR } from "../../constants/roles";
import ErrorIcon from "../../icons/ErrorIcon";
import UserService from "../../services/UserService";

const AssignOrReassignOneOnOneForm = ({
  handleClose,
  handleSubmit,
  oneOnOne,
  user,
  mode,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [hrs, setHRs] = useState([]);

  const initializeValues = () => {
    return {
      hr: "",
    };
  };

  const hrValidationSchema = (intl) =>
    Yup.object({
      hr: Yup.object()
        .nullable()
        .default(undefined)
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    });

  useEffect(() => {
    if (isLoading) {
      Promise.all([UserService.getAllActiveUsers(HR)]).then(([users]) => {
        setHRs(
          users.filter(
            (u) => u.id !== user.id && u.id !== oneOnOne?.employee?.id
          )
        );
      });
      setIsLoading(false);
    }
  }, [isLoading, user, oneOnOne]);

  const getStatus = (mode) => {
    if (mode === ASSIGN_ONE_ON_ONE) {
      return ASSIGNED;
    } else if (mode === REASSIGN_ONE_ON_ONE) {
      return REASSIGNED;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initializeValues()}
      validationSchema={() =>
        Yup.lazy((values) => {
          if (mode === REASSIGN_ONE_ON_ONE) {
            return hrValidationSchema(intl);
          }
        })
      }
      onSubmit={(values) => {
        handleSubmit(
          {
            status: getStatus(mode),
            personId: values?.hr?.value,
          },
          mode
        );
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>
                <FormattedMessage
                  id={`OneOnOneStatus.${getStatus(mode)}.title`}
                />
              </Modal.Title>
              <hr />
              {mode === REASSIGN_ONE_ON_ONE && hrs?.length > 0 && (
                <>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>HR</Form.Label>
                      <Select
                        value={formik.values.hr}
                        style={{ marginTop: "2%" }}
                        options={hrs?.map((element) => {
                          return {
                            value: element.id,
                            label: element.fullName,
                          };
                        })}
                        onChange={(selectedOption) => {
                          formik.setFieldValue("hr", selectedOption, true);
                        }}
                        placeholder={intl.formatMessage({
                          id: "AssignOrREassignOneOnOneForm.hr.default",
                        })}
                        className="hr-select"
                        classNamePrefix="select"
                        name="hr"
                        isClearable
                      />
                      {formik?.errors?.hr && (
                        <div className="validation-error-msg">
                          {" "}
                          <ErrorIcon height="16px" width="16px" />{" "}
                          <span style={{ marginLeft: "1%" }}>
                            {" "}
                            {formik?.errors?.hr}{" "}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                </>
              )}
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="PerformanceReviewForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="Users.save" />
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default AssignOrReassignOneOnOneForm;
