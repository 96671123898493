import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { INPUT_DATE_FORMAT } from "../../../constants/dateTimeFormatterTypes";
import useLocale, { getDateFnsLocale } from "../../../i18n/LocaleProvider";
import "react-datepicker/dist/react-datepicker.css";
import "./custom-datepicker-field.css";

export const CustomDatePicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const { locale } = useLocale();
  const [field] = useField(props);

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <DatePicker
      {...field}
      {...props}
      locale={getDateFnsLocale(locale)}
      className="custom-date-picker"
      dateFormat={props.dateFormat ? props.dateFormat : INPUT_DATE_FORMAT}
      dropdownMode="select"
      filterDate={isWeekday}
      selected={(field.value && new Date(field.value)) || undefined}
      onChange={(val) => setFieldValue(field.name, val)}
      weekStartsOn={1}
    />
  );
};

export default CustomDatePicker;
