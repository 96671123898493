import React, { useEffect, useState } from "react";
import AppraisalService from "../../services/AppraisalService";
import { PERMISSION_OVERVIEW } from "../../constants/permissions";
import Loading from "../common/display/Loading";
import ErrorComponent from "../common/ErrorComponent";
import {
  NOT_FOUND_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import AuthenticationService from "../../services/AuthenticationService";

const WithHRProved = (props) => {
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AppraisalService.getAppraisalById(props.match.params.id)
      .then((appraisal) => {
        setFound(true);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [props]);

  if (loading) {
    return <Loading />;
  } else if (props.user && !found) {
    return (
      <ErrorComponent statusCode={NOT_FOUND_ERROR_CODE} user={props.user} />
    );
  } else if (
    !AuthenticationService.hasPermission(props.user, PERMISSION_OVERVIEW)
  ) {
    return (
      <ErrorComponent statusCode={FORBIDDEN_ERROR_CODE} user={props.user} />
    );
  }
  return props.children;
};

export default WithHRProved;
