import React, { useState } from "react";
import { Card, Accordion } from "react-bootstrap";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccordionComponent = ({ title, textArray, openCloseAccordion }) => {
  const [open, setOpen] = useState(false);
  return (
    <Accordion
      activeKey={open ? "0" : undefined}
      onSelect={() => setOpen(!open)}
    >
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          {title}
          {open ? (
            <FontAwesomeIcon
              icon={faAngleUp}
              size="lg"
              className="float-right"
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleDown}
              size="lg"
              className="float-right"
            />
          )}
        </Accordion.Toggle>
        {textArray.length !== 0 && (
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Card.Text>{textArray}</Card.Text>
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
};

export default AccordionComponent;
