import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import ErrorIcon from "../../icons/ErrorIcon";
import { CANCELLED } from "../../constants/oneOnOneRefStatus";

const CancelOneOnOneForm = ({ handleClose, handleSubmit, mode }) => {
  const intl = useIntl();

  const initializeValues = () => {
    return {
      reason: "",
    };
  };

  const oneOnOneValidationSchema = (intl) =>
    Yup.object({
      reason: Yup.string()
        .max(50, <FormattedMessage id="SetOneOnOneForm.reason.length" />)
        .ensure()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    });

  return (
    <Formik
      enableReinitialize
      initialValues={initializeValues}
      validationSchema={oneOnOneValidationSchema(intl)}
      onSubmit={(values) => {
        handleSubmit(
          {
            cancelReason: values.reason,
            status: CANCELLED,
          },
          mode
        );
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  textAlign: "center",
                  fontSize: "larger",
                }}
              >
                {<FormattedMessage id="Cancel.confirm" />}
              </Modal.Title>
              <hr />
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="CancelOneOnOneForm.cancelReason.label" />
                  </Form.Label>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Control
                    className="CancelOneOnOneForm"
                    type="text"
                    name="reason"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.reason && (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}>
                        {" "}
                        {formik?.errors?.reason}{" "}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="PerformanceReviewForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="Users.save" />
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default CancelOneOnOneForm;
