import React, { useEffect, useState } from "react";
import { Pagination as PaginationRB } from "react-bootstrap";

const Pagination = ({ active, pages, onClick }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    populateItems(pages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, pages]);

  const populateItems = (pages) => {
    const tempItems = [];

    if (active !== 0) {
      tempItems.push(<PaginationRB.First onClick={() => onClick(0)} />);
      tempItems.push(<PaginationRB.Prev onClick={() => onClick(active - 1)} />);
    }

    for (let number = 0; number < pages; number++) {
      tempItems.push(
        <PaginationRB.Item
          key={number}
          active={number === active}
          onClick={() => onClick(number)}
        >
          {number + 1}
        </PaginationRB.Item>
      );
    }

    if (pages > 0 && active !== pages - 1) {
      tempItems.push(<PaginationRB.Next onClick={() => onClick(active + 1)} />);
      tempItems.push(<PaginationRB.Last onClick={() => onClick(pages - 1)} />);
    }

    setItems(tempItems);
  };

  return <PaginationRB>{items}</PaginationRB>;
};

export default Pagination;
