import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment-timezone";
import {
  PERMISSION_ASSESS_EMPLOYEES,
  SET_ONE_ON_ONE,
} from "../../constants/permissions";
import { HR } from "../../constants/roles";
import {
  BAD_REQUEST_ERROR_CODE,
  INTERNAL_SERVER_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import {
  CREATED,
  SCHEDULED,
  RESCHEDULED,
  ASSIGNED,
  REASSIGNED,
} from "../../constants/oneOnOneRefStatus";
import {
  DIRECT_REPORT,
  WE_LISTEN,
  HR_EMPLOYEE,
} from "../../constants/oneOnOneRefType";
import {
  SCHEDULE_ONE_ON_ONE,
  RESCHEDULE_ONE_ON_ONE,
  CANCEL_ONE_ON_ONE,
  COMPLETE_ONE_ON_ONE,
  ASSIGN_ONE_ON_ONE,
  REASSIGN_ONE_ON_ONE,
} from "../../constants/oneOnOneModes";

import OneOnOnesService from "../../services/OneOnOnesService";
import AuthenticationService from "../../services/AuthenticationService";
import Loading from "../common/display/Loading";
import OneOnOneComment from "./OneOnOneComment";
import OneOnOneModal from "./OneOnOneModal";
import OneOnOneTimeline from "./OneOnOneTimeline";
import OneOnOneFeedbackView from "./OneOnOneFeedbackView";
import "./one-on-one-card.css";

export default function OneOnOneCard(props) {
  const {
    oneOnOne,
    user,
    showSuccessNotification,
    showErrorNotification,
    reload,
    selectedFilterItem,
    activeTab,
  } = props;

  const intl = useIntl();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [audits, setAudits] = useState([]);
  const [mode, setMode] = useState(undefined);
  const [showScheduleOrReschedule, setShowScheduleOrReschedule] =
    useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [showAssignOrReassign, setShowAssignOrReassign] = useState(false);

  const handleMode = (mode) => {
    setMode(mode);
    if (mode === SCHEDULE_ONE_ON_ONE || mode === RESCHEDULE_ONE_ON_ONE) {
      setShowScheduleOrReschedule(true);
    } else if (mode === CANCEL_ONE_ON_ONE) {
      setShowCancel(true);
    } else if (mode === COMPLETE_ONE_ON_ONE) {
      setShowComplete(true);
    } else if (mode === REASSIGN_ONE_ON_ONE) {
      setShowAssignOrReassign(true);
    }
  };

  const handleCloseModal = () => {
    setMode(undefined);
    if (mode === SCHEDULE_ONE_ON_ONE || mode === RESCHEDULE_ONE_ON_ONE) {
      setShowScheduleOrReschedule(false);
    } else if (mode === CANCEL_ONE_ON_ONE) {
      setShowCancel(false);
    } else if (mode === COMPLETE_ONE_ON_ONE) {
      setShowComplete(false);
    } else if (mode === REASSIGN_ONE_ON_ONE) {
      setShowAssignOrReassign(false);
    }
  };

  useEffect(() => {
    if (isLoading || history?.location?.pathname !== selectedFilterItem) {
      Promise.all([
        OneOnOnesService.getOneOnOnesAuditsByOneOnOneId(
          oneOnOne.id,
          moment.tz.guess(true)
        ),
      ]).then(([audits]) => {
        setAudits(audits);
      });
      setIsLoading(false);
      reload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, history, selectedFilterItem, oneOnOne]);

  const scheduleOneOnOne = async (values, mode) => {
    if (
      mode === SCHEDULE_ONE_ON_ONE ||
      mode === RESCHEDULE_ONE_ON_ONE ||
      mode === COMPLETE_ONE_ON_ONE
    ) {
      await OneOnOnesService.updateOneOnOne(oneOnOne.id, values).then(
        (response) => {
          setShowScheduleOrReschedule(false);
          setShowComplete(false);
          if (response.ok) {
            if (
              mode === SCHEDULE_ONE_ON_ONE ||
              mode === RESCHEDULE_ONE_ON_ONE
            ) {
              showSuccessNotification(
                intl.formatMessage({
                  id: "OneOnOneCard.ok",
                })
              );
            } else {
              showSuccessNotification(
                intl.formatMessage({
                  id: "OneOnOneCard.completed.ok",
                })
              );
            }

            setTimeout(() => reload(true), 1000);
          } else if (
            response.status === BAD_REQUEST_ERROR_CODE ||
            response.status === INTERNAL_SERVER_ERROR_CODE
          ) {
            response
              .json()
              .then((error) => showErrorNotification(error.message));
          }
        }
      );
    } else if (mode === CANCEL_ONE_ON_ONE) {
      await OneOnOnesService.updateOneOnOne(oneOnOne.id, values).then(
        (response) => {
          setShowCancel(false);
          if (response.ok) {
            showSuccessNotification(
              intl.formatMessage({
                id: "OneOnOneCard.cancelled.ok",
              })
            );
            setTimeout(() => reload(true), 1000);
          } else if (
            response.status === BAD_REQUEST_ERROR_CODE ||
            response.status === INTERNAL_SERVER_ERROR_CODE
          ) {
            response
              .json()
              .then((error) => showErrorNotification(error.message));
          }
        }
      );
    } else if (mode === ASSIGN_ONE_ON_ONE || mode === REASSIGN_ONE_ON_ONE) {
      await OneOnOnesService.updateOneOnOne(oneOnOne.id, values).then(
        (response) => {
          setShowAssignOrReassign(false);
          if (response.ok) {
            showSuccessNotification(
              intl.formatMessage({
                id: "OneOnOneCard.assigned.ok",
              })
            );
            setTimeout(() => reload(true), 1000);
          } else if (
            response.status === BAD_REQUEST_ERROR_CODE ||
            response.status === INTERNAL_SERVER_ERROR_CODE
          ) {
            response
              .json()
              .then((error) => showErrorNotification(error.message));
          }
        }
      );
    }
  };

  const getPopupToShow = (mode) => {
    if (mode === SCHEDULE_ONE_ON_ONE || mode === RESCHEDULE_ONE_ON_ONE) {
      return showScheduleOrReschedule;
    } else if (mode === CANCEL_ONE_ON_ONE) {
      return showCancel;
    } else if (mode === COMPLETE_ONE_ON_ONE) {
      return showComplete;
    } else if (mode === REASSIGN_ONE_ON_ONE) {
      return showAssignOrReassign;
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Card className="one-on-one-card">
      <Card.Header>
        <Card.Subtitle>
          <span className="info-title">
            <FormattedMessage id="OneOnOneCard.employee" />
            {"  "}
          </span>{" "}
          {oneOnOne?.type?.type === DIRECT_REPORT ||
          oneOnOne?.type?.type === WE_LISTEN ||
          oneOnOne?.type?.type === HR_EMPLOYEE
            ? `${oneOnOne?.person?.firstName} ${oneOnOne?.person?.lastName}`
            : `${oneOnOne?.employee?.firstName} ${oneOnOne?.employee?.lastName}`}
        </Card.Subtitle>
      </Card.Header>

      {audits && (
        <Card.Body className="flex-display-wrap">
          <div className="text-center">
            <span className="info-title">
              <FormattedMessage id="OneOnOneCard.timeline.label" />{" "}
            </span>
            <br />
            <OneOnOneTimeline audits={audits} />
          </div>
        </Card.Body>
      )}
      {oneOnOne?.comment && (
        <>
          <h4 className="oneOnOne-label">
            <FormattedMessage id="OneOnOneCard.reason.label" />
          </h4>
          {oneOnOne?.type?.type === DIRECT_REPORT ||
          oneOnOne?.type?.type === WE_LISTEN ||
          oneOnOne?.type?.type === HR_EMPLOYEE ? (
            <OneOnOneComment
              user={oneOnOne?.person}
              comment={oneOnOne?.comment}
              userEmail={oneOnOne?.person?.email}
              isFinal={false}
              marginRight={"3.5rem"}
            />
          ) : (
            <OneOnOneComment
              user={oneOnOne?.employee}
              comment={oneOnOne?.comment}
              userEmail={oneOnOne?.employee?.email}
              isFinal={false}
              marginRight={"3.5rem"}
            />
          )}
        </>
      )}

      {oneOnOne?.rescheduleReason && (
        <>
          <h4 className="oneOnOne-label">
            <FormattedMessage id="ScheduleOrRescheduleOneOnOneForm.rescheduleReason.label" />
          </h4>
          <OneOnOneComment
            user={oneOnOne?.person}
            comment={oneOnOne?.rescheduleReason}
            userEmail={oneOnOne?.person?.email}
            isFinal={false}
            marginRight={"3.5rem"}
          />
        </>
      )}

      {oneOnOne?.oneOnOneFeedbacks?.length > 0 && (
        <>
          <h4 className="oneOnOne-label">
            {" "}
            <FormattedMessage id="OneOnOneCard.feedback.label" />
          </h4>
          <OneOnOneFeedbackView
            type={{
              label: oneOnOne?.type?.type,
              value: oneOnOne?.type?.id,
            }}
            oneOnOne={oneOnOne}
            oneonOneFeedback={oneOnOne?.oneOnOneFeedbacks}
            setOneOnOneFeedback={() => {}}
            setTotalMandatoryQuestions={() => {}}
            viewOnly={true}
          />
        </>
      )}

      {oneOnOne?.actionPlan && (
        <>
          <h4 className="oneOnOne-label">
            <FormattedMessage id="OneOnOneCard.actionPlan.label" />
          </h4>
          <Row
            className="appraisal-preview-row appraisal-row content"
            style={{
              display: "flex",
              margin: "1%",
              flexDirection: "column",
            }}
          >
            <Col>
              <Form.Control
                className="text-area"
                as="textarea"
                rows={oneOnOne?.actionPlan?.length > 250 ? 5 : 1}
                maxLength={4000}
                value={oneOnOne?.actionPlan || ""}
                disabled={true}
              />
            </Col>
          </Row>
        </>
      )}

      {oneOnOne?.cancelReason && (
        <>
          <h4 className="oneOnOne-label">
            <FormattedMessage id="CancelOneOnOneForm.cancelReason.label" />
          </h4>
          <OneOnOneComment
            user={oneOnOne?.person}
            comment={oneOnOne?.cancelReason}
            userEmail={oneOnOne?.person?.email}
            isFinal={false}
            marginRight={"3.5rem"}
          />
        </>
      )}

      <Card.Footer className="flex-display-wrap">
        <div className="footer-actions-wrapper flex-display-wrap">
          {oneOnOne?.status?.status === CREATED &&
            AuthenticationService.hasRole(user, HR) &&
            activeTab === "hr" &&
            oneOnOne?.employee?.id !== user.id && (
              <>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    scheduleOneOnOne(
                      { personId: user.id, status: ASSIGNED },
                      ASSIGN_ONE_ON_ONE
                    );
                  }}
                >
                  <FormattedMessage id="OneOnOneCard.button.assign.label" />
                </Button>
              </>
            )}
          {oneOnOne?.status?.status === SCHEDULED &&
            AuthenticationService.hasRole(user, HR) &&
            activeTab === "hr" &&
            oneOnOne?.person &&
            oneOnOne?.employee?.id !== user.id && (
              <>
                <Button
                  variant="outline-primary"
                  onClick={() => handleMode(REASSIGN_ONE_ON_ONE)}
                >
                  <FormattedMessage id="OneOnOneCard.button.reassign.label" />
                </Button>
              </>
            )}

          {oneOnOne?.status?.status === CREATED &&
            (AuthenticationService.hasPermission(
              user,
              PERMISSION_ASSESS_EMPLOYEES
            ) ||
              AuthenticationService.hasRole(oneOnOne?.person, HR)) &&
            oneOnOne?.employee?.id !== user.id &&
            oneOnOne?.person?.id === user.id && (
              <>
                {oneOnOne?.person && (
                  <>
                    <Button
                      variant="outline-primary"
                      onClick={() => handleMode(SCHEDULE_ONE_ON_ONE)}
                    >
                      <FormattedMessage id="OneOnOneCard.button.schedule.label" />
                    </Button>
                  </>
                )}
              </>
            )}

          {oneOnOne?.status?.status === ASSIGNED &&
            AuthenticationService.hasRole(user, HR) &&
            oneOnOne?.employee?.id !== user.id && (
              <>
                {oneOnOne?.person && oneOnOne?.person?.id === user.id && (
                  <>
                    <Button
                      variant="outline-primary"
                      onClick={() => handleMode(SCHEDULE_ONE_ON_ONE)}
                    >
                      <FormattedMessage id="OneOnOneCard.button.schedule.label" />
                    </Button>
                  </>
                )}
              </>
            )}

          {oneOnOne?.status?.status === SCHEDULED &&
            (AuthenticationService.hasPermission(
              oneOnOne?.person,
              PERMISSION_ASSESS_EMPLOYEES
            ) ||
              AuthenticationService.hasRole(oneOnOne?.person, HR)) &&
            oneOnOne?.person?.id === user.id &&
            oneOnOne?.employee?.id !== user.id && (
              <>
                <Button
                  variant="outline-primary"
                  onClick={() => handleMode(RESCHEDULE_ONE_ON_ONE)}
                >
                  <FormattedMessage id="OneOnOneCard.button.reschedule.label" />
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => handleMode(COMPLETE_ONE_ON_ONE)}
                >
                  <FormattedMessage id="OneOnOneCard.button.complete.label" />
                </Button>
              </>
            )}
          {(oneOnOne?.status?.status === RESCHEDULED ||
            oneOnOne?.status?.status === REASSIGNED) &&
            (AuthenticationService.hasPermission(
              oneOnOne?.person,
              PERMISSION_ASSESS_EMPLOYEES
            ) ||
              AuthenticationService.hasRole(oneOnOne?.person, HR)) &&
            oneOnOne?.person?.id === user.id && (
              <>
                <Button
                  variant="outline-primary"
                  onClick={() => handleMode(COMPLETE_ONE_ON_ONE)}
                >
                  <FormattedMessage id="OneOnOneCard.button.complete.label" />
                </Button>
              </>
            )}
          {(AuthenticationService.hasPermission(
            oneOnOne?.person,
            PERMISSION_ASSESS_EMPLOYEES
          ) ||
            AuthenticationService.hasRole(oneOnOne?.person, HR)) &&
            oneOnOne?.status?.closeable === false &&
            oneOnOne?.person?.id === user.id && (
              <Button
                variant="outline-primary"
                onClick={() => handleMode(CANCEL_ONE_ON_ONE)}
              >
                <FormattedMessage id="OneOnOneCard.button.cancel.label" />
              </Button>
            )}
        </div>
      </Card.Footer>
      <OneOnOneModal
        showModal={getPopupToShow(mode)}
        handleClose={handleCloseModal}
        handleSubmit={scheduleOneOnOne}
        mode={mode}
        user={user}
        oneOnOne={mode !== SET_ONE_ON_ONE && oneOnOne}
      />
    </Card>
  );
}
