import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/admin";

const AdminService = {
  getUsers: (page, roleId, teamId, name, managerId, isActive) =>
    authFetchJSON(
      `${BASE_URL}/users?page=${page}&roleId=${roleId}&teamId=${teamId}&name=${name}&managerId=${managerId}&isActive=${isActive}`
    ).then((response) => response.json()),

  searchUsers: (page, roleId, isActive, teamId, name, managerId) => {
    let URL = `${BASE_URL}/users?page=${page}`;
    if (roleId) {
      URL += `&roleId=${roleId}`;
    }
    if (teamId) {
      URL += `&teamId=${teamId}`;
    }
    if (name) {
      URL += `&name=${name}`;
    }
    if (managerId) {
      URL += `&managerId=${managerId}`;
    }
    if (isActive) {
      URL += `&isActive=${isActive}`;
    }

    authFetchJSON(`${URL}`).then((response) => response.json());
  },

  getUsersByRoleId: (roleId, isActive) =>
    authFetchJSON(
      `${BASE_URL}/users?roleId=${roleId}&isActive=${isActive}`
    ).then((response) => response.json()),

  getAvaibleManagers: (userId) =>
    authFetchJSON(
      `${BASE_URL}/available-managers?${
        userId ? `userId=${userId}` : `userId=`
      }`
    ).then((response) => response.json()),

  createUser: (userData) =>
    authFetchJSON(`${BASE_URL}/users`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    }),

  updateUser: (userId, userData) =>
    authFetchJSON(`${BASE_URL}/users/${userId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    }),

  deleteUser: (userId) =>
    authFetchJSON(`${BASE_URL}/users/${userId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),

  getAuditLogsPagable: (page, sortBy, username, startDate, endDate) =>
    authFetchJSON(
      `${BASE_URL}/users/audit-logs?page=${page}&sortBy=${sortBy}&username=${username}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ startDate, endDate }),
      }
    ).then((response) => response.json()),
};

export default AdminService;
