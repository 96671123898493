import React, { useEffect, useState } from "react";
import {
  GOAL_COPY_MODE,
  GOAL_CREATE_MODE,
  GOAL_DELETE_MODE,
  GOAL_UPDATE_MODE,
} from "../../constants/goalModes";
import GoalTemplateService from "../../services/GoalTemplateService";
import Loading from "../common/display/Loading";
import Goals from "./Goals";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FormattedMessage } from "react-intl";
const GoalTemplatesContainer = ({ filter, goalTypes, handleOpenModal }) => {
  const { goalTemplateGroup, owner } = filter;

  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (owner
      ? GoalTemplateService.getAllGoalTemplatesByGoalTemplateGroupIdAndOwnerId(
          goalTemplateGroup.id,
          owner.id
        )
      : GoalTemplateService.getAllGoalTemplatesByGoalTemplateGroupIdAndWithoutOwner(
          goalTemplateGroup.id
        )
    ).then((fetchedGoals) => {
      fetchedGoals.forEach(addActions);

      setGoals(fetchedGoals);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner, goalTemplateGroup]);

  const addActions = (goal) =>
    Object.assign(goal, {
      buttons: [
        {
          icon: faEdit,
          label: <FormattedMessage id="Button.edit" />,
          action: () => handleOpenModal(GOAL_UPDATE_MODE, goal),
        },
        {
          icon: faTrashAlt,
          label: <FormattedMessage id="Button.delete" />,
          action: () => handleOpenModal(GOAL_DELETE_MODE, goal),
        },
      ],
    });

  const addGoal = (goalType) => handleOpenModal(GOAL_CREATE_MODE, goalType);
  const copyGoal = (goalType) => handleOpenModal(GOAL_COPY_MODE, goalType);

  return isLoading ? (
    <Loading />
  ) : (
    goalTypes.map((goalType, index) => (
      <Goals
        key={`goals-container-${index}`}
        addGoalAction={() => addGoal(goalType)}
        copyGoalsAction={
          owner !== undefined ? () => copyGoal(goalType) : undefined
        }
        goals={goals}
        goalType={goalType}
        isOrganizational={owner === undefined}
        isTemplate={true}
      />
    ))
  );
};

export default GoalTemplatesContainer;
