import React from "react";
import { Modal } from "react-bootstrap";
import {
  SET_ONE_ON_ONE,
  SCHEDULE_ONE_ON_ONE,
  RESCHEDULE_ONE_ON_ONE,
  COMPLETE_ONE_ON_ONE,
  CANCEL_ONE_ON_ONE,
  REASSIGN_ONE_ON_ONE,
  ASSIGN_ONE_ON_ONE,
} from "../../constants/oneOnOneModes";
import SetOneOnOneForm from "./SetOneOnOneForm";
import ScheduleOrRescheduleOneOnOneForm from "./ScheduleOrRescheduleOneOnOneForm";
import CompleteOneOnOneForm from "./CompleteOneOnOneForm";
import CancelOneOnOneForm from "./CancelOneOnOneForm";
import AssignOrReassignOneOnOneForm from "./AssignOrReassignOneOnOneForm";

const OneOnOneModal = ({
  showModal,
  handleClose,
  handleSubmit,
  mode,
  user,
  oneOnOne,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      size={mode === COMPLETE_ONE_ON_ONE && "lg"}
    >
      {mode === SET_ONE_ON_ONE && (
        <SetOneOnOneForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          user={user}
        />
      )}
      {(mode === SCHEDULE_ONE_ON_ONE || mode === RESCHEDULE_ONE_ON_ONE) && (
        <ScheduleOrRescheduleOneOnOneForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          user={user}
          oneOnOne={oneOnOne}
          mode={mode}
        />
      )}
      {mode === COMPLETE_ONE_ON_ONE && (
        <CompleteOneOnOneForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          oneOnOne={oneOnOne}
          mode={mode}
        />
      )}
      {(mode === ASSIGN_ONE_ON_ONE || mode === REASSIGN_ONE_ON_ONE) && (
        <AssignOrReassignOneOnOneForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          user={user}
          oneOnOne={oneOnOne}
          mode={mode}
        />
      )}
      {mode === CANCEL_ONE_ON_ONE && (
        <CancelOneOnOneForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          mode={mode}
        />
      )}
    </Modal>
  );
};

export default OneOnOneModal;
