import {
  APPRAISAL_TYPE_FINAL,
  APPRAISAL_TYPE_INTERMEDIATE,
} from "../constants/appraisalType";

export const getAppraisalTitle = (appraisalType) => {
  switch (appraisalType) {
    case APPRAISAL_TYPE_INTERMEDIATE:
      return "Midterm";
    case APPRAISAL_TYPE_FINAL:
      return "Annual";
    default:
      return;
  }
};
