import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SORT_NONE, SORT_ASC, SORT_DESC } from "../../../constants/resultsSort";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

const SortButton = ({ style, label, sort, onClick }) => {
  return (
    <span style={style} className="sort" onClick={onClick}>
      {label}{" "}
      <FontAwesomeIcon
        icon={
          (sort === SORT_NONE && faSort) ||
          (sort === SORT_ASC && faSortUp) ||
          (sort === SORT_DESC && faSortDown)
        }
      />
    </span>
  );
};

export default SortButton;
