import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ManagerGradeSelector from "../manager/feedback/ManagerGradeSelector";

const ManagerFeedbackContainer = ({
  questionId,
  question,
  setManagerFeedback,
  feedback,
  viewOnly,
  grades,
}) => {
  const [score, setScore] = useState(
    feedback && feedback.score > 0 ? feedback.score : 0
  );
  const [comment, setComment] = useState(feedback ? feedback.comment : "");

  const onUpdate = (comment, score) => {
    setComment(comment);
    setScore(score);
    setManagerFeedback(questionId, comment, score, question);
  };
  return (
    <>
      <>
        <Row className="appraisal-preview-row appraisal-row content">
          <Col className="col-9">
            <h5>{question}</h5>
          </Col>

          <Col
            style={{
              marginLeft: "45px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!viewOnly ? (
              <ManagerGradeSelector
                onChange={(rating) => {
                  onUpdate(comment, rating);
                }}
                grades={grades}
                readOnly={false}
                circleWidth="2.75rem"
                value={score}
                id={questionId}
              />
            ) : (
              <ManagerGradeSelector
                grades={grades}
                readOnly={true}
                circleWidth="2.75rem"
                value={score}
                id={questionId}
              />
            )}
          </Col>
        </Row>
        <Row className="content comment-row">
          <Col>
            <Row>
              <Col>
                <>
                  <h6>
                    <FormattedMessage id="Goal.comment" />
                    <Form.Control
                      className="text-area"
                      as="textarea"
                      rows={comment?.length > 250 ? 5 : 1}
                      maxLength={4000}
                      value={comment || ""}
                      onChange={(event) => {
                        setComment(comment);
                        onUpdate(event.target.value, score);
                      }}
                      disabled={viewOnly}
                    />
                  </h6>
                </>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </>
  );
};

export default ManagerFeedbackContainer;
