import React from "react";
import { Table } from "react-bootstrap";
import UserInfo from "./UserInfo";
import "../common/table/table.css";
import { FormattedMessage } from "react-intl";
const UsersTable = ({ principal, users, handleOpenModal }) => {
  return (
    <Table className="actionsTable" responsive borderless>
      <thead>
        <tr className="table-title">
          <th colSpan={2}>
            <FormattedMessage id="UsersTable.name" />
          </th>
          <th>
            <FormattedMessage id="UsersTable.role" />
          </th>
          <th>
            <FormattedMessage id="UsersTable.team" />
          </th>
          <th>
            <FormattedMessage id="UsersTable.manager" />
          </th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <UserInfo
            key={`employee-row-${index}`}
            user={user}
            principal={principal}
            handleOpenModal={handleOpenModal}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default UsersTable;
