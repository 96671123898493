import authFetchJSON from "../components/security/fetchClient";

const HomeService = {
  isRunAsMeAvailable: () =>
    authFetchJSON("/run-as-me/available").then((response) => response.json()),

  runAs: (username) =>
    authFetchJSON(`/run-as?username=${username}`, { method: "POST" }),
};

export default HomeService;
