import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import {
  APPRAISAL_STATUS_HISTORY_MODE,
  GOALS_SETTING_STATUS_HISTORY_MODE,
  SET_GOALS_MODAL_MODE,
} from "../../constants/managerViewModes";
import {
  PROCESS_USER_ACTION_APPRAISAL,
  PROCESS_USER_ACTION_GOALS,
} from "../../constants/processUserAction";
import {
  PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
  PERFORMANCE_REVIEW_GOAL_PENDING,
} from "../../constants/performanceReviewGoalStatus";
import AuthenticationService from "../../services/AuthenticationService";
import {
  PERMISSION_ASSESS_EMPLOYEES,
  PERMISSION_SET_GOALS,
  PERMISSION_TEAM_HISTORY,
  PERMISSION_VIEW_LIMITED,
  VIEWER,
} from "../../constants/permissions";
import { PROCESS_STATUS_OPEN } from "../../constants/processStatus";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_CLOSED,
  APPRAISAL_STATUS_REVIEW,
} from "../../constants/appraisalStatus";
import { MANAGER_ASSESSOR_TYPE } from "../../constants/assessorTypes";
import AppraisalService from "../../services/AppraisalService";
import UserService from "../../services/UserService";
import PerformanceReviewService from "../../services/PerformanceReviewService";
import ManagerService from "../../services/ManagerService";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import TeamService from "../../services/TeamService";
import GoalTemplateGroupService from "../../services/GoalTemplateGroupService";
import Loading from "../common/display/Loading";
import ManagerEmployees from "./ManagerEmployees";

import SetGoalsModal from "./SetGoalsModal";
import StatusTimelineModal from "../common/StatusTimelineModal";
import TeamEmployees from "../profile/TeamEmployees";

const { Control, Group } = Form;

const ManagerEmployeesContainer = ({
  filter,
  filterItems,
  setFilterItems,
  manager,
  performanceReview,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: "Manager.searchByNameGoals",
  });
  const [managerEmployees, setManagerEmployees] = useState([]);
  const [teamEmployees, setTeamEmployees] = useState([]);
  const [historyEmployees, setHistoryEmployees] = useState([]);
  const [audits, setAudits] = useState([]);
  const [
    organizationalGoalTemplateGroups,
    setOrganizationalGoalTemplateGroups,
  ] = useState([]);
  const [goalTemplateGroups, setGoalTemplateGroups] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [showSetGoalsModal, setShowSetGoalsModal] = useState(false);
  const [showStatusTimelineModal, setShowStatusTimelineModal] = useState(false);
  const [query, setQuery] = useState("");
  const [queryChanged, setQueryChanged] = useState(false);
  const [jobTitles, setJobTitles] = useState([]);
  const [names, setNames] = useState([]);
  const [viewedUsers, setViewedUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    saveFilterValue(filter);

    if (isLoading) {
      Promise.all([
        fetchDirectManagementEmployees(query),
        fetchTeamManagementEmployees(query),
        fetchHistoryEmployees(),
        fetchWatchedUsers(),
      ]).then(() => setIsLoading(false));
    }
    if (queryChanged) {
      Promise.all([
        fetchDirectManagementEmployees(query, performanceReview.id),
        fetchTeamManagementEmployees(query, performanceReview.id),
      ]).then(() => setQueryChanged(false));
    }
    if (filter === "history") {
      setQuery("");
    }
    if (filter === "viewer") {
      setQuery("");
    }

    if (names.length === 0 || jobTitles.length === 0) {
      UserService.getJobTitles().then((jobTitles) => {
        setJobTitles(jobTitles);
      });
      UserService.getAllNames(manager.id).then((names) => {
        setNames(names);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, manager, performanceReview, query]);

  const saveFilterValue = (filterValue) =>
    history.replace(history.location.pathname, {
      selectedFilterItem: { value: filterValue },
    });

  const fetchDirectManagementEmployees = (query, performanceReviewId) =>
    Promise.all([
      ManagerService.getUsersByManagerId(
        manager.id,
        query,
        performanceReviewId
      ),
      ManagerService.getProcessPerUserByManagerIdAndPerformanceReviewId(
        manager.id,
        performanceReview.id
      ),
    ]).then(([users, processUsers]) => {
      const directManagementEmployee = createEmployees(
        users,
        processUsers,
        false
      );

      setFilterItems(
        createFilterItems(
          "direct",
          directManagementEmployee.length < 999
            ? directManagementEmployee.length
            : "1k+"
        )
      );
      setManagerEmployees(directManagementEmployee);
    });

  const fetchWatchedUsers = () => {
    if (AuthenticationService.hasPermission(manager, VIEWER)) {
      Promise.all([
        UserService.getWatchedUsersByViewerId(manager.id, performanceReview.id),
      ]).then(([users]) => {
        const watchedUsers = [];
        users.forEach((user, index) => {
          user.buttons = [getViewOnlyGoalsButton(user)].filter(
            (button) => button
          );

          watchedUsers.push(user);
        });
        setFilterItems(
          createFilterItems("viewer", users.length < 999 ? users.length : "1k+")
        );
        setViewedUsers(watchedUsers);
      });
    }
  };

  const fetchTeamManagementEmployees = (query, performanceReviewId) => {
    if (manager.teamId) {
      Promise.all([
        TeamService.getUsersByTeamId(
          manager.teamId,
          query,
          performanceReviewId
        ),
        TeamService.getProcessPerUserByTeamIdAndPerformanceReviewId(
          manager.teamId,
          performanceReview.id
        ),
      ]).then(([users, processUsers]) => {
        const teamManagementEmployees = createEmployees(
          users,
          processUsers,
          true
        );

        setFilterItems(
          createFilterItems(
            "team",
            teamManagementEmployees.length < 999
              ? teamManagementEmployees.length
              : "1k+"
          )
        );
        setTeamEmployees(teamManagementEmployees);
      });
    } else Promise.resolve();
  };

  const fetchHistoryEmployees = () =>
    AppraisalService.getAppraisalsByManagerId(manager.id).then((appraisals) => {
      const lastAppraisalsByEmployees = [];

      appraisals.forEach(
        (appraisal) =>
          !lastAppraisalsByEmployees.find(
            (a) => a.targetEmployeeId === appraisal.targetEmployeeId
          ) && lastAppraisalsByEmployees.push(appraisal)
      );

      return Promise.all(
        lastAppraisalsByEmployees.map((appraisal) =>
          Promise.all([
            UserService.getUserById(appraisal.targetEmployeeId),
            PerformanceReviewService.getPerformanceReviewPeriodById(
              appraisal.performanceReviewId
            ),
          ])
        )
      ).then((responses) => {
        const historyManagementEmployees = [];

        responses.forEach((response, index) => {
          const appraisal = lastAppraisalsByEmployees[index];
          const user = response[0];
          const period = response[1];

          setEmployeeTitle(user, appraisal.targetEmployeeTitle);

          user.performanceReview = {
            start: period.startDate,
            end: period.endDate,
          };
          user.processAction = PROCESS_USER_ACTION_APPRAISAL;
          user.appraisal = appraisal;
          user.status = appraisal.status;
          user.handleStatusClick = handleOpenModal;
          user.actionButton = getAppraisalButton(user, appraisal);
          user.buttons = [
            getViewGoalsButton(user),
            getViewHistoryButton(user),
          ].filter((button) => button);
          user.viewGoalsButton = getViewGoalsButton(user);
          user.viewHistoryButton = getViewHistoryButton(user);

          historyManagementEmployees.push(user);
        });

        setFilterItems(
          createFilterItems(
            "history",
            historyManagementEmployees.length < 999
              ? historyManagementEmployees.length
              : "1k+"
          )
        );
        setHistoryEmployees(historyManagementEmployees);
      });
    });

  const createFilterItems = (filterValue, count) =>
    filterItems.map((item) => {
      if (item.value === filterValue) item.count = count;
      return item;
    });

  const createEmployees = (users, processUsers, isTeam) => {
    const newUsers = users?.filter((u) => u.id !== manager.id);
    return newUsers.map((user) => {
      const processUser = processUsers.find(
        (processUser) => processUser.user.id === user.id
      );

      if (processUser) {
        Object.assign(user, {
          process: processUser.process,
          processAction: processUser.action,
          performanceReview: processUser.process?.performanceReview,
        });

        if (processUser.performanceReviewGoal) {
          setEmployeeTitle(
            user,
            processUser.performanceReviewGoal.targetEmployeeTitle
          );

          Object.assign(user, {
            performanceReviewGoal: processUser.performanceReviewGoal,
            status:
              processUser.performanceReviewGoal.performanceReviewGoalStatus,
            actionButton: getSetGoalsButton(user, processUser),
          });
        } else if (processUser.appraisal) {
          setEmployeeTitle(user, processUser.appraisal.targetEmployeeTitle);

          Object.assign(user, {
            appraisal: processUser.appraisal,
            status: processUser.appraisal.status,
            actionButton: getAppraisalButton(user, processUser.appraisal),
          });
        }

        Object.assign(user, { handleStatusClick: handleOpenModal });
      }

      if (!isTeam) {
        Object.assign(user, {
          buttons: [
            getViewGoalsButton(user),
            getViewHistoryButton(user),
          ].filter((button) => button),
        });
      }

      return user;
    });
  };

  const setEmployeeTitle = (employee, targetEmployeeTitle) =>
    (employee.title = targetEmployeeTitle);

  const handleOpenModal = (mode, employee) => {
    if (mode === SET_GOALS_MODAL_MODE) {
      Promise.all([
        GoalTemplateGroupService.getTopicsWithoutOwner(),
        GoalTemplateGroupService.getTopicsByOwnerId(manager.id),
      ]).then(
        ([
          fetchedOrganizationalGoalTemplateGroups,
          fetchedGoalTemplateGroups,
        ]) => {
          setOrganizationalGoalTemplateGroups(
            fetchedOrganizationalGoalTemplateGroups
          );
          setGoalTemplateGroups(fetchedGoalTemplateGroups);
          setSelectedEmployee(employee);
          setShowSetGoalsModal(true);
        }
      );
    } else if (mode === APPRAISAL_STATUS_HISTORY_MODE) {
      Promise.all([
        AppraisalService.getAppraisalAuditsByAppraisalId(employee.appraisal.id),
        AppraisalService.getAssessorAuditsByAppraisalId(employee.appraisal.id),
      ]).then(([appraisalAudits, appraisalAssessorAudits]) => {
        setSelectedEmployee(employee);
        setAudits(
          appraisalAssessorAudits
            .concat(appraisalAudits)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        );
        setShowStatusTimelineModal(true);
      });
    } else if (mode === GOALS_SETTING_STATUS_HISTORY_MODE) {
      PerformanceReviewGoalService.getPerformanceReviewGoalAuditsByPerformanceReviewGoalId(
        employee.performanceReviewGoal.performanceReviewGoalId
      ).then((performanceReviewGoalAudits) => {
        setSelectedEmployee(employee);
        setAudits(performanceReviewGoalAudits);
        setShowStatusTimelineModal(true);
      });
    }
  };

  const handleCloseModal = () => {
    setShowSetGoalsModal(false);
    setShowStatusTimelineModal(false);
  };

  const getSetGoalsButton = (employee, processUser) => {
    if (
      AuthenticationService.hasPermission(manager, PERMISSION_SET_GOALS) &&
      manager.id === employee.managerId
    ) {
      if (
        processUser.action === PROCESS_USER_ACTION_GOALS &&
        processUser.process?.status === PROCESS_STATUS_OPEN
      ) {
        if (
          processUser.performanceReviewGoal?.performanceReviewGoalStatus ===
          PERFORMANCE_REVIEW_GOAL_PENDING
        ) {
          return (
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => handleOpenModal(SET_GOALS_MODAL_MODE, employee)}
            >
              <FormattedMessage id="Goals.setGoals" />
            </Button>
          );
        }
        if (
          processUser.performanceReviewGoal?.performanceReviewGoalStatus ===
          PERFORMANCE_REVIEW_GOAL_IN_PROGRESS
        ) {
          return (
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() =>
                history.push(
                  `/prg/${processUser.performanceReviewGoal.performanceReviewGoalId}/goals`
                )
              }
            >
              <FormattedMessage id="Goals.setGoals" />
            </Button>
          );
        }
      }
    }
    return;
  };
  const getAppraisalButton = (employee, appraisal) => {
    if (
      AuthenticationService.hasPermission(
        manager,
        PERMISSION_ASSESS_EMPLOYEES
      ) &&
      manager.id === employee.managerId
    ) {
      if (
        appraisal?.status === APPRAISAL_STATUS_ACTIVE &&
        appraisal?.assessors.find((a) => a.type === MANAGER_ASSESSOR_TYPE)
          ?.draft
      ) {
        return (
          <Button
            variant="primary"
            size="sm"
            onClick={() =>
              history.push({
                pathname: `/appraisals/${appraisal.id}/assess`,
                state: { managerRedirect: "viewOrAssess" },
              })
            }
          >
            <FormattedMessage id="Goals.assess" />
          </Button>
        );
      }
      if (appraisal?.status === APPRAISAL_STATUS_REVIEW) {
        return (
          <Button
            variant="success"
            size="sm"
            onClick={() => history.push(`/appraisals/${appraisal.id}/review`)}
          >
            <FormattedMessage id="Goals.meeting" />
          </Button>
        );
      }
      if (
        (appraisal?.status === APPRAISAL_STATUS_ACTIVE &&
          !appraisal?.assessors.find((a) => a.type === MANAGER_ASSESSOR_TYPE)
            ?.draft) ||
        appraisal?.status === APPRAISAL_STATUS_CLOSED
      ) {
        return (
          <Button
            variant="secondary"
            size="sm"
            onClick={() =>
              history.push({
                pathname: `/appraisals/${appraisal.id}/view`,
                state: { managerRedirect: "viewOrAssess" },
              })
            }
          >
            <FormattedMessage id="Goals.view" />
          </Button>
        );
      }
    }
    return;
  };

  const getViewOnlyGoalsButton = (viewerUser) => {
    if (viewerUser.visible)
      return {
        label: <FormattedMessage id="ProcessParticipantsFilter.goals" />,
        action: () => handleViewerButtonClick(viewerUser.id, "goals"),
      };
  };

  const getViewGoalsButton = (employee) => {
    const isDirectManager = manager.id === employee.managerId;
    const isTeamMember = manager.teamId && manager.teamId === employee.team?.id;
    const hasGoals =
      (employee.processAction === PROCESS_USER_ACTION_GOALS &&
        employee.performanceReviewGoal &&
        employee.status !== PERFORMANCE_REVIEW_GOAL_PENDING) ||
      (employee.processAction === PROCESS_USER_ACTION_APPRAISAL &&
        employee.appraisal);

    const isAllowedToSeeLimited =
      employee.limitedVisibility &&
      AuthenticationService.hasPermission(manager, PERMISSION_VIEW_LIMITED);

    if ((isDirectManager || isTeamMember || isAllowedToSeeLimited) && hasGoals)
      return {
        label: <FormattedMessage id="ProcessParticipantsFilter.goals" />,
        action: () => handleButtonClick(employee.id, "goals"),
      };
  };

  const getViewHistoryButton = (employee) => {
    const hasPermisison = AuthenticationService.hasPermission(
      manager,
      PERMISSION_TEAM_HISTORY
    );
    const isAllowedToSeeLimited =
      employee.limitedVisibility &&
      AuthenticationService.hasPermission(manager, PERMISSION_VIEW_LIMITED);
    if (hasPermisison || isAllowedToSeeLimited) {
      return {
        icon: faHistory,
        label: <FormattedMessage id="ProcessParticipants.history" />,
        action: () => handleButtonClick(employee.id, "history"),
      };
    }
  };

  const handleViewerButtonClick = (employeeId, type) =>
    history.push({
      pathname: `/viewer/users/${employeeId}/${type}`,
      state: { managerRedirect: type },
    });

  const handleButtonClick = (employeeId, type) =>
    history.push({
      pathname: `/manager/users/${employeeId}/${type}`,
      state: { managerRedirect: type },
    });

  const filterByQuery = (event) => {
    setQuery(event.target.value);
    setQueryChanged(true);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Group>
        <Row className="mt-3">
          <Col sm={6}>
            {filter === "direct" && (
              <Control
                type="text"
                name="description"
                size="sm"
                placeholder={placeholder}
                onChange={filterByQuery}
              />
            )}
          </Col>
        </Row>

        <>
          <Row className="mt-3">
            {filter !== "team" && (
              <ManagerEmployees
                employees={
                  filter === "direct"
                    ? managerEmployees
                    : filter === "history"
                    ? historyEmployees
                    : filter === "viewer"
                    ? viewedUsers
                    : []
                }
              />
            )}
            {filter === "team" && <TeamEmployees employees={teamEmployees} />}
            <Modal show={showSetGoalsModal} onHide={handleCloseModal} centered>
              <SetGoalsModal
                selectedUser={selectedEmployee}
                organizationalGoalsTemplates={organizationalGoalTemplateGroups}
                goalTemplateGroupTopics={goalTemplateGroups}
                handleClose={handleCloseModal}
              />
            </Modal>
            <StatusTimelineModal
              showModal={showStatusTimelineModal}
              handleClose={handleCloseModal}
              user={selectedEmployee}
              audits={audits}
            />
          </Row>
        </>
      </Group>
    </>
  );
};

export default ManagerEmployeesContainer;
