import React from "react";
import { Pagination } from "react-bootstrap";
const AuditPagination = (props) => {
  let paginationPageNumbers = [];

  for (let number = 0; number <= props.pages - 1; number++) {
    if (number <= 5) {
      paginationPageNumbers.push(
        <Pagination.Item
          active={number === props.currentPage}
          key={paginationPageNumbers}
          onClick={(event) => props.paginationClickHandler(event)}
        >
          {number + 1}
        </Pagination.Item>
      );
    }
  }

  return (
    <Pagination
      className="justify-content-md-center primary-dark"
      style={props.style}
    >
      {props.currentPage === 0 ? (
        <Pagination.First disabled />
      ) : (
        <Pagination.First onClick={() => props.setCurrentPage(0)} />
      )}
      {props.currentPage - 1 >= 0 ? (
        <Pagination.Prev
          onClick={() => props.setCurrentPage(props.currentPage - 1)}
        />
      ) : (
        <Pagination.Prev disabled />
      )}
      {paginationPageNumbers}
      {props.currentPage + 1 >= 6 && props.pages - 1 > 6 && (
        <Pagination.Ellipsis disabled />
      )}
      {props.currentPage + 1 > 6 && (
        <Pagination.Item active>{props.currentPage + 1}</Pagination.Item>
      )}

      {props.currentPage + 1 < props.pages ? (
        <Pagination.Next
          active
          onClick={() => {
            if (props.currentPage < props.pages) {
              props.setCurrentPage(props.currentPage + 1);
            }
          }}
        />
      ) : (
        <Pagination.Next disabled />
      )}
      {props.currentPage === props.pages - 1 ? (
        <Pagination.Last disabled />
      ) : (
        <Pagination.Last
          onClick={() => props.setCurrentPage(props.pages - 1)}
        />
      )}
    </Pagination>
  );
};

export default AuditPagination;
