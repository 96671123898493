import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import GoalTemplateGroupService from "../../services/GoalTemplateGroupService";
import GoalTypeService from "../../services/GoalTypeService";
import { ORGANIZATIONAL_GOAL_TYPE_ID } from "../../constants/goalTypes";
import {
  TEMPLATE_GROUP_CREATE_MODE,
  TEMPLATE_GROUP_DELETE_MODE,
  TEMPLATE_GROUP_MODES,
} from "../../constants/templateGroupModes";
import Loading from "../common/display/Loading";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastr";
import GoalTemplatesContainer from "./GoalTemplatesContainer";
import TemplateGroups from "./TemplateGroups";
import GoalTemplateGroupModal from "./GoalTemplateGroupModal";
import GoalModal from "./GoalModal";
import {
  GOAL_CREATE_MODE,
  GOAL_DELETE_MODE,
  GOAL_MODES,
  GOAL_UPDATE_MODE,
} from "../../constants/goalModes";
import GoalTemplateService from "../../services/GoalTemplateService";
import {
  CONFLICT_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FormattedMessage } from "react-intl";
let toastContainer;

const OrganizationalGoalsTemplatesContainer = () => {
  const history = useHistory();

  const [templateGroups, setTemplateGroups] = useState([]);
  const [templateGroupName, setTemplateGroupName] = useState("");
  const [goalType, setGoalType] = useState();
  const [selectedTemplateGroup, setSelectedTemplateGroup] = useState();
  const [selectedGoalTemplate, setSelectedGoalTemplate] = useState();
  const [mode, setMode] = useState();
  const [showTemplateGroupModal, setShowTemplateGroupModal] = useState(false);
  const [showGoalModal, setShowGoalModal] = useState(false);
  const [showGoals, setShowGoals] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onBackListener();

    if (isLoading)
      Promise.all([
        GoalTemplateGroupService.getTopicsWithoutOwner(),
        GoalTypeService.getGoalTypeById(ORGANIZATIONAL_GOAL_TYPE_ID),
      ]).then(([fetchedTemplateGroups, fetchedGoalType]) => {
        fetchedTemplateGroups.forEach(addActions);

        setTemplateGroups(fetchedTemplateGroups);
        setGoalType(fetchedGoalType);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onBackListener = () => history.listen(() => setShowGoals(false));

  const addActions = (templateGroup) =>
    Object.assign(templateGroup, {
      buttons: [
        {
          icon: faEdit,
          label: <FormattedMessage id="Button.edit" />,
          action: () => onClick(templateGroup),
        },
        {
          icon: faTrashAlt,
          label: <FormattedMessage id="Button.delete" />,
          action: () =>
            handleOpenModal(TEMPLATE_GROUP_DELETE_MODE, templateGroup),
        },
      ],
    });

  const onClick = (templateGroup) => {
    history.push(history.location.pathname);
    setTemplateGroupName(templateGroup.topic);
    setSelectedTemplateGroup(templateGroup);
    setShowGoals(true);
  };

  const handleOpenModal = (mode, data) => {
    setMode(mode);
    if (TEMPLATE_GROUP_MODES.includes(mode)) {
      setSelectedTemplateGroup(data);
      setShowTemplateGroupModal(true);
    } else if (GOAL_MODES.includes(mode)) {
      setSelectedGoalTemplate(mode === GOAL_CREATE_MODE ? undefined : data);
      setShowGoalModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowTemplateGroupModal(false);
    setShowGoalModal(false);
    setMode();
    TEMPLATE_GROUP_MODES.includes(mode) && setSelectedTemplateGroup();
    GOAL_MODES.includes(mode) && setSelectedGoalTemplate();
  };

  const addTemplateGroup = (event) =>
    handleOpenModal(TEMPLATE_GROUP_CREATE_MODE);

  const handleSubmitTemplateGroup = (templateGroup) => {
    if (mode === TEMPLATE_GROUP_CREATE_MODE)
      GoalTemplateGroupService.attemptSavingTopic(templateGroup).then(
        (response) => {
          if (response.ok) {
            success("Topic saved successfully.");
            setIsLoading(true);
          } else error("Something went wrong.");
        }
      );
    else if (mode === TEMPLATE_GROUP_DELETE_MODE)
      GoalTemplateGroupService.attemptDeletingTopicById(templateGroup).then(
        (response) => {
          if (response.ok) {
            setIsLoading(true);
            success("Topic deleted successfully.");
          } else if (response.status === CONFLICT_ERROR_CODE) {
            error(
              "There are Goal Templates assigned to this topic. Firstly, you have to delete them."
            );
          }
        }
      );
  };

  const handleSubmitGoal = (goalTemplate) =>
    (mode === GOAL_CREATE_MODE || mode === GOAL_UPDATE_MODE
      ? GoalTemplateService.attemptSavingGoalTemplate(goalTemplate)
      : mode === GOAL_DELETE_MODE
      ? GoalTemplateService.attemptDeletingGoalTemplateById(goalTemplate.id)
      : Promise.reject()
    ).then((response) => {
      if (response.ok) {
        success("Goal Template saved successfully.");
        setIsLoading(true);
      } else if (response.status === FORBIDDEN_ERROR_CODE) {
        error("You don't have rights to delete this Goal Template.");
      }
    });

  const success = (message) =>
    toastContainer.success(message, "", { closeButton: true });

  const error = (message) =>
    toastContainer.error(message, "", { closeButton: true });

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <ToastContainer
        ref={(ref) => (toastContainer = ref)}
        className="toast-top-right"
      />

      {!showGoals && (
        <Row>
          <Col>
            <header style={{ color: "#6c757d" }}>
              {templateGroups.length > 0 ? (
                <Breadcrumb>
                  <Breadcrumb.Item active>
                    <FormattedMessage id="Templates.organizational" />
                  </Breadcrumb.Item>
                </Breadcrumb>
              ) : (
                <FormattedMessage id="Templates.noFound" />
              )}
            </header>
          </Col>
        </Row>
      )}
      {showGoals && (
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => history.go(-1)}>
              <FormattedMessage id="Templates.organizationalTempaltes" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {templateGroupName.toUpperCase()}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      )}
      {showGoals ? (
        <GoalTemplatesContainer
          filter={{ goalTemplateGroup: selectedTemplateGroup }}
          goalTypes={Array.of(goalType)}
          handleOpenModal={handleOpenModal}
        />
      ) : (
        <>
          {addTemplateGroup && (
            <Row
              style={{
                marginBottom: "2rem",
              }}
            >
              <Col>
                <Button
                  className="float-right"
                  onClick={addTemplateGroup}
                  variant="link"
                  style={{ fontSize: "1.1rem", justifyContent: "right" }}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                  {<FormattedMessage id="Button.add" />}
                </Button>
              </Col>
            </Row>
          )}
          <TemplateGroups templates={templateGroups} />
        </>
      )}

      <GoalTemplateGroupModal
        showModal={showTemplateGroupModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitTemplateGroup}
        mode={mode}
        goalTemplateGroup={selectedTemplateGroup}
      />

      <GoalModal
        showModal={showGoalModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitGoal}
        mode={mode}
        goal={selectedGoalTemplate}
        goalType={goalType}
        goalTemplateGroup={selectedTemplateGroup}
      />
    </Container>
  );
};

export default OrganizationalGoalsTemplatesContainer;
