export const PERMISSION_SELF_ASSESS = "self_assess";
export const PERMISSION_ASSESS_EMPLOYEES = "assess_employees";
export const PERMISSION_MANAGE_USERS = "manage_users";
export const PERMISSION_MANAGE_ROLES = "manage_roles";
export const PERMISSION_MANAGE_TEAMS = "manage_teams";
export const PERMISSION_MANAGER_TRAININGS = "manager_trainings";
export const PERMISSION_OVERVIEW = "overview";
export const PERMISSION_MODIFY_GOALS = "modify_goals";
export const PERMISSION_VIEW_HISTORY = "view_history";
export const PERMISSION_IMPERSONATE = "impersonate";
export const PERMISSION_MANAGE_ORGANIZATIONAL_GOALS = "manage_org_goals";
export const PERMISSION_SET_GOALS = "set_goals";
export const PERMISSION_MANAGE_GOAL_TEMPLATES = "manage_goal_templates";
export const PERMISSION_TEAM_HISTORY = "team_history";
export const PERMISSION_VIEW_LIMITED = "view_limited";
export const PERMISSION_APPRAISAL_EXPORT_OWN = "appraisal_export_own";
export const PERMISSION_APPRAISAL_EXPORT_TEAM = "appraisal_export_team";
export const PERMISSION_APPRAISAL_EXPORT_ALL = "appraisal_export_all";
export const PERMISSION_MANAGE_PROCESS = "manage_process";
export const PERMISSION_MY_TRAINING = "my_training";
export const PERMISSION_TRAINING_STATUS_MANAGER = "training_status_manager";
export const PERMISSION_TRAINING_REPORTING = "training_reporting";
export const PERMISSION_MANAGE_PROCESS_PARTICIPANTS =
  "manage_process_participants";
export const PERMISSION_MANAGE_PROCESS_UPLOAD_USERS =
  "manage_users_creation_by_csv_file";
export const PERMISSION_VIEW_AUDIT = "view_audit";
export const PERMISSION_MANAGE_FEEDBACKS = "manage_feedbacks";
export const MANAGER_FEEDBACK_VIEW = "manager_feedback_view";
export const MANAGER_FEEDBACK_VIEW_ALL = "manager_feedback_view_all";
export const VIEWER = "viewer";
export const SET_ONE_ON_ONE = "set_one_on_one";
export const MANAGE_ONE_ON_ONE = "manage_one_on_one";
export const MANAGE_ONE_ON_ONE_FEEDBACK_QUESTIONS =
  "manage_one_on_one_feedback_questions";
