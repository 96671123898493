import React from "react";
import { Card } from "react-bootstrap";
import Image from "../common/Image";
import "./one-on-one-comment.css";

const FINAL_COMMENT_IMAGE_URL = "/images/final-agreement.svg";

export default function OneOnOneComment(props) {
  const { user, comment, isFinal, userEmail } = props;

  return (
    <div className="one-on-one-comment-display-wrapper">
      <div className="image-wrapper">
        {!userEmail && (
          <Image
            src={isFinal ? FINAL_COMMENT_IMAGE_URL : user?.imageUrl}
            roundedCircle
          />
        )}
        {userEmail && <Image email={userEmail} roundedCircle />}
      </div>
      <Card>
        <Card.Body style={{ flex: "1 1 auto" }}>
          <Card.Title style={!comment ? { margin: "0" } : {}}>
            {`${user?.firstName} ${user?.lastName}: `}
          </Card.Title>
          <Card.Text style={{ wordBreak: "break-all" }}>{comment}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
