import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { Form, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import Select from "react-select";
import AdminService from "../../../services/AdminService";
import ErrorIcon from "../../../icons/ErrorIcon";

const { Group, Label, Control } = Form;
const { Dialog, Body, Title, Header } = Modal;

const EditTeamForm = ({ team, handleClose, handleSubmit }) => {
  const intl = useIntl();

  const editPlaceholder = intl.formatMessage({
    id: "Reviews.tabs.edit",
  });
  const newPlaceholder = intl.formatMessage({
    id: "Goals.new",
  });
  const teamPlaceholder = intl.formatMessage({
    id: "Manager.team",
  });
  const noViewersPlaceholder = intl.formatMessage({
    id: "EditTeamForm.noViewers",
  });

  const teamValidationSchema = Yup.object({
    name: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: "EditRoleForm.name.minLength",
        })
      )
      .required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      ),
  });

  const [viewers, setViewers] = useState(
    team?.viewers?.map((element) => {
      return {
        value: element.id,
        label: element.firstName + " " + element.lastName,
        firstName: element.firstName,
        lastName: element.lastName,
        email: element.email,
        username: element.username,
        id: element.id,
      };
    })
  );

  const [managers, setManagers] = useState([]);

  const initializeValues = (team) => {
    if (team) {
      return {
        id: team.id,
        name: team.name,
        manager: managers
          .filter((el) => el.id === team?.manager?.id)
          .flatMap((el) => {
            return {
              value: el.id,
              label: el.firstName + " " + el.lastName,
              firstName: el.firstName,
              lastName: el.lastName,
              email: el.email,
              username: el.username,
              id: el.id,
            };
          })[0],
        viewers: team?.viewers?.map((element) => {
          return {
            value: element?.id,
            label: element?.firstName + " " + element?.lastName,
            firstName: element?.firstName,
            lastName: element?.lastName,
            email: element?.email,
            username: element?.username,
            id: element.id,
          };
        }),
      };
    }
    return {
      name: "",
      viewers: [],
      manager: {},
    };
  };

  useEffect(() => {
    AdminService.getAvaibleManagers().then((viewers) => {
      setViewers(viewers);
      setManagers(viewers);
    });
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initializeValues(team)}
      validationSchema={teamValidationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Header closeButton />
          <Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Body>
              <Title>{`${
                team ? editPlaceholder : newPlaceholder
              } ${teamPlaceholder}`}</Title>
              <hr />
              <Group>
                <Label>
                  <FormattedMessage id="EditForm.name" />
                </Label>
                <Control
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}> {msg} </span>
                    </div>
                  )}
                  name="name"
                />
              </Group>
              <Group>
                <Label>
                  <FormattedMessage id="EditTeamForm.viewer" />
                </Label>
                <Select
                  defaultValue={noViewersPlaceholder}
                  isMulti
                  name="viewers"
                  options={viewers?.map((element) => {
                    return {
                      value: element.id,
                      label: element.firstName + " " + element.lastName,
                      firstName: element.firstName,
                      lastName: element.lastName,
                      email: element.email,
                      username: element.username,
                      id: element.id,
                    };
                  })}
                  className="viewers-multi-select"
                  classNamePrefix="select"
                  value={formik.values.viewers}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("viewers", selectedOption);
                  }}
                />
              </Group>
              <Group>
                <Label><FormattedMessage id="EditUserForm.manager" /></Label>
                <Select
                  defaultValue={noViewersPlaceholder}
                  isClearable
                  name="manager"
                  options={managers?.map((element) => {
                    return {
                      value: element.id,
                      label: element.firstName + " " + element.lastName,
                      firstName: element.firstName,
                      lastName: element.lastName,
                      email: element.email,
                      username: element.username,
                      id: element.id,
                    };
                  })}
                  className="manager-select"
                  classNamePrefix="select"
                  value={formik.values.manager}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("manager", selectedOption);
                  }}
                />
              </Group>
            </Body>
          </Dialog>
          <Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="EditForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="EditForm.submit" />
            </Button>
          </Group>
        </Form>
      )}
    </Formik>
  );
};

export default EditTeamForm;
