import React from "react";
import ProcessService from "../../services/ProcessService";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import {
  Button,
  Col,
  Container,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Loading from "../common/display/Loading";
import DeleteConfirmation from "../common/DeleteConfirmation";
import { ToastContainer } from "react-toastr";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_CLOSED,
  APPRAISAL_STATUS_REVIEW,
} from "../../constants/appraisalStatus";
import CloseAndReopenProcessModal from "./CloseAndReopenProcessModal";
import { MODE_CLOSE, MODE_REOPEN } from "../../constants/processModalStatus";
import {
  PROCESS_USER_ACTION_APPRAISAL,
  PROCESS_USER_ACTION_GOALS,
} from "../../constants/processUserAction";
import {
  PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
  PERFORMANCE_REVIEW_GOAL_PENDING,
  PERFORMANCE_REVIEW_GOAL_SET,
} from "../../constants/performanceReviewGoalStatus";
import {
  PROCESS_STATUS_CLOSED,
  PROCESS_STATUS_OPEN,
} from "../../constants/processStatus";
import { BAD_REQUEST_ERROR_CODE } from "../../constants/errorStatusCodes";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import { withRouter } from "react-router-dom";
import "./process-dashboard.css";

const PIE_CHART_LABELS = {
  participants: "Total participants",
  action: {
    appraisal: "Appraisals",
    goals: "Goals settings",
  },
  appraisal: {
    assessment: "Assessment",
    oneToOne: "One-to-one",
    completed: "Completed",
  },
  goalsSetting: {
    pending: "Pending",
    inProgress: "In Progress",
    set: "Set",
  },
};

class ProcessDashboard extends React.Component {
  state = {
    process: null,
    appraisals: null,
    loading: true,
    showDeleteConfirmation: false,
    showCloseAndReopenModal: false,
    closeReopenMode: null,
    closeReopenSubmit: null,
    actionChartData: {},
  };

  componentDidMount() {
    Promise.all([
      ProcessService.getProcessById(this.props.match.params.id),
      ProcessService.getProcessUsersByProcessId(this.props.match.params.id),
    ]).then(([process, processUsers]) => {
      process.participants.forEach(
        (participant) =>
          (participant.processUser = processUsers.find(
            (processUser) => processUser.user.id === participant.id
          ))
      );

      const appraisals = this.getAppraisalsFromProcessUsers(processUsers);

      this.setState({
        process: process,
        appraisals: appraisals,
        loading: false,
        actionChartData: this.getActionChartData(process.participants),
        appraisalsData: this.getAppraisalChartData(appraisals),
        goalsData: this.getGoalsChartData(process.participants),
      });
    });
  }

  regularToastContainer;

  getAppraisalsFromProcessUsers = (processUsers) =>
    processUsers.filter((pu) => pu.appraisal).map((pu) => pu.appraisal);

  getActionChartData = (participants) => {
    return {
      labels: Object.values(PIE_CHART_LABELS.action),
      datasets: [
        {
          data: [
            participants.filter(
              (p) => p.action === PROCESS_USER_ACTION_APPRAISAL
            ).length,
            participants.filter((p) => p.action === PROCESS_USER_ACTION_GOALS)
              .length,
          ],
          backgroundColor: ["#24274c", "#6691ff"],
        },
      ],
    };
  };

  getAppraisalChartData = (appraisals) => {
    return {
      labels: Object.values(PIE_CHART_LABELS.appraisal),
      datasets: [
        {
          data: [
            appraisals.filter((a) => a.status === APPRAISAL_STATUS_ACTIVE)
              .length,
            appraisals.filter((a) => a.status === APPRAISAL_STATUS_REVIEW)
              .length,
            appraisals.filter((a) => a.status === APPRAISAL_STATUS_CLOSED)
              .length,
          ],
          backgroundColor: ["#dd1273", "#24274c", "#6691ff"],
        },
      ],
    };
  };

  getGoalsChartData = (participants) => {
    return {
      labels: Object.values(PIE_CHART_LABELS.goalsSetting),
      datasets: [
        {
          data: [
            participants.filter(
              (p) =>
                p.action === PROCESS_USER_ACTION_GOALS &&
                p.processUser &&
                p.processUser.performanceReviewGoal &&
                p.processUser.performanceReviewGoal
                  .performanceReviewGoalStatus ===
                  PERFORMANCE_REVIEW_GOAL_PENDING
            ).length,
            participants.filter(
              (p) =>
                p.action === PROCESS_USER_ACTION_GOALS &&
                p.processUser &&
                p.processUser.performanceReviewGoal &&
                p.processUser.performanceReviewGoal
                  .performanceReviewGoalStatus ===
                  PERFORMANCE_REVIEW_GOAL_IN_PROGRESS
            ).length,
            participants.filter(
              (p) =>
                p.action === PROCESS_USER_ACTION_GOALS &&
                p.processUser &&
                p.processUser.performanceReviewGoal &&
                p.processUser.performanceReviewGoal
                  .performanceReviewGoalStatus === PERFORMANCE_REVIEW_GOAL_SET
            ).length,
          ],
          backgroundColor: ["#dd1273", "#24274c", "#6691ff"],
        },
      ],
    };
  };

  getParticipantsBtn = () => (
    <Button
      size="sm"
      variant="primary"
      onClick={() =>
        this.props.history.push(
          `/processes/${this.props.match.params.id}/participants`
        )
      }
    >
      Participants
    </Button>
  );

  getCloseProcessBtn = () => (
    <Button
      size="sm"
      variant="secondary"
      disabled={this.state.process.status === APPRAISAL_STATUS_CLOSED}
      onClick={() =>
        this.setState({
          closeReopenMode: MODE_CLOSE,
          closeReopenSubmit: this.submitCloseProcess,
          showCloseAndReopenModal: true,
        })
      }
    >
      Close Process
    </Button>
  );

  getReopenProcessBtn = () => (
    <Button
      size="sm"
      variant="secondary"
      disabled={this.state.process.status !== APPRAISAL_STATUS_CLOSED}
      onClick={() =>
        this.setState({
          closeReopenMode: MODE_REOPEN,
          closeReopenSubmit: this.submitReopenProcess,
          showCloseAndReopenModal: true,
        })
      }
    >
      Reopen Process
    </Button>
  );

  getDeleteProcessBtn = () => (
    <Button
      size="sm"
      variant="secondary"
      disabled={this.state.process.status === APPRAISAL_STATUS_CLOSED}
      onClick={() =>
        this.setState({
          showDeleteConfirmation: true,
        })
      }
    >
      Delete Process
    </Button>
  );

  openExternalDashboard = () => (
    <Button
      size="sm"
      variant="secondary"
      onClick={() =>
        ProcessService.getExternalAnalyticDashboardByProcessId(
          this.props.match.params.id
        ).then((result) => {
          const win = window.open(result.url, "_blank");
          win.focus();
        })
      }
    >
      External Dashboard
    </Button>
  );

  getEmptyCircleShapeWithCount = (count, type = "participants") => (
    <Col>
      <Row className="circle">
        <h5>{count}</h5>
      </Row>
      {this.getPieLegend(type)}
    </Col>
  );

  getPieLegend = (type) => {
    if (type === "participants") {
      return (
        <Row className="pie-legend">
          <Col
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants`
              )
            }
          >
            {PIE_CHART_LABELS.participants}
          </Col>
        </Row>
      );
    } else if (type === "action") {
      return (
        <Row className="pie-legend">
          <Col
            className="primary text-right"
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants?action=${PROCESS_USER_ACTION_APPRAISAL}`
              )
            }
          >
            {PIE_CHART_LABELS.action.appraisal}
          </Col>
          <Col
            className="success"
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants?action=${PROCESS_USER_ACTION_GOALS}`
              )
            }
          >
            {PIE_CHART_LABELS.action.goals}
          </Col>
        </Row>
      );
    } else if (type === "appraisal") {
      return (
        <Row className="pie-legend">
          <Col
            className="danger"
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants?appraisal=${APPRAISAL_STATUS_ACTIVE}`
              )
            }
          >
            {PIE_CHART_LABELS.appraisal.assessment}
          </Col>
          <Col
            className="primary"
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants?appraisal=${APPRAISAL_STATUS_REVIEW}`
              )
            }
          >
            {PIE_CHART_LABELS.appraisal.oneToOne}
          </Col>
          <Col
            className="success"
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants?appraisal=${APPRAISAL_STATUS_CLOSED}`
              )
            }
          >
            {PIE_CHART_LABELS.appraisal.completed}
          </Col>
        </Row>
      );
    } else if (type === "goals") {
      return (
        <Row className="pie-legend">
          <Col
            className="danger"
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants?goals=${PERFORMANCE_REVIEW_GOAL_PENDING}`
              )
            }
          >
            {PIE_CHART_LABELS.goalsSetting.pending}
          </Col>
          <Col
            className="primary"
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants?goals=${PERFORMANCE_REVIEW_GOAL_IN_PROGRESS}`
              )
            }
          >
            {PIE_CHART_LABELS.goalsSetting.inProgress}
          </Col>
          <Col
            className="success"
            onClick={() =>
              this.props.history.push(
                `/processes/${this.props.match.params.id}/participants?goals=${PERFORMANCE_REVIEW_GOAL_SET}`
              )
            }
          >
            {PIE_CHART_LABELS.goalsSetting.set}
          </Col>
        </Row>
      );
    }
  };

  handleCloseModal = () =>
    this.setState({
      showDeleteConfirmation: false,
      showCloseAndReopenModal: false,
    });

  submitCloseProcess = () =>
    ProcessService.updateProcessStatus(
      this.state.process.id,
      PROCESS_STATUS_CLOSED
    ).then((response) => {
      this.setState({ showCloseAndReopenModal: false });
      if (response.ok) {
        this.componentDidMount();
        this.showSuccessNotification("Process closed successfully.");
      } else if (response.status === BAD_REQUEST_ERROR_CODE) {
        response
          .json()
          .then((error) => this.showErrorNotification(error.message));
      }
    });

  submitReopenProcess = () =>
    ProcessService.updateProcessStatus(
      this.state.process.id,
      PROCESS_STATUS_OPEN
    ).then((response) => {
      this.setState({ showCloseAndReopenModal: false });
      if (response.status === BAD_REQUEST_ERROR_CODE) {
        this.showErrorNotification("Process is not closed yet.");
      } else {
        this.componentDidMount();
        this.showSuccessNotification("Process reopened successfully.");
      }
    });

  submitDeletingProcess = (process) =>
    ProcessService.attemptDeletingProcessById(process.id).then((response) => {
      if (response.status === BAD_REQUEST_ERROR_CODE) {
        this.showErrorNotification(
          "There are already participants and/or appraisals attached to this process. Please, remove them first and try again."
        );
      } else {
        this.props.history.push("/processes");
      }
    });

  showSuccessNotification = (message) => {
    if (this.regularToastContainer && message) {
      this.regularToastContainer.success(message, "", {
        closeButton: true,
      });
    }
  };

  showErrorNotification = (message) => {
    if (this.regularToastContainer && message) {
      this.regularToastContainer.error(message, "", {
        closeButton: true,
      });
    }
  };

  render() {
    return this.state.loading ? (
      <Loading />
    ) : (
      <>
        <ToastContainer
          className="toast-top-right"
          ref={(ref) => {
            this.regularToastContainer = ref;
          }}
        />
        <Container className="process-dashboard">
          <header className="title text-center">
            <h4>{this.state.process.title}</h4>
            <h5>
              <ConfigurationPropertiesContext.Consumer>
                {({ formatDate }) =>
                  `${formatDate(this.state.process.startDate)} - ${formatDate(
                    this.state.process.endDate
                  )}`
                }
              </ConfigurationPropertiesContext.Consumer>
            </h5>
            <Row>
              <Col>
                {this.getParticipantsBtn()}
                {this.getCloseProcessBtn()}
                {this.getReopenProcessBtn()}
                {this.getDeleteProcessBtn()}
                {this.openExternalDashboard()}
              </Col>
            </Row>
          </header>
          <Row xs={1} sm={1} md={2} lg={2} xl={2} className="process-progress">
            <Col>
              <h5>{`Completion: ${this.state.process.completion}%`}</h5>
              <ProgressBar now={this.state.process.completion} />
            </Col>
            <Col>
              <h5>{`Deadline approaching: ${this.state.process.deadlineApproaching}%`}</h5>
              <ProgressBar now={this.state.process.deadlineApproaching} />
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            md={4}
            lg={4}
            xl={4}
            className="process-pie-charts"
          >
            {this.getEmptyCircleShapeWithCount(
              this.state.process.participants.length
            )}
            {this.state.process.participants.length === 0 ? (
              this.getEmptyCircleShapeWithCount(0, "action")
            ) : (
              <Col>
                <Pie
                  height={180}
                  data={this.state.actionChartData}
                  options={{
                    legend: { display: false },
                    plugins: {
                      datalabels: {
                        display: true,
                        color: "#ffffff",
                      },
                    },
                  }}
                />
                {this.getPieLegend("action")}
              </Col>
            )}
            {this.state.appraisals.length === 0 ? (
              this.getEmptyCircleShapeWithCount(0, "appraisal")
            ) : (
              <Col>
                <Pie
                  height={180}
                  data={this.state.appraisalsData}
                  options={{
                    legend: { display: false },
                    plugins: {
                      datalabels: {
                        display: true,
                        color: "#ffffff",
                      },
                    },
                  }}
                />
                {this.getPieLegend("appraisal")}
              </Col>
            )}
            {this.state.process.participants.length === 0 ||
            this.state.process.participants.filter(
              (p) => p.action === PROCESS_USER_ACTION_GOALS
            ).length === 0 ? (
              this.getEmptyCircleShapeWithCount(0, "goals")
            ) : (
              <Col>
                <Pie
                  height={180}
                  data={this.state.goalsData}
                  options={{
                    legend: { display: false },
                    plugins: {
                      datalabels: {
                        display: true,
                        color: "#ffffff",
                      },
                    },
                  }}
                />
                {this.getPieLegend("goals")}
              </Col>
            )}
          </Row>
          <CloseAndReopenProcessModal
            showModal={this.state.showCloseAndReopenModal}
            handleCloseModal={this.handleCloseModal}
            process={this.state.process}
            mode={this.state.closeReopenMode}
            handleSubmit={this.state.closeReopenSubmit}
          />
          <Modal
            show={this.state.showDeleteConfirmation}
            onHide={this.handleCloseModal}
            centered
          >
            <DeleteConfirmation
              process={this.state.process}
              handleClose={this.handleCloseModal}
              handleSubmit={this.submitDeletingProcess}
            />
          </Modal>
        </Container>
      </>
    );
  }
}

export default withRouter(ProcessDashboard);
