import React from "react";
import { getRoleName } from "../../../util/roleName";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RoleDataRow = ({ hoveredRole, role, ...rest }) => (
  <tr {...rest}>
    <td>
      {getRoleName(role)}
      {role.id === hoveredRole?.id && (
        <div className="actions">
          {role.buttons.map((button, index) => (
            <Button
              key={`button-role-action-${index}`}
              variant="link"
              onClick={button?.action}
            >
              <FontAwesomeIcon icon={button.icon} />
              {button.label}
            </Button>
          ))}
        </div>
      )}
    </td>
    <td>{role.description}</td>
  </tr>
);

export default RoleDataRow;
