import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import { Image as ImageRB } from "react-bootstrap";

const Image = ({ email, ...props }) => {
  const [src, setSrc] = useState();

  useEffect(() => {
    UserService.getUserProfilePictureUrlByEmail(email, setSrc);
  }, [email]);

  return <ImageRB src={src} {...props} />;
};

export default Image;
