export const TEAM_CREATE_MODE = "create_team";
export const TEAM_EDIT_MODE = "edit_team";
export const TEAM_DELETE_MODE = "delete_team";
export const TEAM_SHOW_MEMBERS = "show_team_members";

const TEAM_MODES = [
  TEAM_CREATE_MODE,
  TEAM_EDIT_MODE,
  TEAM_DELETE_MODE,
  TEAM_SHOW_MEMBERS,
];

export default TEAM_MODES;
