import React, { useState } from "react";
import { useHistory } from "react-router";
import { Badge, Button, Dropdown, Table } from "react-bootstrap";
import {
  PROCESS_USER_ACTION_APPRAISAL,
  PROCESS_USER_ACTION_GOALS,
} from "../../constants/processUserAction";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_CLOSED,
  APPRAISAL_STATUS_REVIEW,
} from "../../constants/appraisalStatus";
import {
  PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
  PERFORMANCE_REVIEW_GOAL_PENDING,
  PERFORMANCE_REVIEW_GOAL_SET,
} from "../../constants/performanceReviewGoalStatus";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import {
  PROCESS_STATUS_CLOSED,
  PROCESS_STATUS_PENDING,
} from "../../constants/processStatus";
import { CircularProgressbar } from "react-circular-progressbar";
import MathUtils from "../../util/MathUtils";
import { capitalizeWord } from "../../util/stringUtils";
import "./processes.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { useIntl, FormattedMessage } from "react-intl";

const Processes = ({ processes }) => {
  const history = useHistory();

  const [hoveredProcess, setHoveredProcess] = useState();

  const intl = useIntl();

  const daysLeftPlaceholder = intl.formatMessage({
    id: "Proccesses.daysLeft",
  });
  const daysOverduePlaceholder = intl.formatMessage({
    id: "Proccesses.overdue",
  });
  const onMouseOver = (process) => setHoveredProcess(process);

  const onMouseLeave = () => setHoveredProcess();

  const renderStats = (process) => (
    <Dropdown as={Dropdown.Toggle} drop="up" variant="link">
      <FontAwesomeIcon icon={faChartBar} />
      {<FormattedMessage id="Processes.tabs.stats" />}
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              search: `?action=${PROCESS_USER_ACTION_APPRAISAL}`,
              state: { participants: "processes" },
            })
          }
        >
          <strong>
            <FormattedMessage id="Processes.tabs.stats.appraisals" />
          </strong>
          <Badge bg="primary">{process.stats.appraisalsCount}</Badge>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              search: `?appraisal=${APPRAISAL_STATUS_ACTIVE}`,
              state: { participants: "processes" },
            })
          }
        >
          <FormattedMessage id="Processes.tabs.stats.assessment" />
          <Badge bg="primary">{process.stats.activeAppraisalsCount}</Badge>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              search: `?appraisal=${APPRAISAL_STATUS_REVIEW}`,
              state: { participants: "processes" },
            })
          }
        >
          <FormattedMessage id="Processes.tabs.stats.oneToOne" />
          <Badge bg="primary">{process.stats.reviewAppraisalsCount}</Badge>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              search: `?appraisal=${APPRAISAL_STATUS_CLOSED}`,
              state: { participants: "processes" },
            })
          }
        >
          <FormattedMessage id="Processes.tabs.stats.completed" />
          <Badge bg="primary">{process.stats.closedAppraisalsCount}</Badge>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              search: `?action=${PROCESS_USER_ACTION_GOALS}`,
              state: { participants: "processes" },
            })
          }
        >
          <FormattedMessage id="Processes.tabs.stats.goalSetting" />
          <Badge bg="primary">{process.stats.goalsSettingsCount}</Badge>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              search: `?goals=${PERFORMANCE_REVIEW_GOAL_PENDING}`,
              state: { participants: "processes" },
            })
          }
        >
          <FormattedMessage id="Processes.tabs.stats.pending" />
          <Badge bg="primary">{process.stats.pendingGoalsSettingsCount}</Badge>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              search: `?goals=${PERFORMANCE_REVIEW_GOAL_IN_PROGRESS}`,
              state: { participants: "processes" },
            })
          }
        >
          <FormattedMessage id="Processes.tabs.stats.inProgress" />{" "}
          <Badge bg="primary">
            {process.stats.inProgressGoalsSettingsCount}
          </Badge>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            history.push({
              pathname: `/processes/${process.id}/participants`,
              search: `?goals=${PERFORMANCE_REVIEW_GOAL_SET}`,
              state: { participants: "processes" },
            })
          }
        >
          <FormattedMessage id="Processes.tabs.stats.set" />
          <Badge bg="primary">{process.stats.setGoalsSettingsCount}</Badge>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const renderProcessCompletion = (process) => (
    <div className="process-completion">
      <CircularProgressbar
        value={MathUtils.calculateProcessCompletionPercentage(process)}
      />
      <div>
        <div className="process-completed-participants">
          {process.completedParticipantsCount}
        </div>
        <div className="process-participants">{process.participantsCount}</div>
      </div>
    </div>
  );

  return (
    <ConfigurationPropertiesContext.Consumer>
      {({ formatDate }) => (
        <Table className="processes" responsive borderless>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Processes.process" />
              </th>
              <th>
                <FormattedMessage id="Processes.status" />
              </th>
              <th>
                <FormattedMessage id="Processes.period" />
              </th>
            </tr>
          </thead>
          {processes.length > 0 ? (
            <tbody>
              {processes.map((process, index) => (
                <tr
                  key={`process-row-${index}`}
                  onMouseOver={() => onMouseOver(process)}
                  onMouseLeave={onMouseLeave}
                >
                  <td>
                    {renderProcessCompletion(process)}
                    <div className="process-info">
                      <div className="process-title">
                        <strong>{process.title}</strong>
                        <span>{` (${capitalizeWord(process.type)})`}</span>
                      </div>
                      <div className="process-managed-by">
                        <span>
                          {<FormattedMessage id="Processes.managedBy" />}
                        </span>
                        <span>{`${process.managedBy.firstName} ${process.managedBy.lastName}`}</span>
                      </div>
                    </div>
                    {process.id === hoveredProcess?.id && (
                      <div
                        className="process actions"
                        style={{ marginLeft: "108px" }}
                      >
                        {process.leftButtons.map((button, index) => (
                          <Button
                            key={`left-button-process-action-${index}`}
                            variant="link"
                            onClick={button.action}
                          >
                            <FontAwesomeIcon icon={button.icon} />
                            {button.label}
                          </Button>
                        ))}
                      </div>
                    )}
                  </td>
                  <td className="process-status">{process.status}</td>
                  <td>
                    <div
                      className={`process-days-left ${
                        process.daysLeft > 5
                          ? "green"
                          : process.daysLeft <= 0
                          ? "red"
                          : "yellow"
                      }`}
                    >
                      {process.status === PROCESS_STATUS_PENDING &&
                      process.daysLeft === 0 ? (
                        <></>
                      ) : process.status !== PROCESS_STATUS_CLOSED &&
                        process.daysLeft >= 0 ? (
                        `${process.daysLeft} ${daysLeftPlaceholder}`
                      ) : (
                        process.status !== PROCESS_STATUS_CLOSED &&
                        `${Math.abs(
                          process.daysLeft
                        )} ${daysOverduePlaceholder}`
                      )}
                    </div>
                    <div className="process-period">{`${formatDate(
                      process.startDate
                    )} - ${formatDate(process.endDate)}`}</div>
                    <div className="actions" style={{ marginLeft: "36px" }}>
                      {process.id === hoveredProcess?.id && (
                        <>
                          {process.stats && renderStats(process)}
                          {process.rightButtons.map((button, index) => (
                            <Button
                              key={`right-button-process-action-${index}`}
                              variant="link"
                              onClick={button.action}
                            >
                              <FontAwesomeIcon icon={button.icon} />
                              {button.label}
                            </Button>
                          ))}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <h4 className="text-center mt-3">
              <FormattedMessage id="Processes.noProcesses" />
            </h4>
          )}
        </Table>
      )}
    </ConfigurationPropertiesContext.Consumer>
  );
};

export default Processes;
