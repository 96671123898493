import React, { useEffect, useState } from "react";
import { faPlusCircle, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form, Container, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import { ToastContainer } from "react-toastr";
import { useParams } from "react-router-dom";
import TrainingsService from "../../../services/TrainingsService";
import Loading from "../../common/display/Loading";
import TeamTrainingEmployees from "../TeamTraining/TeamTrainingEmployees";
import DebouncedSearchInput from "../../common/form/DebouncedSearchInput";
import Filter from "../../common/Filter";
import Pagination from "../../admin/Pagination";
import PerformanceReviewService from "../../../services/PerformanceReviewService";
import AddTrainingForm from "../AddTrainingForm";
import GoalService from "../../../services/GoalService";
import PerformanceReviewGoalService from "../../../services/PerformanceReviewGoalService";
import TeamService from "../../../services/TeamService";
let toastContainer;

const UsersTrainings = ({ user }) => {
  const intl = useIntl();
  const { performanceReviewId } = useParams();
  const tabValue = "all";
  const mode = "create_goal";
  const goalType = {
    id: 3,
    title: "Personal Development Items",
    weight: 100,
  };
  const emptyUser = {
    id: "",
  };
  const trainingStatuses = [
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Requested",
      }),
      value: "Requested",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.In.Progress",
      }),
      value: "In Progress",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Vendor.Selected",
      }),
      value: "Vendor Selected",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Provided",
      }),
      value: "Provided",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Internal.Done",
      }),
      value: "Internal Done",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Cancelled.by.Employee",
      }),
      value: "Cancelled by Employee",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Cancelled.by.P.and.C",
      }),
      value: "Cancelled by P and C",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Left.the.Company",
      }),
      value: "Left the Company",
    },
  ];

  const [allUsers, setAllUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [categories, setCategories] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [employeesTrainings, setEmployeesTrainings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [performanceReview, setPerformanceReview] = useState();
  const [totalTrainingsCount, setTotalTrainingsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState({
    employeeName: null,
    trainingName: null,
    refTrainingId: null,
    trainingStatus: null,
    categoryId: null,
    teamId: null,
  });
  const [showAddTrainingModal, setAddTrainingModal] = useState(false);

  const toggleAddTrainingModal = () => {
    setAddTrainingModal(!showAddTrainingModal);
  };

  const fetchTrainings = () => {
    TrainingsService.getUsersTrainings(
      performanceReviewId,
      currentPage,
      filters
    )
      .then((response) => response.json())
      .then((usersTrainings) => {
        setTotalPages(usersTrainings.totalPages);
        setEmployeesTrainings(usersTrainings.content);
        setTotalTrainingsCount(usersTrainings.totalElements);
      });
  };

  const createNewTraining = (goal) => {
    goal.managerCreatorId = user.id;
    goal.goalTypeId = goalType.id;
    goal.performanceReviewId = performanceReview.id;
    goal.userId = goal.selectedUserId;
    PerformanceReviewGoalService.getLatestPerformanceReviewGoalByUserId(
      performanceReview.id,
      goal.selectedUserId
    )
      .then((performanceReviewGoal) => {
        goal.performanceReviewGoalId =
          performanceReviewGoal.performanceReviewGoalId;
        GoalService.attemptSavingGoal(goal).then((response) => {
          if (response.ok) {
            showSuccessNotification(
              intl.formatMessage({ id: "Training.saved.successfully" })
            );
            fetchTrainings();
            TrainingsService.searchRefTraining(true, goal.categoryId).then(
              (trainings) => {
                setTrainings(trainings);
              }
            );
          } else {
            response
              .json()
              .then((error) => showErrorNotification(error.message));
          }
        });
      })
      .catch(() =>
        showErrorNotification(
          intl.formatMessage({ id: "User.not.added.to.process" })
        )
      );
  };

  const updateTrainingStatus = (training, event, setEditableIndex) => {
    training.status = event.target.value;
    TrainingsService.updateTraining(training.trainingId, training).then(
      (response) => {
        if (response.ok) {
          showSuccessNotification(
            intl.formatMessage({ id: "Training.status.changed.successfully" })
          );
          fetchTrainings();
          setEditableIndex(null);
        } else {
          response.json().then((error) => showErrorNotification(error.message));
        }
      }
    );
  };

  const updateTrainingFeedback = (training) => {
    TrainingsService.updateTraining(training.trainingId, training).then(
      (response) => {
        if (response.ok) {
          showSuccessNotification(
            intl.formatMessage({ id: "Training.comment.changed.successfully" })
          );
          fetchTrainings();
        } else {
          response.json().then((error) => showErrorNotification(error.message));
        }
      }
    );
  };

  const showSuccessNotification = (message) =>
    toastContainer.success(message, "", {
      closeButton: true,
    });

  const showErrorNotification = (message) =>
    toastContainer.error(message, "", {
      closeButton: true,
    });

  const paginationClickHandler = (event) => {
    if (event.target.text === undefined) {
      return;
    } else {
      setCurrentPage(parseInt(event.target.text) - 1);
    }
  };

  const onNameValueChange = (value) => {
    const nameValue = value === "" ? null : value.toLowerCase();
    setCurrentPage(0);
    setFilters((prevFilters) => ({
      ...prevFilters,
      employeeName: nameValue,
    }));
  };

  const onTrainingNameValueChange = (value) => {
    const trainingNameValue = value === "" ? null : value.toLowerCase();
    setCurrentPage(0);
    setTrainings([]);
    const tempCategories = categories;
    setCategories([]);
    setCategories(tempCategories);
    setFilters((prevFilters) => ({
      ...prevFilters,
      trainingName: trainingNameValue,
      refTrainingId: null,
      categoryId: null,
    }));
  };

  const onCategoryChange = (event) => {
    setCurrentPage(0);
    const categoryId =
      event.target.value === "any" ? null : Number(event.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      refTrainingId: null,
      categoryId: categoryId,
    }));
    if (categoryId !== null) {
      TrainingsService.searchRefTraining(true, categoryId).then((trainings) => {
        setTrainings([]);
        setTrainings(trainings);
      });
    } else {
      setTrainings([]);
    }
  };

  const onTrainingValueChange = (event) => {
    setCurrentPage(0);
    const refTrainingId =
      event.target.value === "any" ? null : Number(event.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      refTrainingId: refTrainingId,
    }));
  };

  const onStatusValueChange = (event) => {
    setCurrentPage(0);
    const trainingStatus =
      event.target.value === "any" ? null : event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      trainingStatus: trainingStatus,
    }));
  };

  const onTeamsValueChange = (event) => {
    setCurrentPage(0);
    const teamId = event.target.value === "any" ? null : event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      teamId: teamId,
    }));
  };

  useEffect(() => {
    TrainingsService.getCategories().then((categories) => {
      setCategories(categories);
    });
    PerformanceReviewService.getPerformanceReviewUsers(
      performanceReviewId
    ).then((users) => {
      setAllUsers(users);
    });
    TeamService.getTeams().then((teams) => {
      setTeams(teams);
    });
    PerformanceReviewService.getPerformanceReviewById(performanceReviewId).then(
      (performanceReview) => {
        setPerformanceReview(performanceReview);
      }
    );
  }, [performanceReviewId]);

  useEffect(() => {
    if (performanceReviewId) {
      TrainingsService.getUsersTrainings(
        performanceReviewId,
        currentPage,
        filters
      )
        .then((response) => response.json())
        .then((usersTrainings) => {
          setTotalPages(usersTrainings.totalPages);
          setEmployeesTrainings(usersTrainings.content);
          setTotalTrainingsCount(usersTrainings.totalElements);
          setIsLoading(false);
        });
    }
  }, [performanceReviewId, currentPage, filters]);

  const renderFilters = () => (
    <Row className="pt-2">
      <Col>
        <DebouncedSearchInput
          name="name"
          placeholder={intl.formatMessage({ id: "Training.employees.name" })}
          id="name-control"
          size="sm"
          type="text"
          onChange={onNameValueChange}
        />
      </Col>
      <Col>
        <DebouncedSearchInput
          name="training name"
          placeholder={intl.formatMessage({ id: "Training.search.name" })}
          id="name-control"
          size="sm"
          type="text"
          onChange={onTrainingNameValueChange}
        />
      </Col>
      <Col>
        <Form.Control
          id="team-control"
          as="select"
          name="role"
          size="sm"
          className="filter-item"
          onChange={onTeamsValueChange}
        >
          <option key={"any"} value={"any"}>
            {intl.formatMessage({ id: "Filter.any.team" })}
          </option>
          {teams.map((team, index) => (
            <option key={index} value={team.id} id={index}>
              {team.name}
            </option>
          ))}
        </Form.Control>
      </Col>
      <Col>
        <Form.Control
          id="role-control"
          as="select"
          name="role"
          size="sm"
          className="filter-item"
          onChange={onCategoryChange}
        >
          <option key={"any"} value={"any"}>
            {intl.formatMessage({ id: "Filter.any.category" })}
          </option>
          {categories.map((value, index) => (
            <option key={index} value={value.id} id={index}>
              {value.category}
            </option>
          ))}
        </Form.Control>
      </Col>
      <Col>
        <Form.Control
          id="role-control"
          as="select"
          name="role"
          size="sm"
          className="filter-item"
          onChange={onTrainingValueChange}
        >
          <option key={"any"} value={"any"}>
            {intl.formatMessage({ id: "Filter.any.training" })}
          </option>
          {trainings.map((value, index) => (
            <option key={index} value={value.id} id={index}>
              {value.label}
            </option>
          ))}
        </Form.Control>
      </Col>
      <Col>
        <Form.Control
          id="team-control"
          as="select"
          name="role"
          size="sm"
          className="filter-item"
          onChange={onStatusValueChange}
        >
          <option key={"any"} value={"any"}>
            {intl.formatMessage({ id: "Filter.any.status" })}
          </option>
          {trainingStatuses.map((status, index) => (
            <option key={index} value={status.value} id={index}>
              {status.label}
            </option>
          ))}
        </Form.Control>
      </Col>
    </Row>
  );

  const handleExport = () =>
    TrainingsService.exportAsExcel(currentPage, performanceReviewId, filters);

  return (
    <Container>
      <ToastContainer
        ref={(ref) => (toastContainer = ref)}
        className="toast-top-right"
      />
      <Form.Group>
        {performanceReview && (
          <>
            <div style={{ color: "var(--primary-light)" }}>
              {intl.formatMessage({ id: "Trainings.label" }) +
                performanceReview.title}
            </div>
            <Row className="mt-2">
              <Col md={6}>
                <Filter
                  value={tabValue}
                  items={[
                    {
                      value: tabValue,
                      title: intl.formatMessage({
                        id: "All.employees.label",
                      }),
                      count: totalTrainingsCount,
                    },
                  ]}
                />
              </Col>
              <Col md={6}>
                <Button
                  className="float-right"
                  variant="link"
                  style={{ fontSize: "1.1rem" }}
                  onClick={toggleAddTrainingModal}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                  {<FormattedMessage id="Button.add" />}
                </Button>
                <Button
                  className="float-right"
                  variant="link"
                  style={{ fontSize: "1.1rem" }}
                  onClick={handleExport}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  {<FormattedMessage id="Training.button.export" />}
                </Button>
              </Col>
            </Row>
            {renderFilters()}
          </>
        )}
        {isLoading ? (
          <Loading />
        ) : employeesTrainings.length > 0 ? (
          <>
            <Row className="mt-3">
              <TeamTrainingEmployees
                employeesTrainings={employeesTrainings}
                updateTrainingStatus={updateTrainingStatus}
                updateTrainingFeedback={updateTrainingFeedback}
                mode={"StatusManger"}
              />
            </Row>
            <Pagination
              currentPage={currentPage}
              paginationClickHandler={paginationClickHandler}
              setCurrentPage={setCurrentPage}
              pages={totalPages}
            />
          </>
        ) : (
          <Row className="mt-3">
            <Col sm={6}>
              <FormattedMessage id="Manager.noEmployeesToShow" />
            </Col>
          </Row>
        )}
        <Modal
          size="xl"
          show={showAddTrainingModal}
          onHide={toggleAddTrainingModal}
          centered
        >
          <AddTrainingForm
            mode={mode}
            user={emptyUser}
            manager={user}
            handleClose={toggleAddTrainingModal}
            handleSubmit={createNewTraining}
            goalType={goalType}
            setting={true}
            copyingGoals={false}
            allUsers={allUsers}
          />
        </Modal>
      </Form.Group>
    </Container>
  );
};

export default UsersTrainings;
