import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/goal-types";

const GoalTypeService = {
  getAllGoalTypes: () =>
    authFetchJSON(BASE_URL).then((response) => response.json()),

  getGoalTypeById: (goalTypeId) =>
    authFetchJSON(`${BASE_URL}/${goalTypeId}`).then((response) =>
      response.json()
    ),
};

export default GoalTypeService;
