import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import Loading from "./display/Loading";
import ErrorComponent from "./ErrorComponent";
import AuthenticationService from "../../services/AuthenticationService";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import { PERMISSION_SELF_ASSESS } from "../../constants/permissions";
import "./home.css";

class Home extends React.Component {
  state = {
    performanceReviewGoal: null,
    goals: [],
    loading: true,
    error: null,
    notFound: false,
  };

  componentDidMount() {
    this.props.user
      ? PerformanceReviewGoalService.getCurrentPerformanceReviewGoalByUserId(
          this.props.user.id
        )
          .then((performanceReviewGoal) =>
            PerformanceReviewGoalService.getGoalsByPerformanceReviewGoalId(
              performanceReviewGoal.performanceReviewGoalId
            )
              .then((goals) => {
                this.setState({
                  performanceReviewGoal,
                  goals,
                  loading: false,
                });
              })
              .catch(this.handleError)
          )
          .catch(this.handleError)
      : this.setState({ loading: false });
  }

  handleError = (error) => {
    if (error.message.includes("not found")) {
      this.setState({
        notFound: true,
        loading: false,
      });
    } else {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else if (this.state.error) {
      return <ErrorComponent message={this.state.error.message} />;
    } else if (
      this.props.user &&
      AuthenticationService.hasPermission(
        this.props.user,
        PERMISSION_SELF_ASSESS
      )
    ) {
      if (this.state.goals.length > 0) {
        return <Redirect to="/my-goals" />;
      } else {
        return (
          <Container className="animated home">
            <Row>
              <Col>
                <h2>
                  {this.props.intl.formatMessage({ id: "Home.welcome" })}{" "}
                  {this.props.user.firstName}
                </h2>
                <h3>
                  <FormattedMessage id="Home.welcome.title" />
                </h3>
                <p>
                  <FormattedMessage id="Home.welcome.message" />
                </p>
                <p>
                  <FormattedMessage id="Home.welcome.questions" />
                  {this.props.configProps.team}{" "}
                  <a href={`mailto:${this.props.configProps.teamEmail}`}>
                    {this.props.configProps.teamEmail}
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        );
      }

    } else {
      return <></>;
    }
  }
}

export default injectIntl(withRouter(Home));
