import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/manager-feedback";

const ManagerFeedbackService = {
  getManagerFeedbackQuestions: () =>
    authFetchJSON(`${BASE_URL}/questions`).then((response) => response.json()),
  submitFeedback: (
    managerFeedback,
    userId,
    managerId,
    assessorId,
    anonymous
  ) => {
    return authFetchJSON(BASE_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        managerFeedback: managerFeedback,
        userId: userId,
        managerId: managerId,
        assessorId: assessorId,
        anonymous: anonymous,
      }),
    });
  },
  getManagerFeedbackDrafts: (userId, assessorId) =>
    authFetchJSON(
      `${BASE_URL}/${userId}/feedback-scores/?assessorId=${assessorId}`
    ).then((response) => response.json()),
  getManagerRecievedScores: (userId, performanceReviewId) =>
    authFetchJSON(
      `${BASE_URL}/${userId}/recieved-scores${
        !performanceReviewId ? `` : `?review=${performanceReviewId}`
      }`
    ).then((response) => response.json()),
  getAvgManagerScore: (userId, processId, performanceReviewId) =>
    authFetchJSON(
      `${BASE_URL}/${userId}/avg-score?processId=${processId}&performanceReviewId=${performanceReviewId}`
    ).then((response) => response.json()),
  getManagerFeedbackByPerfReviewAndProcess: (
    userId,
    processId,
    performanceReviewId
  ) => {
    if (processId && performanceReviewId) {
      authFetchJSON(
        `${BASE_URL}/${userId}/?processId=${processId}&performanceReviewId=${performanceReviewId}`
      ).then((response) => response.json());
    }
  },
  getManagerFeedbackByPerfReview: (userId, performanceReviewId) => {
    if (performanceReviewId) {
      authFetchJSON(
        `${BASE_URL}/${userId}/?performanceReviewId=${performanceReviewId}`
      )
        .then((response) => response.ok && response.json())
        .catch((e) => console.log(e));
    }
  },
  getManagerProcessAndPerformanceReviews: (managerId) =>
    authFetchJSON(`${BASE_URL}/${managerId}/processes`).then(
      (response) => response.ok && response.json()
    ),
};

export default ManagerFeedbackService;
