import React from "react";
import { useLocation } from "react-router";
import { Redirect, Route } from "react-router-dom";
import AuthenticationService from "../../../services/AuthenticationService";
import MyProfileContainer from "../../profile/MyProfileContainer";

const ProfileRoutes = ({
  component: Component,
  path,
  exact,
  user,
  permission,
  readOnly,
  loading,
  configProps,
}) => {
  const location = useLocation();

  return (
    <Route
      exact={exact}
      path={path}
      render={() =>
        AuthenticationService.hasPermission(user, permission) ? (
          <>
            <MyProfileContainer
              user={user}
              loading={loading}
              configProps={configProps}
            />
            <Component
              key={location.pathname}
              user={user}
              readOnly={readOnly}
            />
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default ProfileRoutes;
