const {
  EMPLOYEE_ASESSOR_TYPE,
  MANAGER_ASSESSOR_TYPE,
  FINAL_ASSESSOR_TYPE,
} = require("../constants/assessorTypes");

export const getAssessorStatus = (assessor) => {
  if (
    (assessor.type === EMPLOYEE_ASESSOR_TYPE ||
      assessor.type === MANAGER_ASSESSOR_TYPE) &&
    !assessor.draft
  ) {
    return `${assessor.createdBy.firstName} is ready`;
  } else if (assessor.type === FINAL_ASSESSOR_TYPE && !assessor.draft) {
    return "Meeting done";
  }
};
