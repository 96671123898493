import React, { useMemo, useEffect } from "react";
import debounce from "lodash.debounce";
import { Form } from "react-bootstrap";

const { Control } = Form;

const DebouncedSearchInput = ({
  onChange,
  placeholder,
  name,
  id,
  size,
  type,
  style,
}) => {
  const debouncedResults = useMemo(() => {
    return debounce((value) => onChange(value), 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = ({ target }) => debouncedResults(target.value);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <Control
      style={style}
      type={type}
      name={name}
      size={size}
      id={id}
      className="filter-item"
      placeholder={placeholder}
      onChange={onChangeHandler}
    />
  );
};

export default DebouncedSearchInput;
