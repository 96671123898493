import React from "react";
import PropTypes from "prop-types"; // for our bonus, see below
import { default as ReactSelect } from "react-select";

// specify props.allowSelectAll = true to enable!
const CustomSelect = (props) => {
  if (props?.allowSelectAll) {
    if (
      props?.value?.length === props?.options?.length &&
      props?.options?.length > 0 &&
      props?.value?.length > 0
    ) {
      return (
        <ReactSelect
          {...props}
          value={[props?.allOption]}
          onChange={(selected) => props.onChange(selected?.slice(1))}
        />
      );
    }

    if (props?.options?.length > 0) {
      return (
        <ReactSelect
          {...props}
          options={[props.allOption, ...props.options]}
          onChange={(selected) => {
            if (
              selected?.length > 0 &&
              selected[selected?.length - 1].value === props.allOption.value
            ) {
              return props.onChange(props.options);
            }
            return props.onChange(selected);
          }}
        />
      );
    }
  }

  return <ReactSelect {...props} />;
};

export default CustomSelect;

CustomSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

CustomSelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};
