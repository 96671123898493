import authFetchJSON from "../components/security/fetchClient";

const ApplicationService = {
  getApplicationVersion: () =>
    authFetchJSON("/actuator/info").then((response) => response.json()),

  getApplicationConfigurationProperties: () =>
    authFetchJSON("/actuator/configprops").then((response) => response.json()),
};

export default ApplicationService;
