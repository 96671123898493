import React, { useState, useEffect } from "react";
import { Card, Image, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Loading from "../../common/display/Loading";
import ManagerGradeSelector from "./ManagerGradeSelector";
import UserService from "../../../services/UserService";
import UserSecretIcon from "../../../icons/UserSecretIcon";
import "../../appraisal/comment-display.css";

const DEFAULT_IMAGE = "/images/profile-pictures/default-profile-picture.jpg";

export default function FeedbackCommentV2(props) {
  const { userId, data, anonymous, marginRight, hr } = props;
  const [isLoading, setIsLoading] = useState(true);

  const [showMore, setShowMore] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setShowMore(data.comment.length > 35);
    if (isLoading) {
      Promise.all([UserService.getUserById(userId)])
        .then(([targetUser]) => {
          UserService.getUserProfilePictureUrlByEmail(
            targetUser.email,
            (url) => {
              setImageUrl(url);
            }
          ).catch(() => setImageUrl(null));
        })
        .catch(() => setImageUrl(null));
      setIsLoading(false);
    }
  }, [data.comment, userId, isLoading]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div
        className="comment-display-wrapper"
        style={{
          display: "flex",
          flexDirection: "row",
          border: "none",
          alignItems: "normal",
          backgroundColor: "transparent",
        }}
      >
        <div className="image-wrapper">
          <Image
            src={anonymous || imageUrl === null ? DEFAULT_IMAGE : imageUrl}
            roundedCircle
          />
        </div>
        <Card>
          <Card.Body>
            {hr && data.anonymous && (
              <div style={{ float: "left", marginTop: "-3px" }}>
                <UserSecretIcon height="16px" width="16px" />
              </div>
            )}
            <Card.Title
              style={
                (!data.comment ? { margin: "0" } : {},
                hr && data.anonymous ? { marginLeft: "7%" } : {})
              }
            >
              {anonymous ? "" : `${data.name} `}
            </Card.Title>

            <Card.Text>
              {showMore && data.comment.length > 34
                ? `${data.comment.substring(0, 34)}`
                : data.comment}
            </Card.Text>
            {data.comment.length > 35 && (
              <Button
                variant="link"
                style={{ padding: "0", margin: "0" }}
                onClick={(e) => {
                  setShowMore(!showMore);
                  e.target.blur();
                }}
              >
                {showMore && data.comment.length > 34 ? (
                  <FormattedMessage id="ManagerFeedback.showLongComment" />
                ) : (
                  <FormattedMessage id="ManagerFeedback.hideLongComment" />
                )}
              </Button>
            )}
          </Card.Body>
          <div style={{ marginRight }}>
            <ManagerGradeSelector
              readOnly
              circleWidth="2.75rem"
              value={data.score}
            />
          </div>
        </Card>
      </div>
    </>
  );
}
