import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getFirstElement } from "../../util/arrayUtils";
import { Formik } from "formik";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Image from "../common/Image";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import "./setGoalsModal.css";
import { useIntl, FormattedMessage } from "react-intl";
const { Group, Label, Control } = Form;

const SetGoalsModal = ({
  selectedUser,
  organizationalGoalsTemplates,
  goalTemplateGroupTopics,
  handleClose,
}) => {
  const history = useHistory();
  const [subject, setSubject] = useState("0");
  const [organizationalTemplate, setOrganizationalTemplate] = useState(() => {
    const defaultOrganizationalTemplate = getFirstElement(
      organizationalGoalsTemplates
    );
    return defaultOrganizationalTemplate
      ? defaultOrganizationalTemplate.id.toString()
      : undefined;
  });
  const intl = useIntl();

  const initialValues = initValues(
    selectedUser,
    subject,
    organizationalTemplate
  );
  const byPlaceholder = intl.formatMessage({
    id: "Appraisal.by",
  });
  const periodPlaceholder = intl.formatMessage({
    id: "Reviews.period",
  });
  const defaultGoalsTemplate = intl.formatMessage({
    id: "Goals.template.default",
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => submit(values, history, handleClose)}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog className="set-goals-modal">
            <Modal.Body>
              <Modal.Title>
                <FormattedMessage id="PeformanceReview.start" />
              </Modal.Title>
              <hr />
              <Row>
                <Col md="4">
                  <Image email={values.user.email} thumbnail />
                </Col>
                <Col className="set-goals-modal-text" md={8}>
                  <h4>{`${values.user.firstName} ${values.user.lastName}`}</h4>
                  <p>{values.user.position}</p>
                  <p>
                    <ConfigurationPropertiesContext.Consumer>
                      {({ formatDate }) =>
                        `${periodPlaceholder}: ${formatDate(
                          values.user.performanceReview.start
                        )} - ${formatDate(values.user.performanceReview.end)}`
                      }
                    </ConfigurationPropertiesContext.Consumer>
                  </p>
                  <p>{`${byPlaceholder}: ${values.user.managerName}`}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Group as={Col}>
                  <Label>
                    <FormattedMessage id="Goals.template" />
                  </Label>
                  <Control
                    as="select"
                    name="subject"
                    id="subject"
                    value={values.subject}
                    onChange={(event) => setSubject(event.target.value)}
                  >
                    <option value="0">{defaultGoalsTemplate}</option>
                    {goalTemplateGroupTopics &&
                      goalTemplateGroupTopics.map((g) => (
                        <option key={g.id} value={`${g.id}`}>
                          {g.topic}
                        </option>
                      ))}
                  </Control>
                </Group>
              </Row>
              <Row className="mt-3">
                <Group as={Col}>
                  <Label>
                    <FormattedMessage id="Goals.goalsType" />
                  </Label>
                  <Control
                    as="select"
                    name="organizationalTemplate"
                    id="organizationalTemplate"
                    value={values.organizationalTemplate}
                    onChange={(event) =>
                      setOrganizationalTemplate(event.target.value)
                    }
                  >
                    {organizationalGoalsTemplates &&
                      organizationalGoalsTemplates.map((g) => (
                        <option key={g.id} value={`${g.id}`}>
                          {g.topic}
                        </option>
                      ))}
                  </Control>
                </Group>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
          <Row className="mt-3 btn-row">
            <Group as={Col}>
              <Button className="float-right" variant="primary" type="submit">
                <FormattedMessage id="EditForm.submit" />
              </Button>
              <Button
                className="mr-2 float-right"
                variant="secondary"
                onClick={handleClose}
              >
                <FormattedMessage id="EditForm.cancel" />
              </Button>
            </Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const initValues = (selectedUser, subject, organizationalTemplate) => {
  return {
    user: selectedUser,
    subject,
    organizationalTemplate,
  };
};

const submit = (values, history, handleCloseModal) => {
  PerformanceReviewGoalService.applyPerformanceReviewGoalTemplate(
    values.user,
    Number(values.subject),
    Number(values.organizationalTemplate)
  ).then(() => {
    handleCloseModal();
    history.push(
      `/prg/${values.user.performanceReviewGoal.performanceReviewGoalId}/goals`
    );
  });
};

export default SetGoalsModal;
