import React from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faFileExport } from "@fortawesome/free-solid-svg-icons";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_REVIEW,
  APPRAISAL_STATUS_CLOSED,
} from "../../constants/appraisalStatus";
import {
  PROCESS_USER_ACTION_APPRAISAL,
  PROCESS_USER_ACTION_GOALS,
} from "../../constants/processUserAction";
import {
  PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
  PERFORMANCE_REVIEW_GOAL_PENDING,
  PERFORMANCE_REVIEW_GOAL_SET,
} from "../../constants/performanceReviewGoalStatus";
import "../common/filter.css";
import Filter from "../common/Filter";
const { Control } = Form;

class ProcessParticipantsFilter extends React.Component {
  state = {
    activeValue: "Action any",
    name: "",
    status: this.props.queryParams.appraisal
      ? this.props.queryParams.appraisal
      : "Any status",
    goals: this.props.queryParams.goals
      ? this.props.queryParams.goals
      : "Any goals",
    action:
      this.props.actionParam[0] === "?action"
        ? this.props.actionParam[1]
        : this.props.actionParam[0].substring(1).toUpperCase(),
    manager: "All managers",
    resetAvailable:
      this.props.queryParams.appraisal ||
      this.props.queryParams.goals ||
      this.props.queryParams.action
        ? true
        : false,
    tabs: [
      {
        value: "Action any",
        title: <FormattedMessage id="ProcessParticipantsFilter.any" />,
        count:
          this.props.participantsToDisplay.length < 999
            ? this.props.participantsToDisplay.length
            : "1k+",
      },
      {
        value: PROCESS_USER_ACTION_APPRAISAL,
        title: <FormattedMessage id="ProcessParticipantsFilter.appraisals" />,
        count:
          this.props.appraisalAction < 999 ? this.props.appraisalAction : "1k+",
      },
      {
        value: PROCESS_USER_ACTION_GOALS,
        title: <FormattedMessage id="ProcessParticipantsFilter.goals" />,
        count: this.props.goalsAction < 999 ? this.props.goalsAction : "1k+",
      },
    ],
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.goalsAction !== this.props.goalsAction ||
      prevProps.appraisalAction !== this.props.appraisalAction
    ) {
      this.setState({
        tabs: [
          {
            value: "Action any",
            title: <FormattedMessage id="ProcessParticipantsFilter.any" />,
            count:
              this.props.participantsToDisplay.length < 999
                ? this.props.participantsToDisplay.length
                : "1k+",
          },
          {
            value: PROCESS_USER_ACTION_APPRAISAL,
            title: (
              <FormattedMessage id="ProcessParticipantsFilter.appraisals" />
            ),
            count:
              this.props.appraisalAction < 999
                ? this.props.appraisalAction
                : "1k+",
          },
          {
            value: PROCESS_USER_ACTION_GOALS,
            title: <FormattedMessage id="ProcessParticipantsFilter.goals" />,
            count:
              this.props.goalsAction < 999 ? this.props.goalsAction : "1k+",
          },
        ],
      });
    }
  }
  handleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => this.handleFilter()
    );
  };

  handleReset = () => {
    this.setState({
      name: "",
      status: "Any status",
      goals: "Any goals",
      action: "Action any",
      manager: "All managers",
      resetAvailable: false,
    });
    this.props.handleSubmit(
      "",
      "Any status",
      "Any goals",
      "Action any",
      "All managers",
      true
    );
  };

  handleFilter = () => {
    this.setState({
      resetAvailable: true,
    });
    this.props.handleSubmit(
      this.state.name,
      this.state.status,
      this.state.goals,
      this.state.action,
      this.state.manager
    );
  };

  handleFilterChange = (event) =>
    this.setState(
      {
        action: event,
        activeValue: event,
        goals: "Any goals",
        status: "Any status",
      },
      () => this.handleFilter()
    );

  handleStatusChange = (event) => {
    this.setState(
      {
        status: event.target.value,
      },
      () => this.handleFilter()
    );
  };

  handleGoalsChange = (event) => {
    this.setState(
      {
        goals: event.target.value,
      },
      () => this.handleFilter()
    );
  };
  render() {
    return (
      <>
        <Row className="mb-3">
          <Col>
            <Filter
              value={
                this.state.action ? this.state.action : this.state.activeValue
              }
              items={this.state.tabs}
              onChange={this.handleFilterChange}
            />
          </Col>
          <Col>
            {this.props.processStatus !== APPRAISAL_STATUS_CLOSED &&
            this.props.performanceReviewStatus !== "COMPLETED" ? (
              <Button
                className="float-right"
                variant="link"
                onClick={this.props.handleAddParticipant}
                style={{ fontSize: "1.1rem" }}
              >
                <FontAwesomeIcon icon={faPlusCircle} />
                {<FormattedMessage id="ProcessParticipantsFilter.add" />}
              </Button>
            ) : (
              <></>
            )}
            {this.props.participantsToDisplay.length !== 0 && (
              <Button
                className="float-right"
                variant="link"
                onClick={this.props.handleExport}
                disabled={this.props.participantsToDisplay.length === 0}
                style={{ fontSize: "1.1rem" }}
              >
                <FontAwesomeIcon icon={faFileExport} />
                {<FormattedMessage id="ProcessParticipantsFilter.export" />}
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-4">
            <Control
              type="text"
              name="name"
              placeholder={this.props.intl.formatMessage({
                id: "UsersTable.name",
              })}
              size="sm"
              className="filter-item"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </Col>
          <Col className="col-lg-4">
            <Control
              as="select"
              name="manager"
              size="sm"
              className="filter-item"
              value={this.state.manager}
              onChange={this.handleChange}
            >
              <FormattedMessage id="Users.anyManager">
                {(message) => (
                  <option key="All" value="All managers">
                    {message}
                  </option>
                )}
              </FormattedMessage>

              {this.props.managers.map((u) => (
                <option key={u.id}>{`${u.firstName} ${u.lastName}`}</option>
              ))}
            </Control>
          </Col>

          {this.state.action === PROCESS_USER_ACTION_APPRAISAL && (
            <Col className="col-lg-4">
              <Control
                as="select"
                name="appraisals"
                size="sm"
                className="filter-item"
                onChange={this.handleStatusChange}
                value={
                  this.state.action === PROCESS_USER_ACTION_APPRAISAL &&
                  this.state.status
                }
              >
                <FormattedMessage id="ProcessParticipantsFilter.anyStatus">
                  {(message) => (
                    <option name="status" value="Any status">
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                <FormattedMessage id={`Status.${APPRAISAL_STATUS_ACTIVE}`}>
                  {(message) => (
                    <option name="status" value={APPRAISAL_STATUS_ACTIVE}>
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                <FormattedMessage id={`Status.${APPRAISAL_STATUS_REVIEW}`}>
                  {(message) => (
                    <option name="status" value={APPRAISAL_STATUS_REVIEW}>
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                <FormattedMessage id={`Status.${APPRAISAL_STATUS_CLOSED}`}>
                  {(message) => (
                    <option name="status" value={APPRAISAL_STATUS_CLOSED}>
                      {message}
                    </option>
                  )}
                </FormattedMessage>
              </Control>
            </Col>
          )}
          {this.state.action === PROCESS_USER_ACTION_GOALS && (
            <Col className="col-lg-4">
              <Control
                as="select"
                name="goals"
                size="sm"
                className="filter-item"
                onChange={this.handleGoalsChange}
                value={
                  this.state.action === PROCESS_USER_ACTION_GOALS &&
                  this.state.goals
                }
              >
                <FormattedMessage id="ProcessParticipantsFilter.anyGoals">
                  {(message) => (
                    <option name="goals" value="Any goals">
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                <FormattedMessage
                  id={`Status.${PERFORMANCE_REVIEW_GOAL_PENDING}`}
                >
                  {(message) => (
                    <option
                      name="goals"
                      value={PERFORMANCE_REVIEW_GOAL_PENDING}
                    >
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                <FormattedMessage
                  id={`Status.${PERFORMANCE_REVIEW_GOAL_IN_PROGRESS}`}
                >
                  {(message) => (
                    <option
                      name="goals"
                      value={PERFORMANCE_REVIEW_GOAL_IN_PROGRESS}
                    >
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                <FormattedMessage id={`Status.${PERFORMANCE_REVIEW_GOAL_SET}`}>
                  {(message) => (
                    <option name="goals" value={PERFORMANCE_REVIEW_GOAL_SET}>
                      {message}
                    </option>
                  )}
                </FormattedMessage>
              </Control>
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default injectIntl(ProcessParticipantsFilter);
