import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import UserAuthenticationForm from "../components/common/UserAuthenticationForm";

const Login = () => {
  useEffect(() => {
    document.body.className = "login";
  }, []);

  return (
    <Container
      className="animated slideInLeft"
      style={{ alignItems: "center", paddingTop: "0" }}
    >
      <Row>
        <Col>
          <UserAuthenticationForm />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Login);
