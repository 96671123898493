import React from "react";
import FeedbackQuestionStatisticV2 from "./FeedbackQuestionStatisticV2";
import "./QuestionStatisticList.css";

const QuestionStatisticListV2 = ({ questions, anonymous, hr }) => {
  return !questions || questions.length === 0 ? null : (
    <div>
      <ul style={{ padding: "0", margin: "0" }}>
        {questions.map((e) => {
          return (
            <li className="questionCard">
              <FeedbackQuestionStatisticV2
                data={e}
                anonymous={anonymous}
                hr={hr}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QuestionStatisticListV2;
