import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { injectIntl } from "react-intl";
import FeedbackQuestionV2 from "./FeedbackQuestionV2";
import "./FeedbackQuestionStatistic.css";

const FeedbackQuestionStatisticV2 = ({ data, anonymous, intl, hr }) => {
  const generateStatistic = (res) => {
    const labels = [
      intl.formatMessage({
        id: "ManagerFeedback.graph.score1",
      }),
      intl.formatMessage({
        id: "ManagerFeedback.graph.score2",
      }),
      intl.formatMessage({
        id: "ManagerFeedback.graph.score3",
      }),
      intl.formatMessage({
        id: "ManagerFeedback.graph.score4",
      }),
      intl.formatMessage({
        id: "ManagerFeedback.graph.score5",
      }),
    ];
    const info = [0, 0, 0, 0, 0];
    res.forEach((e) => {
      info[e.score - 1]++;
    });

    return {
      labels: labels,
      datasets: [
        {
          data: info,
          backgroundColor: "#6691FF",
          borderColor: "#2D2F66",
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <Container
      fluid
      style={{ minHeight: "0%", paddingTop: "2%", paddingBotton: "2%" }}
    >
      <Col>
        <Row>
          <FeedbackQuestionV2
            question={data.questionBody}
            statistic={generateStatistic(data.questionResponses)}
            responses={data.questionResponses}
            anonymous={anonymous}
            hr={hr}
          />
        </Row>
      </Col>
    </Container>
  );
};

export default injectIntl(FeedbackQuestionStatisticV2);
