import React, { useState } from "react";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Form, Container, Modal } from "react-bootstrap";
import { Formik } from "formik";
import AuthenticationService from "../services/AuthenticationService";
import ErrorIcon from "../icons/ErrorIcon";
import TextInput from "../components/common/form/TextField";

import "./forgot-password.css";
import PasswordConfirm from "./PasswordConfirm";

const SendForgotPassEmail = ({ onHide, showModal }) => {
  const intl = useIntl();

  const [error, setError] = useState(undefined);
  const [mode, setMode] = useState();
  const [showOk, setShowOk] = useState(false);

  const handleOpenModal = (mode) => {
    setMode(mode);
    setShowOk(true);
  };

  const passwordValidationSchema = Yup.object({
    email: Yup.string()
      .required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      )
      .email(
        intl.formatMessage({
          id: "SendForgotPassEmail.email.validation",
        })
      ),
  });

  const submitLogin = (values) =>
    AuthenticationService.sendForgotPassEmail(values)
      .then((response) => {
        if (response.ok) {
          handleOpenModal("OK");
          //  onHide();
        } else if (response.status === 422) {
          window.location.href = "/";
        } else {
          response.json().then((error) => setError(error));
        }
      })
      .catch(() => {
        window.location.href = "/";
      });

  return (
    <>
      {!mode && (
        <Modal
          className="forgot-password"
          show={showModal}
          onHide={onHide}
          centered
          style={{ height: "70%" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="SendForgotPassEmail.title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="forgotPass">
            <Container className="forgotPass" style={{ paddingTop: "0" }}>
              <Formik
                enableReinitialize
                initialValues={{
                  email: "",
                }}
                validationSchema={passwordValidationSchema}
                onSubmit={(values) => submitLogin(values)}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  errors,
                }) => (
                  <>
                    <Form
                      className="SendForgotPassEmail-form authentication-form"
                      onSubmit={handleSubmit}
                    >
                      <p>
                        <FormattedMessage id="SendForgotPassEmail.instruction" />
                      </p>
                      <TextInput
                        formGroupClass="SendForgotPassEmail-form"
                        formControlClass="SendForgotPassEmail"
                        formControlType="text"
                        formControlName="email"
                        name="email"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: "ForgotPassword.email",
                        })}
                        value={values.email}
                        handleChange={handleChange}
                        onChange={(value) => {
                          setFieldValue("email", value);
                        }}
                        onBlur={handleBlur}
                      />

                      {error && (
                        <Form.Group
                          as={Col}
                          className="SendForgotPassEmail-form"
                        >
                          <div className="validation-error-msg">
                            {" "}
                            <ErrorIcon height="16px" width="16px" />{" "}
                            <span style={{ marginLeft: "1%" }}>
                              {" "}
                              {error.type}{" "}
                            </span>
                          </div>
                        </Form.Group>
                      )}

                      <hr />

                      <Modal.Footer
                        className="forgotPass"
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          border: "none",
                        }}
                      >
                        <Button size="sm" type="submit" variant="primary">
                          <FormattedMessage id="ForgotPassword.send" />
                        </Button>
                        <Button
                          size="sm"
                          type="button"
                          variant="outline-secondary"
                          onClick={onHide}
                        >
                          <FormattedMessage id="ForgotPassword.cancel" />
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </>
                )}
              </Formik>
            </Container>
          </Modal.Body>
        </Modal>
      )}

      {mode === "OK" && (
        <PasswordConfirm onHide={onHide} showModal={showOk} setMode={setMode} />
      )}
    </>
  );
};

export default SendForgotPassEmail;
