import React from "react";
import UserService from "../../services/UserService";
import Loading from "../common/display/Loading";
import { APPRAISAL_STATUS_CLOSED } from "../../constants/appraisalStatus";
import AppraisalManage from "./AppraisalManage";
import { EMPLOYEE_ASESSOR_TYPE } from "../../constants/assessorTypes";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
class MyLastAppraisal extends React.Component {
  state = {
    appraisal: undefined,
    loading: true,
    notFound: false,
  };

  componentDidMount() {
    UserService.getCurrentAppraisalByUserId(this.props.user.id)
      .then((appraisal) => this.setState({ appraisal }))
      .catch(() => this.setState({ notFound: true }))
      .then(() => this.setState({ loading: false }));
  }

  render() {
    return this.state.loading ? (
      <Loading />
    ) : this.state.appraisal &&
      this.state.appraisal.status !== APPRAISAL_STATUS_CLOSED ? (
      <AppraisalManage
        user={this.props.user}
        appraisalId={this.state.appraisal.id}
        assessorType={EMPLOYEE_ASESSOR_TYPE}
      />
    ) : (
      <Container fluid>
        <h4 className="text-center">
          {this.state.notFound && <FormattedMessage id="Appraisal.noFound" />}
          {this.state.appraisal &&
            this.state.appraisal.status === APPRAISAL_STATUS_CLOSED && (
              <FormattedMessage id="Appraisal.noActiveExitsts" />
            )}
        </h4>
      </Container>
    );
  }
}

export default MyLastAppraisal;
