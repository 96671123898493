/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Loading from "../common/display/Loading";
import { FieldArray, Formik } from "formik";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import GoalService from "../../services/GoalService";
import Pagination from "../common/Pagination";
import CustomPopover from "../common/display/CustomPopover";
import ReactHtmlParser from "react-html-parser";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./copy-goals.css";
import UserService from "../../services/UserService";
import { useIntl, FormattedMessage } from "react-intl";
const CopyGoalsForm = ({
  manager,
  goalType,
  handleClose,
  handleSubmit,
  copyingGoals,
}) => {
  const intl = useIntl();

  const [filter, setFilter] = useState();
  const [goals, setGoals] = useState([]);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState();
  const [jobTitles, setJobTitles] = useState([]);
  const [names, setNames] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchGoals(0).then(() => setIsLoading(false));

    if (names !== [] || jobTitles !== []) {
      UserService.getJobTitles().then((jobTitles) => {
        setJobTitles(jobTitles);
      });
      UserService.getAllNames().then((names) => {
        setNames(names);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, goalType]);
  const placeholder = intl.formatMessage({
    id: "Manager.searchByNameGoals",
  });
  const fetchGoals = (selected) => {
    if (filter) {
      return GoalService.getPageOfGoalsBy(
        filter,
        manager.id,
        goalType.id,
        selected
      ).then((response) => {
        setGoals(response.goals);
        setPages(response.pages);
        setSelectedPage(selected);
      });
    }
    return Promise.resolve().then(() => {
      setGoals([]);
      setPages(0);
      setSelectedPage(selected);
    });
  };

  const handleChangeFilterInput = (event) => {
    const input = event.target.value;

    if (input) setFilter(event.target.value);
    else setFilter();
  };

  const handlePaginationClick = (selected) => fetchGoals(selected);

  const handleSelectAll = (isSelected, setFieldValue) => {
    setFieldValue("goals", isSelected ? goals : []);

    goals.forEach(
      (goal) => (document.getElementById(goal.id).checked = isSelected)
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Formik
      enableReinitialize
      initialValues={{ goals: [] }}
      onSubmit={(values) => handleSubmit(values.goals)}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "95%", maxWidth: "100%" }}>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    size="sm"
                    value={filter}
                    onChange={(event) => handleChangeFilterInput(event)}
                    placeholder={placeholder}
                  />
                </Col>
              </Row>
              <Container className="copy-goals-container mt-3" fluid>
                <Row>
                  <Col md={1}>
                    <Form.Check
                      id={goalType.id}
                      type="checkbox"
                      label=""
                      custom
                      onChange={(event) =>
                        handleSelectAll(event.target.checked, setFieldValue)
                      }
                    />
                  </Col>
                  <Col md={11} className="goals-title">
                    <FormattedMessage id={`GoalType.${goalType.id}`} />
                  </Col>
                </Row>
                <Row className="table-header-row">
                  <Col children={<FormattedMessage id="Goals.weight" />} />
                </Row>
                <div className="goals">
                  {goals && goals.length > 0 ? (
                    <FieldArray
                      name="goals"
                      render={(arrayHelpers) =>
                        goals.map((goal, index) => (
                          <Row
                            key={`goal-row-${index}`}
                            className={`table-row`}
                          >
                            <Col md={1} className="full-height-centered">
                              <Form.Check
                                id={goal.id}
                                type="checkbox"
                                custom
                                label=""
                                onChange={(event) =>
                                  event.target.checked
                                    ? arrayHelpers.push(goal)
                                    : arrayHelpers.remove(index)
                                }
                              />
                            </Col>
                            <Col md={10}>
                              <Row className="goal-title">
                                <Col>{goal.title}</Col>
                              </Row>
                              {goal.description && (
                                <Row className="goal-desc">
                                  <Col>{goal.description}</Col>
                                </Row>
                              )}
                              <Row className="goal-kpi">
                                <Col>
                                  <i>{"KPI: "}</i>
                                  {goal.kpi ? (
                                    <>
                                      {goal.kpiDescription ? (
                                        <CustomPopover
                                          placement="right"
                                          text={ReactHtmlParser(
                                            goal.kpiDescription
                                          )}
                                        >
                                          <span>
                                            {goal.kpi}
                                            <FontAwesomeIcon
                                              style={{ marginLeft: "5px" }}
                                              icon={faInfoCircle}
                                            />
                                          </span>
                                        </CustomPopover>
                                      ) : (
                                        goal.kpi
                                      )}
                                    </>
                                  ) : (
                                    "--"
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              md={1}
                              className="weight-column full-height-centered"
                              children={Number(goal.weight).toFixed(2)}
                            />
                          </Row>
                        ))
                      }
                    />
                  ) : (
                    <h4 className="text-center mt-3">
                      {" "}
                      <FormattedMessage id="Goals.noFoundАny" />.
                    </h4>
                  )}
                </div>
              </Container>
              <Row className="pagination">
                <Col>
                  <Pagination
                    active={selectedPage}
                    pages={pages}
                    onClick={(selected) => handlePaginationClick(selected)}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "97.5%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="EditForm.cancel" />
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={values.goals.length === 0 || copyingGoals}
            >
              {copyingGoals ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {" Copying..."}
                </>
              ) : (
                <FormattedMessage id="Goals.copySelected" />
              )}
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default CopyGoalsForm;
