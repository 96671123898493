import React from "react";
import { Card, Dropdown, Button, Badge, Modal } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faEdit,
  faLock,
  faLockOpen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import {
  PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
  PERFORMANCE_REVIEW_GOAL_PENDING,
  PERFORMANCE_REVIEW_GOAL_SET,
} from "../../constants/performanceReviewGoalStatus";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_CLOSED,
  APPRAISAL_STATUS_REVIEW,
} from "../../constants/appraisalStatus";
import { SORT_ASC, SORT_DESC, SORT_NONE } from "../../constants/resultsSort";
import {
  BAD_REQUEST_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import {
  PROCESS_STATUS_CLOSED,
  PROCESS_STATUS_OPEN,
} from "../../constants/processStatus";
import {
  PROCESS_USER_ACTION_APPRAISAL,
  PROCESS_USER_ACTION_GOALS,
} from "../../constants/processUserAction";
import {
  PROCESS_CLOSE_MODE,
  PROCESS_OPEN_MODE,
} from "../../constants/processModes";
import ProcessService from "../../services/ProcessService";
import AddParticipantsModal from "../hr/AddParticipantsModal";
import CloseAndReopenProcessModal from "../hr/CloseAndReopenProcessModal";
import DeleteConfirmation from "./DeleteConfirmation";
import StepProgressBar from "./StepProgressBar";
import "./process-card.css";

export default function ProcessCard(props) {
  const {
    managers,
    onEditClick,
    showSuccessNotification,
    showErrorNotification,
    audits,
  } = props;

  const [process, setProcess] = React.useState(props.process);
  const [stats, setStats] = React.useState([]);
  const [showAddParticipantsModal, setShowAddParticipantsModal] =
    React.useState(false);
  const [showCloseAndReopenModal, setShowCloseAndReopenModal] =
    React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const circleData = [
    { value: process.participants.length, text: "Total" },
    { value: process.completion, text: "Completed" },
    { value: process.deadlineApproaching, text: "Deadline<br/>close" },
  ];

  const fetchData = React.useCallback(() => {
    Promise.all([
      ProcessService.getProcessById(process.id),
      ProcessService.getProcessUsersByProcessId(process.id),
    ]).then(([process, processUsers]) => {
      process.participants.forEach((participant) => {
        participant.processUser = processUsers.find(
          (processUser) => processUser.user.id === participant.id
        );

        participant.processAction = participant.processUser.action;
        if (participant.processUser.appraisal !== null) {
          participant.status = participant.processUser.appraisal.status;
        } else if (participant.processUser.performanceReviewGoal !== null) {
          participant.status =
            participant.processUser.performanceReviewGoal.performanceReviewGoalStatus;
        } else {
          participant.status = PERFORMANCE_REVIEW_GOAL_PENDING;
        }
      });

      process.appraisals = processUsers
        .filter((pu) => pu.appraisal)
        .map((pu) => pu.appraisal);
      setProcess(process);
      setStats([
        {
          title: "Appraisals",
          value: process.participants.filter(
            (p) => p.action === PROCESS_USER_ACTION_APPRAISAL
          ).length,
          filter: {
            param: "appraisals",
            value: PROCESS_USER_ACTION_APPRAISAL,
          },
          children: [
            {
              title: "Assessment",
              value: process.appraisals.filter(
                (a) => a.status === APPRAISAL_STATUS_ACTIVE
              ).length,
              filter: APPRAISAL_STATUS_ACTIVE,
            },
            {
              title: "One-to-one",
              value: process.appraisals.filter(
                (a) => a.status === APPRAISAL_STATUS_REVIEW
              ).length,
              filter: APPRAISAL_STATUS_REVIEW,
            },
            {
              title: "Completed",
              value: process.appraisals.filter(
                (a) => a.status === APPRAISAL_STATUS_CLOSED
              ).length,
              filter: APPRAISAL_STATUS_CLOSED,
            },
          ],
        },
        {
          title: "Goal setting",
          value: process.participants.filter(
            (p) => p.action === PROCESS_USER_ACTION_GOALS
          ).length,
          filter: {
            param: "goals",
            value: PROCESS_USER_ACTION_GOALS,
          },
          children: [
            {
              title: "Pending",
              value: process.participants.filter(
                (p) =>
                  p.action === PROCESS_USER_ACTION_GOALS &&
                  p.processUser &&
                  p.processUser.performanceReviewGoal &&
                  p.processUser.performanceReviewGoal
                    .performanceReviewGoalStatus ===
                    PERFORMANCE_REVIEW_GOAL_PENDING
              ).length,
              filter: PERFORMANCE_REVIEW_GOAL_PENDING,
            },
            {
              title: "In Progress",
              value: process.participants.filter(
                (p) =>
                  p.action === PROCESS_USER_ACTION_GOALS &&
                  p.processUser &&
                  p.processUser.performanceReviewGoal &&
                  p.processUser.performanceReviewGoal
                    .performanceReviewGoalStatus ===
                    PERFORMANCE_REVIEW_GOAL_IN_PROGRESS
              ).length,
              filter: PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
            },
            {
              title: "Set",
              value: process.participants.filter(
                (p) =>
                  p.action === PROCESS_USER_ACTION_GOALS &&
                  p.processUser &&
                  p.processUser.performanceReviewGoal &&
                  p.processUser.performanceReviewGoal
                    .performanceReviewGoalStatus === PERFORMANCE_REVIEW_GOAL_SET
              ).length,
              filter: PERFORMANCE_REVIEW_GOAL_SET,
            },
          ],
        },
      ]);
    });
  }, [process.id]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    const dataExport = {
      processId: process.id,
      participants: process.participants.map((participant) => {
        const manager = managers.find((m) => m.id === participant.managerId);

        return {
          action: participant.action,
          email: participant.email,
          firstName: participant.firstName,
          lastName: participant.lastName,
          managerName: participant.managerName,
          managerEmail: manager !== undefined ? manager.email : null,
          status: participant.status,
        };
      }),
      filter: {
        name: "",
        status: [],
        goals: [],
        action: [],
        manager: "",
      },
    };

    ProcessService.exportDashboardAsExcel(dataExport).then((r) => r);
  };

  const submitNewParticipants = (process, participants) => {
    ProcessService.addParticipantsToProcess(process.id, participants).then(
      (response) => {
        if (response.ok) {
          response.text().then((message) => {
            showSuccessNotification(message);
          });
        } else if (response.status === BAD_REQUEST_ERROR_CODE) {
          response.text().then((message) => {
            showErrorNotification(message);
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          showErrorNotification(
            "You don't have permission to add participants to process."
          );
        }
        setShowAddParticipantsModal(false);
        fetchData();
      }
    );
  };

  const submitCloseProcess = () =>
    ProcessService.updateProcessStatus(process.id, PROCESS_STATUS_CLOSED).then(
      (response) => {
        setShowCloseAndReopenModal(false);
        if (response.ok) {
          fetchData();
          showSuccessNotification("Process closed successfully.");
        } else if (response.status === BAD_REQUEST_ERROR_CODE) {
          response.json().then((error) => showErrorNotification(error.message));
        }
      }
    );

  const submitReopenProcess = () =>
    ProcessService.updateProcessStatus(process.id, PROCESS_STATUS_OPEN).then(
      (response) => {
        setShowCloseAndReopenModal(false);
        if (response.status === BAD_REQUEST_ERROR_CODE) {
          showErrorNotification("Process is not closed yet.");
        } else {
          fetchData();
          showSuccessNotification("Process reopened successfully.");
        }
      }
    );

  const submitDeletingProcess = (process) =>
    ProcessService.attemptDeletingProcessById(process.id).then((response) => {
      if (response.status === BAD_REQUEST_ERROR_CODE) {
        showErrorNotification(
          "There are already participants and/or appraisals attached to this process. Please, remove them first and try again."
        );
      } else {
        window.location.reload();
      }
    });

  const getNextSortOption = (sortOption) => {
    if (sortOption === SORT_NONE) {
      return SORT_ASC;
    } else if (sortOption === SORT_ASC) {
      return SORT_DESC;
    }
    return SORT_NONE;
  };

  return (
    <Card className="process-card">
      <Card.Header>
        <Card.Title>
          {process.title}
          <span>
            {" ("}
            {process.type[0] + process.type.toString().toLowerCase().slice(1)})
          </span>
          <Dropdown alignRight>
            <Dropdown.Toggle className="ellipsis-btn" variant="outline-light">
              <FontAwesomeIcon icon={faEllipsisH} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={onEditClick}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </Dropdown.Item>
              {process.status !== APPRAISAL_STATUS_CLOSED ? (
                <Dropdown.Item onClick={() => setShowCloseAndReopenModal(true)}>
                  <FontAwesomeIcon icon={faLock} />
                  Close
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={() => setShowCloseAndReopenModal(true)}>
                  <FontAwesomeIcon icon={faLockOpen} />
                  Reopen
                </Dropdown.Item>
              )}
              {process.status !== APPRAISAL_STATUS_CLOSED && (
                <Dropdown.Item onClick={() => setShowDeleteConfirmation(true)}>
                  <FontAwesomeIcon icon={faTrash} />
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Card.Title>
        <Card.Subtitle>
          <span className="info-title">Managed by: </span>
          {process.managedBy.firstName + " " + process.managedBy.lastName}
          <span className="subtitle-italic-right">
            {moment(process.startDate).format("DD.MM.YYYY")} -{" "}
            {moment(process.endDate).format("DD.MM.YYYY")}
          </span>
        </Card.Subtitle>
      </Card.Header>
      <Card.Body className="flex-display-wrap">
        <div className="half-width">
          <p className="info-title" style={{ marginBottom: "25px" }}>
            Participants
          </p>
          <div className="three-column-grid">
            {circleData.map((data, index) => (
              <div key={index}>
                <div className="circle-wrapper">
                  <CircularProgressbar
                    value={data.text === "Total" ? 100 : data.value}
                    text={data.text === "Total" ? data.value : data.value + "%"}
                    styles={{ path: { stroke: "var(--primary-light)" } }}
                  />
                </div>
                <p>{ReactHtmlParser(data.text)}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="half-width text-center">
          <span className="info-title">Status</span>
          <StepProgressBar audits={audits} />
        </div>
      </Card.Body>
      <Card.Footer className="flex-display-wrap">
        <div className="footer-actions-wrapper flex-display-wrap">
          <Dropdown>
            <Dropdown.Toggle variant="outline-primary">Stats</Dropdown.Toggle>

            <Dropdown.Menu>
              {stats.map((topLevel, index) => (
                <React.Fragment key={index}>
                  <Dropdown.Item
                    href={`/processes/${process.id}/participants?action=${topLevel.filter.value}`}
                  >
                    <strong>{topLevel.title}</strong>{" "}
                    <Badge bg="primary">{topLevel.value}</Badge>
                  </Dropdown.Item>
                  <Dropdown.Divider></Dropdown.Divider>
                  <Dropdown.Divider></Dropdown.Divider>
                  {topLevel.children.map((child, index) => (
                    <Dropdown.Item
                      key={index}
                      href={`/processes/${process.id}/participants?${topLevel.filter.param}=${child.filter}`}
                    >
                      {child.title} <Badge bg="primary">{child.value}</Badge>
                    </Dropdown.Item>
                  ))}
                  {index === 0 && <Dropdown.Divider></Dropdown.Divider>}
                </React.Fragment>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {process.status !== APPRAISAL_STATUS_CLOSED && (
            <Button
              variant="outline-primary"
              onClick={() => setShowAddParticipantsModal(true)}
            >
              Add participants
            </Button>
          )}
          <Button
            variant="outline-primary"
            onClick={handleExport}
            disabled={process.participants.length === 0}
          >
            Export
          </Button>
        </div>
        <Button
          className="float-right"
          href={`/processes/${process.id}/participants`}
        >
          Participants
        </Button>
      </Card.Footer>
      <AddParticipantsModal
        show={showAddParticipantsModal}
        handleClose={() => setShowAddParticipantsModal(false)}
        handleSubmit={submitNewParticipants}
        process={process}
        getNextSortOption={getNextSortOption}
        managers={managers}
      />
      <CloseAndReopenProcessModal
        showModal={showCloseAndReopenModal}
        handleCloseModal={() => setShowCloseAndReopenModal(false)}
        process={process}
        mode={
          process.status !== APPRAISAL_STATUS_CLOSED
            ? PROCESS_CLOSE_MODE
            : PROCESS_OPEN_MODE
        }
        handleSubmit={
          process.status !== APPRAISAL_STATUS_CLOSED
            ? submitCloseProcess
            : submitReopenProcess
        }
      />
      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        centered
      >
        <DeleteConfirmation
          process={process}
          handleClose={() => setShowDeleteConfirmation(false)}
          handleSubmit={submitDeletingProcess}
        />
      </Modal>
    </Card>
  );
}
