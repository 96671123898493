import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/teams";

const TeamService = {
  getTeams: () => authFetchJSON(BASE_URL).then((response) => response.json()),

  getTeam: (teamId) =>
    authFetchJSON(`${BASE_URL}/${teamId}`).then((response) => response.json()),

  getUsersByTeamId: (teamId, query) =>
    authFetchJSON(
      `${BASE_URL}/${teamId}/users?${query ? `queryString=${query}` : ``}`
    ).then((response) => response.json()),

  attemptSavingTeam: (team) =>
    authFetchJSON(`${BASE_URL}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(team),
    }),

  updateMembers: (teamId, userIds) =>
    authFetchJSON(`${BASE_URL}/${teamId}/users`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userIds),
    }),

  attemptDeletingTeamById: (teamId) =>
    authFetchJSON(`${BASE_URL}/${teamId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),

  getUsersForReviewByTeamId: (teamId, query, performanceReviewId) =>
    authFetchJSON(
      `${BASE_URL}/${teamId}/users-for-review?${
        query && performanceReviewId
          ? `queryString=${query}&performance_review_id=${performanceReviewId}`
          : ``
      }`
    ).then((response) => response.json()),

  getProcessPerUserByTeamIdAndPerformanceReviewId: (
    teamId,
    performanceReviewId
  ) =>
    authFetchJSON(
      `${BASE_URL}/${teamId}/process-users?performance_review_id=${performanceReviewId}`
    ).then((response) => response.json()),
};

export default TeamService;
