import React from "react";
import {
  Badge,
  Col,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import classnames from "classnames";
import "./filter.css";

const Filter = ({ items, className, ...rest }) => {
  return (
    <Row className={classnames("filter", className)}>
      <Col>
        <ToggleButtonGroup name="filter" type="radio" {...rest}>
          {items.map((item, index) => (
            <ToggleButton
              id={`filter-item-${index}`}
              key={`filter-item-${index}`}
              variant="outline-dark"
              value={item.value}
            >
              <div style={{ fontSize: "1.1rem" }}>
                {item.title}
                <Badge variant="light">{item.count}</Badge>{" "}
              </div>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Col>
    </Row>
  );
};

export default Filter;
