export const PROCESS_CREATE_MODE = "create_process";
export const PROCESS_UPDATE_MODE = "update_process";
export const PROCESS_DELETE_MODE = "delete_process";
export const PROCESS_OPEN_MODE = "open_process";
export const PROCESS_CLOSE_MODE = "close_process";
export const PROCESS_ADD_PARTICIPANTS_MODE = "add_participants_to_process";

const PROCESS_MODES = [
  PROCESS_CREATE_MODE,
  PROCESS_UPDATE_MODE,
  PROCESS_DELETE_MODE,
  PROCESS_OPEN_MODE,
  PROCESS_CLOSE_MODE,
  PROCESS_ADD_PARTICIPANTS_MODE,
];

export default PROCESS_MODES;
