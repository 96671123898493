import React from "react";
import { Image, Row } from "react-bootstrap";
import "./footer.css";

const CustomFooter = ({ versionData }) => (
  <>
    <Row className="data">
      {versionData && (
        <p>
          {versionData.git
            ? `Ver.: ${versionData.build.version} / Rev.${versionData.git.commit.id} / ${versionData.git.commit.time}`
            : `Ver.: ${versionData.build.version}`}
        </p>
      )}
      <a href="https://see.proxiad.com/">
        <Image src="/images/proxiad-logo.png" alt="Proxiad" height={45} />
      </a>
    </Row>
  </>
);

export default CustomFooter;
