import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/oneOnOnes/feedback-questions";

const OneOnOneFeedbackQuestionsService = {
  addQuestion: (data) => {
    return authFetchJSON(`${BASE_URL}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        type: data.type.label,
        content: data.content,
        hasScore: data.hasScore,
        active: data.active,
        mandatory: data.mandatory,
      }),
    });
  },

  searchQuestions: (
    page,
    sortBy,
    sortDir,
    pageSize,
    type,
    content,
    hasScore,
    active,
    mandatory
  ) => {
    let URL = `${BASE_URL}?page=${page}&sortBy=${sortBy}&sortDir=${sortDir}&pageSize=${pageSize}`;
    if (type) {
      URL += `&typeId=${type?.value}`;
    }
    if (content) {
      URL += `&content=${content}`;
    }
    if (hasScore !== undefined) {
      URL += `&hasScore=${hasScore}`;
    }
    if (active !== undefined) {
      URL += `&active=${active}`;
    }
    if (mandatory !== undefined) {
      URL += `&mandatory=${mandatory}`;
    }
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },

  questionIsUsed: (id) => {
    let URL = `${BASE_URL}/${id}/isUsed`;
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },

  updateQuestion: (id, data) =>
    authFetchJSON(`${BASE_URL}/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        type: data.type.label,
        content: data.content,
        hasScore: data.hasScore,
        active: data.active,
        mandatory: data.mandatory,
      }),
    }),

  deleteQuestion: (id) =>
    authFetchJSON(`${BASE_URL}/${id}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),
};

export default OneOnOneFeedbackQuestionsService;
