export const GRADES_DICTIONARY_NUM = {
  "N/A": -1,
  "Select grade": 0,
  Unsatisfactory: 1,
  "Improvement needed": 2,
  "Meets expectations": 3,
  "Exceeds expectations": 4,
  Exceptional: 5,
};

export const GRADES_DICTIONARY_TEXT = {
  "-1": "N/A",
  0: "Select grade",
  1: "Unsatisfactory",
  2: "Improvement needed",
  3: "Meets expectations",
  4: "Exceeds expectations",
  5: "Exceptional",
};

export const GOAL_GRADE = 3;
