export const ORGANIZATIONAL_GOAL_TYPE_ID = 1;
export const PROJECT_GOAL_TYPE_ID = 2;
export const PERSONAL_DEVELOPMENT_GOAL_TYPE_ID = 3;

export const getGoalTypeNameById = (id) => {
  if (id === ORGANIZATIONAL_GOAL_TYPE_ID) {
    return "organizational";
  } else if (id === PROJECT_GOAL_TYPE_ID) {
    return "performance";
  }
  return "personal development";
};
