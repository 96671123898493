import React, { useState, useEffect } from "react";
import Loading from "../common/display/Loading";
import AdminService from "../../services/AdminService";
import { Table, Container, Form, Row, Col, Breadcrumb } from "react-bootstrap";
import Pagination from "./Pagination";
import { FormattedMessage } from "react-intl";

import "./audit-styles.css";
import "../common/filter.css";
import "../common/datepicker-field.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { INPUT_DATE_FORMAT } from "../../constants/dateTimeFormatterTypes";

const { Control } = Form;
const Audit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [audits, setAudits] = useState([]);
  const [pages, setPages] = useState();

  const [sortBy, setSortBy] = useState("createdAt");
  const [username, setUsername] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    AdminService.getAuditLogsPagable(
      currentPage,
      sortBy,
      username,
      startDate,
      endDate
    ).then((auditLogs) => {
      setAudits(auditLogs.audits);
      setPages(auditLogs.pages);
      setIsLoading(false);
    });
  }, [currentPage, sortBy, username, startDate, endDate]);

  const paginationClickHandler = (event) => {
    if (event.target.text === undefined) {
      return;
    } else {
      setCurrentPage(parseInt(event.target.text) - 1);
    }
  };
  const setStartDateHandler = (data) => {
    setCurrentPage(0);
    setStartDate(data);
  };
  const setEndDateHandler = (data) => {
    setCurrentPage(0);
    setEndDate(data);
  };

  const setUsernameHandler = (event) => {
    setCurrentPage(0);
    setUsername(event.target.value);
  };

  const allAudits = audits.map((audit, index) => (
    <tbody style={{ backgroundColor: "white" }} key={audit.newValue + index}>
      <tr>
        <td>{audit.entityId}</td>
        <td>{audit.entityName}</td>
        <td>{audit.entityAttribute}</td>
        <td>{audit.operation}</td>
        {audit.oldValue.length > 12 ? (
          <td data-toggle="tooltip" title={audit.oldValue}>
            {audit.oldValue.substring(0, 12) + "..."}
          </td>
        ) : (
          <td>{audit.oldValue === "" ? "--" : audit.oldValue}</td>
        )}
        {audit.newValue.length > 12 ? (
          <td data-toggle="tooltip" title={audit.newValue}>
            {audit.newValue.substring(0, 12) + "..."}
          </td>
        ) : (
          <td>{audit.newValue === "" ? "--" : audit.newValue}</td>
        )}
        {audit.createdBy.length > 12 ? (
          <td data-toggle="tooltip" title={audit.createdBy}>
            {audit.createdBy.substring(0, 12) + "..."}
          </td>
        ) : (
          <td>{audit.createdBy === "" ? "--" : audit.createdBy}</td>
        )}
        <td>{audit.createdAt}</td>
      </tr>
    </tbody>
  ));
  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item active>
              <FormattedMessage id="Audit.audit" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <div className="flter p-2">
        <Row className="justify-content-md-center">
          <Col>
            <Control
              type="text"
              name="name"
              placeholder="User"
              className="filter-item"
              value={username}
              onChange={setUsernameHandler}
            />
          </Col>
          <Col>
            <DatePicker
              placeholderText="from: DD/MM/YYYY"
              className="date-picker"
              dateFormat={INPUT_DATE_FORMAT}
              selected={startDate}
              size="sm"
              onChange={(date) => setStartDateHandler(date)}
            ></DatePicker>
          </Col>
          <Col>
            <DatePicker
              placeholderText="to: DD/MM/YYYY"
              className="date-picker"
              dateFormat={INPUT_DATE_FORMAT}
              selected={endDate}
              onChange={(date) => setEndDateHandler(date)}
            ></DatePicker>
          </Col>
        </Row>
      </div>

      <Table responsive style={{ marginTop: "1%" }}>
        <thead
          style={{
            color: "#7d7c7c",
            fontSize: "1.125rem",
            fontWeight: "300",
          }}
        >
          <tr className="table-title">
            <th scope="col">
              <a href="#a" onClick={() => setSortBy("entityId")}>
                Id
              </a>
            </th>
            <th scope="col">
              <a href="#a" onClick={() => setSortBy("entityName")}>
                <FormattedMessage id="Audit.entity" />
              </a>
            </th>
            <th scope="col">
              <a href="#a" onClick={() => setSortBy("entityAttribute")}>
                <FormattedMessage id="Audit.attribute" />
              </a>
            </th>
            <th scope="col">
              <a href="#a" onClick={() => setSortBy("message")}>
                <FormattedMessage id="Audit.operation" />
              </a>
            </th>
            <th scope="col">
              <a href="#a" onClick={() => setSortBy("oldValue")}>
                <FormattedMessage id="Audit.oldValue" />
              </a>
            </th>
            <th scope="col">
              <a href="#a" onClick={() => setSortBy("newValue")}>
                <FormattedMessage id="Audit.newValue" />
              </a>
            </th>
            <th scope="col">
              <a href="#a" onClick={() => setSortBy("createdBy")}>
                <FormattedMessage id="Audit.user" />
              </a>
            </th>
            <th scope="col">
              <a href="#a" onClick={() => setSortBy("createdAt")}>
                <FormattedMessage id="Audit.date" />
              </a>
            </th>
          </tr>
        </thead>

        {allAudits}
      </Table>
      {audits.length === 0 && (
        <h4 className="title text-center">No data found</h4>
      )}
      {pages > 0 && (
        <Pagination
          style={{ left: 0 }}
          currentPage={currentPage}
          paginationClickHandler={paginationClickHandler}
          setCurrentPage={setCurrentPage}
          pages={pages}
        ></Pagination>
      )}
    </Container>
  );
};

export default Audit;
