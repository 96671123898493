import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

const TeamDataRow = ({ hoveredTeam, team, ...rest }) => (
  <tr {...rest}>
    <td>
      {team.name}
      {team.id === hoveredTeam?.id && (
        <div className="actions">
          {team.buttons.map((button, index) => (
            <Button
              key={`button-team-action-${index}`}
              variant="link"
              onClick={button?.action}
            >
              {" "}
              <FontAwesomeIcon icon={button.icon} />
              {button.label}
            </Button>
          ))}
        </div>
      )}
    </td>
  </tr>
);

export default TeamDataRow;
