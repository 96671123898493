import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import FeedbackCommentV2 from "./FeedbackCommentV2";

const FeedbackCommentListV2 = ({ responses, anonymous, hr }) => {
  const [hasComments, setHasComments] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const hasAtLeastOneComment = responses.find((e) => e.comment !== "");
    setHasComments(hasAtLeastOneComment);
    setShowMore(hasAtLeastOneComment);
  }, [responses]);
  return hasComments ? (
    <div>
      <button
        className="btn btn-primary"
        style={{ marginBottom: "2%", marginLeft: showMore ? "2px" : "10px" }}
        onClick={(e) => {
          setShowMore(!showMore);
          e.target.blur();
        }}
      >
        {showMore && hasComments ? (
          <FormattedMessage id="ManagerFeedback.showComments" />
        ) : (
          <FormattedMessage id="ManagerFeedback.hideComments" />
        )}
      </button>
      {!showMore && (
        <ul style={{ margin: "0", padding: "0", width: "50%" }}>
          {responses.map((e) => {
            return e.comment !== "" ? (
              <li>
                <FeedbackCommentV2
                  data={e}
                  anonymous={anonymous}
                  userId={e.userId}
                  hr={hr}
                />
              </li>
            ) : null;
          })}
        </ul>
      )}
    </div>
  ) : (
    <Row style={{ marginTop: "5%" }}>
      <Col>
        <span>
          <FormattedMessage id="ManagerFeedback.noComments" />
        </span>
      </Col>
    </Row>
  );
};

export default FeedbackCommentListV2;
