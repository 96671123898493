import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v3/processes";

const ProcessServiceV3 = {
  getProcessesByPerformanceReviewId: (performanceReviewId) =>
    authFetchJSON(
      `${BASE_URL}/?performanceReviewId=${performanceReviewId}`
    ).then((response) => response.json()),
};

export default ProcessServiceV3;
