import React, { useEffect, useState } from "react";
import ManagerFeedbackService from "../../services/ManagerFeedbackService";
import ScoreCircle from "../common/ScoreCircle";

const ManagerFeedbackAvgScore = ({
  userId,
  processId,
  performanceReviewId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [score, setScore] = useState();

  useEffect(() => {
    if (isLoading) {
      Promise.all([
        ManagerFeedbackService.getAvgManagerScore(
          userId,
          processId,
          performanceReviewId
        ),
      ]).then(([score]) => {
        setScore(score);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  return (
    !isLoading && (
      <div className="appraisal-score">
        <ScoreCircle score={score} />
      </div>
    )
  );
};

export default ManagerFeedbackAvgScore;
