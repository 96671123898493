import React from "react";
import { Modal, Row } from "react-bootstrap";
import { getRoleName } from "../../util/roleName";
import NoModalBtn from "./buttons/NoModalBtn";
import YesModalBtn from "./buttons/YesModalBtn";
import { FormattedMessage } from "react-intl";

const DeleteConfirmation = (props) => {
  const submitDeleting = (object) => {
    return props.handleSubmit(object);
  };

  return (
    <>
      <Modal.Header closeButton />
      <Modal.Body>
        <Row className="justify-content-center my-4">
          <Modal.Title
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "center",
              fontSize: "larger",
            }}
          >
            {<FormattedMessage id="Delete.confirm" />}
            {props.user && `${props.user.firstName} ${props.user.lastName}?`}
            {props.role && `role '${getRoleName(props.role)}'?`}
            {props.goal &&
              `goal ${getGoalNameForModalTitle(props.goal.title)}?`}
            {props.goalTemplateGroup && `'${props.goalTemplateGroup.topic}'?`}
            {props.process &&
              `process ${getGoalNameForModalTitle(props.process.title)}?`}
            {props.processParticipant &&
              `participant ${props.processParticipant.firstName} ${props.processParticipant.lastName} from the process?`}
            {props.team && `team '${props.team.name}'?`}
            {props.performanceReview &&
              `global performance review '${props.performanceReview.title}'?`}
            {props.question && (
              <FormattedMessage id="Delete.question.confirm" />
            )}
          </Modal.Title>
        </Row>
        <Row className="justify-content-center">
          <YesModalBtn
            onClick={() => {
              props.user && submitDeleting(props.user);
              props.role && submitDeleting(props.role);
              props.goal && submitDeleting(props.goal);
              props.process && submitDeleting(props.process);
              props.goalTemplateGroup &&
                submitDeleting(props.goalTemplateGroup.id);
              props.processParticipant &&
                submitDeleting(props.processParticipant.id);
              props.team && submitDeleting(props.team.id);
              props.performanceReview &&
                submitDeleting(props.performanceReview.id);
              props.question && submitDeleting(props.question.id);
              props.handleClose();
            }}
          />
          <NoModalBtn onClick={props.handleClose} />
        </Row>
      </Modal.Body>
    </>
  );
};

const getGoalNameForModalTitle = (name) => {
  if (name.length <= 50) {
    return `'${name}'`;
  }
  return `'${name.substring(0, 47)}...'`;
};

export default DeleteConfirmation;
