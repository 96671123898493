import React, { useEffect, useState, useRef } from "react";
import UserService from "../../../services/UserService";
import { Avatar, Tooltip } from "antd";
import Loading from "./Loading";

const AvatarImage = ({ name, email, ...props }) => {
  const [src, setSrc] = useState();
  const imgEl = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const onImageLoaded = () => setLoaded(true);

  useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener("load", onImageLoaded);
      return () => imgElCurrent.removeEventListener("load", onImageLoaded);
    }
  }, [imgEl]);

  useEffect(() => {
    UserService.getUserProfilePictureUrlByEmail(email, setSrc);
    onImageLoaded();
  }, [email]);

  return loaded ? (
    <Tooltip ref={imgEl} title={name} placement="top">
      <Avatar src={src} loading="lazy" {...props} />
    </Tooltip>
  ) : (
    <Loading />
  );
};

export default AvatarImage;
