import en from "./en.json";
import bg from "./bg.json";
import fr from "./fr.json";

const languages = {
  en: { label: "English", messages: en },
  bg: { label: "Български", messages: bg },
  fr: { label: "Français", messages: fr },
};

export default languages;
