import * as React from "react";
const UserSecretIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 25 25",
    }}
    viewBox="0 0 25 25"
    {...props}
  >
    <style>{".st1{fill:none}"}</style>
    <path
      d="m9 14.6.5.1c.1 0 .2-.1.2-.2.4-.5.5-1.4.4-1.9-.7-.1-2.3-.3-3.6-.5V12.7c0 .5.1.8.2 1 .4.7 1.4.8 2.3.9z"
      className="st1"
    />
    <path
      d="M14.9 16.4h-.3c-1.1 0-2-1-2.3-2.7v-.1h-.4v.1c-.3 1.6-1.2 2.7-2.3 2.7h-.3l-.5-.1c-.7-.1-1.4-.2-2.1-.5.9 3.6 3.2 6 5.8 6 2.7 0 5.1-2.7 5.9-6.5-.9.7-2 .8-3 1l-.5.1z"
      className="st1"
    />
    <path
      d="m14.4 14.5.2.2.5-.1c.9-.1 1.9-.2 2.3-.8.2-.3.3-.9.2-1.7-1.3.2-3 .4-3.7.5V12.9c.1.5.2 1.2.5 1.6zM19.6 6.9v.6c0 .5-.4.9-.9.9s-.9-.4-.9-.9c0-2.9-.5-4.4-3.4-4.4-.3 0-.5.1-.7.2-.3.1-.7.2-1.1.2-.5 0-.9-.1-1.2-.2-.2-.1-.4-.1-.7-.1-3 0-3.5 1.4-3.5 4.4 0 .5-.4.9-.9.9s-.9-.5-.9-1v-.6c-2 .6-2.5 1.3-2.5 1.4 0 .7 3.4 2.3 9.6 2.3s9.6-1.6 9.6-2.3c0-.1-.5-.8-2.5-1.4z"
      className="st1"
    />
    <path
      d="M23.9 8.3c0-1.4-1.6-2.5-4.5-3.3-.4-1.8-1.5-3.7-5-3.7-.6 0-1 .1-1.3.2-.5.2-.8.1-1.3 0-.3-.1-.7-.2-1.2-.2-3.5.1-4.6 1.9-5 3.8-2.9.7-4.5 1.8-4.5 3.2 0 1.4 1.5 2.4 3.7 3.1 0 .2-.1.6-.1 1-.1.9-.2 1.5-.1 1.8 0 .4.1.8.2 1.2.9 4.9 4 8.3 7.7 8.3 3.8 0 6.9-3.5 7.7-8.5.1-.4.1-.8.1-1.2 0-.3 0-.7-.1-1.3 0-.3-.1-.6-.1-.8 0-.1 0-.3-.1-.4 2.3-.7 3.9-1.7 3.9-3.2zM12.4 21.9c-2.6 0-4.9-2.4-5.8-6 .7.3 1.5.4 2.1.5l.5.1h.3c1.1 0 2-1 2.3-2.7v-.1h.4v.1c.3 1.6 1.2 2.7 2.3 2.7h.3l.5-.1c1-.1 2.1-.3 3-1-.7 3.8-3.2 6.5-5.9 6.5zm-6-9.1V12.2c1.3.2 3 .4 3.6.5.1.4-.1 1.4-.4 1.9l-.2.2-.4-.2c-.9-.1-1.9-.2-2.3-.8-.2-.2-.3-.6-.3-1zm7.6-.1s0-.1 0 0c.7-.2 2.3-.4 3.7-.6.1.8.1 1.3-.2 1.7-.4.6-1.4.7-2.3.8l-.5.1c-.1 0-.2-.1-.2-.2-.3-.4-.4-1.1-.4-1.6-.1 0-.1-.1-.1-.2 0 .1 0 0 0 0zm-1.5-2.1c-6.3 0-9.6-1.6-9.6-2.3 0-.2.5-.8 2.5-1.4v.6c0 .5.4.9.9.9s.9-.4.9-.9c0-3 .5-4.4 3.5-4.4.2 0 .4.1.7.1.3.1.7.2 1.2.2s.9-.1 1.1-.2c.3-.1.4-.2.7-.2 2.9 0 3.4 1.4 3.4 4.4 0 .5.4.9.9.9s.9-.4.9-.9v-.6c2 .6 2.5 1.3 2.5 1.4 0 .8-3.3 2.4-9.6 2.4z"
      style={{
        fill: props.color,
      }}
    />
  </svg>
);
export default UserSecretIcon;
