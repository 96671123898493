import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import {
  EMPLOYEE_ASESSOR_TYPE,
  FINAL_ASSESSOR_TYPE,
  MANAGER_ASSESSOR_TYPE,
} from "../../constants/assessorTypes";
import { PROCESS_USER_ACTION_APPRAISAL } from "../../constants/processUserAction";
import { getAppraisalAssessorByAssessorType } from "../../util/getAppraisalAssessor";
import UserService from "../../services/UserService";
import Loading from "../common/display/Loading";
import Appraisals from "../appraisal/Appraisals";

const HRAppraisalsContainer = ({ user }) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [employee, setEmployee] = useState({});
  const [appraisals, setAppraisals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const intl = useIntl();

  const noAppraisalFound = intl.formatMessage({
    id: "Appraisal.noAppraisalsFor",
  });

  const appraisalsPlaceHolder = intl.formatMessage({
    id: "Processes.tabs.stats.appraisals",
  });

  const userNoFoundPlaceHolder = intl.formatMessage({
    id: "User.noFound",
  });

  const viewAppraisal = (appraisal) =>
    history.push({
      pathname: `/appraisals/${appraisal.id}/overview`,
      state: {
        reviewTitle: location?.state?.reviewTitle,
        processTitle: location?.state?.processTitle,
        historyTitle: "history",
      },
    });

  useEffect(() => {
    UserService.getUserById(id)
      .then((employee) =>
        UserService.getProcessUsersByUserIdAndAction(
          employee.id,
          PROCESS_USER_ACTION_APPRAISAL
        ).then((processUsers) => {
          const appraisals = processUsers.map((processUser) => {
            Object.assign(processUser.appraisal, {
              startDate: processUser.process.performanceReview.start,
              endDate: processUser.process.performanceReview.end,
              assessors: [
                getAppraisalAssessorByAssessorType(
                  processUser.appraisal,
                  EMPLOYEE_ASESSOR_TYPE
                ),
                getAppraisalAssessorByAssessorType(
                  processUser.appraisal,
                  MANAGER_ASSESSOR_TYPE
                ),
                getAppraisalAssessorByAssessorType(
                  processUser.appraisal,
                  FINAL_ASSESSOR_TYPE
                ),
              ],
              viewAction: () => viewAppraisal(processUser.appraisal),
            });

            return processUser.appraisal;
          });

          setEmployee(employee);
          setAppraisals(appraisals);
          setIsLoading(false);
        })
      )
      .catch(() => {
        setNotFound(true);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getTitle = () => {
    if (notFound) return userNoFoundPlaceHolder;
    if (appraisals.length === 0)
      return `${noAppraisalFound}  ${employee.firstName} ${employee.lastName}`;
    else
      return `${employee.firstName} ${employee.lastName} ${appraisalsPlaceHolder}`;
  };
  return isLoading ? (
    <Loading />
  ) : (
    <Container
      fluid
      style={{
        width: "66%",
        marginTop: "1%",
        display: "flex",
        flexDirection: "column",
        padding: 0,
      }}
    >
      {location?.state && appraisals.length > 0 ? (
        <Row style={{ paddingLeft: "4rem" }}>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => history.push("/reviews")}>
              <FormattedMessage id="Breadcrumb.review" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => history.go(-2)}>
              {location?.state?.reviewTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => history.go(-1)}>
              {location?.state?.processTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              active
            >{`${employee.firstName.toUpperCase()} ${employee.lastName.toUpperCase()} APPRAISALS`}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      ) : (
        <Row className="mb-5">
          <Col>
            <header className="text-center">
              <h4>{getTitle()}</h4>
            </header>
          </Col>
        </Row>
      )}
      {!notFound && appraisals.length > 0 && (
        <Appraisals appraisals={appraisals} />
      )}
    </Container>
  );
};

export default HRAppraisalsContainer;
