import React from "react";
import { Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../../common/table/table.css";

import OneOnOneQuestionRow from "./OneOnOneQuestionRow";

const OneOnOneQuestionsTable = ({ questions, handleOpenModal }) => {
  return (
    <Table className="actionsTable" responsive borderless>
      <thead>
        <tr className="table-title">
          <th>
            <FormattedMessage id="OneOnOneQuestionsTable.content.label" />
          </th>
          <th style={{ padding: "0px 0px 13px", width: "6%" }}>
            <FormattedMessage id="OneOnOneQuestionsTable.type.label" />{" "}
          </th>
          <th>
            <FormattedMessage id="OneOnOneQuestionsTable.active.label" />{" "}
          </th>
          <th>
            <FormattedMessage id="OneOnOneQuestionsTable.mandatory.label" />{" "}
          </th>
          <th>
            <FormattedMessage id="OneOnOneQuestionsTable.hasScore.label" />{" "}
          </th>
          <th>
            <FormattedMessage id="OneOnOneQuestionsTable.createdBy.label" />{" "}
          </th>
          <th>
            <FormattedMessage id="OneOnOneQuestionsTable.createdOn.label" />
          </th>
          <th>
            <FormattedMessage id="OneOnOneQuestionsTable.updatedBy.label" />
          </th>
          <th style={{ padding: "0px 0px 13px" }}>
            <FormattedMessage id="OneOnOneQuestionsTable.updatedOn.label" />
          </th>
        </tr>
      </thead>
      <tbody>
        {questions.map((question, index) => (
          <OneOnOneQuestionRow
            key={`question-row-${index}`}
            question={question}
            handleOpenModal={handleOpenModal}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default OneOnOneQuestionsTable;
