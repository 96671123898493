import React from "react";
import "./step-progress-bar.css";
import ReactHtmlParser from "react-html-parser";

const ALL_STEPS = [
  { title: "Pending" },
  { title: "Open" },
  { title: "Closed" },
];

export default function StepProgressBar({ audits }) {
  let steps;
  if (audits) {
    steps = audits
      .map((audit) => {
        return {
          ...audit,
          active: true,
          title: audit.status[0] + audit.status.slice(1).toLowerCase(),
          topText: audit.createdAt,
        };
      })
      .concat(ALL_STEPS.slice(audits.length));
  }

  return (
    <div className="step-progressbar">
      {steps &&
        steps.map((step, index) => (
          <div
            className={`step ${step.active ? "done" : ""} ${
              index === audits.length - 1 ? "active" : ""
            }`}
            key={index}
          >
            <p className="label top">
              {step.topText &&
                ReactHtmlParser(step.topText.split(" ").join("<br/>"))}
            </p>
            <div className="drawing">
              <div className="circle"></div>
            </div>
            <p className="label bottom">{step.title}</p>
          </div>
        ))}
    </div>
  );
}
