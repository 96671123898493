import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Row } from "react-bootstrap";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import {
  PERFORMANCE_REVIEW_GOAL_PENDING,
  PERFORMANCE_REVIEW_GOAL_SET,
} from "../../constants/performanceReviewGoalStatus";
import { FormattedMessage } from "react-intl";
let history;

const DefaultEditGoalsModal = (props) => {
  history = useHistory();

  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <h3>
          <FormattedMessage id="Edit.confirmation" />
        </h3>
      </Modal.Header>
      <Modal.Body>
        <p>{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        {props.type === "submit" && (
          <Row>
            <Button
              variant="secondary"
              onClick={props.handleClose}
              className="mr-2"
            >
              No
            </Button>
            <Button variant="primary" onClick={() => handleSubmit(props.id)}>
              Yes
            </Button>
          </Row>
        )}
        {props.type === "draft" && (
          <Row>
            <Button variant="primary" onClick={handleDraft}>
              OK
            </Button>
          </Row>
        )}
        {props.type === "discard" && (
          <Row>
            <Button
              variant="secondary"
              onClick={() => handleDiscard(props.id)}
              className="mr-2"
            >
              Yes
            </Button>
            <Button variant="primary" onClick={props.handleClose}>
              No
            </Button>
          </Row>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const handleSubmit = (id) =>
  PerformanceReviewGoalService.updatePerformanceReviewGoalStatus(
    id,
    PERFORMANCE_REVIEW_GOAL_SET
  ).then(() => history.push("/manager"));

const handleDraft = () => history.push("/manager");

const handleDiscard = (id) =>
  PerformanceReviewGoalService.removeGoalsFromPerformanceReviewGoal(id).then(
    () =>
      PerformanceReviewGoalService.updatePerformanceReviewGoalStatus(
        id,
        PERFORMANCE_REVIEW_GOAL_PENDING
      ).then(() => history.push("/manager"))
  );

export default DefaultEditGoalsModal;
