import { SORT_NONE, SORT_ASC, SORT_DESC } from "../constants/resultsSort";

export const getFirstElement = (array) =>
  array && array.length > 0 ? array[0] : undefined;

export function getUniqueArray(array) {
  const unique = [];
  const tempObject = {};
  array.forEach((item) => {
    const isExsit = tempObject[item.value];
    if (!isExsit) {
      unique.push(item);
      tempObject[item.value] = item.value;
    }
  });
  return unique;
}

export function getUniqueList(array) {
  const unique = [];
  const tempObject = {};
  array.forEach((item) => {
    const isExsit = tempObject[item.firstname + item.lastName];
    if (!isExsit) {
      unique.push(item);
      tempObject[item.firstname + item.lastName] =
        item.firstname + item.lastName;
    }
  });
  return unique;
}

export function getCommonElements(array1, array2) {
  const uniqueArray = [];

  array1.forEach((ele1) => {
    array2.forEach((ele2) => ele1.id === ele2.id && uniqueArray.push(ele1));
  });
  return uniqueArray;
}

export const getNextSortOption = (sortOption) => {
  if (sortOption === SORT_NONE) {
    return SORT_ASC;
  } else if (sortOption === SORT_ASC) {
    return SORT_DESC;
  }
  return SORT_NONE;
};

export const sortByName = (array, sortByName) => {
  return [].concat(array).sort((a, b) => {
    const aFullName = `${a.firstName} ${a.lastName}`;
    const bFullName = `${b.firstName} ${b.lastName}`;

    if (sortByName !== SORT_NONE && aFullName > bFullName) {
      return sortByName === SORT_ASC ? 1 : -1;
    } else if (sortByName !== SORT_NONE && aFullName < bFullName) {
      return sortByName === SORT_ASC ? -1 : 1;
    }
    return 0;
  });
};
