import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

import "./forgot-password.css";

const PasswordConfirm = ({ onHide, showModal, setMode }) => {
  return (
    <>
      <Modal
        className="forgot-password"
        show={showModal}
        onHide={onHide}
        centered
        style={{ height: "70%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="PasswordConfirm.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage id="PasswordConfirm.instruction" />
          </p>
        </Modal.Body>

        <Modal.Footer
          style={{
            marginBottom: "3%",
          }}
        >
          <Button
            size="sm"
            type="submit"
            variant="primary"
            onClick={() => {
              onHide();
              setMode(null);
            }}
          >
            <FormattedMessage id="PasswordConfirm.button" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PasswordConfirm;
