import {
  faEdit,
  faFileExport,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastr";
import {
  BAD_REQUEST_ERROR_CODE,
  CONFLICT_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import {
  PR_CREATE_MODE,
  PR_DELETE_MODE,
  PR_EDIT_MODE,
} from "../../constants/performanceReviewModes";
import { PERFORMANCE_REVIEW_COMPLETED } from "../../constants/performanceReviewStatus";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import PerformanceReviewService from "../../services/PerformanceReviewService";
import ProcessService from "../../services/ProcessService";
import Loading from "../common/display/Loading";
import Filter from "../common/Filter";
import PerformanceReviewModal from "./PerformanceReviewModal";
import { FormattedMessage } from "react-intl";
let toastContainer;

const Reviews = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [performanceReviews, setPerformanceReviews] = useState([]);
  const [performanceReview, setPerformanceReview] = useState();

  const [prMode, setPrMode] = useState(undefined);
  const [showPRModal, setShowPRModal] = useState(false);
  const [hoverItemId, setHoverItemId] = useState();
  const [status, setStatus] = useState("active");

  const [period, setPeriod] = useState({});

  const [tabs, setTabs] = useState([
    {
      value: "any",
      title: <FormattedMessage id="Reviews.tabs.any" />,
      count: 0,
    },
    {
      value: "pending",
      title: <FormattedMessage id="Reviews.tabs.pending" />,
      count: 0,
    },
    {
      value: "active",
      title: <FormattedMessage id="Reviews.tabs.active" />,
      count: 0,
    },

    {
      value: "completed",
      title: <FormattedMessage id="Reviews.tabs.completed" />,
      count: 0,
    },
  ]);

  const filterClickHandler = (event) => {
    setStatus(event);
  };

  const fetchPerformanceReviews = (status) =>
    PerformanceReviewService.getPerformanceReviewsByStatus(status).then(
      (reviews) => {
        setPerformanceReviews(reviews);
        setIsLoading(false);
      }
    );
  const fetchCounts = () =>
    PerformanceReviewService.getPerformanceReviewsStatusCount().then(
      (counts) => {
        setTabs([
          {
            value: "any",
            title: <FormattedMessage id="Reviews.tabs.any" />,
            count:
              counts.activeCount + counts.pendingCount + counts.completedCount <
              999
                ? counts.activeCount +
                  counts.pendingCount +
                  counts.completedCount
                : "1k+",
          },
          {
            value: "pending",
            title: <FormattedMessage id="Reviews.tabs.pending" />,
            count: counts.pendingCount < 999 ? counts.pendingCount : "1k+",
          },
          {
            value: "active",
            title: <FormattedMessage id="Reviews.tabs.active" />,
            count: counts.activeCount < 999 ? counts.activeCount : "1k+",
          },

          {
            value: "completed",
            title: <FormattedMessage id="Reviews.tabs.completed" />,
            count: counts.completedCount < 999 ? counts.completedCount : "1k+",
          },
        ]);
      }
    );
  useEffect(() => {
    fetchPerformanceReviews(status);
    fetchCounts();
    ProcessService.getPeriod().then((period) => setPeriod(period));
  }, [status]);

  const allPerformanceReviews = performanceReviews.map((review, index) => (
    <tr
      key={`performance-review-row-${index}`}
      className="change-on-hover"
      onClick={(event) =>
        !(event.target instanceof HTMLButtonElement) &&
        history.push(`/reviews/${review.id}/processes`)
      }
      onMouseOver={() => {
        setHoverItemId(review.id);
      }}
      onMouseLeave={() => setHoverItemId()}
    >
      <td>
        <div className="review-title">{review.title}</div>
        {hoverItemId === review.id && (
          <div className="actions">
            {review.status !== PERFORMANCE_REVIEW_COMPLETED && (
              <Button
                variant="link"
                onClick={() => {
                  setPerformanceReview(review);
                  handleOpenPRModal(PR_EDIT_MODE, review);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
                {<FormattedMessage id="Reviews.tabs.edit" />}
              </Button>
            )}
            {review.status !== PERFORMANCE_REVIEW_COMPLETED && (
              <Button
                variant="link"
                onClick={() => {
                  setPerformanceReview(review);
                  handleOpenPRModal(PR_DELETE_MODE, review);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />

                {<FormattedMessage id="Reviews.tabs.delete" />}
              </Button>
            )}
            <Button
              variant="link"
              onClick={() =>
                PerformanceReviewService.exportPersonalDevelopmentGoals(
                  review.id
                )
              }
            >
              <FontAwesomeIcon icon={faFileExport} />
              {<FormattedMessage id="Reviews.tabs.export" />}
            </Button>
          </div>
        )}
      </td>

      <td className="text-center">
        <ConfigurationPropertiesContext.Consumer>
          {({ formatDate }) => `${formatDate(review.createdAt)}`}
        </ConfigurationPropertiesContext.Consumer>
      </td>
      <td>
        <ConfigurationPropertiesContext.Consumer>
          {({ formatDate }) =>
            `${formatDate(review.start)} - ${formatDate(review.end)}`
          }
        </ConfigurationPropertiesContext.Consumer>
      </td>
      <td>
        <FormattedMessage id={`Review.${review.status}`} />{" "}
      </td>
    </tr>
  ));

  const handleOpenPRModal = (mode) => {
    setPrMode(mode);
    setShowPRModal(true);
  };
  const showSuccessNotification = (message) =>
    toastContainer.success(message, "", {
      closeButton: true,
    });
  const showErrorNotification = (message) =>
    toastContainer.error(message, "", {
      closeButton: true,
    });

  const handleSubmitPerformanceReview = async (performanceReview) => {
    if (prMode === PR_CREATE_MODE || prMode === PR_EDIT_MODE) {
      await PerformanceReviewService.attemptSavingPerformanceReview(
        performanceReview
      ).then((response) => {
        if (response.ok) {
          response.json().then(() => {
            fetchPerformanceReviews(status);
            fetchCounts();
            showSuccessNotification(
              <FormattedMessage id="PerfomanceReview.success.saved" />
            );
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          showErrorNotification(
            <FormattedMessage id="PerfomanceReview.noRights" />
          );
        } else if (response.status === BAD_REQUEST_ERROR_CODE) {
          response
            .json()
            .then((error) => showErrorNotification(`${error.message}`));
        }
      });
    } else if (prMode === PR_DELETE_MODE) {
      await PerformanceReviewService.attemptDeletingPerformanceReviewById(
        performanceReview
      ).then((response) => {
        if (response.ok) {
          fetchPerformanceReviews(status);
          fetchCounts();
          showSuccessNotification(
            <FormattedMessage id="PerfomanceReview.success.delete" />
          );
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          showErrorNotification(
            <FormattedMessage id="PerfomanceReview.noRights.delete" />
          );
        } else if (response.status === CONFLICT_ERROR_CODE) {
          showErrorNotification(
            <FormattedMessage id="PerfomanceReview.assignedProcesses.delete" />
          );
        }
      });
    }
  };

  const handleCloseModal = () => {
    setPrMode(undefined);
    setShowPRModal(false);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <ToastContainer
        ref={(ref) => (toastContainer = ref)}
        className="toast-top-right"
      />
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item active>
              <FormattedMessage id="Reviews.reviews" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Filter
            value={status}
            items={tabs}
            onChange={filterClickHandler}
          ></Filter>
        </Col>
        {status !== "completed" && (
          <Col>
            <Button
              className="float-right"
              variant="link"
              onClick={() => handleOpenPRModal(PR_CREATE_MODE)}
              style={{ fontSize: "1.1rem", justifyContent: "right" }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              {<FormattedMessage id="Reviews.add" />}
            </Button>
          </Col>
        )}
      </Row>
      <Table className="actionsTable" borderless>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>
              <FormattedMessage id="Reviews.title" />
            </th>
            <th>
              <FormattedMessage id="Reviews.createdOn" />
            </th>
            <th>
              <FormattedMessage id="Reviews.period" />
            </th>
            <th>
              <FormattedMessage id="Reviews.status" />
            </th>
          </tr>
        </thead>
        <tbody>{allPerformanceReviews}</tbody>
      </Table>
      {performanceReviews.length === 0 && (
        <h4 className="text-center">
          <FormattedMessage id="Reviews.noFound" />
        </h4>
      )}
      <PerformanceReviewModal
        showModal={showPRModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitPerformanceReview}
        mode={prMode}
        performanceReview={prMode !== PR_CREATE_MODE && performanceReview}
        period={period}
      />
    </Container>
  );
};

export default Reviews;
