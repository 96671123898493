import { FORBIDDEN_ERROR_CODE } from "../constants/errorStatusCodes";
import {
  EMPLOYEE_ASESSOR_TYPE,
  MANAGER_ASSESSOR_TYPE,
  FINAL_ASSESSOR_TYPE,
} from "../constants/assessorTypes";
import { saveAs } from "file-saver";
import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/appraisals";

const AppraisalService = {
  getAppraisalById: (appraisalId) =>
    authFetchJSON(`${BASE_URL}/${appraisalId}`).then(
      (response) => response.ok && response.json()
    ),

  getProcessUserByAppraisalId: (appraisalId) =>
    authFetchJSON(`${BASE_URL}/${appraisalId}/process-user`).then((response) =>
      response.json()
    ),

  getAppraisalsByEmployeeId: (employeeId) =>
    authFetchJSON(`${BASE_URL}?employee_id=${employeeId}`).then((response) =>
      response.json()
    ),

  getAppraisalsByManagerId: (managerId) =>
    authFetchJSON(`${BASE_URL}?manager_id=${managerId}`).then((response) =>
      response.json()
    ),

  getGoalsByAppraisalId: (appraisalId) =>
    authFetchJSON(`${BASE_URL}/${appraisalId}/goals`).then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === FORBIDDEN_ERROR_CODE) {
        return Promise.reject(response.json());
      }
    }),

  getEmployeeAppraisalAssessment: (appraisalId) =>
    authFetchJSON(
      `${BASE_URL}/${appraisalId}/assessors/${EMPLOYEE_ASESSOR_TYPE}/assessments`
    ).then((response) => response.json()),

  getManagerAppraisalAssessment: (appraisalId) =>
    authFetchJSON(
      `${BASE_URL}/${appraisalId}/assessors/${MANAGER_ASSESSOR_TYPE}/assessments`
    ).then((response) => response.json()),

  getFinalApprisalAssessment: (appraisalId) =>
    authFetchJSON(
      `${BASE_URL}/${appraisalId}/assessors/${FINAL_ASSESSOR_TYPE}/assessments`
    ).then((response) => response.json()),

  getAppraisalDefaultDueDate: () =>
    authFetchJSON(`${BASE_URL}/due-date-default`).then((response) =>
      response.json()
    ),

  submitAppraisal: (appraisalId, assessments, assessorType, comment, draft) => {
    let url = `${BASE_URL}/${appraisalId}/assessors/${assessorType}/assessments`;
    draft ? (url += "/draft") : (url += "");

    return authFetchJSON(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assessments: [...assessments], comment: comment }),
    });
  },

  getOrganizationalGrades: () =>
    authFetchJSON(`${BASE_URL}/grades/${1}`).then((response) =>
      response.json()
    ),

  getProjectGrades: () =>
    authFetchJSON(`${BASE_URL}/grades/${2}`).then((response) =>
      response.json()
    ),

  getPersonalDevelopmentGrades: () =>
    authFetchJSON(`${BASE_URL}/grades/${3}`).then((response) =>
      response.json()
    ),

  getAppraisalAuditsByAppraisalId: (appraisalId) =>
    authFetchJSON(`${BASE_URL}/${appraisalId}/audits`).then((response) =>
      response.json()
    ),

  getAssessorAuditsByAppraisalId: (appraisalId) =>
    authFetchJSON(`${BASE_URL}/${appraisalId}/assessor-audits`).then(
      (response) => response.json()
    ),

  exportAppraisalAsPDF: async (appraisalId, status, assesorType) => {
    const response = await authFetchJSON(
      `${BASE_URL}/${appraisalId}/export-as-pdf?status=${status}&assessorType=${assesorType}`
    );

    if (response.ok) {
      let filename = "";
      let disposition = response.headers.get("content-disposition");
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
      let blob = await response.blob();
      saveAs(blob, filename);
    } else {
      const error = await response.json();
      return Promise.reject(error);
    }
  },
};

export default AppraisalService;
