import React from "react";
import { Modal } from "react-bootstrap";
import {
  GOAL_CREATE_MODE,
  GOAL_UPDATE_MODE,
  GOAL_DELETE_MODE,
  GOAL_COPY_MODE,
} from "../../constants/goalModes";
import GoalDefinitionForm from "./GoalDefinitionForm";
import DeleteConfirmation from "../common/DeleteConfirmation";
import CopyGoalsForm from "../manager/CopyGoalsForm";
import AddTrainingForm from "../trainings/AddTrainingForm";

const GoalModal = ({
  showModal,
  handleClose,
  handleSubmit,
  mode,
  goal,
  user,
  performanceReviewGoal,
  manager,
  goalType,
  goalTemplateGroup,
  setting,
  copyingGoals,
}) => (
  <Modal
    size={
      mode === GOAL_CREATE_MODE ||
      mode === GOAL_COPY_MODE ||
      mode === GOAL_UPDATE_MODE
        ? "xl"
        : "lg"
    }
    show={showModal}
    onHide={handleClose}
    centered
  >
    {(mode === GOAL_CREATE_MODE || mode === GOAL_UPDATE_MODE) &&
      (goalType?.id === 3 || goalType === 3 ? (
        <AddTrainingForm
          mode={mode}
          user={user}
          goal={goal}
          performanceReviewGoal={performanceReviewGoal}
          manager={manager}
          goalTypeId={goalType?.id ? goalType?.id : goalType}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          goalTemplateGroup={goalTemplateGroup}
          setting={setting}
        />
      ) : (
        <GoalDefinitionForm
          user={user}
          goal={goal}
          performanceReviewGoal={performanceReviewGoal}
          manager={manager}
          goalTypeId={goalType?.id}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          goalTemplateGroup={goalTemplateGroup}
          setting={setting}
        />
      ))}
    {mode === GOAL_COPY_MODE && (
      <CopyGoalsForm
        manager={manager}
        goalType={goalType}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        copyingGoals={copyingGoals}
      />
    )}
    {mode === GOAL_DELETE_MODE && (
      <DeleteConfirmation
        goal={goal}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    )}
  </Modal>
);

export default GoalModal;
