import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faUsers,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { SORT_NONE, SORT_ASC } from "../../constants/resultsSort";
import SortButton from "../common/buttons/SortButton";
import UserService from "../../services/UserService";

const ALL_MODE = "all";
const ONLY_SELECTED_MODE = "only_selected";

const AddParticipantsModal = ({
  show,
  handleClose,
  handleSubmit,
  process,
  getNextSortOption,
  managers,
}) => {
  const intl = useIntl();
  const [employees, setEmployees] = useState(() => {
    UserService.getAllActiveUsers().then((users) => setEmployees(users));
  });
  const [selectedManager, setSelectedManager] = useState(undefined);
  const [notSelectedEmployees, setNotSelectedEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesToRemove, setEmployeesToRemove] = useState([]);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (employees) {
      const filteredEmployees = employees.filter(
        (employee) => !selectedEmployees.includes(employee)
      );
      setNotSelectedEmployees(
        selectedManager
          ? filteredEmployees.filter(
              (employee) => employee.managerId === selectedManager.id
            )
          : filteredEmployees
      );
    }
  }, [employees, selectedEmployees, selectedManager]);

  const handleAdd = (employees) => {
    setSelectedEmployees(selectedEmployees.concat(employees));
    setEmployeesToAdd([]);
  };

  const handleRemove = (mode) => {
    if (mode === ALL_MODE) {
      setSelectedEmployees([]);
    } else if (mode === ONLY_SELECTED_MODE) {
      setSelectedEmployees(
        selectedEmployees.filter(
          (employee) => !employeesToRemove.includes(employee)
        )
      );
    }
    setEmployeesToRemove([]);
  };

  const blurElement = (event) => event.currentTarget.blur();

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton />
      <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
        <Modal.Body>
          <Modal.Title>{`Add participants to '${process?.title}'`}</Modal.Title>
          <hr />
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="AddParticipants.available" />
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  value={selectedManager && selectedManager.id}
                  onChange={(event) => {
                    setSelectedManager(
                      managers.find(
                        (manager) => manager.id === Number(event.target.value)
                      )
                    );
                    blurElement(event);
                  }}
                >
                  <option value={undefined}>
                    {intl.formatMessage({
                      id: "Users.anyManager",
                    })}
                  </option>
                  {managers &&
                    managers.map((manager) => (
                      <option key={manager.id} value={manager.id}>
                        {`${manager.firstName}  ${manager.lastName}`}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                {notSelectedEmployees && (
                  <TableOfEmployees
                    tableHeight="266px"
                    tableBodyHeight="240px"
                    employeesToShow={notSelectedEmployees}
                    employees={employeesToAdd}
                    employeesSetter={setEmployeesToAdd}
                    getNextSortOption={getNextSortOption}
                    operation="toAdd"
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={3}>
              <Row style={{ marginTop: "130px", justifyContent: "center" }}>
                <Button
                  data-tip
                  data-for="add-all"
                  variant="link"
                  onClick={() => handleAdd(notSelectedEmployees)}
                >
                  <FontAwesomeIcon icon={faUsers} size="lg" />
                  <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" />
                </Button>
                {getTooltip(
                  "add-all",
                  "left",
                  <FormattedMessage id="Users.addAll" />
                )}
              </Row>
              <Row style={{ marginTop: "7px", justifyContent: "center" }}>
                <Button
                  data-tip
                  data-for="add-selected"
                  variant="link"
                  onClick={() => handleAdd(employeesToAdd)}
                >
                  <FontAwesomeIcon icon={faUserCheck} size="lg" />
                  <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" />
                </Button>
                {getTooltip(
                  "add-selected",
                  "left",
                  <FormattedMessage id="Users.addSelected" />
                )}
              </Row>
              <Row style={{ marginTop: "35px", justifyContent: "center" }}>
                <Button
                  data-tip
                  data-for="remove-selected"
                  variant="link"
                  onClick={() => handleRemove(ONLY_SELECTED_MODE)}
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} className="ml-1" />
                  <FontAwesomeIcon
                    icon={faUserCheck}
                    size="lg"
                    className="ml-2"
                  />
                </Button>
                {getTooltip(
                  "remove-selected",
                  "right",
                  <FormattedMessage id="Users.removeSelected" />
                )}
              </Row>
              <Row style={{ marginTop: "7px", justifyContent: "center" }}>
                <Button
                  data-tip
                  data-for="remove-all"
                  variant="link"
                  onClick={() => handleRemove(ALL_MODE)}
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  <FontAwesomeIcon icon={faUsers} size="lg" className="ml-1" />
                </Button>
                {getTooltip(
                  "remove-all",
                  "right",
                  <FormattedMessage id="Users.removeAll" />
                )}
              </Row>
            </Col>
            <Col>
              <Form.Label>
                <FormattedMessage id="AddParticipants.selected" />
              </Form.Label>
              {error && selectedEmployees.length === 0 && (
                <div className="validation-error-msg">{error}</div>
              )}
              {selectedEmployees && (
                <TableOfEmployees
                  tableHeight="315px"
                  tableBodyHeight="289px"
                  employeesToShow={selectedEmployees}
                  employees={employeesToRemove}
                  employeesSetter={setEmployeesToRemove}
                  getNextSortOption={getNextSortOption}
                  operation="toRemove"
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal.Dialog>
      <Form.Group style={{ width: "95%", textAlign: "right" }}>
        <Button
          variant="outline-secondary"
          className="mr-1"
          onClick={handleClose}
        >
          <FormattedMessage id="EditForm.cancel" />
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (selectedEmployees.length === 0) {
              setError("There aren't any selected employees.");
            } else {
              handleSubmit(process, selectedEmployees);
            }
          }}
        >
          OK
        </Button>
      </Form.Group>
    </Modal>
  );
};

const TableOfEmployees = ({
  tableHeight,
  tableBodyHeight,
  employeesToShow,
  employees,
  employeesSetter,
  getNextSortOption,
  operation,
}) => {
  const [sortEmployeesByName, setSortEmployeesByName] = useState(SORT_NONE);

  useEffect(() => {
    if (employeesToShow.length === 0) {
      setSortEmployeesByName(SORT_NONE);
    }
  }, [employeesToShow, sortEmployeesByName]);

  const sortEmployees = () => {
    return [].concat(employeesToShow).sort((a, b) => {
      const aFullName = `${a.firstName} ${a.lastName}`;
      const bFullName = `${b.firstName} ${b.lastName}`;

      if (sortEmployeesByName !== SORT_NONE && aFullName > bFullName) {
        return sortEmployeesByName === SORT_ASC ? 1 : -1;
      } else if (sortEmployeesByName !== SORT_NONE && aFullName < bFullName) {
        return sortEmployeesByName === SORT_ASC ? -1 : 1;
      }
      return 0;
    });
  };

  return (
    <div
      style={{
        height: tableHeight,
        border: "1px lightgray solid",
        borderRadius: "3px",
      }}
    >
      {employeesToShow.length > 0 && (
        <Row style={{ fontWeight: "bold" }}>
          <Col md={1} style={{ marginLeft: "3px" }}>
            <Form.Check
              id={operation}
              type="checkbox"
              custom
              label=""
              onChange={(event) => {
                sortEmployees().map(
                  (employee) =>
                    (document.getElementById(employee.id).checked =
                      event.target.checked)
                );
                employeesSetter(event.target.checked ? employeesToShow : []);
              }}
            />
          </Col>
          <Col style={{ paddingLeft: "8px" }}>
            <SortButton
              label="Name"
              sort={sortEmployeesByName}
              onClick={() => setSortEmployeesByName(getNextSortOption)}
            />
          </Col>
        </Row>
      )}
      <div
        style={{
          height: tableBodyHeight,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {sortEmployees().map((employee) => (
          <Row key={employee.id}>
            <Col style={{ marginLeft: "3px" }}>
              <Form.Check
                id={employee.id}
                type="checkbox"
                custom
                label={`${employee.firstName} ${employee.lastName}`}
                onChange={(event) => {
                  if (event.target.checked) {
                    employeesSetter([...employees, employee]);
                  } else {
                    employeesSetter(
                      employees.filter((e) => e.id !== employee.id)
                    );
                  }
                }}
              />
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

const getTooltip = (id, place, message) => {
  return (
    <ReactTooltip
      id={id}
      place={place}
      backgroundColor="#4baeed"
      effect="solid"
    >
      <span>{message}</span>
    </ReactTooltip>
  );
};

export default AddParticipantsModal;
