import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Form, Row, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Formik } from "formik";
import AuthenticationService from "../services/AuthenticationService";
import Icon, { ICONS } from "../icons/Icon";
import ErrorIcon from "../icons/ErrorIcon";
import TextInput from "../components/common/form/TextField";

import "./password.css";

const ForgotPassword = () => {
  const intl = useIntl();
  const { search } = useLocation();
  const email = decodeURIComponent(new URLSearchParams(search).get("email"));

  const [error, setError] = useState(undefined);

  const passwordValidationSchema = Yup.object({
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      )
      .min(
        4,
        intl.formatMessage({
          id: "ForgotPassword.password.length",
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      )
      .oneOf(
        [Yup.ref("password"), null],
        intl.formatMessage({
          id: "ForgotPassword.password.match",
        })
      ),
  });

  const savePassword = (credentials) =>
    AuthenticationService.updatePassword(credentials).then((response) => {
      if (response.ok) {
        window.location.href = "/";
      } else {
        response.json().then((error) => setError(error));
      }
    });

  useEffect(() => {
    document.body.className = "login";
  }, []);

  return (
    <>
      <Container
        className="animated slideInLeft"
        style={{ alignItems: "center", paddingTop: "0" }}
      >
        <Row>
          <Col>
            <Formik
              enableReinitialize
              initialValues={{
                email: email ? email : "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={passwordValidationSchema}
              onSubmit={(values) =>
                savePassword({
                  newPassword: values.password,
                  email: values.email,
                })
              }
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                errors,
              }) => (
                <>
                  <Icon
                    icon={ICONS.LOGO}
                    height="200"
                    width="400"
                    color="white"
                    stroke="white"
                    strokeWidth="1"
                    fillRule="evenodd"
                    style={{ marginTop: "14vmax" }}
                  />
                  <Form
                    className="Password-form authentication-form"
                    onSubmit={handleSubmit}
                  >
                    <TextInput
                      formGroupClass="Password-form"
                      formControlClass="Password"
                      /*                 formControlClass={`UserAuthenticationForm ${
                  isValid ? "" : "notAuthenticated"
                }`} */
                      formControlType="text"
                      formControlName="email"
                      name="email"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "ForgotPassword.email",
                      })}
                      value={values.email}
                      handleChange={handleChange}
                      onChange={(value) => {
                        setFieldValue("email", value);
                      }}
                      onBlur={handleBlur}
                    />
                    <TextInput
                      formGroupClass="Password-form"
                      formControlClass="Password"
                      /* formControlClass={`UserAuthenticationForm ${
                  error ? "notAuthenticated" : ""
                }`} */
                      formControlType="password"
                      formControlName="password"
                      name="password"
                      type="password"
                      placeholder={intl.formatMessage({
                        id: "ForgotPassword.new",
                      })}
                      value={values.password}
                      onChange={(value) => {
                        setFieldValue("password", value);
                      }}
                      onBlur={handleBlur}
                    />
                    <TextInput
                      formGroupClass="Password-form"
                      formControlClass="Password"
                      /* formControlClass={`UserAuthenticationForm ${
                  error ? "notAuthenticated" : ""
                }`} */
                      formControlType="password"
                      formControlName="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder={intl.formatMessage({
                        id: "ForgotPassword.confirm",
                      })}
                      value={values.password}
                      onChange={(value) => {
                        setFieldValue("confirmPassword", value);
                      }}
                      onBlur={handleBlur}
                    />

                    {error && (
                      <Form.Group
                        as={Col}
                        className="UserAuthenticationForm-form"
                      >
                        <div className="validation-error-msg">
                          {" "}
                          <ErrorIcon height="16px" width="16px" />{" "}
                          <span style={{ marginLeft: "1%" }}>
                            {" "}
                            {error.type}{" "}
                          </span>
                        </div>
                      </Form.Group>
                    )}
                    <Form.Group
                      as={Col}
                      style={{ marginTop: "10%" }}
                      className="Password-form"
                    >
                      <Button type="submit" variant="primary">
                        <FormattedMessage id="Users.save" />
                      </Button>
                    </Form.Group>
                    <Icon
                      icon={ICONS.POWERED_BY}
                      height="100"
                      width="150"
                      color="white"
                      style={{ marginLeft: "33%", opacity: "50%" }}
                    />
                  </Form>
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
