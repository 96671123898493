import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import AccordionComponent from "../../common/AccordionComponent";
import MyTrainingPersonalDevelopment from "./MyTrainingPersonalDevelopment";
import Pagination from "../../admin/Pagination";
import Loading from "../../common/display/Loading";
import PerformanceReviewService from "../../../services/PerformanceReviewService";

import "react-datepicker/dist/react-datepicker.css";
import "../../oneOnOne/one-on-one.css";

const MyTraining = ({ user }) => {
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [
    personalDevelopmentPerformanceReviews,
    setPersonalDevelopmentPerformanceReviews,
  ] = useState([]);

  useEffect(() => {
    Promise.all([
      PerformanceReviewService.getPersonalTrainingReviews(user.id, currentPage),
    ]).then(([performanceReviews]) => {
      setPersonalDevelopmentPerformanceReviews(performanceReviews.content);
      setCurrentPage(performanceReviews.pageable.pageNumber);
      setTotalPages(performanceReviews.totalPages);
    });
    setIsLoading(false);
  }, [user.id, currentPage]);

  const getYearFromTitle = (title) => {
    const year = title.trim().slice(-4);
    if (isNaN(year)) {
      return title;
    } else {
      return year;
    }
  };

  const paginationClickHandler = (event) => {
    if (event.target.text === undefined) {
      return;
    } else {
      setCurrentPage(parseInt(event.target.text) - 1);
    }
  };

  return (
    <Container fluid style={{ width: "82%" }}>
      <Container
        style={{
          float: "right",
          width: "69%",
          margin: 0,
          paddingTop: "2%",
          paddingLeft: 0,
        }}
      >
        {isLoading ? (
          <Loading />
        ) : personalDevelopmentPerformanceReviews.length === 0 ? (
          <Row className="appraisal-preview-row content pt-3 pb-2">
            <Col lg={12} align="center">
              <h4>
                <FormattedMessage id="MyTrainings.personal.development.not.found" />
              </h4>
            </Col>
          </Row>
        ) : (
          <>
            {personalDevelopmentPerformanceReviews.map((performanceReview) => (
              <div style={{ paddingBottom: "10px" }} key={performanceReview.id}>
                <AccordionComponent
                  title={
                    intl.formatMessage({
                      id: "MyTrainings.personal.development.label",
                    }) + getYearFromTitle(performanceReview.title)
                  }
                  textArray={
                    <MyTrainingPersonalDevelopment
                      performanceReviewId={performanceReview.id}
                      user={user}
                      key={performanceReview.id}
                    />
                  }
                />
              </div>
            ))}
            <Pagination
              style={{ marginTop: "2%", position: "relative" }}
              currentPage={currentPage}
              paginationClickHandler={paginationClickHandler}
              setCurrentPage={setCurrentPage}
              pages={totalPages}
            />
          </>
        )}
      </Container>
    </Container>
  );
};

export default MyTraining;
