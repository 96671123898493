import React, { useEffect, useState } from "react";
import { ListGroup, Card, Container, Row, Col } from "react-bootstrap";
import { withRouter, useLocation } from "react-router-dom";
import AppraisalServiceV2 from "../../../services/AppraisalServiceV2";
import Loading from "../../common/display/Loading";
import EmployeeManagerFeedbackPageV2 from "./EmployeeManagerFeedbackPageV2";
import "./AllManagersFeedbackGroup.css";

const ManagerFeedback = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const [appraisal, setAppraisal] = useState();

  useEffect(() => {
    if (isLoading) {
      Promise.all([fetchAppraisal(getAppraisalId())]).then(() => {
        setIsLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, location]);

  const getAppraisalId = () =>
    props.appraisal ? props.appraisal : props.match.params.id;

  const fetchAppraisal = (appraisalId) => {
    AppraisalServiceV2.getAppraisalById(props.match.params.id).then(
      (appraisal) => {
        setAppraisal(appraisal);
      }
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container fluid>
      <Row className="mt-3">
        <Col>
          <header className="text-center" style={{ marginTop: "2%" }}>
            <h4> {appraisal?.performanceReviewTitle}</h4>
            <h4> {appraisal?.processTitle}</h4>
          </header>
        </Col>
      </Row>

      <ListGroup style={{ paddingTop: "3%" }}>
        <ListGroup.Item>
          <Card>
            <Card.Body>
              <EmployeeManagerFeedbackPageV2
                manager={props.user}
                processId={appraisal?.processId}
                performanceReviewId={appraisal?.performanceReviewId}
                hr={false}
                loading={isLoading}
              />
            </Card.Body>
          </Card>
        </ListGroup.Item>
      </ListGroup>
    </Container>
  );
};

export default withRouter(ManagerFeedback);
