import React from "react";
import * as Yup from "yup";
import { Modal, Form, Button } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { Formik, ErrorMessage } from "formik";
import {
  TEMPLATE_GROUP_CREATE_MODE,
  TEMPLATE_GROUP_DELETE_MODE,
} from "../../constants/templateGroupModes";
import DeleteConfirmation from "../common/DeleteConfirmation";
import ErrorIcon from "../../icons/ErrorIcon";

const GoalTemplateGroupModal = ({
  showModal,
  handleClose,
  handleSubmit,
  mode,
  goalTemplateGroup,
  ownerId,
}) => {
  const intl = useIntl();
  const goalTemplateGroupValidationSchema = Yup.object({
    goalTemplateGroup: Yup.object({
      topic: Yup.string().required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      ),
    }),
  });
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      {mode === TEMPLATE_GROUP_CREATE_MODE && (
        <Formik
          enableReinitialize
          initialValues={{
            goalTemplateGroup: {
              topic: "",
              ownerId: ownerId,
            },
          }}
          validationSchema={goalTemplateGroupValidationSchema}
          onSubmit={(values) => {
            handleSubmit(values.goalTemplateGroup);
            handleClose();
          }}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Modal.Header closeButton />
              <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
                <Modal.Body>
                  <Modal.Title>
                    <FormattedMessage id="Goals.newTopic" />
                  </Modal.Title>
                  <hr />
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage id="Goals.title" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="goalTemplateGroup.topic"
                      value={formik.values.goalTemplateGroup.topic}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="validation-error-msg">
                          {" "}
                          <ErrorIcon height="16px" width="16px" />{" "}
                          <span style={{ marginLeft: "1%" }}> {msg} </span>
                        </div>
                      )}
                      name="goalTemplateGroup.topic"
                    />
                  </Form.Group>
                </Modal.Body>
              </Modal.Dialog>
              <Form.Group style={{ width: "95%", textAlign: "right" }}>
                <Button
                  variant="secondary"
                  className="mr-1"
                  onClick={handleClose}
                >
                  <FormattedMessage id="EditForm.close" />
                </Button>
                <Button type="submit" variant="primary">
                  <FormattedMessage id="EditForm.submit" />
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      )}
      {mode === TEMPLATE_GROUP_DELETE_MODE && (
        <DeleteConfirmation
          goalTemplateGroup={goalTemplateGroup}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

export default GoalTemplateGroupModal;
