import * as React from "react";
const AdminRolesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 25 25",
      marginRight: "2%",
    }}
    viewBox="0 0 25 25"
    {...props}
  >
    <style>{".st0{fill:}" + props.color + "}"}</style>
    <path
      d="m21.2 8.7-3.8-1.1c-.2-.1-.5-.1-.7-.1-1.1 0-2.1.7-2.4 1.8l-1.4 5.1c-.4 1.3 1.5 5.2 3.1 5.7.7.2 2-.2 3.2-.9 1.4-.8 2.2-1.8 2.4-2.3l1.4-5.1c.2-.6.1-1.3-.2-1.9-.4-.6-.9-1-1.6-1.2zM15.8 10h1.3c.5 0 .9.4.9.9s-.4.9-.9.9h-1.3c-.5 0-.9-.4-.9-.9s.5-.9.9-.9zm3.8 7.1c-.1.1-1.2.8-2.4.8-.3 0-.6 0-1-.1-1.7-.5-2.4-2.4-2.4-2.7-.2-.5.1-1 .5-1.1.5-.2 1 .1 1.1.5.1.4.6 1.3 1.3 1.6.7.2 1.6-.2 1.9-.4.4-.3.9-.2 1.2.2.3.4.2 1-.2 1.2zm1.9-4.1c-.2.2-.4.4-.7.4-.2 0-.4-.1-.5-.2l-1-.8c-.4-.3-.5-.8-.2-1.2.3-.4.8-.5 1.2-.2l1 .8c.4.3.5.8.2 1.2z"
      style={{
        fill: "none",
      }}
    />
    <path
      d="M11.8 5.7C11.1 4 9.4 2.9 7.5 3.2l-3.4.5c-1.1.2-2 .8-2.6 1.6-.6.8-.8 1.9-.7 2.9l.7 4.7c.3 2 3.7 4.9 6.1 4.9H8c.5-.1.9-.3 1.4-.6.4-.3.5-.8.2-1.2-.3-.4-.8-.5-1.2-.2-.2.1-.5.2-.6.2-1.3.2-4.3-2.3-4.5-3.4l-.7-4.7c-.1-.6 0-1.1.4-1.6.3-.5.8-.8 1.4-.8L7.9 5c1-.2 2 .4 2.3 1.4.2.5.7.7 1.1.5.4-.3.6-.8.5-1.2zM24.3 9c-.6-1-1.5-1.7-2.6-2l-3.8-1.1c-2.3-.6-4.6.7-5.2 2.9l-1.4 5.1c-.6 2.2 1.7 7.1 4.3 7.8.3.1.6.1.9.1 1.5 0 3-.8 3.7-1.2.6-.4 2.7-1.7 3.2-3.3l1.4-5.1c.2-1 0-2.2-.5-3.2zM23 11.8l-1.4 5.1c-.1.5-1 1.5-2.4 2.3-1.3.8-2.6 1.1-3.2.9-1.5-.4-3.4-4.3-3.1-5.7l1.4-5.1c.3-1.1 1.3-1.8 2.4-1.8.2 0 .5 0 .7.1l3.8 1.1c.6.2 1.2.6 1.5 1.2.4.6.4 1.2.3 1.9z"
      className="st0"
    />
    <path
      d="M18.6 15.7c-.3.2-1.2.6-1.9.4-.7-.2-1.2-1.2-1.3-1.6-.2-.5-.7-.7-1.1-.5-.5.2-.7.7-.5 1.1.1.2.8 2.1 2.4 2.7.3.1.7.1 1 .1 1.3 0 2.3-.7 2.4-.8.4-.3.5-.8.2-1.2-.2-.4-.8-.5-1.2-.2zM15.8 11.8h1.3c.5 0 .9-.4.9-.9s-.4-.9-.9-.9h-1.3c-.5 0-.9.4-.9.9s.5.9.9.9zM20.2 11c-.4-.3-.9-.2-1.2.2-.3.4-.2.9.2 1.2l1 .8c.2.1.3.2.5.2.3 0 .5-.1.7-.4.3-.4.2-.9-.2-1.2l-1-.8z"
      className="st0"
    />
  </svg>
);
export default AdminRolesIcon;
