import {
  PERMISSION_MANAGE_ORGANIZATIONAL_GOALS,
  PERMISSION_TEAM_HISTORY,
} from "../constants/permissions";

export const getPermissionName = (permission) => {
  if (permission === PERMISSION_TEAM_HISTORY)
    return "Own team appraisal history";
  else if (permission === PERMISSION_MANAGE_ORGANIZATIONAL_GOALS)
    return "Manage organizational goals";
  else {
    const name = permission.replace(/_/gi, " ");
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
};
