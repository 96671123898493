import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import UserService from "../../services/UserService";
import ErrorIcon from "../../icons/ErrorIcon";

const UserChangePasswordForm = ({
  handleClose,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const intl = useIntl();

  const passwordValidationSchema = Yup.object({
    oldPassword: Yup.string().required(
      intl.formatMessage({
        id: "UserAuthenticationForm.validation.error",
      })
    ),
    password: Yup.string()
      .required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      )
      .min(
        4,
        intl.formatMessage({
          id: "ForgotPassword.password.length",
        })
      )
      .notOneOf(
        [Yup.ref("oldPassword"), null],
        intl.formatMessage({
          id: "UserChangePasswordForm.password.old",
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      )
      .oneOf(
        [Yup.ref("password"), null],
        intl.formatMessage({
          id: "ForgotPassword.password.match",
        })
      ),
  });

  const changePassword = (passwords) =>
    UserService.attemptUpdatingCurrentUserPassword(passwords).then(
      (response) => {
        if (response.ok) {
          response.text().then((response) => {
            showSuccessNotification(response);
          });
          handleClose();
        } else {
          response.json().then((error) => {
            showErrorNotification(error.message);
            handleClose();
          });
        }
      }
    );

  return (
    <Formik
      enableReinitialize
      initialValues={{ oldPassword: "", password: "", confirmPassword: "" }}
      validationSchema={passwordValidationSchema}
      onSubmit={(values) =>
        changePassword({
          oldPassword: values.oldPassword,
          newPassword: values.password,
        })
      }
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>
                <FormattedMessage id="Password.change" />
              </Modal.Title>
              <hr />
              <Form.Group as={Col}>
                <Form.Label>
                  <FormattedMessage id="Password.current" />
                </Form.Label>
                <Form.Control
                  type="password"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}> {msg} </span>
                    </div>
                  )}
                  name="oldPassword"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>
                  {" "}
                  <FormattedMessage id="Password.new" />
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}> {msg} </span>
                    </div>
                  )}
                  name="password"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>
                  <FormattedMessage id="Password.confirm" />
                </Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={values.password.length === 0}
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}> {msg} </span>
                    </div>
                  )}
                  name="confirmPassword"
                />
              </Form.Group>
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="EditForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="EditForm.submit" />
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default UserChangePasswordForm;
