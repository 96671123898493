import React, { useEffect, useState } from "react";
import AccordionComponent from "../common/AccordionComponent";
import "./file-upload-log.css";

const FileUploadLog = ({ logs }) => {
  const [open, setOpen] = useState(false);

  const openCloseAccordion = () => {
    setOpen(open ? false : true);
  };

  useEffect(() => {
    setOpen(logs.length !== 0);
  }, [logs.length]);

  return (
    <div className="file-upload-log">
      <AccordionComponent
        title="Execution log"
        openCloseAccordion={openCloseAccordion}
        open={open}
        textArray={logs.map((l) => {
          return (
            <span>
              {l}
              <br></br>
            </span>
          );
        })}
      ></AccordionComponent>
    </div>
  );
};

export default FileUploadLog;
