import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/process-users";

const ProcessUserService = {
  getProcessUserByPerformanceReviewGoalId: (performanceReviewGoalId) =>
    authFetchJSON(
      `${BASE_URL}?performanceReviewGoalId=${performanceReviewGoalId}`
    ).then((response) => response.json()),

  getProcessUsersByPerformanceReviewId: (performanceReviewId) =>
    authFetchJSON(
      `${BASE_URL}?performanceReviewId=${performanceReviewId}`
    ).then((response) => response.json()),

  existsByUserId: (userId) =>
    authFetchJSON(`${BASE_URL}/visibility?userId=${userId}`).then((response) =>
      response.json()
    ),
};

export default ProcessUserService;
