import React from "react";
import { withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { Col, Container, Row, Table } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { faHistory, faTasks } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_REVIEW,
  APPRAISAL_STATUS_CLOSED,
} from "../../constants/appraisalStatus";
import {
  MANAGER_ASSESSOR_TYPE,
  EMPLOYEE_ASESSOR_TYPE,
} from "../../constants/assessorTypes";
import { SORT_NONE, SORT_ASC, SORT_DESC } from "../../constants/resultsSort";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import PerformanceReviewService from "../../services/PerformanceReviewService";
import ManagerService from "../../services/ManagerService";
import ProcessUserService from "../../services/ProcessUserService";
import Loading from "../common/display/Loading";
import FAButtonAndTooltip from "../common/buttons/FAButtonAndTooltip";
import SortButton from "../common/buttons/SortButton";
import Image from "../common/Image";
import OverviewFilter from "./OverviewFilter";

import "./overview.css";

class Overview extends React.Component {
  state = {
    users: [],
    usersToDisplay: [],
    nonSortedUsers: [],
    managers: [],
    performanceReviews: [],
    activePerformanceReviewId: undefined,
    activeAppraisals: 0,
    reviewAppraisals: 0,
    closedAppraisals: 0,
    managerDraftAppraisals: 0,
    managerNotDraftAppraisals: 0,
    employeeDraftAppraisals: 0,
    employeeNotDraftAppraisals: 0,
    sortResultsByName: SORT_NONE,
    sortResultsByStatus: SORT_NONE,
    loading: true,
    manager: null,
    selectedManagers: [],
    currentUser: this.props.user.id,
  };

  componentDidMount() {
    PerformanceReviewService.getPerformanceReviews().then(
      (allPerformanceReviews) =>
        allPerformanceReviews.length > 0 &&
        PerformanceReviewService.getActivePerformanceReviewId()
          .then((activePerformanceReviewId) => {
            const activePerformanceReview = allPerformanceReviews.find(
              (pr) => pr.id === activePerformanceReviewId
            );
            allPerformanceReviews.splice(
              allPerformanceReviews.indexOf(activePerformanceReview),
              1
            );
            allPerformanceReviews.unshift(activePerformanceReview);

            return allPerformanceReviews;
          })
          .then((performanceReviews) =>
            this.setState({ performanceReviews }, () =>
              this.fetchResources(performanceReviews[0].id)
            )
          )
    );
  }

  fetchResources = (performanceReviewId) =>
    Promise.all([
      ManagerService.getUsersByManagerId(this.props.user.id),
      ManagerService.getManagersOverview(this.props.user.id, true),
    ]).then(([users, managers]) => {
      if (performanceReviewId) {
        ProcessUserService.getProcessUsersByPerformanceReviewId(
          performanceReviewId
        ).then((processUsers) => {
          users.forEach((user) => {
            const processUser = processUsers.find(
              (pru) => pru.user.id === user.id
            );
            if (processUser) {
              user.process = processUser.process;
              user.processAction = processUser.action;
              user.performanceReview = processUser.process.performanceReview;
              if (processUser.performanceReviewGoal !== null) {
                user.performanceReviewGoal = processUser.performanceReviewGoal;
                user.status =
                  processUser.performanceReviewGoal.performanceReviewGoalStatus;
              } else if (processUser.appraisal !== null) {
                user.appraisal = processUser.appraisal;
                user.status = processUser.appraisal.status;
              }
            }
          });
        });
      }
      setTimeout(() => {
        const differentAppraisalsCounts =
          this.getFilterRadioBadgesValues(users);
        this.setState({
          users,
          usersToDisplay: users,
          nonSortedUsers: users,
          managers: managers,
          activeAppraisals: differentAppraisalsCounts.activeAppraisals,
          reviewAppraisals: differentAppraisalsCounts.reviewAppraisals,
          closedAppraisals: differentAppraisalsCounts.closedAppraisals,
          managerDraftAppraisals:
            differentAppraisalsCounts.managerDraftAppraisals,
          managerNotDraftAppraisals:
            differentAppraisalsCounts.managerNotDraftAppraisals,
          employeeDraftAppraisals:
            differentAppraisalsCounts.employeeDraftAppraisals,
          employeeNotDraftAppraisals:
            differentAppraisalsCounts.employeeNotDraftAppraisals,
          loading: false,
          selectedManagers: [
            `${this.props.user.firstName} ${this.props.user.lastName}`,
          ],
        });
      }, 1000);
    });

  componentDidUpdate(prevProps, prevState) {
    if (this.state.manager === this.props.user.id) {
      this.fetchResources(this.state.performanceReviews[0].id);
    }
    if (prevState.manager !== this.state.manager) {
      if (this.state.selectedManagers.includes(this.state.manager)) {
        this.setState({
          selectedManagers: this.state.selectedManagers.filter(
            (s) => s !== Number(this.state.manager)
          )?.managerName,
        });
        return;
      }

      if (this.state.selectedManagers.length > 0) {
        this.setState({
          selectedManagers: [
            ...this.state.selectedManagers,
            this.state.usersToDisplay.find(
              (user) => user.managerId === Number(this.state.manager)
            )?.managerName,
          ],
        });
      }
    }
  }

  getFilterRadioBadgesValues = (
    users,
    name = ".",
    status = [
      APPRAISAL_STATUS_ACTIVE,
      APPRAISAL_STATUS_CLOSED,
      APPRAISAL_STATUS_REVIEW,
    ],
    managerDraft = "any",
    employeeDraft = "any",
    manager = "All managers",
    performanceReview
  ) => {
    let filteredUsers = this.fetchUsers(performanceReview?.id, manager);
    let usersWithManager = users;
    if (filteredUsers) {
      usersWithManager = filteredUsers
        .filter((u) =>
          new RegExp(`${name}+`, "i").test(`${u.firstName} ${u.lastName}`)
        )
        .filter((u) => {
          if (performanceReview) {
            return (
              u.performanceReview &&
              u.performanceReview.id === performanceReview.id
            );
          }
          return true;
        });
    } else {
      usersWithManager = users
        ?.filter((u) =>
          new RegExp(`${name}+`, "i").test(`${u.firstName} ${u.lastName}`)
        )
        ?.filter((u) => {
          if (performanceReview) {
            return (
              u.performanceReview &&
              u.performanceReview.id === performanceReview.id
            );
          }
          return true;
        });
    }

    const activeAppraisals = this.filterAppraisalsDrafts(
      usersWithManager,
      managerDraft,
      employeeDraft
    ).filter(
      (u) => u.appraisal && u.appraisal.status === APPRAISAL_STATUS_ACTIVE
    ).length;
    const reviewAppraisals = this.filterAppraisalsDrafts(
      usersWithManager,
      managerDraft,
      employeeDraft
    ).filter(
      (u) => u.appraisal && u.appraisal.status === APPRAISAL_STATUS_REVIEW
    ).length;
    const closedAppraisals = this.filterAppraisalsDrafts(
      usersWithManager,
      managerDraft,
      employeeDraft
    ).filter(
      (u) => u.appraisal && u.appraisal.status === APPRAISAL_STATUS_CLOSED
    ).length;
    const managerDraftAppraisals = this.filterAppraisalsDrafts(
      usersWithManager,
      true,
      employeeDraft
    ).filter((u) => u.appraisal && status.includes(u.appraisal.status)).length;
    const managerNotDraftAppraisals = this.filterAppraisalsDrafts(
      usersWithManager,
      false,
      employeeDraft
    ).filter((u) => u.appraisal && status.includes(u.appraisal.status)).length;
    const employeeDraftAppraisals = this.filterAppraisalsDrafts(
      usersWithManager,
      managerDraft,
      true
    ).filter((u) => u.appraisal && status.includes(u.appraisal.status)).length;
    const employeeNotDraftAppraisals = this.filterAppraisalsDrafts(
      usersWithManager,
      managerDraft,
      false
    ).filter((u) => u.appraisal && status.includes(u.appraisal.status)).length;

    return {
      activeAppraisals,
      reviewAppraisals,
      closedAppraisals,
      managerDraftAppraisals,
      managerNotDraftAppraisals,
      employeeDraftAppraisals,
      employeeNotDraftAppraisals,
    };
  };

  sortResults = (sortOption) =>
    this.setState(
      (prevState) => ({
        [sortOption]: this.getNextSortOption(prevState[sortOption]),
      }),
      () => this.sortUsersToReview()
    );

  getNextSortOption = (sortOption) =>
    (sortOption === SORT_NONE && SORT_ASC) ||
    (sortOption === SORT_ASC && SORT_DESC) ||
    (sortOption === SORT_DESC && SORT_NONE);

  sortUsersToReview = () => {
    const currentUsersList = [].concat(this.state.usersToDisplay);
    let sortedResults = currentUsersList.sort((a, b) => {
      if (this.state.sortResultsByName !== SORT_NONE) {
        const aFullName = `${a.firstName} ${a.lastName}`;
        const bFullName = `${b.firstName} ${b.lastName}`;

        if (aFullName > bFullName) {
          return this.state.sortResultsByName === SORT_ASC ? 1 : -1;
        } else if (aFullName < bFullName) {
          return this.state.sortResultsByName === SORT_ASC ? -1 : 1;
        } else {
          if (this.state.sortResultsByStatus !== SORT_NONE) {
            if (a.appraisal && !b.appraisal) {
              return -1;
            } else if (!a.appraisal && b.appraisal) {
              return 1;
            } else if (a.appraisal.status > b.appraisal.status) {
              return this.state.sortResultsByStatus === SORT_ASC ? 1 : -1;
            } else if (a.appraisal.status < b.appraisal.status) {
              return this.state.sortResultsByStatus === SORT_ASC ? -1 : 1;
            }
          }
          return 0;
        }
      } else if (this.state.sortResultsByStatus !== SORT_NONE) {
        if (a.appraisal && !b.appraisal) {
          return -1;
        } else if (!a.appraisal && b.appraisal) {
          return 1;
        } else if (
          a.appraisal &&
          b.appraisal &&
          a.appraisal.status > b.appraisal.status
        ) {
          return this.state.sortResultsByStatus === SORT_ASC ? 1 : -1;
        } else if (
          a.appraisal &&
          b.appraisal &&
          a.appraisal.status < b.appraisal.status
        ) {
          return this.state.sortResultsByStatus === SORT_ASC ? -1 : 1;
        }
      }
      return 0;
    });

    this.setState((prevState) => ({
      usersToDisplay:
        prevState.sortResultsByName === SORT_NONE &&
        prevState.sortResultsByStatus === SORT_NONE
          ? prevState.nonSortedUsers
          : sortedResults,
    }));
  };

  getEmployeesDataAsTableRows = () =>
    this.state.usersToDisplay.map((user) => {
      return (
        <ConfigurationPropertiesContext.Consumer key={user.id}>
          {({ formatDate }) => (
            <tr>
              <td className="profile-img">
                <LazyLoad height={50} offset={100}>
                  <Image email={user.email} roundedCircle />
                </LazyLoad>
              </td>
              <td>
                {`${user.firstName} ${user.lastName}`}
                <p className="email">{user.email}</p>
              </td>
              <td>{user?.managerName ? `${user?.managerName}` : "--"}</td>
              <td>
                {user.performanceReview
                  ? `${formatDate(user.performanceReview.start)} - ${formatDate(
                      user.performanceReview.end
                    )}`
                  : "--"}
              </td>
              <td>{user.process ? formatDate(user.process.endDate) : "--"}</td>
              <td>
                {user.status ? (
                  <FormattedMessage id={`Status.${user.status}`} />
                ) : (
                  "--"
                )}
              </td>
              <td className="text-center">{this.getGoalsButton(user.id)}</td>
            </tr>
          )}
        </ConfigurationPropertiesContext.Consumer>
      );
    });

  getViewButton = (appraisal, userId) => (
    <FAButtonAndTooltip
      id={`${userId}-view`}
      icon={faEye}
      disabled={!appraisal}
      tooltipMessage="Check user's last appraisal"
      onClick={() =>
        this.props.history.push(`/appraisals/${appraisal.id}/overview`)
      }
    />
  );

  getHistoryButton = (userId) => (
    <FAButtonAndTooltip
      id={`${userId}-history`}
      icon={faHistory}
      tooltipOffset={{ right: "7px" }}
      tooltipMessage="Check user's appraisal history"
      onClick={() => this.props.history.push(`/users/${userId}/history`)}
    />
  );

  getGoalsButton = (userId) => (
    <FAButtonAndTooltip
      id={`${userId}-goals`}
      icon={faTasks}
      tooltipOffset={{ right: "7px" }}
      tooltipMessage={this.props.intl.formatMessage({
        id: "Overview.goals.button.tooltip",
      })}
      onClick={() => this.props.history.push(`/users/${userId}/goals`)}
    />
  );

  handleFilterSubmit = (
    name,
    status,
    managerDraft,
    employeeDraft,
    manager,
    performanceReview,
    reset
  ) => {
    if (reset) {
      this.setState((prevState) => ({
        manager: this.props.user.id,
        selectedManagers: [],
      }));
    } else {
      name = name === "" ? "." : name;
      status =
        status === "All statuses"
          ? [
              APPRAISAL_STATUS_ACTIVE,
              APPRAISAL_STATUS_CLOSED,
              APPRAISAL_STATUS_REVIEW,
            ]
          : [status];
      managerDraft = this.getManagerDraftValue(managerDraft);
      employeeDraft = this.getEmployeeDraftValue(employeeDraft);

      let filteredUsers = this.fetchUsers(performanceReview?.id, manager);
      let usersWithManager = this.state.users;
      if (filteredUsers) {
        usersWithManager = filteredUsers
          .filter((u) =>
            new RegExp(`${name}+`, "i").test(`${u.firstName} ${u.lastName}`)
          )
          .filter((u) => {
            if (performanceReview) {
              return (
                u.performanceReview &&
                u.performanceReview.id === performanceReview.id
              );
            }
            return true;
          });
      } else {
        usersWithManager = this.state.users
          .filter((u) =>
            new RegExp(`${name}+`, "i").test(`${u.firstName} ${u.lastName}`)
          )
          .filter((u) => {
            if (performanceReview) {
              return (
                u.performanceReview &&
                u.performanceReview.id === performanceReview.id
              );
            }
            return true;
          });
      }
      const usersToDisplay =
        managerDraft === "any" && employeeDraft === "any" && status.length === 3
          ? usersWithManager.filter((u) =>
              new RegExp(`${name}+`, "i").test(`${u.firstName} ${u.lastName}`)
            )
          : this.filterAppraisalsDrafts(
              usersWithManager,
              managerDraft,
              employeeDraft
            )
              .filter((u) => {
                if (!u.appraisal && status.length === 3) {
                  return true;
                }
                return u.appraisal && status.includes(u.appraisal.status);
              })
              .filter((u) =>
                new RegExp(`${name}+`, "i").test(`${u.firstName} ${u.lastName}`)
              );
      const differentAppraisalsCounts = this.getFilterRadioBadgesValues(
        this.state.users,
        name,
        status,
        managerDraft,
        employeeDraft,
        manager,
        performanceReview
      );
      this.setState({
        usersToDisplay: usersToDisplay,
        nonSortedUsers: usersToDisplay,
        activeAppraisals: differentAppraisalsCounts.activeAppraisals,
        reviewAppraisals: differentAppraisalsCounts.reviewAppraisals,
        closedAppraisals: differentAppraisalsCounts.closedAppraisals,
        managerDraftAppraisals:
          differentAppraisalsCounts.managerDraftAppraisals,
        managerNotDraftAppraisals:
          differentAppraisalsCounts.managerNotDraftAppraisals,
        employeeDraftAppraisals:
          differentAppraisalsCounts.employeeDraftAppraisals,
        employeeNotDraftAppraisals:
          differentAppraisalsCounts.employeeNotDraftAppraisals,
        // manager: manager,
      });
    }
  };

  fetchUsers = (performanceReviewId, manager) => {
    if (manager !== "All managers" && manager !== null) {
      this.setState({
        loading: true,
      });
      Promise.all([
        ManagerService.getUsersByManagerId(manager),
        ManagerService.getManagersOverview(manager, true),
      ]).then(([users, managers]) => {
        if (performanceReviewId) {
          ProcessUserService.getProcessUsersByPerformanceReviewId(
            performanceReviewId
          ).then((processUsers) => {
            users.forEach((user) => {
              const processUser = processUsers.find(
                (pru) => pru.user.id === user.id
              );
              if (processUser) {
                user.process = processUser.process;
                user.processAction = processUser.action;
                user.performanceReview = processUser.process.performanceReview;
                if (processUser.performanceReviewGoal !== null) {
                  user.performanceReviewGoal =
                    processUser.performanceReviewGoal;
                  user.status =
                    processUser.performanceReviewGoal.performanceReviewGoalStatus;
                } else if (processUser.appraisal !== null) {
                  user.appraisal = processUser.appraisal;
                  user.status = processUser.appraisal.status;
                }
              }
              this.setState({
                manager: user.managerId,
              });
            });
          });
        }
        setTimeout(() => {
          const differentAppraisalsCounts =
            this.getFilterRadioBadgesValues(users);
          this.setState({
            users,
            usersToDisplay: users,
            nonSortedUsers: users,
            managers: managers,
            activeAppraisals: differentAppraisalsCounts.activeAppraisals,
            reviewAppraisals: differentAppraisalsCounts.reviewAppraisals,
            closedAppraisals: differentAppraisalsCounts.closedAppraisals,
            managerDraftAppraisals:
              differentAppraisalsCounts.managerDraftAppraisals,
            managerNotDraftAppraisals:
              differentAppraisalsCounts.managerNotDraftAppraisals,
            employeeDraftAppraisals:
              differentAppraisalsCounts.employeeDraftAppraisals,
            employeeNotDraftAppraisals:
              differentAppraisalsCounts.employeeNotDraftAppraisals,
            loading: false,
          });
        }, 1000);
      });
    }
  };

  filterAppraisalsDrafts = (users, managerDraft, employeeDraft) => {
    if (managerDraft !== "any" && employeeDraft !== "any") {
      return users.filter(
        (u) =>
          u.appraisal &&
          u.appraisal.assessors.find((a) => a.type === MANAGER_ASSESSOR_TYPE)
            .draft === managerDraft &&
          u.appraisal.assessors.find((a) => a.type === EMPLOYEE_ASESSOR_TYPE)
            .draft === employeeDraft
      );
    } else if (managerDraft !== "any") {
      return users.filter(
        (u) =>
          u.appraisal &&
          u.appraisal.assessors.find((a) => a.type === MANAGER_ASSESSOR_TYPE)
            .draft === managerDraft
      );
    } else if (employeeDraft !== "any") {
      return users.filter(
        (u) =>
          u.appraisal &&
          u.appraisal.assessors.find((a) => a.type === EMPLOYEE_ASESSOR_TYPE)
            .draft === employeeDraft
      );
    }
    return users;
  };

  getManagerDraftValue = (managerDraft) => {
    managerDraft =
      managerDraft === "Manager any"
        ? "any"
        : managerDraft === "Manager draft"
        ? true
        : false;

    return managerDraft;
  };

  getEmployeeDraftValue = (employeeDraft) => {
    employeeDraft =
      employeeDraft === "Employee any"
        ? "any"
        : employeeDraft === "Employee draft"
        ? true
        : false;

    return employeeDraft;
  };

  getManagerNames = () => {
    if (this.state.loading === false) {
      if (this.state.selectedManagers.length > 0) {
        let totalClosed = "";

        this.state.selectedManagers
          .filter((el) => el !== undefined)
          .forEach((arrayItem) => {
            totalClosed = totalClosed + arrayItem + " / ";
          });
        return totalClosed.trim().substring(0, totalClosed.length - 2);
      } else {
        return `${this.props.user.firstName} ${this.props.user.lastName}`;
      }
    }
  };

  render() {
    return this.state.loading ? (
      <Loading />
    ) : (
      <Container className="animated">
        <Row className="mb-4">
          <Col>
            <header className="title text-center">
              <h4>
                <FormattedMessage id="Manager.usersToReview" />
              </h4>
              <h5>{this.getManagerNames()}</h5>
            </header>
          </Col>
        </Row>
        <OverviewFilter
          handleSubmit={this.handleFilterSubmit}
          managers={this.state.managers}
          performanceReviews={this.state.performanceReviews}
          activeAppraisals={this.state.activeAppraisals}
          reviewAppraisals={this.state.reviewAppraisals}
          closedAppraisals={this.state.closedAppraisals}
          managerDraftAppraisals={this.state.managerDraftAppraisals}
          managerNotDraftAppraisals={this.state.managerNotDraftAppraisals}
          employeeDraftAppraisals={this.state.employeeDraftAppraisals}
          employeeNotDraftAppraisals={this.state.employeeNotDraftAppraisals}
        />
        <div className="mt-3">
          {this.state.usersToDisplay.length === 0 ? (
            <h4 className="text-center">
              <FormattedMessage id="ProcessParticipants.noUsersFound" />
            </h4>
          ) : (
            <Table className="mb-3 overview-table" responsive>
              <thead>
                <tr>
                  <th colSpan={2}>
                    <SortButton
                      label={this.props.intl.formatMessage({
                        id: "UsersTable.name",
                      })}
                      sort={this.state.sortResultsByName}
                      onClick={() => this.sortResults("sortResultsByName")}
                    />
                  </th>
                  <th>
                    <FormattedMessage id="EditUserForm.manager" />
                  </th>
                  <th>
                    <FormattedMessage id="Manager.period" />
                  </th>
                  <th>
                    <FormattedMessage id="Manager.dueDate" />
                  </th>

                  <th>
                    <SortButton
                      label={this.props.intl.formatMessage({
                        id: "Reviews.status",
                      })}
                      sort={this.state.sortResultsByStatus}
                      onClick={() => this.sortResults("sortResultsByStatus")}
                    />
                  </th>
                  <th className="text-center">
                    <FormattedMessage id="Manager.actions" />
                  </th>
                </tr>
              </thead>
              <tbody>{this.getEmployeesDataAsTableRows()}</tbody>
            </Table>
          )}
        </div>
      </Container>
    );
  }
}

export default injectIntl(withRouter(Overview));
