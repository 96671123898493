import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/users";

const loadImage = async (email, callback) => {
  let imageObjectURL = `${process.env.PUBLIC_URL}/user-icon.svg`;
  const imageResponse = await authFetchJSON(
    `${BASE_URL}/extra-data/profile-picture?email=${email}&${Date.now()}`
  );

  if (imageResponse.ok) {
    const image = await imageResponse.blob();

    if (image) {
      // Then create a local URL for that image and print it
      imageObjectURL = URL.createObjectURL(image);
    }
  }

  if (callback instanceof Function) {
    callback(imageObjectURL);
  } else if (callback) {
    callback.imageObjectURL = imageObjectURL;
  }
};

const UserService = {
  getAllActiveUsers: (roleName) => {
    let URL = `${BASE_URL}`;
    if (roleName) {
      URL += `?roleName=${roleName}`;
    }
    return authFetchJSON(`${URL}`).then((response) => response.json());
  },
  getUserById: (id) =>
    authFetchJSON(`${BASE_URL}/${id}`).then((response) => response.json()),

  attemptCreatingNewAppraisal: (userId, dueDate, type) =>
    authFetchJSON(`${BASE_URL}/${userId}/appraisals`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ dueDate, type }),
    }),

  getUserExtraDataByEmail: (email) =>
    authFetchJSON(`${BASE_URL}/extra-data?email=${email}`).then((response) =>
      response.json()
    ),

  getUserProfilePictureUrlByEmail: loadImage,

  getCurrentProcessUserByUserId: (userId) =>
    authFetchJSON(`${BASE_URL}/${userId}/process-user/current`).then(
      (response) => response.json()
    ),

  getCurrentAppraisalByUserId: (userId) =>
    authFetchJSON(`${BASE_URL}/${userId}/appraisal/current`).then((response) =>
      response.json()
    ),

  getProcessUsersByUserIdAndAction: (userId, action) =>
    authFetchJSON(`${BASE_URL}/${userId}/process-users?action=${action}`).then(
      (response) => response.json()
    ),

  getWatchedUsersByViewerId: (userId, performanceReviewId) =>
    authFetchJSON(
      `${BASE_URL}/${userId}/viewer-users?performanceReviewId=${performanceReviewId}`
    ).then((response) => response.json()),
  attemptUpdatingCurrentUserPassword: (passwords) =>
    authFetchJSON(`${BASE_URL}/current/password`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(passwords),
    }),

  createUsersByCsvFile: (data) =>
    authFetchJSON(`${BASE_URL}`, {
      method: "POST",
      body: data,
    }),

  attemptUpdatingCurrentUserProfilePicture: (profilePicture) =>
    authFetchJSON(`${BASE_URL}/current/profile-picture`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ profilePicture }),
    }),
  getJobTitles: () =>
    authFetchJSON(`${BASE_URL}/job-titles`).then((response) => response.json()),
  getAllNames: (managerId) =>
    authFetchJSON(
      `${BASE_URL}/names${managerId ? `?managerId=${managerId}` : ``}`
    ).then((response) => response.json()),
};

export default UserService;
