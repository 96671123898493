import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import debounce from "lodash.debounce";
import TeamService from "../../../services/TeamService";
import Loading from "../../common/display/Loading";
import TeamEmployees from "../../profile/TeamEmployees";

const { Control, Group } = Form;

const MyTeamContainer = ({ user }) => {
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: "MyTeamContainer.searchByNameGoals",
  });
  const [teamEmployees, setTeamEmployees] = useState([]);

  const delayedQuery = useCallback(
    debounce((q) => sendQuery(q), 500),
    []
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      Promise.all([fetchTeamManagementEmployees()]).then(() =>
        setIsLoading(false)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);

  const sendQuery = (query) => {
    fetchTeamManagementEmployees(query);
  };

  const fetchTeamManagementEmployees = (query) => {
    if (user?.teamId) {
      Promise.all([TeamService.getUsersByTeamId(user?.teamId, query)]).then(
        ([users]) => {
          setTeamEmployees(users?.filter((u) => u.id !== user.id));
        }
      );
    } else Promise.resolve();
  };

  const filterByQuery = (value) => {
    delayedQuery(value);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Container
        fluid
        style={{ width: "66%", float: "right", marginTop: "1%" }}
      >
        <Group>
          <Row className="mt-3">
            <Col sm={6} style={{ marginLeft: "-16px" }}>
              <Control
                type="text"
                name="description"
                size="sm"
                placeholder={placeholder}
                onChange={(event) => filterByQuery(event.target.value)}
              />
            </Col>
          </Row>

          {teamEmployees?.length > 0 ? (
            <Row className="mt-3">
              <TeamEmployees employees={teamEmployees} />
            </Row>
          ) : (
            <h4 className="title text-center" style={{ marginTop: "2%" }}>
              <FormattedMessage id="Nodata.message" />
            </h4>
          )}
        </Group>
      </Container>
    </>
  );
};

export default MyTeamContainer;
