import React from "react";
import { Form, Col } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";
import ErrorIcon from "../../../icons/ErrorIcon";

export default function TextInput(props) {
  const [field, meta] = useField(props);

  return (
    <Form.Group as={Col} className={props.formGroupClass}>
      <Form.Control
        className={`${props.formControlClass} ${
          meta.touched && meta.error ? "error" : ""
        }`}
        type={props.formControlType}
        name={props.formControlName}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        {...field}
      />
      <ErrorMessage
        render={(msg) => (
          <div className="validation-error-msg">
            {" "}
            <ErrorIcon height="16px" width="16px" />{" "}
            <span style={{ marginLeft: "1%" }}> {msg} </span>
          </div>
        )}
        name={props.formControlName}
      />
    </Form.Group>
  );
}
