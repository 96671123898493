import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import TrainingsService from "../../../services/TrainingsService";
import Loading from "../../common/display/Loading";
import MyTrainingTimeline from "./MyTrainingTimeline";
import "../../oneOnOne/one-on-one-card.css";

export default function MyTrainingPersonalDevelopment(props) {
  const { performanceReviewId, user } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    setIsLoading(true);
  }, [performanceReviewId]);

  useEffect(() => {
    if (isLoading) {
      Promise.all([
        TrainingsService.getPersonalTraining(user.id, performanceReviewId),
      ]).then(([trainings]) => {
        const labelHashmap = {};
        trainings.forEach((item) => {
          const { trainingId, ...rest } = item;

          if (!labelHashmap[trainingId]) {
            labelHashmap[trainingId] = [];
          }

          labelHashmap[trainingId].push(rest);
        });
        setTrainings(labelHashmap);
      });
      setIsLoading(false);
    }
  }, [isLoading, performanceReviewId, user.id]);

  if (isLoading) {
    return <Loading />;
  } else if (Object.keys(trainings).length === 0) {
    return <FormattedMessage id="MyTraining.training.not.available" />;
  } else {
    return (
      <>
        {Object.entries(trainings).map(([trainingId, trainings]) => (
          <Card className="one-on-one-card" key={trainingId}>
            <Card.Header>
              <Card.Subtitle>
                <span className="info-title">{trainings[0].label}</span>
              </Card.Subtitle>
            </Card.Header>
            <Card.Body className="flex-display-wrap">
              <div className="text-center">
                <span className="info-title">
                  <FormattedMessage id="OneOnOneCard.timeline.label" />{" "}
                </span>
                <br />
                <MyTrainingTimeline trainings={trainings} />
              </div>
            </Card.Body>
          </Card>
        ))}
      </>
    );
  }
}
