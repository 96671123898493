import React, { useContext, useState } from "react";
import { IntlProvider } from "react-intl";
import { Helmet } from "react-helmet";
import bg from "date-fns/locale/bg";
import en from "date-fns/locale/en-GB";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";
import LocaleContext, {
  defaultLocale,
  prefferedLocale,
  STORAGE_KEY_LOCALE,
} from "../contexts/LocaleContext";
import languages from "./compiled-lang";

registerLocale("fr", fr);
registerLocale("bg", bg);
registerLocale("en", en);

export const getDateFnsLocale = (locale) => {
  if (locale === "fr") {
    return fr;
  }
  if (locale === "bg") {
    return bg;
  }
  if (locale === "en") {
    return en;
  }
  return en;
};

export default function useLocale() {
  const { locale, setLocale } = useContext(LocaleContext);

  return {
    locale,
    supportedLocales: languages,
    messages: languages[locale]?.messages || [],
    setLocale,
  };
}

export function LocaleProvider({ children }) {
  const [locale, setLocale] = useState(
    languages[defaultLocale] ? defaultLocale : "en"
  );

  const changeLocale = (language) => {
    if (languages[language]) {
      setLocale(language);
      if (language === prefferedLocale) {
        localStorage.removeItem(STORAGE_KEY_LOCALE);
      } else {
        localStorage.setItem(STORAGE_KEY_LOCALE, language);
      }
    } else {
      console.warn(
        `The selected language ${language} is not supported by the application. Please, consider choosing one of the following languages: ${Object.keys(
          languages
        ).map((lang, index) =>
          languages.length > index + 1 ? `${lang}, ` : lang
        )}`
      );
    }
  };

  return (
    <LocaleContext.Provider value={{ locale: locale, setLocale: changeLocale }}>
      <Helmet htmlAttributes={{ lang: locale }} />
      <IntlProvider
        locale={locale}
        messages={languages[locale]?.messages || []}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}
