import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { COMPLETED } from "../../constants/oneOnOneRefStatus";
import { DIRECT_REPORT, WE_LISTEN } from "../../constants/oneOnOneRefType";
import ErrorIcon from "../../icons/ErrorIcon";
import OneOnOneFeedbackView from "./OneOnOneFeedbackView";

const CompleteOneOnOneForm = ({
  handleClose,
  handleSubmit,
  mode,
  oneOnOne,
}) => {
  const intl = useIntl();
  const [oneonOneFeedback, setOneOnOneFeedback] = useState([]);
  const [totalMandatoryQuestion, setTotalMandatoryQuestions] = useState(0);

  const initializeValues = () => {
    return {
      actionPlan: "",
      oneOnOneFeedbacks: oneonOneFeedback,
    };
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initializeValues()}
      onSubmit={(values) => {
        if (
          values?.actionPlan !== "" ||
          (oneonOneFeedback.length > 0 &&
            oneonOneFeedback.filter((el) => el.mandatory === true).length ===
              totalMandatoryQuestion)
        ) {
          handleSubmit(
            {
              actionPlan: values.actionPlan,
              status: COMPLETED,
              meetingDate: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              ).toJSON(),
              oneOnOneFeedbacks: oneonOneFeedback,
            },
            mode
          );

          handleClose();
        } else {
          window.alert(
            intl.formatMessage({
              id: "OneOnOneCard.validation.message",
            })
          );
        }
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>
                <FormattedMessage
                  id={`OneOnOneStatus.Completed.${oneOnOne?.type?.type}.title`}
                />
              </Modal.Title>
              <hr />
              {(oneOnOne?.type?.type === DIRECT_REPORT ||
                oneOnOne?.type?.type === WE_LISTEN) && (
                <span style={{ color: "var(--light-grey)" }}>
                  <FormattedMessage
                    id={`CompleteOneOnOneForm.mandatory.indication`}
                  />
                </span>
              )}

              <OneOnOneFeedbackView
                type={{
                  label: oneOnOne?.type?.type,
                  value: oneOnOne?.type?.id,
                }}
                oneOnOne={oneOnOne}
                oneonOneFeedback={oneonOneFeedback}
                setOneOnOneFeedback={setOneOnOneFeedback}
                setTotalMandatoryQuestions={setTotalMandatoryQuestions}
                viewOnly={false}
              />
              <Row>
                <Form.Group as={Col}>
                  <Form.Label style={{ fontWeight: 700 }}>
                    <FormattedMessage id="CompleteOneOnOneForm.actionPlan.label" />
                  </Form.Label>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col}>
                  <Form.Control
                    as="textarea"
                    className="CompleteOneOnOneForm"
                    type="text"
                    name="actionPlan"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik?.errors?.actionPlan && (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}>
                        {" "}
                        {formik?.errors?.actionPlan}{" "}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="PerformanceReviewForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="Users.save" />
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default CompleteOneOnOneForm;
