import authFetchJSON from "../components/security/fetchClient";
import { FORBIDDEN_ERROR_CODE } from "../constants/errorStatusCodes";

const BASE_URL = "/api/v1/performance-review-goals";

const PerformanceReviewGoalService = {
  getPerformanceReviewGoalById: (id) =>
    authFetchJSON(`${BASE_URL}/${id}`).then((response) => response.json()),

  getCurrentPerformanceReviewGoalByUserId: (userId) =>
    authFetchJSON(`${BASE_URL}/current?targetEmployeeId=${userId}`).then(
      (response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Performance review goal not found.");
      }
    ),

  getLatestPerformanceReviewGoalByUserId: (performanceReviewId, userId) =>
    authFetchJSON(
      `${BASE_URL}/latest?performanceReviewId=${performanceReviewId}&targetEmployeeId=${userId}`
    ).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Performance review goal not found.");
    }),

  applyPerformanceReviewGoalTemplate: (user, subject, orgTemplate) =>
    authFetchJSON(
      `${BASE_URL}/${user.performanceReviewGoal.performanceReviewGoalId}/apply-goal-template`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({
          performanceReviewGoalId:
            user.performanceReviewGoal.performanceReviewGoalId,
          subjectId: subject,
          organizationalGoalTemplateId: orgTemplate,
        }),
      }
    ).then((response) => response),

  getGoalsByPerformanceReviewGoalId: (performanceReviewGoalId, goalTypeId) =>
    authFetchJSON(
      `${BASE_URL}/${performanceReviewGoalId}/goals${
        goalTypeId ? `?goalTypeId=${goalTypeId}` : ""
      }`
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === FORBIDDEN_ERROR_CODE) {
        return Promise.reject(response.json());
      }
    }),

  updatePerformanceReviewGoalStatus: (performanceReviewGoalId, status) =>
    authFetchJSON(`${BASE_URL}/${performanceReviewGoalId}?status=${status}`, {
      method: "PUT",
    }).then((response) => response.json()),

  removeGoalsFromPerformanceReviewGoal: (performanceReviewGoalId) =>
    authFetchJSON(`${BASE_URL}/${performanceReviewGoalId}/goals`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),

  getPerformanceReviewGoalAuditsByPerformanceReviewGoalId: (
    performanceReviewGoalId
  ) =>
    authFetchJSON(`${BASE_URL}/${performanceReviewGoalId}/audits`).then(
      (response) => response.json()
    ),
};

export default PerformanceReviewGoalService;
