import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/goal-templates";

const GoalTemplateService = {
  // NOT EXISTING
  getAllOrganizationalGoalTemplates: () =>
    authFetchJSON(`${BASE_URL}/organizational`).then((response) =>
      response.json()
    ),

  // NOT EXISTING
  getAllGoalTempletesByOwnerId: (ownerId) =>
    authFetchJSON(`${BASE_URL}?ownerId=${ownerId}`).then((response) =>
      response.json()
    ),

  getAllGoalTemplatesByGoalTemplateGroupIdAndWithoutOwner: (
    goalTemplateGroupId
  ) =>
    authFetchJSON(
      `${BASE_URL}?goalTemplateGroupId=${goalTemplateGroupId}`
    ).then((response) => response.json()),

  getAllGoalTemplatesByGoalTemplateGroupIdAndOwnerId: (
    goalTemplateGroupId,
    ownerId
  ) =>
    authFetchJSON(
      `${BASE_URL}?goalTemplateGroupId=${goalTemplateGroupId}&ownerId=${ownerId}`
    ).then((response) => response.json()),

  attemptSavingGoalTemplate: (goalTemplate) =>
    authFetchJSON(`${BASE_URL}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(goalTemplate),
    }),

  attemptDeletingGoalTemplateById: (goalTemplateId) =>
    authFetchJSON(`${BASE_URL}/${goalTemplateId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),
  attemptSavingGoalsTemplates: (goalsTemplates) =>
    authFetchJSON(`${BASE_URL}/all`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(goalsTemplates),
    }),
  attemptUpdateOrder: (goal) =>
    authFetchJSON(`${BASE_URL}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(goal),
    }),
};

export default GoalTemplateService;
