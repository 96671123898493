import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

const YesModalBtn = ({ variant = "primary", onClick }) => (
  <Button
    variant={variant}
    className="mr-3"
    style={{ width: "85px" }}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faCheck} size="lg" className="mr-2" />
    <FormattedMessage id="Button.yes" />
  </Button>
);

export default YesModalBtn;
