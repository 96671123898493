import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ManagerGradeSelector from "../manager/feedback/ManagerGradeSelector";

const OneOnOneFeedbackWithScoreRow = ({
  question,
  setOneOnOneFeedback,
  feedback,
  viewOnly,
  grades,
}) => {
  const [score, setScore] = useState(
    feedback && Number(feedback.answer) > 0 ? Number(feedback.answer) : 0
  );

  const onUpdate = (score) => {
    setScore(score);
    setOneOnOneFeedback(question.id, score, question.mandatory);
  };

  const styleHeader = viewOnly
    ? { display: "flex", marginLeft: "2%" }
    : { display: "flex", marginLeft: "0%" };

  const rowHeader = viewOnly
    ? {
        display: "flex",
        flexDirection: "column",
        marginLeft: "-1%",
        padding: 0,
      }
    : { display: "flex", flexDirection: "column" };

  return (
    <>
      <>
        <Row
          className="appraisal-preview-row appraisal-row content"
          style={styleHeader}
        >
          <Row style={rowHeader}>
            <Col className="col-9">
              <h5>
                {question?.content} {question?.mandatory && "*"}{" "}
              </h5>
            </Col>
          </Row>

          <Row style={{ right: viewOnly ? "1%" : "14%", position: "absolute" }}>
            <Col
              style={{
                marginLeft: "45px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!viewOnly ? (
                <ManagerGradeSelector
                  onChange={(rating) => {
                    onUpdate(rating);
                  }}
                  grades={grades}
                  readOnly={false}
                  circleWidth="2.75rem"
                  value={Number(score)}
                  id={question.id}
                />
              ) : (
                <ManagerGradeSelector
                  grades={grades}
                  readOnly={true}
                  circleWidth="2.75rem"
                  value={Number(score)}
                  id={question.id}
                />
              )}
            </Col>
          </Row>
        </Row>
      </>
    </>
  );
};

export default OneOnOneFeedbackWithScoreRow;
