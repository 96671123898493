export const MANAGER_GRADES_DICTIONARY_NUM = {
  /*  0: 0, */
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
};

export const GOAL_GRADE = 3;
