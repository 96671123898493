import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/goals";

const GoalService = {
  getPageOfGoalsByManagerIdAndGoalTypeId: (managerId, goalTypeId, page) =>
    authFetchJSON(
      `${BASE_URL}?managerId=${managerId}&goalTypeId=${goalTypeId}&page=${page}`
    ).then((response) => response.json()),

  getPageOfGoalsBy: (searchQuery, managerId, goalTypeId, page) =>
    authFetchJSON(
      `${BASE_URL}?q=${searchQuery}&managerId=${managerId}&goalTypeId=${goalTypeId}&page=${page}`
    ).then((response) => response.json()),

  attemptSavingGoal: (goal) =>
    authFetchJSON(`${BASE_URL}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(goal),
    }),

  attemptSavingGoals: (goals) =>
    authFetchJSON(`${BASE_URL}/all`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(goals),
    }),

  attemptUpdateGoalById: (goalId, goal) =>
    authFetchJSON(`${BASE_URL}/${goalId}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(goal),
    }),

  attemptDeletingGoalById: (goalId) =>
    authFetchJSON(`${BASE_URL}/${goalId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),

  attemptCopyingGoalsBy: (performanceReviewGoalId, goalTypeId) =>
    authFetchJSON(
      `${BASE_URL}/copy?performanceReviewGoalId=${performanceReviewGoalId}&goalTypeId=${goalTypeId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    ),
  attemptUpdateOrder: (goal) =>
    authFetchJSON(`${BASE_URL}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(goal),
    }),
};

export default GoalService;
