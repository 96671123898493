export const ROLE_CREATE_MODE = "create_role";
export const ROLE_EDIT_MODE = "edit_role";
export const ROLE_DELETE_MODE = "delete_role";
export const ROLE_SHOW_MEMBERS = "show_role_members";

const ROLE_MODES = [
  ROLE_CREATE_MODE,
  ROLE_EDIT_MODE,
  ROLE_DELETE_MODE,
  ROLE_SHOW_MEMBERS,
];

export default ROLE_MODES;
