import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  BAD_REQUEST_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import { Link } from "react-router-dom";
import getHomeLink from "../../util/homeLink";
import "./error-component.css";
import { FormattedMessage } from "react-intl";
const ErrorComponent = ({ statusCode, user, message, style }) => (
  <Container className="error-component-layout" fluid style={style}>
    <Row xs={1} sm={1} md={1} lg={1} xl={1}>
      <Col>
        <div className="error-component-status-code">{statusCode}</div>
        <div className="error-component-heading">
          {statusCode === BAD_REQUEST_ERROR_CODE && (
            <FormattedMessage id="Error.badRequest" />
          )}
          {statusCode === FORBIDDEN_ERROR_CODE && (
            <FormattedMessage id="Error.cannotAccess" />
          )}
          {statusCode === NOT_FOUND_ERROR_CODE && (
            <FormattedMessage id="Error.pageNotFound" />
          )}
        </div>
        {message && <div className="error-component-message">{message}</div>}
        <Button className="mt-5" variant="success">
          <Link to={getHomeLink(user)} className="error-component-link">
            <FormattedMessage id="Button.backHome" />
          </Link>
        </Button>
      </Col>
    </Row>
  </Container>
);

export default ErrorComponent;
