import React, { Component } from "react";
import ApplicationService from "./services/ApplicationService";
import Loading from "./components/common/display/Loading";
import App from "./App";
import AppMsal from "./msal/AppMsal";

class AppAuth extends Component {
  state = {
    appProps: undefined,
    loading: true,
  };

  componentDidMount() {
    ApplicationService.getApplicationConfigurationProperties()
      .then((configProps) =>
        this.setState({
          appProps: configProps,
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    const { appProps } = this.state;
    let cld = <Loading />;

    if (appProps) {
      if (!appProps.internalUserManagement && !appProps.oauth2ImplicitGrant) {
        cld = <AppMsal appProps={appProps} />;
      } else {
        cld = <App configProps={appProps} />;
      }
    }

    return <>{cld}</>;
  }
}

export default AppAuth;
