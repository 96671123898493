import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal, Form } from "react-bootstrap";

const FeedbackModal = ({ show, training, updateTraining, toggleModal }) => {
  const intl = useIntl();
  const [feedback, setFeedback] = useState();
  return (
    <>
      {training && (
        <Modal show={show} onHide={toggleModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {training.label +
                intl.formatMessage({ id: "Training.of.label" }) +
                `${training.firstName} ${training.lastName}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Training.comment.label" />
              </Form.Label>
              <textarea
                className="form-control"
                rows="3"
                cols="2"
                onChange={(event) => {
                  setFeedback(event.target.value);
                }}
              >
                {training.feedback}
              </textarea>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              <FormattedMessage id="EditForm.close" />
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                training.feedback = feedback;
                updateTraining(training);
                toggleModal();
              }}
            >
              <FormattedMessage id="EditForm.submit" />
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default FeedbackModal;
