import React from "react";
import "./icon-styles.css";
const GoalIcon = () => {
  return (
    <svg className="icon">
      <path d="m11.76728,11.95317l3.00171,0c0.15917,0 0.29101,0.10034 0.29101,0.22422l0,3.48716c0,0.12264 -0.13023,0.22422 -0.29101,0.22422l-3.00171,0c-0.15917,0 -0.29101,-0.10034 -0.29101,-0.22422l0,-3.48716c0,-0.12264 0.13184,-0.22422 0.29101,-0.22422l0,0zm-8.80738,-10.24964c3.53871,-1.88542 3.80399,1.84578 7.27839,-0.48312l0,3.80181c-3.31362,2.29669 -4.02908,-1.41468 -7.27839,0.47197l0,-3.79066l0,0zm-0.87302,-1.03686c0.373,0 0.67526,0.23289 0.67526,0.52029c0,0.19077 -0.13345,0.35801 -0.3312,0.44844l0.03216,0l0,4.60949l1.33124,0c0.15917,0 0.29101,0.10034 0.29101,0.22422l0,9.19668c0,0.12264 -0.13023,0.22422 -0.29101,0.22422l-3.00332,0c-0.15917,0 -0.29101,-0.10034 -0.29101,-0.22422l0,-9.19668c0,-0.12264 0.13023,-0.22422 0.29101,-0.22422l0,0l0.92125,0l0,-4.60949l0.03216,0c-0.19936,-0.09043 -0.33281,-0.25767 -0.33281,-0.44844c0,-0.2874 0.30226,-0.52029 0.67526,-0.52029l0,0zm4.19147,8.26388l3.00171,0c0.15917,0 0.29101,0.10034 0.29101,0.22422l0,6.51101c0,0.12264 -0.13023,0.22422 -0.29101,0.22422l-3.00171,0c-0.15917,0 -0.29101,-0.10034 -0.29101,-0.22422l0,-6.51101c0.00161,-0.12388 0.13184,-0.22422 0.29101,-0.22422l0,0z" />
    </svg>
  );
};

export default GoalIcon;
