import React from "react";
import { Dropdown } from "react-bootstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import {
  GRADES_DICTIONARY_NUM,
  GOAL_GRADE,
} from "../../constants/gradeDictionary";
import "react-circular-progressbar/dist/styles.css";
import "./grade-selector.css";
import { FormattedMessage } from "react-intl";
export default function GradeSelector(props) {
  const { grades, readOnly } = props;
  const [currentGrade, setCurrentGrade] = React.useState(
    GRADES_DICTIONARY_NUM[props.value]
  );

  let circleColor = "var(--primary-light)";
  if (currentGrade === 4) circleColor = "var(--primary-dark)";
  if (currentGrade === 5) circleColor = "var(--primary-dark)";
  if (readOnly) circleColor = "#a6a6a6";

  const circleSpanId = "circle-overlay-" + props.id;

  const circleToggle = React.forwardRef(({ onClick }, ref) => (
    <CircularProgressbarWithChildren
      value={(currentGrade / GOAL_GRADE) * 100}
      text={currentGrade !== -1 ? currentGrade : "N/A"}
      styles={{ path: { stroke: circleColor } }}
    >
      {!readOnly && (
        <span
          id={circleSpanId}
          className="circle-overlay"
          tabIndex="0"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        ></span>
      )}
    </CircularProgressbarWithChildren>
  ));

  const updateAssessment = (gradeTitle) => {
    const event = {
      target: {
        id: props.id.toString(),
        value: gradeTitle,
        type: "select-one",
      },
    };
    props.onChange(gradeTitle);
    props.assessment(event);
  };

  return (
    <div className={`grade-selector ${readOnly ? "row-aligned" : ""}`}>
      <Dropdown
        key={props.id}
        style={{ width: props.circleWidth ? props.circleWidth : "3.125rem" }}
      >
        <Dropdown.Toggle as={circleToggle} id="dropdown-custom-components" />
        <Dropdown.Menu role="menu">
          {grades &&
            grades.map((grade, key) => (
              <Dropdown.Item
                key={key}
                onClick={() => {
                  setCurrentGrade(GRADES_DICTIONARY_NUM[grade.title]);
                  updateAssessment(grade.title);
                }}
              >
                <FormattedMessage id={`Grade.${grade.weight}`} />
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      {!readOnly && (
        <p
          className="grade-text select-arrow"
          onClick={() => document.getElementById(circleSpanId).click()}
        >
          <FormattedMessage id={`Grade.${currentGrade}`} />
        </p>
      )}
    </div>
  );
}
