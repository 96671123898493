import React, { useEffect, useState } from "react";
import AppraisalService from "../../services/AppraisalService";
import AuthenticationService from "../../services/AuthenticationService";
import { PERMISSION_ASSESS_EMPLOYEES } from "../../constants/permissions";
import UserService from "../../services/UserService";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_REVIEW,
} from "../../constants/appraisalStatus";
import { MANAGER_ASSESSOR_TYPE } from "../../constants/assessorTypes";
import Loading from "../common/display/Loading";
import ErrorComponent from "../common/ErrorComponent";
import {
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import { withRouter } from "react-router";

const WithManagerProved = (props) => {
  const [found, setFound] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAppraisal();
  });

  const fetchAppraisal = () => {
    if (userCanAssessEmployees(props.user)) {
      AppraisalService.getAppraisalById(props.match.params.id)
        .then((appraisal) => getTargetEmployee(appraisal))
        .catch(() => setLoading(false));
    } else {
      setFound(true);
      setLoading(false);
    }
  };

  const userCanAssessEmployees = (user) =>
    AuthenticationService.hasPermission(user, PERMISSION_ASSESS_EMPLOYEES);

  const getTargetEmployee = (appraisal) =>
    UserService.getUserById(appraisal.targetEmployeeId).then((targetEmployee) =>
      verify(targetEmployee, appraisal)
    );

  const verify = (targetEmployee, appraisal) => {
    if (props.user) {
      if (
        props.user.id === targetEmployee.managerId &&
        props.command === "assess"
      ) {
        setAllowed(isAllowedToAssess(appraisal));
      } else if (
        (props.user.id === targetEmployee.managerId ||
          props.user.id === appraisal.managerId ||
          (props.teamAccessView && !userCanAssessEmployees(targetEmployee))) &&
        props.command === "view"
      ) {
        setAllowed(isAllowedToView(appraisal));
      } else if (
        props.user.id === targetEmployee.managerId &&
        props.command === "review"
      ) {
        setAllowed(isAllowedToReview(appraisal));
      }
      setFound(true);
      setLoading(false);
    } else {
      setAllowed(false);
    }
  };

  const isAllowedToAssess = (appraisal) => {
    if (appraisal.status === APPRAISAL_STATUS_ACTIVE) {
      const managerAssessor = getManagerAssessor(appraisal);
      if (managerAssessor) {
        return managerAssessor.draft;
      }
      return true;
    }
    return false;
  };

  const isAllowedToView = (appraisal) => {
    const managerAssessor = getManagerAssessor(appraisal);
    if (managerAssessor) {
      return !managerAssessor.draft;
    }
    return false;
  };

  const isAllowedToReview = (appraisal) =>
    appraisal.status === APPRAISAL_STATUS_REVIEW;

  const getManagerAssessor = (appraisal) =>
    appraisal.assessors.find(
      (assessor) => assessor.type === MANAGER_ASSESSOR_TYPE
    );

  if (props.user) {
    if (loading) {
      return <Loading />;
    } else {
      if (!found) {
        return (
          <ErrorComponent statusCode={NOT_FOUND_ERROR_CODE} user={props.user} />
        );
      } else if (!allowed) {
        return (
          <ErrorComponent statusCode={FORBIDDEN_ERROR_CODE} user={props.user} />
        );
      }
      return props.children;
    }
  }
  return <React.Fragment />;
};

export default withRouter(WithManagerProved);
