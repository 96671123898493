import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ORGANIZATIONAL_GOAL_TYPE_ID } from "../../constants/goalTypes";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import ErrorIcon from "../../icons/ErrorIcon";

const GoalDefinitionForm = ({
  user,
  goal,
  performanceReviewGoal,
  goalTypeId,
  goalTemplateGroup,
  manager,
  handleClose,
  handleSubmit,
  setting,
}) => {
  const intl = useIntl();
  const editPlaceholder = intl.formatMessage({
    id: "Reviews.tabs.edit",
  });
  const newPlaceholder = intl.formatMessage({
    id: "Goals.new",
  });
  const goalPlaceholder = intl.formatMessage({
    id: "Goals.goal",
  });
  const goalsActivePlaceholder = intl.formatMessage({
    id: "EditUserForm.active",
  });
  const goalsPeriod = intl.formatMessage({
    id: "Reviews.period",
  });

  const goalValidationSchema = Yup.object({
    goal: Yup.object({
      title: Yup.string().required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      ),
      weight: Yup.number()
        .moreThan(0, <FormattedMessage id="Validation.weightMoreThan" />)
        .max(1, <FormattedMessage id="Validation.weightMax" />)
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    }),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        goal:
          user && performanceReviewGoal && manager
            ? goal || {
                id: 0,
                title: "",
                description: "",
                kpi: "",
                kpiDescription: "",
                weight: "",
                goalTypeId,
                active: true,
                performanceReviewGoalId:
                  performanceReviewGoal.performanceReviewGoalId,
                managerCreatorId: manager.id,
              }
            : goal || {
                id: 0,
                title: "",
                description: "",
                kpi: "",
                kpiDescription: "",
                weight: "",
                goalTypeId,
                active: true,
                organizational: goalTypeId === ORGANIZATIONAL_GOAL_TYPE_ID,
                ownerId:
                  goalTypeId !== ORGANIZATIONAL_GOAL_TYPE_ID
                    ? manager.id
                    : undefined,
                goalTemplateGroupId: goalTemplateGroup?.id,
              },
      }}
      validationSchema={goalValidationSchema}
      onSubmit={(values) => {
        handleSubmit(values.goal);
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>
                <Row>
                  <Col>
                    {`${
                      goal ? editPlaceholder : newPlaceholder
                    } ${goalPlaceholder}`}
                    {user && ` ${user.firstName} ${user.lastName}`}
                    {performanceReviewGoal && (
                      <>
                        <br />
                        <small>
                          <ConfigurationPropertiesContext.Consumer>
                            {({ formatDate }) =>
                              `${goalsPeriod}: ${formatDate(
                                performanceReviewGoal.performanceReviewStart
                              )} - ${formatDate(
                                performanceReviewGoal.performanceReviewEnd
                              )}`
                            }
                          </ConfigurationPropertiesContext.Consumer>
                        </small>
                      </>
                    )}
                  </Col>
                </Row>
              </Modal.Title>
              <hr />
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="Goals.title" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="goal.title"
                    value={formik.values.goal.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span style={{ marginLeft: "1%" }}> {msg} </span>
                      </div>
                    )}
                    name="goal.title"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    {" "}
                    <FormattedMessage id="Goals.description" />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="goal.description"
                    value={
                      formik.values.goal.description
                        ? formik.values.goal.description
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={{ span: 8 }}>
                  <Form.Label>
                    <FormattedMessage id="Goals.kpi" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="goal.kpi"
                    value={formik.values.goal.kpi ? formik.values.goal.kpi : ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
                <Form.Group as={Col} md={{ span: 4 }}>
                  <Form.Label>
                    <FormattedMessage id="Goals.weight.defitionion" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="goal.weight"
                    value={formik.values.goal.weight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span style={{ marginLeft: "1%" }}> {msg} </span>
                      </div>
                    )}
                    name="goal.weight"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="Goals.kpiDescription" />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="goal.kpiDescription"
                    value={
                      formik.values.goal.kpiDescription
                        ? formik.values.goal.kpiDescription
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
          <Row>
            <Form.Group
              as={Col}
              style={{ marginLeft: "5%", textAlign: "left" }}
            >
              {setting ? (
                <Form.Check
                  type="switch"
                  name="goal.active"
                  label={goalsActivePlaceholder}
                  checked={formik.values.goal.active}
                  disabled
                />
              ) : (
                <Form.Check
                  id="goal.active"
                  type="switch"
                  name="goal.active"
                  label={goalsActivePlaceholder}
                  checked={formik.values.goal.active}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              style={{ marginRight: "5%", textAlign: "right" }}
            >
              <Button
                variant="secondary"
                className="mr-1"
                onClick={handleClose}
              >
                <FormattedMessage id="EditForm.cancel" />
              </Button>
              <Button type="submit" variant="primary">
                <FormattedMessage id="EditForm.submit" />
              </Button>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default GoalDefinitionForm;
