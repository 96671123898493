import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./loading.css";

const Loading = () => (
  <Container className="loading-container">
    <Row className="loading-logo">
      <Col>
        <Image src="/images/logo-tree.png" alt="Grow" />
      </Col>
    </Row>
    <Row className="loading-text">
      <Col>Loading</Col>
    </Row>
  </Container>
);

export default Loading;
