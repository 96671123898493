const MathUtils = {
  roundToTwoDecimalPlaces: (number) =>
    Math.round((number + Number.EPSILON) * 100) / 100,

  calculateProcessCompletionPercentage: ({
    completedParticipantsCount,
    participantsCount,
  }) => (completedParticipantsCount / participantsCount) * 100,
};

export default MathUtils;
