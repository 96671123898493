/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Breadcrumb,
  Button,
  Container,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LazyLoad from "react-lazyload";
import { ToastContainer } from "react-toastr";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faTasks,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ProcessService from "../../services/ProcessService";
import ManagerService from "../../services/ManagerService";
import AppraisalService from "../../services/AppraisalService";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import AuthenticationService from "../../services/AuthenticationService";
import Loading from "../common/display/Loading";
// import AddParticipantsModal from "./AddParticipantsModal";
import ProcessParticipantsFilter from "./ProcessParticipantsFilter";
import { SORT_ASC, SORT_DESC, SORT_NONE } from "../../constants/resultsSort";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_CLOSED,
  APPRAISAL_STATUS_REVIEW,
} from "../../constants/appraisalStatus";
import { capitalizeWord } from "../../util/stringUtils";
import {
  PROCESS_USER_ACTION_APPRAISAL,
  PROCESS_USER_ACTION_GOALS,
} from "../../constants/processUserAction";
import { PERMISSION_OVERVIEW } from "../../constants/permissions";

import {
  PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
  PERFORMANCE_REVIEW_GOAL_PENDING,
  PERFORMANCE_REVIEW_GOAL_SET,
} from "../../constants/performanceReviewGoalStatus";
import {
  BAD_REQUEST_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import {
  APPRAISAL_STATUS_HISTORY_MODE,
  GOALS_SETTING_STATUS_HISTORY_MODE,
} from "../../constants/managerViewModes";
import { PROCESS_STATUS_CLOSED } from "../../constants/processStatus";
import DeleteConfirmation from "../common/DeleteConfirmation";
import SortButton from "../common/buttons/SortButton";
import StatusTimelineModal from "../common/StatusTimelineModal";
import Image from "../common/Image";
import GoalIcon from "../common/buttons/GoalIcon";
import AddOrRemoveUserModal from "../admin/users/AddOrRemoveUserModal";
import "./overview.css";
import "./processParticipants.css";
import "../common/table/table.css";

class ProcessParticipants extends React.Component {
  state = {
    process: null,
    processParticipants: [],
    nonSortedParticipants: [],
    participantsToDisplay: [],
    managers: [],
    audits: [],
    showAddParticipantsModal: false,
    loading: true,
    sortParticipantsByName: SORT_NONE,
    sortParticipantsByStatus: SORT_NONE,
    selectedParticipant: null,
    showDeleteModal: false,
    showStatusTimelineModal: false,
    activeAppraisals: 0,
    reviewAppraisals: 0,
    closedAppraisals: 0,
    appraisalAction: 0,
    goalsAction: 0,
    pendingGoals: 0,
    inProgressGoals: 0,
    setGoals: 0,
    filterCalledOnes: false,
    filter: {
      name: "",
      status: [],
      goals: [],
      action: [],
      manager: "",
    },
    hoverItemId: undefined,
  };

  componentDidMount() {
    Promise.all([
      ProcessService.getProcessById(this.props.match.params.id),
      ProcessService.getProcessUsersByProcessId(this.props.match.params.id),
      ManagerService.getActiveManagers(),
    ]).then(([process, processUsers, managers]) => {
      process.participants.forEach((participant) => {
        participant.processUser = processUsers.find(
          (processUser) => processUser.user.id === participant.id
        );

        participant.processAction = participant.processUser.action;
        if (participant.processUser.appraisal !== null) {
          participant.status = participant.processUser.appraisal.status;
        } else if (participant.processUser.performanceReviewGoal !== null) {
          participant.status =
            participant.processUser.performanceReviewGoal.performanceReviewGoalStatus;
        }
        /* else {
          participant.status = PERFORMANCE_REVIEW_GOAL_PENDING;
        } */
      });
      const filterBadgesCount = this.getFilterRadioBadgesValues(
        process.participants
      );
      this.setState(
        {
          process,
          processParticipants: process.participants,
          nonSortedParticipants: process.participants,
          participantsToDisplay: process.participants,
          managers,
          loading: false,
          activeAppraisals: filterBadgesCount.activeAppraisals,
          reviewAppraisals: filterBadgesCount.reviewAppraisals,
          closedAppraisals: filterBadgesCount.closedAppraisals,
          appraisalAction: filterBadgesCount.appraisalAction,
          goalsAction: filterBadgesCount.goalsAction,
          pendingGoals: filterBadgesCount.pendingGoals,
          inProgressGoals: filterBadgesCount.inProgressGoals,
          setGoals: filterBadgesCount.setGoals,
        },
        () => this.callFilterInTheBeginning()
      );
    });
  }

  toastContainer;

  callFilterInTheBeginning = () => {
    const queryParams = queryString.parse(this.props.location.search);
    if (
      queryParams &&
      (queryParams.appraisal || queryParams.goals || queryParams.action)
    ) {
      this.handleFilterSubmit(
        "",
        queryParams.appraisal ? queryParams.appraisal : "Any status",
        queryParams.goals ? queryParams.goals : "Any goals",
        queryParams.action ? queryParams.action : "Action any",
        "All managers"
      );
    }
  };

  handleAddParticipantsBtnClick = () =>
    this.setState({ showAddParticipantsModal: true });

  handleExport = () => {
    const dataExport = {
      processId: this.state.process.id,
      participants: this.state.participantsToDisplay.map((participant) => {
        const manager = this.state.managers.find(
          (m) => m.id === participant.managerId
        );

        return {
          action: participant.action,
          email: participant.email,
          firstName: participant.firstName,
          lastName: participant.lastName,
          managerName: participant.managerName,
          managerEmail: manager !== undefined ? manager.email : null,
          status: participant.status,
        };
      }),
      filter: { ...this.state.filter },
    };

    ProcessService.exportDashboardAsExcel(dataExport).then((r) => r);
  };

  sortParticipants = (sortOption) =>
    this.setState(
      (prevState) => ({
        [sortOption]: this.getNextSortOption(prevState[sortOption]),
      }),
      () => this.sortUsersToReview()
    );

  getNextSortOption = (sortOption) => {
    if (sortOption === SORT_NONE) {
      return SORT_ASC;
    } else if (sortOption === SORT_ASC) {
      return SORT_DESC;
    }
    return SORT_NONE;
  };

  sortUsersToReview = () => {
    const currentUsersList = [].concat(this.state.participantsToDisplay);
    let sortedResults = currentUsersList.sort((a, b) => {
      const aFullName = `${a.firstName} ${a.lastName}`;
      const bFullName = `${b.firstName} ${b.lastName}`;

      if (
        this.state.sortParticipantsByName !== SORT_NONE &&
        aFullName > bFullName
      ) {
        return this.state.sortParticipantsByName === SORT_ASC ? 1 : -1;
      } else if (
        this.state.sortParticipantsByName !== SORT_NONE &&
        aFullName < bFullName
      ) {
        return this.state.sortParticipantsByName === SORT_ASC ? -1 : 1;
      } else {
        if (
          this.state.sortParticipantsByStatus !== SORT_NONE &&
          !a.status &&
          !b.status
        ) {
          return 0;
        } else if (
          this.state.sortParticipantsByStatus !== SORT_NONE &&
          a.status &&
          !b.status
        ) {
          return -1;
        } else if (
          this.state.sortParticipantsByStatus !== SORT_NONE &&
          !a.status &&
          b.status
        ) {
          return 1;
        } else if (
          this.state.sortParticipantsByStatus !== SORT_NONE &&
          a.status > b.status
        ) {
          return this.state.sortParticipantsByStatus === SORT_ASC ? 1 : -1;
        } else if (
          this.state.sortParticipantsByStatus !== SORT_NONE &&
          a.status < b.status
        ) {
          return this.state.sortParticipantsByStatus === SORT_ASC ? -1 : 1;
        }
      }
      return 0;
    });

    this.setState((prevState) => ({
      participantsToDisplay:
        prevState.sortParticipantsByName === SORT_NONE &&
        prevState.sortParticipantsByStatus === SORT_NONE
          ? prevState.nonSortedParticipants
          : sortedResults,
    }));
  };

  getFilterRadioBadgesValues = (
    users,
    name = ".",
    status = [
      APPRAISAL_STATUS_ACTIVE,
      APPRAISAL_STATUS_CLOSED,
      APPRAISAL_STATUS_REVIEW,
    ],
    action = [PROCESS_USER_ACTION_APPRAISAL, PROCESS_USER_ACTION_GOALS],
    goals = [
      PERFORMANCE_REVIEW_GOAL_PENDING,
      PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
      PERFORMANCE_REVIEW_GOAL_SET,
    ],
    manager = "All managers"
  ) => {
    let usersWithManager = this.filterUsersWithManager(users, manager).filter(
      (u) => new RegExp(`${name}+`, "i").test(`${u.firstName} ${u.lastName}`)
    );

    const activeAppraisals = usersWithManager.filter(
      (u) =>
        action.includes(u.action) &&
        goals.length === 3 &&
        u.status === APPRAISAL_STATUS_ACTIVE
    ).length;
    const reviewAppraisals = usersWithManager.filter(
      (u) =>
        action.includes(u.action) &&
        goals.length === 3 &&
        u.status === APPRAISAL_STATUS_REVIEW
    ).length;
    const closedAppraisals = usersWithManager.filter(
      (u) =>
        action.includes(u.action) &&
        goals.length === 3 &&
        u.status === APPRAISAL_STATUS_CLOSED
    ).length;
    const appraisalAction = usersWithManager.filter(
      (u) =>
        status.includes(u.status) &&
        goals.length === 3 &&
        u.action === PROCESS_USER_ACTION_APPRAISAL
    ).length;
    const goalsAction = usersWithManager.filter(
      (u) =>
        goals.includes(u.status) &&
        status.length === 3 &&
        u.action === PROCESS_USER_ACTION_GOALS
    ).length;
    const pendingGoals = usersWithManager.filter(
      (u) =>
        action.includes(u.action) &&
        status.length === 3 &&
        u.status === PERFORMANCE_REVIEW_GOAL_PENDING
    ).length;
    const inProgressGoals = usersWithManager.filter(
      (u) =>
        action.includes(u.action) &&
        status.length === 3 &&
        u.status === PERFORMANCE_REVIEW_GOAL_IN_PROGRESS
    ).length;
    const setGoals = usersWithManager.filter(
      (u) =>
        action.includes(u.action) &&
        status.length === 3 &&
        u.status === PERFORMANCE_REVIEW_GOAL_SET
    ).length;

    return {
      activeAppraisals,
      reviewAppraisals,
      closedAppraisals,
      appraisalAction,
      goalsAction,
      pendingGoals,
      inProgressGoals,
      setGoals,
    };
  };

  getParticipantsDataAsTableRows = () =>
    this.state.participantsToDisplay.map((p, index) => {
      const manager = this.state.managers.find((m) => m.id === p.managerId);
      return (
        <tr
          key={index}
          onMouseOver={() =>
            this.setState({
              hoverItemId: p.id,
            })
          }
          onMouseLeave={() =>
            this.setState({
              hoverItemId: undefined,
            })
          }
        >
          <td className="profile-img">
            <LazyLoad height={50} offset={100}>
              <Image className="profile" email={p.email} roundedCircle />
            </LazyLoad>
          </td>
          <td>
            <div className="employee-name">{`${p.firstName} ${p.lastName}`}</div>
            <div className="employee-position">{p.title}</div>
            <div className="employee-email">{p.email}</div>
            {this.state.hoverItemId === p.id && (
              <div className="actions">
                {p.processUser.appraisal &&
                  AuthenticationService.hasPermission(
                    this.props.user,
                    PERMISSION_OVERVIEW
                  ) &&
                  this.getViewButton(p.processUser.appraisal, p.id)}
                {AuthenticationService.hasPermission(
                  this.props.user,
                  PERMISSION_OVERVIEW
                ) && this.getGoalsButton(p.id)}
                {AuthenticationService.hasPermission(
                  this.props.user,
                  PERMISSION_OVERVIEW
                ) && this.getHistoryButton(p.id)}
                {this.state.process.status !== APPRAISAL_STATUS_CLOSED &&
                  this.getDeleteButton(p)}
              </div>
            )}
          </td>
          <td>
            {manager ? `${manager.firstName} ${manager.lastName}` : "--"}
            {manager && <p className="email">{manager.email}</p>}
          </td>
          <td>{p.action ? capitalizeWord(p.action) : "--"}</td>
          <td>
            {p.status ? (
              p.status === PERFORMANCE_REVIEW_GOAL_PENDING &&
              !p.processUser.performanceReviewGoal ? (
                /*  p.action === "APPRAISAL" ? (
                  <FormattedMessage id={`Status.ACTIVE`} />
                ) : ( */
                <FormattedMessage id={`Status.${p.status}`} />
              ) : (
                // )
                <a
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleOpenModal(
                      p.processAction === PROCESS_USER_ACTION_APPRAISAL
                        ? APPRAISAL_STATUS_HISTORY_MODE
                        : p.processAction === PROCESS_USER_ACTION_GOALS &&
                            GOALS_SETTING_STATUS_HISTORY_MODE,
                      p
                    );
                  }}
                >
                  {/*   {capitalizeWord(p.status)} */}
                  {<FormattedMessage id={`Status.${p.status}`} />}
                </a>
              )
            ) : (
              "--"
            )}
          </td>
        </tr>
      );
    });

  getViewButton = (appraisal, userId) => (
    <Button
      id={`${userId}-view`}
      variant="link"
      disabled={
        !(
          appraisal &&
          AuthenticationService.hasPermission(
            this.props.user,
            PERMISSION_OVERVIEW
          )
        )
      }
      onClick={() =>
        this.props.history.push({
          pathname: `/appraisals/${appraisal.id}/overview`,
          state: {
            reviewTitle: this.state.process.performanceReviewTitle,
            processTitle: this.state.process.title,
          },
        })
      }
    >
      <FontAwesomeIcon icon={faTasks} />
      <FormattedMessage id="ProcessParticipants.appraisals" />
    </Button>
  );

  getHistoryButton = (userId) => (
    <Button
      id={`${userId}-history`}
      variant="link"
      disabled={
        !AuthenticationService.hasPermission(
          this.props.user,
          PERMISSION_OVERVIEW
        )
      }
      onClick={() =>
        this.props.history.push({
          pathname: `/users/${userId}/history`,
          state: {
            reviewTitle: this.state.process.performanceReviewTitle,
            processTitle: this.state.process.title,
          },
        })
      }
    >
      <FontAwesomeIcon icon={faHistory} />
      <FormattedMessage id="ProcessParticipants.history" />
    </Button>
  );

  getGoalsButton = (userId) => (
    <Button
      id={`${userId}-goals`}
      variant="link"
      disabled={
        !AuthenticationService.hasPermission(
          this.props.user,
          PERMISSION_OVERVIEW
        )
      }
      onClick={() =>
        this.props.history.push({
          pathname: `/users/${userId}/goals`,
          state: {
            reviewTitle: this.state.process.performanceReviewTitle,
            processTitle: this.state.process.title,
          },
        })
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <GoalIcon />
        <FormattedMessage id="ProcessParticipants.goals" />
      </div>
    </Button>
  );

  getDeleteButton = (user) => (
    <Button
      id={`${user.id}-delete`}
      variant="link"
      disabled={this.state.process.status === APPRAISAL_STATUS_CLOSED}
      onClick={() =>
        this.setState({
          selectedParticipant: user,
          showDeleteModal: true,
        })
      }
    >
      <FontAwesomeIcon icon={faTrashAlt} />
      <FormattedMessage id="ProcessParticipants.remove" />
    </Button>
  );

  handleOpenModal = (mode, participant) => {
    if (mode === APPRAISAL_STATUS_HISTORY_MODE) {
      Promise.all([
        AppraisalService.getAppraisalAuditsByAppraisalId(
          participant.processUser.appraisal.id
        ),
        AppraisalService.getAssessorAuditsByAppraisalId(
          participant.processUser.appraisal.id
        ),
      ]).then(([appraisalAudits, appraisalAssessorAudits]) =>
        this.setState({
          selectedParticipant: participant,
          audits: appraisalAssessorAudits
            .concat(appraisalAudits)
            .sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt);
            }),
          showStatusTimelineModal: true,
        })
      );
    } else if (mode === GOALS_SETTING_STATUS_HISTORY_MODE) {
      PerformanceReviewGoalService.getPerformanceReviewGoalAuditsByPerformanceReviewGoalId(
        participant.processUser.performanceReviewGoal.performanceReviewGoalId
      ).then((performanceReviewGoalAudits) =>
        this.setState({
          selectedParticipant: participant,
          audits: performanceReviewGoalAudits,
          showStatusTimelineModal: true,
        })
      );
    }
  };

  handleDeleteParticipant = (participantId) =>
    ProcessService.removeParticipantFromProcess(
      this.props.match.params.id,
      participantId
    ).then((response) => {
      if (response.ok) {
        this.componentDidMount();
        this.showSuccessNotification(
          "Participant removed from the process successfully."
        );
      } else if (response.status === BAD_REQUEST_ERROR_CODE) {
        response
          .json()
          .then((error) => this.showErrorNotification(error.message));
      } else if (response.status === NOT_FOUND_ERROR_CODE) {
        this.showErrorNotification("Participant not found.");
      }
    });

  handleFilterSubmit = (name, status, goals, action, manager, reset) => {
    if (reset) {
      const filterBadgesCount = this.getFilterRadioBadgesValues(
        this.state.processParticipants
      );

      this.setState((prevState) => ({
        participantsToDisplay: prevState.processParticipants,
        nonSortedParticipants: prevState.processParticipants,
        activeAppraisals: filterBadgesCount.activeAppraisals,
        reviewAppraisals: filterBadgesCount.reviewAppraisals,
        closedAppraisals: filterBadgesCount.closedAppraisals,
        appraisalAction: filterBadgesCount.appraisalAction,
        goalsAction: filterBadgesCount.goalsAction,
        pendingGoals: filterBadgesCount.pendingGoals,
        inProgressGoals: filterBadgesCount.inProgressGoals,
        setGoals: filterBadgesCount.setGoals,
        filter: { name, status, goals, action, manager },
      }));
    } else {
      name = name === "" ? "." : name;
      action = action === "" ? "Action any" : action;
      status =
        status === "Any status"
          ? [
              APPRAISAL_STATUS_ACTIVE,
              APPRAISAL_STATUS_CLOSED,
              APPRAISAL_STATUS_REVIEW,
            ]
          : [status];
      action =
        action === "Action any"
          ? [PROCESS_USER_ACTION_APPRAISAL, PROCESS_USER_ACTION_GOALS]
          : [action];
      goals =
        goals === "Any goals"
          ? [
              PERFORMANCE_REVIEW_GOAL_PENDING,
              PERFORMANCE_REVIEW_GOAL_IN_PROGRESS,
              PERFORMANCE_REVIEW_GOAL_SET,
            ]
          : [goals];

      let usersWithManager = this.filterUsersWithManager(
        this.state.processParticipants,
        manager
      );

      const usersToDisplay = usersWithManager
        .filter((u) => {
          if (status.length === 3) {
            return true;
          }
          return status.includes(u.status);
        })
        .filter((u) => {
          if (action.length === 2) {
            return true;
          }
          return action.includes(u.action);
        })
        .filter((u) => {
          if (goals.length === 3) {
            return true;
          }
          return goals.includes(u.status);
        })
        .filter((u) =>
          new RegExp(`${name}+`, "i").test(`${u.firstName} ${u.lastName}`)
        );
      const filterBadgesCount = this.getFilterRadioBadgesValues(
        this.state.processParticipants,
        name,
        status,
        action,
        goals,
        manager
      );
      this.setState({
        participantsToDisplay: usersToDisplay,
        nonSortedParticipants: usersToDisplay,
        activeAppraisals: filterBadgesCount.activeAppraisals,
        reviewAppraisals: filterBadgesCount.reviewAppraisals,
        closedAppraisals: filterBadgesCount.closedAppraisals,
        appraisalAction: filterBadgesCount.appraisalAction,
        goalsAction: filterBadgesCount.goalsAction,
        pendingGoals: filterBadgesCount.pendingGoals,
        inProgressGoals: filterBadgesCount.inProgressGoals,
        setGoals: filterBadgesCount.setGoals,
        filter: { name, status, goals, action, manager },
      });
    }
  };

  filterUsersWithManager = (foundUsers, manager) =>
    manager && manager !== "All managers"
      ? foundUsers.filter((u) => u.managerName === manager)
      : foundUsers;

  handleCloseModals = () =>
    this.setState({
      showAddParticipantsModal: false,
      showDeleteModal: false,
      showStatusTimelineModal: false,
      selectedParticipant: null,
    });

  showSuccessNotification = (message) =>
    this.toastContainer.success(message, "", {
      closeButton: true,
    });

  showErrorNotification = (message) =>
    this.toastContainer.error(message, "", {
      closeButton: true,
    });

  submitNewParticipants = (process, participants) =>
    ProcessService.addParticipantsToProcess(process.id, participants).then(
      (response) => {
        if (response.ok) {
          response
            .text()
            .then((message) => this.showSuccessNotification(message));
        } else if (response.status === BAD_REQUEST_ERROR_CODE) {
          response
            .json()
            .then((error) => this.showErrorNotification(`${error.message}`));
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          this.showErrorNotification(
            "You don't have permission to add participants to process."
          );
        }
        this.handleCloseModals();
        this.componentDidMount();
      }
    );

  render() {
    return this.state.loading ? (
      <Loading />
    ) : (
      <Container className="animated">
        <ToastContainer
          ref={(ref) => {
            this.toastContainer = ref;
          }}
          className="toast-top-right"
        />
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => this.props.history.go(-2)}>
              <FormattedMessage id="Breadcrumb.review" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => this.props.history.go(-1)}>
              {this.state.process.performanceReviewTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {this.state.process.title.toUpperCase()}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <ProcessParticipantsFilter
          managers={this.state.managers}
          handleSubmit={this.handleFilterSubmit}
          activeAppraisals={this.state.activeAppraisals}
          reviewAppraisals={this.state.reviewAppraisals}
          closedAppraisals={this.state.closedAppraisals}
          appraisalAction={this.state.appraisalAction}
          goalsAction={this.state.goalsAction}
          pendingGoals={this.state.pendingGoals}
          inProgressGoals={this.state.inProgressGoals}
          setGoals={this.state.setGoals}
          processStatus={this.state.process.status}
          performanceReviewStatus={
            this.state.process?.performanceReview?.status
          }
          handleAddParticipant={this.handleAddParticipantsBtnClick}
          handleExport={this.handleExport}
          participantsToDisplay={this.state.participantsToDisplay}
          queryParams={queryString.parse(this.props.location.search)}
          actionParam={this.props.location.search.split("=")}
        />

        <div className="mt-3">
          {this.state.participantsToDisplay.length === 0 ? (
            <h4 className="loader text-center">
              <FormattedMessage id="ProcessParticipants.noUsersFound" />
            </h4>
          ) : (
            <Table
              className="actionsTable overview-table"
              borderless
              responsive
            >
              <thead>
                <tr>
                  <th colSpan={2}>
                    <SortButton
                      style={{
                        color: "#7d7c7c",
                        fontSize: "1.125rem",
                        fontWeight: 300,
                        textAlign: "center",
                      }}
                      label={<FormattedMessage id="ProcessParticipants.name" />}
                      sort={this.state.sortParticipantsByName}
                      onClick={() =>
                        this.sortParticipants("sortParticipantsByName")
                      }
                    />
                  </th>
                  <th>
                    <FormattedMessage id="ProcessParticipants.manager" />
                  </th>
                  <th>
                    <FormattedMessage id="ProcessParticipants.action" />
                  </th>
                  <th>
                    <SortButton
                      style={{
                        color: "#7d7c7c",
                        fontSize: "1.125rem",
                        fontWeight: 300,
                        textAlign: "center",
                      }}
                      label={
                        <FormattedMessage id="ProcessParticipants.status" />
                      }
                      sort={this.state.sortParticipantsByStatus}
                      onClick={() =>
                        this.sortParticipants("sortParticipantsByStatus")
                      }
                    />
                  </th>
                </tr>
              </thead>
              <tbody>{this.getParticipantsDataAsTableRows()}</tbody>
            </Table>
          )}
        </div>
        <Modal
          show={this.state.showDeleteModal}
          onHide={this.handleCloseModals}
          centered
        >
          <DeleteConfirmation
            processParticipant={this.state.selectedParticipant}
            handleSubmit={this.handleDeleteParticipant}
            handleClose={this.handleCloseModals}
          />
        </Modal>
        {this.state.process.status !== PROCESS_STATUS_CLOSED && (
          <Modal
            show={this.state.showAddParticipantsModal}
            onHide={this.handleCloseModals}
            size="lg"
            centered
          >
            <AddOrRemoveUserModal
              handleClose={this.handleCloseModals}
              handleSubmit={this.submitNewParticipants}
              title={this.state.process.title}
              members={
                this.state.process.participants
                  ? this.state.process.participants
                  : []
              }
              role={this.state.process}
              isProcess
            />
          </Modal>
        )}
        <StatusTimelineModal
          showModal={this.state.showStatusTimelineModal}
          handleClose={this.handleCloseModals}
          user={this.state.selectedParticipant}
          audits={this.state.audits}
        />
      </Container>
    );
  }
}

export default withRouter(ProcessParticipants);
