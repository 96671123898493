import React, { useRef, useState, useEffect } from "react";
import UserService from "../../services/UserService";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { Cropper } from "react-cropper";
import FAButtonAndTooltip from "./buttons/FAButtonAndTooltip";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./user-profile-picture-form.css";
import "cropperjs/dist/cropper.css";
import { FormattedMessage } from "react-intl";
const UserChangeProfilePictureForm = ({
  email,
  updateProfilePicture,
  handleClose,
  showSuccessNotification,
  showErrorNotification,
}) => {
  const [profilePicture, setProfilePicture] = useState();
  const [croppedPicture, setCroppedPicture] = useState();
  const [viewMode, setViewMode] = useState(true);

  const cropperRef = useRef(null);

  useEffect(() => {
    UserService.getUserProfilePictureUrlByEmail(email, setProfilePicture);
  }, [email]);

  const handleUpload = () =>
    document.getElementById("profile-picture-input").click();

  const handleRemove = () => {
    setProfilePicture(undefined);
    setCroppedPicture(undefined);
    setViewMode(true);
  };

  const onPictureChange = (event) => {
    event.preventDefault();
    let files;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result.startsWith("data:image")) {
        setViewMode(false);
        setProfilePicture(reader.result);
      } else {
        showErrorNotification("The selected file is not a supported image.");
        handleClose();
      }
    };
    reader.readAsDataURL(files[0]);
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedPicture(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
  };

  const uploadPicture = (event) => {
    event.preventDefault();
    UserService.attemptUpdatingCurrentUserProfilePicture(croppedPicture).then(
      (response) => {
        if (response.ok) {
          response.text().then((response) => {
            UserService.getUserProfilePictureUrlByEmail(
              email,
              updateProfilePicture
            );
            showSuccessNotification(response);
            handleClose();
          });
        } else {
          response.json().then((error) => {
            showErrorNotification(error.message);
            handleClose();
          });
        }
      }
    );
  };

  return (
    <Form className="profile-picture-form" onSubmit={uploadPicture}>
      <Modal.Header closeButton />
      <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
        <Modal.Body>
          <Modal.Title>
            <FormattedMessage id="User.changeProfilePic" />
          </Modal.Title>
          <hr />
          <Row>
            <Col>
              {viewMode ? (
                <Image className="profile-picture" src={profilePicture} />
              ) : (
                <>
                  <Cropper
                    className="profile-picture"
                    src={profilePicture}
                    initialAspectRatio={1}
                    aspectRatio={1}
                    guides={false}
                    crop={onCrop}
                    ref={cropperRef}
                  />
                </>
              )}
            </Col>
          </Row>
          <Form.File id="profile-picture-input" onChange={onPictureChange} />
          <Form.Group as={Row}>
            <Col>
              <FAButtonAndTooltip
                id="upload-picture"
                icon={faImage}
                buttonSize="2x"
                tooltipMessage={
                  <FormattedMessage id="User.uploadNewProfilePic" />
                }
                onClick={handleUpload}
                className="upload-picture"
              />
              <div className="space-between" />
              <FAButtonAndTooltip
                id="remove-picture"
                icon={faTimes}
                disabled={!profilePicture}
                buttonSize="2x"
                tooltipMessage={<FormattedMessage id="User.removeProfilePic" />}
                onClick={handleRemove}
                className="remove-picture"
              />
            </Col>
          </Form.Group>
        </Modal.Body>
      </Modal.Dialog>
      <Form.Group style={{ width: "95%", textAlign: "right" }}>
        <Button variant="secondary" className="mr-1" onClick={handleClose}>
          <FormattedMessage id="EditForm.cancel" />
        </Button>
        <Button type="submit" variant="primary">
          <FormattedMessage id="EditForm.submit" />
        </Button>
      </Form.Group>
    </Form>
  );
};

export default UserChangeProfilePictureForm;
