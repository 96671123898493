import React from "react";
import { Form, Button } from "react-bootstrap";

import "../common/filter.css";
import { injectIntl, FormattedMessage } from "react-intl";

const { Control } = Form;

class OverviewFilter extends React.Component {
  state = {
    name: "",
    status: "All statuses",
    managerDraft: "Manager any",
    employeeDraft: "Employee any",
    manager: "All managers",
    performanceReview: this.props.performanceReviews[0],
    resetAvailable: true,
  };

  handleChange = (event) =>
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => this.handleFilter()
    );

  handleReset = () => {
    this.setState({
      name: "",
      status: "All statuses",
      managerDraft: "Manager any",
      employeeDraft: "Employee any",
      manager: "All managers",
      performanceReview: this.props.performanceReviews[0],
      resetAvailable: false,
    });
    this.props.handleSubmit(
      "",
      "All statuses",
      "Manager any",
      "Employee any",
      "All managers",
      this.props.performanceReviews[0],
      true
    );
  };

  handleFilter = () => {
    this.setState({
      resetAvailable: true,
    });
    this.props.handleSubmit(
      this.state.name,
      this.state.status,
      this.state.managerDraft,
      this.state.employeeDraft,
      this.state.manager,
      this.state.performanceReview
    );
  };

  render() {
    return (
      <div className="filter">
        <Control
          type="text"
          name="name"
          placeholder={this.props.intl.formatMessage({
            id: "UsersTable.name",
          })}
          size="sm"
          className="filter-item"
          value={this.state.name}
          onChange={this.handleChange}
        />
        {this.props.managers.length > 0 && (
          <Control
            as="select"
            name="manager"
            size="sm"
            className="filter-item"
            value={this.state.manager}
            onChange={this.handleChange}
          >
            <FormattedMessage id="Users.anyManager">
              {(message) => (
                <option key="All" value="All managers">
                  {message}
                </option>
              )}
            </FormattedMessage>
            {/* <option key="All">All managers</option> */}
            {this.props.managers.map((u) => (
              <option
                key={u.id}
                value={u.id}
              >{`${u.firstName} ${u.lastName}`}</option>
            ))}
          </Control>
        )}
        <Button
          variant="outline-secondary"
          size="sm"
          className="filter-reset"
          onClick={this.handleReset}
          disabled={!this.state.resetAvailable}
        >
          Reset
        </Button>
        {/*  <Control
          as="select"
          name="performanceReview"
          size="sm"
          className="filter-item"
          value={this.state.performanceReview.id}
          onChange={this.handleChange}
        >
          {this.props.performanceReviews.map((pr) => (
            <option key={pr.id} value={pr.id}>
              {pr.title}
            </option>
          ))}
        </Control> */}
        {/*  <ButtonGroup
          toggle
          size="sm"
          className="filter-item"
          onChange={this.handleChange}
        >
          <ToggleButton
            type="radio"
            variant="secondary"
            name="status"
            value="All statuses"
            checked={"All statuses" === this.state.status}
          >
            Any
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="secondary"
            name="status"
            value={APPRAISAL_STATUS_ACTIVE}
            checked={APPRAISAL_STATUS_ACTIVE === this.state.status}
          >
            <FormattedMessage id={`Status.${APPRAISAL_STATUS_ACTIVE}`} />
            <Badge variant="light">{this.props.activeAppraisals}</Badge>
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="secondary"
            name="status"
            value={APPRAISAL_STATUS_REVIEW}
            checked={APPRAISAL_STATUS_REVIEW === this.state.status}
          >
            <FormattedMessage id={`Status.${APPRAISAL_STATUS_REVIEW}`} />

            <Badge variant="light">{this.props.reviewAppraisals}</Badge>
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="secondary"
            name="status"
            value={APPRAISAL_STATUS_CLOSED}
            checked={APPRAISAL_STATUS_CLOSED === this.state.status}
          >
            <FormattedMessage id={`Status.${APPRAISAL_STATUS_CLOSED}`} />

            <Badge variant="light">{this.props.closedAppraisals}</Badge>
          </ToggleButton>
        </ButtonGroup>
        <ButtonGroup
          toggle
          size="sm"
          className="filter-item"
          onChange={this.handleChange}
        >
          <ToggleButton
            type="radio"
            variant="secondary"
            name="managerDraft"
            value="Manager any"
            checked={"Manager any" === this.state.managerDraft}
          >
            Any
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="secondary"
            name="managerDraft"
            value="Manager not draft"
            checked={"Manager not draft" === this.state.managerDraft}
          >
            {`Manager ready `}
            <Badge variant="light">
              {this.props.managerNotDraftAppraisals}
            </Badge>
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="secondary"
            name="managerDraft"
            value="Manager draft"
            checked={"Manager draft" === this.state.managerDraft}
          >
            {`Manager not ready `}
            <Badge variant="light">{this.props.managerDraftAppraisals}</Badge>
          </ToggleButton>
        </ButtonGroup>
        <ButtonGroup
          toggle
          size="sm"
          className="filter-item"
          onChange={this.handleChange}
        >
          <ToggleButton
            type="radio"
            variant="secondary"
            name="employeeDraft"
            value="Employee any"
            checked={"Employee any" === this.state.employeeDraft}
          >
            Any
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="secondary"
            name="employeeDraft"
            value="Employee not draft"
            checked={"Employee not draft" === this.state.employeeDraft}
          >
            {`Employee ready `}
            <Badge variant="light">
              {this.props.employeeNotDraftAppraisals}
            </Badge>
          </ToggleButton>
          <ToggleButton
            type="radio"
            variant="secondary"
            name="employeeDraft"
            value="Employee draft"
            checked={"Employee draft" === this.state.employeeDraft}
          >
            {`Employee not ready `}
            <Badge variant="light">{this.props.employeeDraftAppraisals}</Badge>
          </ToggleButton>
        </ButtonGroup>
        <Button
          variant="outline-secondary"
          size="sm"
          className="filter-reset"
          onClick={this.handleReset}
          disabled={!this.state.resetAvailable}
        >
          Reset
        </Button> */}
      </div>
    );
  }
}

export default injectIntl(OverviewFilter);
