import React, { useEffect, useState } from "react";
import AuthenticationService from "../../../services/AuthenticationService";
import RoleService from "../../../services/RoleService";
import ROLE_MODES, {
  ROLE_CREATE_MODE,
  ROLE_DELETE_MODE,
  ROLE_EDIT_MODE,
  ROLE_SHOW_MEMBERS,
} from "../../../constants/roleModes";
import {
  CONFLICT_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../../constants/errorStatusCodes";
import Loading from "../../common/display/Loading";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastr";
import Roles from "./Roles";
import RoleModal from "./RoleModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FormattedMessage } from "react-intl";

let toastContainer;

const RolesContainer = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    isLoading && fetchResources().then(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const fetchResources = () =>
    AuthenticationService.getRoles().then((fetchedRoles) => {
      fetchedRoles.forEach(addActions);

      setRoles(fetchedRoles);
    });

  const addActions = (role) =>
    Object.assign(role, {
      buttons: [
        {
          icon: faEdit,
          label: <FormattedMessage id="Button.edit" />,
          action: () => handleOpenModal(ROLE_EDIT_MODE, role),
        },
        {
          icon: faUsers,
          label: <FormattedMessage id="Button.members" />,
          action: () => handleOpenModal(ROLE_SHOW_MEMBERS, role),
        },
        {
          icon: faTrashAlt,
          label: <FormattedMessage id="Button.delete" />,
          action: () => handleOpenModal(ROLE_DELETE_MODE, role),
        },
      ],
    });

  const handleOpenModal = (mode, role = undefined) => {
    if (ROLE_MODES.includes(mode)) {
      setMode(mode);
      setSelectedRole(role);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setSelectedRole();
    setMode();
    setShowModal(false);
  };

  const addRole = () => handleOpenModal(ROLE_CREATE_MODE);

  const handleSubmit = async (role, selectedUsers) => {
    if (mode === ROLE_CREATE_MODE || mode === ROLE_EDIT_MODE) {
      await AuthenticationService.attemptSavingRole(role).then((response) => {
        if (response.ok) {
          toastContainer.success("Role saved successfully.", "", {
            closeButton: true,
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          toastContainer.error(
            "You don't have rights to modify or add Roles.",
            "",
            { closeButton: true }
          );
        }
      });
    } else if (mode === ROLE_DELETE_MODE) {
      await AuthenticationService.attemptDeletingRoleById(role.id).then(
        (response) => {
          if (response.ok) {
            toastContainer.success("Role deleted successfully.", "", {
              closeButton: true,
            });
          } else if (response.status === FORBIDDEN_ERROR_CODE) {
            toastContainer.error("You don't have rights to delete Roles.", "", {
              closeButton: true,
            });
          } else if (response.status === CONFLICT_ERROR_CODE) {
            toastContainer.error(
              "There are members assigned to this Role. Firstly, you have to unassign them.",
              "",
              { closeButton: true }
            );
          }
        }
      );
    } else if (mode === ROLE_SHOW_MEMBERS) {
      await RoleService.updateMembers(role.id, selectedUsers).then(
        (response) => {
          if (response.ok) {
            toastContainer.success("Role updated successfully.", "", {
              closeButton: true,
            });
          }
        }
      );
    }
    fetchResources();
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <ToastContainer
        ref={(ref) => (toastContainer = ref)}
        className="toast-top-right"
      />
      <Row style={{ display: "flex", alignItems: "center" }}>
        {roles.length > 0 ? (
          <Breadcrumb>
            <Breadcrumb.Item active>
              <FormattedMessage id="Roles.roles" />
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          <Col>
            <header className="text-center">
              <h4>
                {" "}
                <FormattedMessage id="Roles.noRolesFound" />
              </h4>
            </header>
          </Col>
        )}
      </Row>
      <Row style={{ width: "100%", alignSelf: "center", marginBottom: "2rem" }}>
        <Col>
          {addRole && (
            <Button
              className="float-right"
              onClick={addRole}
              variant="link"
              style={{ fontSize: "1.1rem", justifyContent: "right" }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              {<FormattedMessage id="Button.add" />}
            </Button>
          )}
        </Col>
      </Row>
      <div className="mt-3">
        <Row>
          <Roles roles={roles} />
        </Row>
      </div>
      <RoleModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        mode={mode}
        role={selectedRole}
      />
    </Container>
  );
};

export default RolesContainer;
