import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

const NoModalBtn = ({ variant = "secondary", onClick }) => (
  <Button
    variant={variant}
    className="ml-3"
    style={{ width: "85px" }}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faTimes} size="lg" className="mr-2" />
    <FormattedMessage id="Button.no" />
  </Button>
);

export default NoModalBtn;
