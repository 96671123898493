import React, { useState, useEffect } from "react";

import { MANAGER_GRADES_DICTIONARY_NUM } from "../../constants/managerFeedbackGradeDictionary";
import OneOnOneFeedbackQuestionsService from "../../services/OneOnOneFeedbackQuestionsService";
import Loading from "../common/display/Loading";
import OneOnOneFeedbackQuestionRow from "./OneOnOneFeedbackQuestionRow";

const OneOnOneFeedbackView = ({
  type,
  oneOnOne,
  oneonOneFeedback,
  setOneOnOneFeedback,
  setTotalMandatoryQuestions,
  viewOnly,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [oneOnOneFeedbackQuestions, setOneOnOneFeedbackQuestions] = useState(
    []
  );
  // eslint-disable-next-line no-unused-vars
  const [sortBy, setSortBy] = useState("id");
  // eslint-disable-next-line no-unused-vars
  const [sortDir, setSortDir] = useState("asc");
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(50);

  const updateFeedback = (questionId, score, mandatory) => {
    const local = oneonOneFeedback;
    const localIndex = local
      .map((element) => element.questionId)
      .indexOf(questionId);
    if (localIndex !== -1) {
      local.splice(localIndex, 1);
    }
    setOneOnOneFeedback([
      ...local,
      {
        questionId: questionId,
        answer: score,
        mandatory: mandatory,
      },
    ]);
  };

  const fetchQuestions = (page, sortBy, sortDir, pageSize, type, active) => {
    Promise.all([
      OneOnOneFeedbackQuestionsService.searchQuestions(
        page,
        sortBy,
        sortDir,
        pageSize,
        type,
        undefined,
        undefined,
        active
      ),
    ])
      .then(([oneOnOneFeedbackQuestions]) => {
        setOneOnOneFeedbackQuestions(
          oneOnOneFeedbackQuestions?.oneOnOneFeedbackQuestions
        );
        setTotalMandatoryQuestions(
          oneOnOneFeedbackQuestions?.oneOnOneFeedbackQuestions?.filter(
            (el) => el.mandatory === true
          )?.length
        );
      })
      .catch(() => setOneOnOneFeedbackQuestions([]));
  };

  useEffect(() => {
    if (isLoading) {
      Promise.all([
        fetchQuestions(0, sortBy, sortDir, pageSize, type, true, true),
      ]).then(() => setIsLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortDir, pageSize, type, isLoading]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {oneOnOneFeedbackQuestions.length > 0 &&
        oneOnOneFeedbackQuestions.map((question) => (
          <OneOnOneFeedbackQuestionRow
            key={question.id}
            feedback={oneOnOne?.oneOnOneFeedbacks?.find(
              (fb) => fb.oneOnOneFeedbackQuestion.id === question.id
            )}
            question={question}
            setOneOnOneFeedback={updateFeedback}
            grades={Object.keys(MANAGER_GRADES_DICTIONARY_NUM).map((key) => {
              return {
                id: key,
                title: MANAGER_GRADES_DICTIONARY_NUM[key].toString(),
              };
            })}
            viewOnly={viewOnly}
          />
        ))}
    </>
  );
};

export default OneOnOneFeedbackView;
