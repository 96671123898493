import React, { useEffect, useState } from "react";
import TeamService from "../../../services/TeamService";
import TEAM_MODES, {
  TEAM_CREATE_MODE,
  TEAM_DELETE_MODE,
  TEAM_EDIT_MODE,
  TEAM_SHOW_MEMBERS,
} from "../../../constants/teamModes";
import {
  CONFLICT_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
} from "../../../constants/errorStatusCodes";
import Loading from "../../common/display/Loading";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastr";
import Teams from "./Teams";
import TeamModal from "./TeamModal";
import {
  faEdit,
  faPlusCircle,
  faTrashAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
let toastContainer;

const TeamsContainer = () => {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    isLoading && fetchResources().then(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const fetchResources = () =>
    TeamService.getTeams().then((fetchedTeams) => {
      fetchedTeams.forEach(addActions);

      setTeams(fetchedTeams);
    });

  const addActions = (team) =>
    Object.assign(team, {
      buttons: [
        {
          icon: faEdit,
          label: <FormattedMessage id="Button.edit" />,
          action: () => handleOpenModal(TEAM_EDIT_MODE, team),
        },
        {
          icon: faUsers,
          label: <FormattedMessage id="Button.members" />,
          action: () => handleOpenModal(TEAM_SHOW_MEMBERS, team),
        },
        {
          icon: faTrashAlt,
          label: <FormattedMessage id="Button.delete" />,
          action: () => handleOpenModal(TEAM_DELETE_MODE, team),
        },
      ],
    });

  const handleOpenModal = (mode, team = undefined) => {
    if (TEAM_MODES.includes(mode)) {
      setMode(mode);
      setSelectedTeam(team);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setSelectedTeam();
    setMode();
    setShowModal(false);
  };

  const addTeam = () => handleOpenModal(TEAM_CREATE_MODE);

  const handleSubmit = async (team, selectedUsers) => {
    if (mode === TEAM_CREATE_MODE || mode === TEAM_EDIT_MODE) {
      await TeamService.attemptSavingTeam(team).then((response) => {
        if (response.ok) {
          toastContainer.success("Team saved successfully.", "", {
            closeButton: true,
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          toastContainer.error(
            "You don't have rights to modify or add Teams.",
            "",
            { closeButton: true }
          );
        }
      });
    } else if (mode === TEAM_DELETE_MODE) {
      await TeamService.attemptDeletingTeamById(team).then((response) => {
        if (response.ok) {
          toastContainer.success("Team deleted successfully.", "", {
            closeButton: true,
          });
        } else if (response.status === FORBIDDEN_ERROR_CODE) {
          toastContainer.error("You don't have rights to delete Teams.", "", {
            closeButton: true,
          });
        } else if (response.status === CONFLICT_ERROR_CODE) {
          toastContainer.error(
            "There are members assigned to this Team. Firstly, you have to unassign them.",
            "",
            { closeButton: true }
          );
        }
      });
    } else if (mode === TEAM_SHOW_MEMBERS) {
      await TeamService.updateMembers(team.id, selectedUsers).then(
        (response) => {
          if (response.ok) {
            toastContainer.success("Team updated successfully.", "", {
              closeButton: true,
            });
          }
        }
      );
    }
    fetchResources();
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <ToastContainer
        ref={(ref) => (toastContainer = ref)}
        className="toast-top-right"
      />
      <Row>
        {teams.length > 0 ? (
          <Breadcrumb>
            <Breadcrumb.Item active>
              <FormattedMessage id="Teams.teams" />
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : (
          <Col>
            <header className="text-center">
              <h4>
                <FormattedMessage id="Teams.noTeamsFound" />
              </h4>
            </header>
          </Col>
        )}
      </Row>
      <Row
        style={{
          width: "100%",
          alignSelf: "center",
          marginBottom: "2rem",
          marginLeft: 0,
        }}
      >
        <Col>
          {addTeam && (
            <Button
              className="float-right"
              onClick={addTeam}
              variant="link"
              style={{ fontSize: "1.1rem", justifyContent: "right" }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              {<FormattedMessage id="Button.add" />}
            </Button>
          )}
        </Col>
      </Row>

      <Teams teams={teams} />

      <TeamModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit}
        mode={mode}
        team={selectedTeam}
      />
    </Container>
  );
};

export default TeamsContainer;
