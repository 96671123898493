import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useLocale from "../../i18n/LocaleProvider";
import UserService from "../../services/UserService";
import ManagerService from "../../services/ManagerService";
import {
  Image,
  Nav,
  Navbar as NavbarRB,
  NavDropdown,
  NavItem,
  Row,
} from "react-bootstrap";
import AuthenticationService from "../../services/AuthenticationService";
import {
  PERMISSION_ASSESS_EMPLOYEES,
  PERMISSION_MANAGE_USERS,
  PERMISSION_MANAGER_TRAININGS,
  PERMISSION_MANAGE_ORGANIZATIONAL_GOALS,
  PERMISSION_MANAGE_GOAL_TEMPLATES,
  PERMISSION_MANAGE_ROLES,
  PERMISSION_MANAGE_PROCESS,
  PERMISSION_MANAGE_TEAMS,
  PERMISSION_VIEW_AUDIT,
  PERMISSION_OVERVIEW,
  MANAGER_FEEDBACK_VIEW_ALL,
  MANAGE_ONE_ON_ONE_FEEDBACK_QUESTIONS,
  PERMISSION_TRAINING_STATUS_MANAGER,
  PERMISSION_TRAINING_REPORTING,
} from "../../constants/permissions";
import { HR } from "../../constants/roles";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import SideBarProfileMenu from "./SideBarProfileMenu";
import Icon, { ICONS } from "../../icons/Icon";
import AdminUsersIcon from "../../icons/AdminUsersIcon";
import AdminRolesIcon from "../../icons/AdminRolesIcon";
import AdminTeamsIcon from "../../icons/AdminTeamsIcon";
import OneOnOneMenuIcon from "../../icons/OneOnOneMenuIcon";
import "./navbar.css";

const URL_SUFFIXES = {
  MY_GOALS: "/my-goals",
  APPRAISALS: "/appraisals",
  MANAGER: "/manager",
  MANAGER_TEAM_TRAININGS: "/performance-review/team-trainings",
  ORGANIZATIONAL_GOALS_TEMPLATES: "/organizational-goals/templates",
  GOALS_TEMPLATES: "/goals/templates",
  EMAIL_TEMPLATES: "/email/templates",
  REVIEWS: "/reviews",
  AUDIT: "/audit",
  OVERVIEW: "/overview",
  MANAGER_FEEDBACK: "/feedback",
  ONE_ON_ONE: "/oneOnOnes",
  ADMIN: {
    USERS: "/admin/users",
    ROLES: "/admin/roles",
    TEAMS: "/admin/teams",
    ONE_ON_ONE_QUESTIONS: "/admin/one-on-ones/feedback-questions",
  },
  TRAINING: {
    REPORTING: "/training/reporting",
    MY_TRAINING: "/training/my-training",
    STATUS_MANAGER: "/training/status-manager",
  },
};

const Navbar = ({ user, runAsMeAvailable }) => {
  const location = useLocation();
  const history = useHistory();
  const { supportedLocales, setLocale } = useLocale();
  const intl = useIntl();

  const [userProfilePicture, setUserProfilePicture] = useState();
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    if (user) {
      UserService.getUserProfilePictureUrlByEmail(
        user.email,
        setUserProfilePicture
      );
      if (
        AuthenticationService.hasPermission(
          user,
          PERMISSION_ASSESS_EMPLOYEES
        ) &&
        AuthenticationService.hasPermission(user, PERMISSION_OVERVIEW)
      )
        ManagerService.getManagersOverview(user.id, true).then((managers) =>
          setManagers(managers)
        );
    }
  }, [user]);

  const isSelected = (suffix) => location.pathname.includes(suffix);

  const handleSelect = (suffix) => {
    if (
      history.location.pathname === URL_SUFFIXES.OVERVIEW &&
      suffix === URL_SUFFIXES.OVERVIEW
    ) {
      window.location.reload();
    } else {
      history.push(suffix);
    }
  };

  return (
    <NavbarRB as={Row} variant="light" expand="sm" collapseOnSelect>
      <NavbarRB.Brand onClick={() => handleSelect("/")}>
        <Image src="/images/logo.png" alt="Grow" />
      </NavbarRB.Brand>
      <NavbarRB.Toggle />
      <NavbarRB.Collapse>
        <Nav>
          {(AuthenticationService.hasPermission(
            user,
            PERMISSION_ASSESS_EMPLOYEES
          ) ||
            AuthenticationService.hasRole(user, HR)) && (
            <Nav.Link
              className={
                isSelected(URL_SUFFIXES.MY_GOALS) ||
                isSelected(URL_SUFFIXES.APPRAISALS) ||
                isSelected(URL_SUFFIXES.MANAGER) ||
                isSelected(URL_SUFFIXES.ONE_ON_ONE) ||
                isSelected(URL_SUFFIXES.TRAINING.MY_TRAINING)
                  ? "selected"
                  : ""
              }
              onClick={() => handleSelect(URL_SUFFIXES.MY_GOALS)}
            >
              <FormattedMessage id="Navbar.menu.profile" />
            </Nav.Link>
          )}
          {(AuthenticationService.hasPermission(
            user,
            PERMISSION_MANAGE_PROCESS
          ) ||
            AuthenticationService.hasPermission(
              user,
              MANAGE_ONE_ON_ONE_FEEDBACK_QUESTIONS
            ) ||
            AuthenticationService.hasPermission(
              user,
              MANAGER_FEEDBACK_VIEW_ALL
            )) && (
            <NavDropdown
              className={
                isSelected(URL_SUFFIXES.REVIEWS) ||
                isSelected(URL_SUFFIXES.ADMIN.ONE_ON_ONE_QUESTIONS) ||
                isSelected(URL_SUFFIXES.MANAGER_FEEDBACK) ||
                location.state?.participants
                  ? "selected"
                  : ""
              }
              // className="dropdown ml-auto"

              title={
                <>
                  {intl.formatMessage({
                    id: "Navbar.menu.processes",
                  })}
                </>
              }
            >
              {AuthenticationService.hasPermission(
                user,
                PERMISSION_MANAGE_PROCESS
              ) && (
                <NavDropdown.Item
                  className={
                    isSelected(URL_SUFFIXES.REVIEWS) ||
                    location.state?.participants
                      ? "selected"
                      : ""
                  }
                  onClick={() => handleSelect(URL_SUFFIXES.REVIEWS)}
                >
                  <Icon
                    icon={ICONS.PERFORMANCE}
                    style={{ marginRight: "4%" }}
                  />
                  <FormattedMessage id="Navbar.menu.reviews" />
                </NavDropdown.Item>
              )}
              {AuthenticationService.hasPermission(
                user,
                MANAGER_FEEDBACK_VIEW_ALL
              ) && (
                <NavDropdown.Item
                  onClick={() => handleSelect(URL_SUFFIXES.MANAGER_FEEDBACK)}
                >
                  <Icon icon={ICONS.MF_ADMIN} style={{ marginRight: "4%" }} />
                  <FormattedMessage id="Navbar.menu.manager.feedback" />
                </NavDropdown.Item>
              )}
              {AuthenticationService.hasPermission(
                user,
                MANAGE_ONE_ON_ONE_FEEDBACK_QUESTIONS
              ) && (
                <NavDropdown.Item
                  className={
                    isSelected(URL_SUFFIXES.ADMIN.ONE_ON_ONE_QUESTIONS)
                      ? "selected"
                      : ""
                  }
                  onClick={() =>
                    handleSelect(URL_SUFFIXES.ADMIN.ONE_ON_ONE_QUESTIONS)
                  }
                >
                  <OneOnOneMenuIcon height="16px" width="16px" />
                  <FormattedMessage id="Navbar.menu.questions.oneOnOne" />
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}

          {AuthenticationService.hasPermission(
            user,
            PERMISSION_ASSESS_EMPLOYEES
          ) &&
            AuthenticationService.hasPermission(user, PERMISSION_OVERVIEW) &&
            managers.length > 0 && (
              <Nav.Link
                className={isSelected(URL_SUFFIXES.OVERVIEW) ? "selected" : ""}
                onClick={() => handleSelect(URL_SUFFIXES.OVERVIEW)}
              >
                <FormattedMessage id="Navbar.menu.overview" />
              </Nav.Link>
            )}
          {(AuthenticationService.hasPermission(
            user,
            PERMISSION_MANAGE_USERS
          ) ||
            AuthenticationService.hasPermission(
              user,
              PERMISSION_MANAGE_ROLES
            ) ||
            AuthenticationService.hasPermission(
              user,
              PERMISSION_MANAGE_TEAMS
            ) ||
            AuthenticationService.hasPermission(user, PERMISSION_VIEW_AUDIT) ||
            AuthenticationService.hasPermission(
              user,
              PERMISSION_MANAGE_ORGANIZATIONAL_GOALS
            ) ||
            AuthenticationService.hasPermission(
              user,
              PERMISSION_MANAGE_GOAL_TEMPLATES
            )) && (
            <NavDropdown
              title={intl.formatMessage({ id: "Navbar.menu.admin" })}
              className={
                isSelected(URL_SUFFIXES.ADMIN.USERS) ||
                isSelected(URL_SUFFIXES.ADMIN.ROLES) ||
                isSelected(URL_SUFFIXES.ADMIN.TEAMS) ||
                isSelected(URL_SUFFIXES.AUDIT) ||
                isSelected(URL_SUFFIXES.ORGANIZATIONAL_GOALS_TEMPLATES) ||
                isSelected(URL_SUFFIXES.GOALS_TEMPLATES)
                  ? "selected"
                  : ""
              }
            >
              {AuthenticationService.hasPermission(
                user,
                PERMISSION_MANAGE_USERS
              ) && (
                <NavDropdown.Item
                  onClick={() => handleSelect(URL_SUFFIXES.ADMIN.USERS)}
                >
                  <AdminUsersIcon height="16px" width="16px" />
                  <FormattedMessage id="Navbar.menu.users" />
                </NavDropdown.Item>
              )}
              {AuthenticationService.hasPermission(
                user,
                PERMISSION_MANAGE_ROLES
              ) && (
                <NavDropdown.Item
                  onClick={() => handleSelect(URL_SUFFIXES.ADMIN.ROLES)}
                >
                  <AdminRolesIcon height="16px" width="16px" />
                  <FormattedMessage id="Navbar.menu.roles" />
                </NavDropdown.Item>
              )}
              {AuthenticationService.hasPermission(
                user,
                PERMISSION_MANAGE_TEAMS
              ) && (
                <NavDropdown.Item
                  onClick={() => handleSelect(URL_SUFFIXES.ADMIN.TEAMS)}
                >
                  <AdminTeamsIcon height="16px" width="16px" />
                  <FormattedMessage id="Navbar.menu.teams" />
                </NavDropdown.Item>
              )}
              {AuthenticationService.hasPermission(
                user,
                PERMISSION_VIEW_AUDIT
              ) && (
                <NavDropdown.Item
                  onClick={() => handleSelect(URL_SUFFIXES.AUDIT)}
                >
                  <Icon icon={ICONS.AUDIT} style={{ marginRight: "2%" }} />
                  <FormattedMessage id="Navbar.menu.audits" />
                </NavDropdown.Item>
              )}
              {(AuthenticationService.hasPermission(
                user,
                PERMISSION_MANAGE_ORGANIZATIONAL_GOALS
              ) ||
                AuthenticationService.hasPermission(
                  user,
                  PERMISSION_MANAGE_GOAL_TEMPLATES
                )) && (
                <>
                  <NavDropdown
                    as={NavItem}
                    className="dropdown ml-auto"
                    style={{ padding: "0.25rem 1rem" }}
                    drop="right"
                    title={
                      <>
                        <Icon
                          icon={ICONS.TEMPLATES_ADMIN}
                          style={{ marginRight: "2%" }}
                        />
                        {intl.formatMessage({
                          id: "Navbar.menu.templates",
                        })}
                      </>
                    }
                  >
                    {AuthenticationService.hasPermission(
                      user,
                      PERMISSION_MANAGE_ORGANIZATIONAL_GOALS
                    ) && (
                      <NavDropdown.Item
                        className={
                          isSelected(
                            URL_SUFFIXES.ORGANIZATIONAL_GOALS_TEMPLATES
                          )
                            ? "selected"
                            : ""
                        }
                        onClick={() =>
                          handleSelect(
                            URL_SUFFIXES.ORGANIZATIONAL_GOALS_TEMPLATES
                          )
                        }
                      >
                        <Icon
                          icon={ICONS.TEMPLATES_ORGANIZATIONAL}
                          style={{ marginRight: "2%" }}
                        />
                        <FormattedMessage id="Navbar.menu.organizationalGoals" />
                      </NavDropdown.Item>
                    )}
                    {AuthenticationService.hasPermission(
                      user,
                      PERMISSION_MANAGE_GOAL_TEMPLATES
                    ) && (
                      <NavDropdown.Item
                        onClick={() =>
                          handleSelect(URL_SUFFIXES.GOALS_TEMPLATES)
                        }
                      >
                        <Icon
                          icon={ICONS.TEMPLATES_GOAL}
                          style={{ marginRight: "2%" }}
                        />
                        <FormattedMessage id="Navbar.menu.goalsTemplates" />
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                </>
              )}
            </NavDropdown>
          )}
          {AuthenticationService.hasPermission(
            user,
            PERMISSION_MANAGER_TRAININGS
          ) && (
            <Nav.Link
              className={
                isSelected(URL_SUFFIXES.MANAGER_TEAM_TRAININGS)
                  ? "selected"
                  : ""
              }
              onClick={() => handleSelect(URL_SUFFIXES.MANAGER_TEAM_TRAININGS)}
            >
              <FormattedMessage id="Navbar.menu.manager.team.trainings" />
            </Nav.Link>
          )}
          {(AuthenticationService.hasPermission(
            user,
            PERMISSION_TRAINING_STATUS_MANAGER
          ) ||
            AuthenticationService.hasPermission(
              user,
              PERMISSION_TRAINING_REPORTING
            )) && (
            <NavDropdown
              className={
                isSelected(URL_SUFFIXES.TRAINING) ||
                isSelected(URL_SUFFIXES.TRAINING.REPORTING) ||
                isSelected(URL_SUFFIXES.TRAINING.STATUS_MANAGER)
                  ? "selected"
                  : ""
              }
              // className="dropdown ml-auto"

              title={
                <>
                  {intl.formatMessage({
                    id: "Navbar.menu.training",
                  })}
                </>
              }
            >
              {AuthenticationService.hasPermission(
                user,
                PERMISSION_TRAINING_REPORTING
              ) && (
                <NavDropdown.Item
                  className={
                    isSelected(URL_SUFFIXES.TRAINING.REPORTING) ||
                    isSelected(URL_SUFFIXES.TRAINING.STATUS_MANAGER) ||
                    isSelected(URL_SUFFIXES.TRAINING) ||
                    location.state?.participants
                      ? "selected"
                      : ""
                  }
                  onClick={() => handleSelect(URL_SUFFIXES.TRAINING.REPORTING)}
                >
                  <Icon icon={ICONS.REPORTING} style={{ marginRight: "4%" }} />
                  <FormattedMessage id="Navbar.menu.reporting" />
                </NavDropdown.Item>
              )}
              {AuthenticationService.hasPermission(
                user,
                PERMISSION_TRAINING_STATUS_MANAGER
              ) && (
                <NavDropdown.Item
                  onClick={() =>
                    handleSelect(URL_SUFFIXES.TRAINING.STATUS_MANAGER)
                  }
                >
                  <Icon
                    icon={ICONS.STATUS_MANAGER}
                    style={{
                      marginRight: "4%",
                    }}
                  />
                  <FormattedMessage id="Navbar.menu.statusManager" />
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
        </Nav>
      </NavbarRB.Collapse>
      <NavDropdown
        title={<FontAwesomeIcon icon={faLanguage} size="lg" className="mr-1" />}
        alignRight
      >
        {Object.entries(supportedLocales).map(([locale, { label }]) => (
          <NavDropdown.Item key={locale} onClick={() => setLocale(locale)}>
            {label}
          </NavDropdown.Item>
        ))}
      </NavDropdown>

      {userProfilePicture && (
        <NavDropdown
          className="user-profile"
          title={<Image src={userProfilePicture} height={30} roundedCircle />}
          onClick={() => setShowUserProfile(!showUserProfile)}
        />
      )}
      {showUserProfile && (
        <SideBarProfileMenu
          user={user}
          profilePicture={userProfilePicture}
          updateProfilePicture={setUserProfilePicture}
          closeUserProfile={() => setShowUserProfile(false)}
          runAsMeAvailable={runAsMeAvailable}
        />
      )}
    </NavbarRB>
  );
};

export default Navbar;
