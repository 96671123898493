import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import UserService from "../../services/UserService";
import { PROCESS_USER_ACTION_GOALS } from "../../constants/processUserAction";
import GoalTypeService from "../../services/GoalTypeService";
import { ORGANIZATIONAL_GOAL_TYPE_ID } from "../../constants/goalTypes";
import {
  GOAL_COPY_MODE,
  GOAL_CREATE_MODE,
  GOAL_DELETE_MODE,
  GOAL_MODES,
  GOAL_UPDATE_MODE,
} from "../../constants/goalModes";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import Loading from "../common/display/Loading";
import ErrorComponent from "../common/ErrorComponent";
import {
  BAD_REQUEST_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastr";
import Image from "../common/Image";
import Goals from "../goal/Goals";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { PERFORMANCE_REVIEW_GOAL_IN_PROGRESS } from "../../constants/performanceReviewGoalStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoalModal from "../goal/GoalModal";
import DefaultEditGoalsModal from "./DefaultEditGoalsModal";
import GoalService from "../../services/GoalService";
import { useIntl, FormattedMessage } from "react-intl";
let toastContainer;

const EditGoalsContainer = ({ user }) => {
  const { id } = useParams();
  const history = useHistory();
  const intl = useIntl();

  const [performanceReviewGoal, setPerformanceReviewGoal] = useState();
  const [isPerformanceReviewActive, setIsPerformanceReviewActive] =
    useState(false);
  const [goals, setGoals] = useState([]);
  const [goalTypes, setGoalTypes] = useState([]);
  const [mode, setMode] = useState();
  const [selectedGoal, setSelectedGoal] = useState();
  const [selectedGoalType, setSelectedGoalType] = useState();
  const [selectedGoalManager, setSelectedGoalManager] = useState();
  const [showGoalModal, setShowGoalModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [copyingGoals, setCopyingGoals] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  useEffect(() => {
    PerformanceReviewGoalService.getPerformanceReviewGoalById(id)
      .then((fetchedPerformanceReviewGoal) =>
        PerformanceReviewGoalService.getGoalsByPerformanceReviewGoalId(
          fetchedPerformanceReviewGoal.performanceReviewGoalId
        )
          .then((fetchedGoals) =>
            Promise.all([
              UserService.getProcessUsersByUserIdAndAction(
                fetchedPerformanceReviewGoal.targetEmployee.id,
                PROCESS_USER_ACTION_GOALS
              ),
              GoalTypeService.getAllGoalTypes(),
            ]).then(([processUsers, fetchedGoalTypes]) => {
              processUsers.shift();
              fetchedGoals.forEach((goal) => {
                goal.setBy =
                  fetchedPerformanceReviewGoal.targetEmployee.managerName;
                addActions(goal, fetchedPerformanceReviewGoal);
              });

              setIsPerformanceReviewActive(
                Date.now() >
                  new Date(
                    fetchedPerformanceReviewGoal.performanceReviewStart
                  ) &&
                  Date.now() <
                    new Date(fetchedPerformanceReviewGoal.performanceReviewEnd)
              );
              setPerformanceReviewGoal(fetchedPerformanceReviewGoal);
              setGoals(fetchedGoals);
              setGoalTypes(fetchedGoalTypes);
              setIsLoading(false);
              setTriggerUpdate(false);
            })
          )
          .catch(() => {
            setAccessDenied(true);
            setPerformanceReviewGoal(fetchedPerformanceReviewGoal);
            setIsLoading(false);
            setTriggerUpdate(false);
          })
      )
      .catch(() => {
        setNotFound(true);
        setIsLoading(false);
        setTriggerUpdate(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, triggerUpdate]);

  const submitPlaceholder = intl.formatMessage({
    id: "PerformanceReview.submit",
  });

  const editPlaceholder = intl.formatMessage({
    id: "PerformanceReview.editLater",
  });

  const deletePlaceholder = intl.formatMessage({
    id: "PerformanceReview.deleteConfirm",
  });

  const addActions = (goal, performanceReviewGoal) =>
    Object.assign(goal, {
      buttons:
        goal.goalTypeId === ORGANIZATIONAL_GOAL_TYPE_ID
          ? []
          : [
              performanceReviewGoal.performanceReviewGoalStatus ===
              PERFORMANCE_REVIEW_GOAL_IN_PROGRESS
                ? {
                    icon: faEdit,
                    label: <FormattedMessage id="Button.edit" />,
                    action: () =>
                      handleOpenGoalModal(
                        GOAL_UPDATE_MODE,
                        goal,
                        goalTypes.filter(
                          (type) => Number(type.id) === goal.goalTypeId
                        )
                      ),
                  }
                : {
                    icon: faEdit,
                    label: ` ${goal.active ? "Deactivate" : "Activate"}`,
                    action: () => handleUpdateActiveStatus(goal),
                  },
              {
                icon: faTrashAlt,
                label: <FormattedMessage id="Button.delete" />,
                action: () => handleOpenGoalModal(GOAL_DELETE_MODE, goal),
              },
            ],
    });

  const handleUpdateActiveStatus = (goal) =>
    GoalService.attemptUpdateGoalById(goal.id, { active: !goal.active }).then(
      (response) => {
        if (response.ok) {
          showSuccessNotification("Goal updated successfully.");
          setTriggerUpdate(true);
        } else {
          showErrorNotification("Goal cannot be updated.");
        }
      }
    );

  const handleOpenGoalModal = (goalMode, goalDefinition) => {
    if (GOAL_MODES.includes(goalMode)) {
      setMode(goalMode);

      switch (goalMode) {
        case GOAL_CREATE_MODE:
        case GOAL_COPY_MODE:
          setSelectedGoalType(goalDefinition);
          setSelectedGoalManager(user);
          break;
        case GOAL_UPDATE_MODE:
          setSelectedGoal(goalDefinition);
          setSelectedGoalType(goalDefinition.goalTypeId);
          setSelectedGoalManager({ id: goalDefinition.managerCreatorId });
          break;
        case GOAL_DELETE_MODE:
          setSelectedGoal(goalDefinition);
          break;
        default:
          break;
      }
      setShowGoalModal(true);
    }
  };

  const handleCloseModal = () => {
    setMode();
    setSelectedGoal();
    setSelectedGoalType();
    setSelectedGoalManager();
    setShowGoalModal(false);
    setShowSubmitModal(false);
    setShowDraftModal(false);
    setShowDiscardModal(false);
  };

  const handleSubmit = (goal) => {
    goal.performanceReviewId = performanceReviewGoal.performanceReviewId;
    if (mode === GOAL_DELETE_MODE) {
      GoalService.attemptDeletingGoalById(goal.id).then((response) => {
        if (response.ok) {
          showSuccessNotification("Goal is deleted successfully.");
          setTriggerUpdate(true);
        } else {
          showErrorNotification(
            "This goal is already assessed and cannot be deleted. You may disable it instead."
          );
        }
      });
    } else if (mode === GOAL_CREATE_MODE || mode === GOAL_UPDATE_MODE) {
      GoalService.attemptSavingGoal(goal).then((response) => {
        if (response.ok) {
          showSuccessNotification("Goal is saved successfully.");
          setTriggerUpdate(true);
        } else {
          response.json().then((error) => showErrorNotification(error.message));
        }
      });
    } else if (mode === GOAL_COPY_MODE) {
      setCopyingGoals(true);

      goal.forEach((g) => {
        g.id = null;
        g.createdAt = null;
        g.performanceReviewGoalId =
          performanceReviewGoal.performanceReviewGoalId;
        g.managerCreatorId = selectedGoalManager.id;
        g.performanceReviewId = performanceReviewGoal.performanceReviewId;
        g.active = true;
      });

      GoalService.attemptSavingGoals(goal).then((response) => {
        setCopyingGoals(false);
        handleCloseModal();

        if (response.ok) {
          showSuccessNotification("Goals are saved successfully.");
          setTriggerUpdate(true);
        } else {
          showErrorNotification("Something went wrong.");
        }
      });
    }
  };

  const handleCopyLastYearGoals = (performanceReviewGoal, goalType) =>
    GoalService.attemptCopyingGoalsBy(
      performanceReviewGoal.performanceReviewGoalId,
      goalType.id
    ).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          if (data.length > 0) {
            showSuccessNotification("Goals are copied successfully.");
            setTriggerUpdate(true);
          } else showErrorNotification("There are no goals.");
        });
      } else {
        showErrorNotification("Something went wrong.");
      }
    });

  const handleSubmitClick = () => setShowSubmitModal(true);

  const handleDraftClick = () => setShowDraftModal(true);

  const handleDiscardClick = () => setShowDiscardModal(true);

  const showSuccessNotification = (message) =>
    toastContainer.success(message, "", {
      closeButton: true,
    });

  const showErrorNotification = (message) =>
    toastContainer.error(message, "", {
      closeButton: true,
    });

  return isLoading ? (
    <Loading />
  ) : notFound ? (
    <ErrorComponent statusCode={NOT_FOUND_ERROR_CODE} user={user} />
  ) : (
    <>
      {user.id !== performanceReviewGoal.targetEmployee.managerId ||
      accessDenied ? (
        <ErrorComponent
          statusCode={FORBIDDEN_ERROR_CODE}
          user={user}
          message={
            accessDenied &&
            `The access to goals of ${performanceReviewGoal.targetEmployee.firstName} ${performanceReviewGoal.targetEmployee.lastName} is restricted`
          }
        />
      ) : (
        <>
          {isPerformanceReviewActive ? (
            <ConfigurationPropertiesContext.Consumer>
              {({ formatDate }) => (
                <Container style={{ width: "66%" }}>
                  <ToastContainer
                    ref={(ref) => (toastContainer = ref)}
                    className="toast-top-right"
                  />
                  <Row className="mb-4">
                    <Col>
                      <header className="text-center">
                        <h4>
                          <h4>
                            <FormattedMessage id="Goal.setting" />
                            {` ${performanceReviewGoal.targetEmployee.firstName}  ${performanceReviewGoal.targetEmployee.lastName}`}
                          </h4>
                        </h4>
                      </header>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col md={6}>
                      <Row>
                        <Col md={3}>
                          <Image
                            email={performanceReviewGoal.targetEmployee.email}
                            width={125}
                            height={125}
                            thumbnail
                          />
                        </Col>
                        <Col md={9}>
                          <h5>
                            {`${performanceReviewGoal.targetEmployee.firstName} ${performanceReviewGoal.targetEmployee.lastName}`}
                          </h5>
                          {/*  <h6>{performanceReviewGoal.targetEmployeeTitle}</h6> */}
                          <br />
                          <h6>
                            <FormattedMessage id="Reviews.period" />
                            {`: ${formatDate(
                              performanceReviewGoal.performanceReviewStart
                            )} - ${formatDate(
                              performanceReviewGoal.performanceReviewEnd
                            )}`}
                          </h6>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="text-right">
                      <h6>
                        <FormattedMessage id="Goal.setBy" />
                        {` ${performanceReviewGoal.targetEmployee.managerName}`}
                      </h6>
                      <h7>
                        <FormattedMessage id="Goal.on" />
                        {`: ${formatDate(performanceReviewGoal.createdAt)}`}
                      </h7>
                    </Col>
                  </Row>
                  {goalTypes.map((goalType, index) => (
                    <Goals
                      key={`goals-container-${index}`}
                      addGoalAction={
                        goalType.id !== ORGANIZATIONAL_GOAL_TYPE_ID
                          ? () =>
                              handleOpenGoalModal(GOAL_CREATE_MODE, goalType)
                          : undefined
                      }
                      copyGoalsAction={
                        goalType.id !== ORGANIZATIONAL_GOAL_TYPE_ID
                          ? () => handleOpenGoalModal(GOAL_COPY_MODE, goalType)
                          : undefined
                      }
                      copyLastYearGoalsAction={
                        goalType.id !== ORGANIZATIONAL_GOAL_TYPE_ID
                          ? () =>
                              handleCopyLastYearGoals(
                                performanceReviewGoal,
                                goalType
                              )
                          : undefined
                      }
                      goals={goals}
                      goalType={goalType}
                    />
                  ))}
                  {performanceReviewGoal.performanceReviewGoalStatus ===
                  PERFORMANCE_REVIEW_GOAL_IN_PROGRESS ? (
                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          className="float-right ml-2 mb-5"
                          onClick={handleSubmitClick}
                        >
                          <FormattedMessage id="EditUserForm.submit" />
                        </Button>
                        <Button
                          variant="secondary"
                          className="float-right ml-2 mb-5"
                          onClick={handleDraftClick}
                        >
                          <FormattedMessage id="EditForm.draft" />
                        </Button>
                        <Button
                          variant="outline-secondary"
                          className="float-right mb-5"
                          onClick={handleDiscardClick}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                          <FormattedMessage id="EditForm.discard" />
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <Button
                          variant="secondary"
                          className="float-right"
                          onClick={() => history.push("/manager")}
                        >
                          <FormattedMessage id="EditForm.close" />
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <GoalModal
                    showModal={showGoalModal}
                    handleClose={handleCloseModal}
                    handleSubmit={handleSubmit}
                    mode={mode}
                    goal={selectedGoal}
                    user={performanceReviewGoal.targetEmployee}
                    performanceReviewGoal={performanceReviewGoal}
                    manager={selectedGoalManager}
                    goalType={selectedGoalType}
                    setting={
                      performanceReviewGoal.performanceReviewGoalStatus ===
                      PERFORMANCE_REVIEW_GOAL_IN_PROGRESS
                    }
                    copyingGoals={copyingGoals}
                  />
                  <DefaultEditGoalsModal
                    id={id}
                    show={showSubmitModal}
                    handleClose={handleCloseModal}
                    type="submit"
                    message={submitPlaceholder}
                  />
                  <DefaultEditGoalsModal
                    show={showDraftModal}
                    handleClose={handleCloseModal}
                    type="draft"
                    message={editPlaceholder}
                  />
                  <DefaultEditGoalsModal
                    id={id}
                    show={showDiscardModal}
                    handleClose={handleCloseModal}
                    type="discard"
                    message={deletePlaceholder}
                  />
                </Container>
              )}
            </ConfigurationPropertiesContext.Consumer>
          ) : (
            <ErrorComponent statusCode={BAD_REQUEST_ERROR_CODE} user={user} />
          )}
        </>
      )}
    </>
  );
};

export default EditGoalsContainer;
