import React from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Badge,
  Breadcrumb,
} from "react-bootstrap";
import { ToastContainer } from "react-toastr";
import { injectIntl, FormattedMessage } from "react-intl";
import { faFileUpload, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import AdminService from "../../services/AdminService";
import ManagerService from "../../services/ManagerService";
import AuthenticationService from "../../services/AuthenticationService";
import UserService from "../../services/UserService";
import TeamService from "../../services/TeamService";
import {
  USER_CREATE_MODE,
  USER_DELETE_MODE,
  USER_EDIT_MODE,
} from "../../constants/userModes";
import {
  CONFLICT_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import { ACTIVE_STATUS, INACTIVE_STATUS } from "../../constants/activeStatuses";
import { PERMISSION_MANAGE_PROCESS_UPLOAD_USERS } from "../../constants/permissions";
import Loading from "../common/display/Loading";
import UserModal from "./UserModal";
import UserUploadFileModal from "./UserUploadFileModal";
import UsersTable from "./UsersTable";
import Filter from "../common/Filter";
import Pagination from "./Pagination";
import DebouncedSearchInput from "../common/form/DebouncedSearchInput";

const { Control } = Form;

const defaultFilterStats = {
  name: "",
  username: "",
  manager: "any",
  activeUsers: ACTIVE_STATUS,
  role: "any",
};
const defaultTabs = [
  {
    value: "any",
    title: <FormattedMessage id="AllUsers.tabs.any" />,
    count: 0,
  },
  {
    value: ACTIVE_STATUS,
    title: <FormattedMessage id="AllUsers.tabs.active" />,
    count: 0,
  },
  {
    value: INACTIVE_STATUS,
    title: <FormattedMessage id="AllUsers.tabs.inactive" />,
    count: 0,
  },
];

class AllUsers extends React.Component {
  state = {
    users: [],
    userFullName: "",
    filterStats: defaultFilterStats,
    tabs: defaultTabs,
    totalPages: 0,
    triggerUpdate: false,
    currentPage: 0,
    selectedUser: undefined,
    managers: [],
    managerId: "",
    roles: [],
    roleId: "",
    rolesResults: [],
    activeUsersCount: 0,
    inactiveUsersCount: 0,
    isActive: true,
    teams: [],
    teamId: "",
    mode: undefined,
    showModal: false,
    loading: true,
    showCsvFileModal: false,
    file: undefined,
    userUploadResult: {
      executionLogs: [],
      success: undefined,
    },
    activeUsersWithoutManager: 0,
  };

  toastContainer;

  componentDidMount() {
    this.getUsers();
    this.fetchResources();
    this.setState({
      loading: false,
    });
  }
  componentDidUpdate() {
    if (this.state.triggerUpdate) {
      this.setState({
        loading: true,
      });
      this.getUsers();
      this.setState({
        triggerUpdate: false,
        loading: false,
      });
    }
  }
  getUsers = () => {
    this.setState({
      loading: true,
    });
    AdminService.getUsers(
      this.state.currentPage,
      this.state.roleId,
      this.state.teamId,
      this.state.userFullName,
      this.state.managerId,
      this.state.isActive
    ).then((users) => {
      this.setState({
        loading: false,
        users: users.usersInfo,
        totalPages: users.pages,
        tabs: [
          {
            value: "any",
            title: <FormattedMessage id="AllUsers.tabs.any" />,
            count: users.anyUsersCount < 999 ? users.anyUsersCount : "1k+",
          },
          {
            value: ACTIVE_STATUS,
            title: <FormattedMessage id="AllUsers.tabs.active" />,
            count:
              users.activeUsersCount < 999 ? users.activeUsersCount : "1k+",
          },
          {
            value: INACTIVE_STATUS,
            title: <FormattedMessage id="AllUsers.tabs.inactive" />,
            count:
              users.inactiveUsersCount < 999 ? users.inactiveUsersCount : "1k+",
          },
        ],
      });
    });
  };

  fetchResources = () => {
    this.setState({
      loading: true,
    });
    Promise.all([
      ManagerService.getManagers(),
      AuthenticationService.getRoles(),
      TeamService.getTeams(),
      AdminService.getUsers(
        this.state.currentPage,
        this.state.roleId,
        this.state.teamId,
        this.state.userFullName,
        0,
        true
      ),
    ]).then(([managers, roles, teams, users]) => {
      this.setState({
        managers,
        roles,
        teams,
        loading: false,
        triggerUpdate: true,
        activeUsersWithoutManager: users.activeUsersCount,
      });
    });
  };

  handleUploadFile = async () => {
    const data = new FormData();
    data.append("file", this.state.file);

    await UserService.createUsersByCsvFile(data).then((response) => {
      if (response.ok) {
        response.json().then((result) => {
          this.setState({
            userUploadResult: {
              executionLogs: result.executionLogs,
              success: result.success,
            },
          });
        });
      } else {
        response
          .json()
          .then((error) => this.showErrorNotification(error.message));
      }
    });
  };

  handleDrop = (acceptedFiles) => {
    this.setState({
      file: acceptedFiles[0],
    });
  };

  removeFile = (event) => {
    event.stopPropagation();
    this.setState({
      file: undefined,
    });
  };

  getRolesResultsCount = (users, roles) => {
    let result = [];
    for (let i = 0; i < roles.length; i++) {
      result[i] = users.filter((u) =>
        u.roles.some((r) => r.name === roles[i].name)
      ).length;
    }
    return result;
  };

  handleFilterStatus = (event) => {
    if (event === ACTIVE_STATUS) {
      this.setState({
        isActive: true,
        currentPage: 0,
        triggerUpdate: true,
      });
    } else if (event === INACTIVE_STATUS) {
      this.setState({
        isActive: false,
        currentPage: 0,
        triggerUpdate: true,
      });
    } else {
      this.setState({
        isActive: "",
        currentPage: 0,
        triggerUpdate: true,
      });
    }
    this.handleFilterSubmit(
      this.state.filterStats.name,
      this.state.filterStats.username,
      this.state.filterStats.manager,
      event,
      this.state.filterStats.role
    );
  };

  paginationClickHandler = (event) => {
    if (event.target.text === undefined) {
      return;
    } else {
      this.setState({
        currentPage: parseInt(event.target.text) - 1,
        triggerUpdate: true,
      });
    }
  };

  roleChangeHandler = (event) => {
    if (event.target[event.target.selectedIndex].id !== "") {
      this.setState({
        roleId: event.target[event.target.selectedIndex].id,
        currentPage: 0,
        triggerUpdate: true,
      });
    } else {
      this.setState({
        roleId: "",
        currentPage: 0,
        triggerUpdate: true,
      });
    }
  };
  teamChangeHandler = (event) => {
    if (event.target[event.target.selectedIndex].id !== "") {
      this.setState({
        teamId: event.target[event.target.selectedIndex].id,
        currentPage: 0,
        triggerUpdate: true,
      });
    } else {
      this.setState({
        teamId: "",
        currentPage: 0,
        triggerUpdate: true,
      });
    }
  };
  managerChangeHandler = (event) => {
    if (event.target[event.target.selectedIndex].id !== "") {
      this.setState({
        managerId: event.target[event.target.selectedIndex].id,
        currentPage: 0,
        triggerUpdate: true,
      });
    } else {
      this.setState({
        managerId: "",
        currentPage: 0,
        triggerUpdate: true,
      });
    }
  };
  noManagerChangeHandler = () => {
    this.setState({
      managerId: "0",
      teamId: "",
      roleId: "",
      userFullName: "",
      currentPage: 0,
      isActive: true,
      triggerUpdate: true,
      filterStats: { activeUsers: ACTIVE_STATUS },
    });
    document.getElementById("manager-control").selectedIndex = 1;
    document.getElementById("team-control").selectedIndex = 0;
    document.getElementById("name-control").value = "";
    document.getElementById("role-control").selectedIndex = 0;
  };
  nameChangeHandler = (value) => {
    this.setState({
      userFullName: value,
      currentPage: 0,
      triggerUpdate: true,
    });
  };

  handleFilterSubmit = (name, username, manager, activeUsers, role) => {
    this.setState({
      filterStats: {
        name,
        username,
        manager,
        activeUsers,
        role,
      },
    });
  };

  handleOpenModal = (mode, user) =>
    this.setState({
      selectedUser: user,
      mode,
      showModal: true,
    });

  handleCsvFileOpenModal = () =>
    this.setState({
      showCsvFileModal: true,
    });

  handleCloseModal = () =>
    this.setState({
      showModal: false,
      mode: undefined,
      selectedUser: undefined,
    });

  handleCsvFileCloseModal = () =>
    this.setState(
      {
        showCsvFileModal: false,
        file: undefined,
        userUploadResult: {
          executionLogs: [],
          success: undefined,
        },
      },
      () => this.fetchResources()
    );

  handleSubmit = async (userData) => {
    this.setState({
      loading: true,
    });
    if (
      this.state.mode === USER_CREATE_MODE ||
      this.state.mode === USER_EDIT_MODE
    ) {
      userData.userRoles = userData.userRoles.map((r) => r.name);
      if (this.state.mode === USER_CREATE_MODE) {
        await AdminService.createUser(userData).then((response) => {
          if (response.ok) {
            this.setState({
              loading: false,
            });
            this.showSuccessNotification("User is added successfully.");
          } else if (response.status === FORBIDDEN_ERROR_CODE) {
            this.setState({
              loading: false,
            });
            this.showErrorNotification(
              "You don't have permission to manage users."
            );
          } else {
            this.setState({
              loading: false,
            });
            this.showErrorNotification("Something went wrong...");
          }
        });
      } else if (this.state.mode === USER_EDIT_MODE) {
        await AdminService.updateUser(
          this.state.selectedUser.id,
          userData
        ).then((response) => {
          if (response.ok) {
            this.setState({
              loading: false,
            });
            this.showSuccessNotification("User is updated successfully.");
          } else if (response.status === NOT_FOUND_ERROR_CODE) {
            this.setState({
              loading: false,
            });
            this.showErrorNotification("User is not found.");
          } else {
            response.json().then((error) => {
              this.setState({
                loading: false,
              });
              this.showErrorNotification(error.message);
            });
          }
        });
      }
    } else if (this.state.mode === USER_DELETE_MODE) {
      this.state.selectedUser.id === userData.id &&
        (await AdminService.deleteUser(userData.id).then((response) => {
          if (response.ok) {
            this.setState({
              loading: false,
            });
            this.showSuccessNotification("User is deleted successfully.");
          } else if (response.status === FORBIDDEN_ERROR_CODE) {
            this.setState({
              loading: false,
            });
            this.showErrorNotification(
              "You don't have permission to manage users."
            );
          } else if (response.status === CONFLICT_ERROR_CODE) {
            this.setState({
              loading: false,
            });
            this.showErrorNotification(
              `User ${userData.firstName} ${userData.lastName} can not be deleted as there is related data. Consider deactivating them instead..`
            );
          } else if (response.status === NOT_FOUND_ERROR_CODE) {
            this.setState({
              loading: false,
            });
            this.showErrorNotification("User not found.");
          }
        }));
    }
    this.fetchResources();
  };

  showSuccessNotification = (message) =>
    this.toastContainer.success(message, "", { closeButton: true });

  showErrorNotification = (message) =>
    this.toastContainer.error(message, "", {
      closeButton: true,
    });

  render() {
    return this.state.loading ? (
      <Loading />
    ) : (
      <Container>
        <ToastContainer
          ref={(ref) => (this.toastContainer = ref)}
          className="toast-top-right"
        />
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item active>
                <FormattedMessage id="AllUsers.users" />
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {this.state.activeUsersWithoutManager > 0 && (
            <Col xs={8}>
              <Button
                className="float-right"
                id="button"
                onClick={this.noManagerChangeHandler}
                style={{ color: "red", padding: "0" }}
                variant="link"
              >
                {this.state.activeUsersWithoutManager > 1 ? (
                  <FormattedMessage id="Users.activePlural" />
                ) : (
                  <FormattedMessage id="Users.activeSingular" />
                )}

                <Badge style={{ backgroundColor: "#e2e7ec" }}>
                  {this.state.activeUsersWithoutManager}
                </Badge>
                {this.state.activeUsersWithoutManager > 1 ? (
                  <FormattedMessage id="Users.activePluralRest" />
                ) : (
                  <FormattedMessage id="Users.activeSingularRest" />
                )}
              </Button>
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          <Col>
            <Filter
              value={this.state.filterStats.activeUsers}
              items={this.state.tabs}
              onChange={this.handleFilterStatus}
            />
          </Col>
          <Col>
            <ConfigurationPropertiesContext.Consumer>
              {({ internalUserManagement }) =>
                internalUserManagement && (
                  <Button
                    className="float-right"
                    variant="link"
                    style={{ fontSize: "1.1rem" }}
                    onClick={() => this.handleOpenModal(USER_CREATE_MODE)}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                    {<FormattedMessage id="Button.add" />}
                  </Button>
                )
              }
            </ConfigurationPropertiesContext.Consumer>

            <ConfigurationPropertiesContext.Consumer>
              {({ internalUserManagement }) =>
                internalUserManagement &&
                AuthenticationService.hasPermission(
                  this.props.user,
                  PERMISSION_MANAGE_PROCESS_UPLOAD_USERS
                ) && (
                  <Button
                    variant="link"
                    className="float-right"
                    style={{ fontSize: "1.1rem" }}
                    onClick={() => this.handleCsvFileOpenModal()}
                  >
                    <FontAwesomeIcon icon={faFileUpload} />
                    {<FormattedMessage id="Button.uploadFile" />}
                  </Button>
                )
              }
            </ConfigurationPropertiesContext.Consumer>
          </Col>
        </Row>
        <Row>
          <Col>
            <DebouncedSearchInput
              onChange={this.nameChangeHandler}
              placeholder={this.props.intl.formatMessage({
                id: "ProcessParticipants.name",
              })}
              name="name"
              id="name-control"
              size="sm"
              type="text"
            />
          </Col>
          <Col>
            <Control
              id="role-control"
              as="select"
              name="role"
              size="sm"
              onChange={this.roleChangeHandler}
              className="filter-item"
            >
              <option key={"any"} value={"any"}>
                {this.props.intl.formatMessage({ id: "Users.anyRole" })}
              </option>

              {this.state.roles.map((role, index) => (
                <option key={index} value={role.name} id={role.id}>
                  {role.name}
                </option>
              ))}
            </Control>
          </Col>
          <Col>
            <Control
              id="team-control"
              as="select"
              name="role"
              size="sm"
              onChange={this.teamChangeHandler}
              className="filter-item"
            >
              <option key={"any"} value={"any"}>
                {this.props.intl.formatMessage({ id: "Users.anyTeam" })}
              </option>
              <option key={"no"} id={0} value={"no"}>
                {this.props.intl.formatMessage({ id: "Users.noTeam" })}
              </option>
              {this.state.teams.map((team, index) => (
                <option key={index} value={team.name} id={team.id}>
                  {team.name}
                </option>
              ))}
            </Control>
          </Col>
          <Col>
            <Control
              as="select"
              name="manager"
              size="sm"
              className="filter-item"
              id="manager-control"
              onChange={this.managerChangeHandler}
            >
              <option key={"any"} value={"any"}>
                {this.props.intl.formatMessage({ id: "Users.anyManager" })}
              </option>
              <option key={"no"} id={0} value={"no"}>
                {this.props.intl.formatMessage({ id: "Users.noManager" })}
              </option>
              {this.state.managers.map((manager, index) => (
                <option
                  key={index}
                  value={`${manager.firstName}  ${manager.lastName}`}
                  id={manager.id}
                >
                  {`${manager.firstName}  ${manager.lastName}`}
                </option>
              ))}
            </Control>
          </Col>
        </Row>
        <div className="mt-3">
          {this.state.users.length === 0 ? (
            <h4 className="text-center">
              {this.props.intl.formatMessage({
                id: "ProcessParticipants.noUsersFound",
              })}
            </h4>
          ) : (
            <Row>
              <UsersTable
                principal={this.props.user}
                users={this.state.users}
                handleOpenModal={this.handleOpenModal}
              />
            </Row>
          )}
        </div>
        <UserModal
          showModal={this.state.showModal}
          handleClose={this.handleCloseModal}
          handleSubmit={this.handleSubmit}
          mode={this.state.mode}
          user={this.state.selectedUser}
          users={this.state.users}
          roles={this.state.roles}
          teams={this.state.teams}
          loading={this.state.loading}
        />
        <UserUploadFileModal
          showModal={this.state.showCsvFileModal}
          file={this.state.file}
          handleClose={this.handleCsvFileCloseModal}
          handlerop={this.handleDrop}
          removeFile={this.removeFile}
          handleUploadFile={this.handleUploadFile}
          userUploadResult={this.state.userUploadResult}
        />
        <Pagination
          currentPage={this.state.currentPage}
          paginationClickHandler={this.paginationClickHandler}
          setCurrentPage={(page) => {
            this.setState({ currentPage: page, triggerUpdate: true });
          }}
          pages={this.state.totalPages}
        />
      </Container>
    );
  }
}

export default injectIntl(AllUsers);
