import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/roles";

const RoleService = {
  getUsersByRoleId: (roleId) => {
    let URL = `${BASE_URL}/${roleId}`;
    authFetchJSON(`${URL}`).then((response) => response.json());
  },

  updateMembers: (roleId, userIds) =>
    authFetchJSON(`${BASE_URL}/${roleId}/users`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userIds),
    }),
};

export default RoleService;
