import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PerformanceReviewService from "../../services/PerformanceReviewService";
import Loading from "../common/display/Loading";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Filter from "../common/Filter";
import ManagerEmployeesContainer from "./ManagerEmployeesContainer";
import { FormattedMessage } from "react-intl";
import AuthenticationService from "../../services/AuthenticationService";
import { VIEWER } from "../../constants/permissions";

const DEFAULT_PERFORMANCE_REVIEW = { id: 0 };

const DEFAULT_FILTER_ITEMS = [
  {
    value: "direct",
    title: <FormattedMessage id="Manager.direct" />,
    count: 0,
  },
  {
    value: "viewer",
    title: <FormattedMessage id="Manager.viewer" />,
    count: 0,
  },
  { value: "team", title: <FormattedMessage id="Manager.team" />, count: 0 },
  {
    value: "history",
    title: <FormattedMessage id="Manager.history" />,
    count: 0,
  },
];

const ManagerContainer = ({ user }) => {
  const location = useLocation();

  const [performanceReview, setPerformanceReview] = useState(
    DEFAULT_PERFORMANCE_REVIEW
  );
  const [filterItems, setFilterItems] = useState(DEFAULT_FILTER_ITEMS);
  const [selectedFilterItem, setSelectedFilterItem] = useState(
    location.state?.selectedFilterItem || DEFAULT_FILTER_ITEMS[0]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    Promise.all([PerformanceReviewService.getPerformanceReviews()]).then(
      ([performanceReviews]) => {
        if (performanceReviews.length > 0) {
          PerformanceReviewService.getActivePerformanceReviewId()
            .then((performanceReviewId) => {
              setPerformanceReview(
                performanceReviews.find(
                  (performanceReview) =>
                    performanceReview.id === performanceReviewId
                )
              );
              setIsLoading(false);
            })
            .catch(() => {
              setPerformanceReview(performanceReviews[0]);
              setIsLoading(false);
            });
        } else {
          setNotFound(true);
          setIsLoading(false);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!AuthenticationService.hasPermission(user, VIEWER)) {
      const index = filterItems.findIndex((item) => item.value === "viewer");
      if (index !== -1) {
        filterItems.splice(index, 1);
      }
    }
  }, [user, filterItems]);

  const handleChange = (value) =>
    setSelectedFilterItem(filterItems.find((item) => item.value === value));

  return isLoading ? (
    <Loading />
  ) : (
    <Container
      fluid
      style={{ width: "66%", float: "right", paddingTop: "1.125rem" }}
    >
      <Row>
        <Col>
          <header className="text-left">
            {notFound ? (
              <h4>
                <FormattedMessage id="Nodata.message" />{" "}
              </h4>
            ) : (
              <Breadcrumb>
                <Breadcrumb.Item active>
                  <FormattedMessage id="Manager.employees" />
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </header>
        </Col>
      </Row>
      {!notFound && (
        <>
          <Filter
            items={filterItems}
            value={selectedFilterItem?.value}
            onChange={handleChange}
          />
          <ManagerEmployeesContainer
            filter={selectedFilterItem?.value}
            filterItems={filterItems}
            setFilterItems={setFilterItems}
            manager={user}
            performanceReview={performanceReview}
          />
        </>
      )}
    </Container>
  );
};

export default ManagerContainer;
