import { createContext } from "react";

export const STORAGE_KEY_LOCALE = "localeLanguage";

export const prefferedLocale = navigator.language.split(/[-_]/)[0];

export const defaultLocale =
  localStorage.getItem(STORAGE_KEY_LOCALE) || prefferedLocale;

const LocaleContext = createContext({
  locale: defaultLocale,
  setLocale: () => {},
});

export default LocaleContext;
