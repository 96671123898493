import React from "react";
import { Modal, Row, Col, Button, Alert } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import FileUploadLog from "./FileUploadLog";
import "./user-upload-file-model.css";
import { FormattedMessage } from "react-intl";
const { Body, Title, Header } = Modal;

const UserUploadFileModal = ({
  showModal,
  handleClose,
  handleDrop,
  removeFile,
  handleUploadFile,
  file,
  userUploadResult,
}) => {
  return (
    <Modal
      dialogClassName="upload-file-modal"
      size={"lg"}
      show={showModal}
      onHide={handleClose}
      backdrop="static"
    >
      <Header closeButton>
        <Title>{<FormattedMessage id="User.uploadCsv" />}</Title>
        <hr />
      </Header>
      <Body className="upload-file-body">
        <Row>
          <Col>
            <Dropzone onDrop={handleDrop} multiple={false} maxFiles={1}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />

                  <p>
                    {file !== undefined ? (
                      <span>
                        {file.name}{" "}
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          onClick={(event) => removeFile(event)}
                        />
                      </span>
                    ) : (
                      <FormattedMessage id="User.fileUpload" />
                    )}
                  </p>
                </div>
              )}
            </Dropzone>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            {userUploadResult.success !== undefined && (
              <Alert variant={userUploadResult.success ? "success" : "danger"}>
                {userUploadResult.success
                  ? "File was processed successfully!"
                  : "Error during processing of file!"}
              </Alert>
            )}
          </Col>
          <Col offset={3}></Col>
          <Col md={4}>
            <Button
              type="button"
              variant="primary"
              className="upload-file-btn float-right"
              disabled={!file}
              onClick={() => handleUploadFile()}
            >
              <FormattedMessage id="EditForm.submit" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <FileUploadLog
              logs={userUploadResult.executionLogs}
            ></FileUploadLog>
          </Col>
        </Row>
      </Body>
    </Modal>
  );
};

export default UserUploadFileModal;
