import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  ONE_ON_ONE_QUESTION_EDIT_MODE,
  ONE_ON_ONE_QUESTION_DELETE_MODE,
} from "../../../constants/oneOnOneQuestionsModes";
import ConfigurationPropertiesContext from "../../../contexts/ConfigurationPropertiesContext";
import OneOnOneFeedbackQuestionsService from "../../../services/OneOnOneFeedbackQuestionsService";

const OneOnOneQuestionRow = ({ question, handleOpenModal }) => {
  const intl = useIntl();
  const [hoverItemId, setHoverItemId] = useState();
  const [showDelete, setShowDelete] = useState(true);

  const yesLabel = intl.formatMessage({
    id: "Button.yes",
  });
  const noLabel = intl.formatMessage({
    id: "Button.no",
  });

  useEffect(() => {
    OneOnOneFeedbackQuestionsService.questionIsUsed(question?.id).then(
      (isUsed) => {
        setShowDelete(!isUsed);
      }
    );
  }, [question]);

  return (
    <tr
      className={!question.active && "inactive"}
      onMouseOver={() => setHoverItemId(question.id)}
      onMouseLeave={() => setHoverItemId()}
    >
      <td style={{ width: "50%" }}>
        <div className="question-content">{question.content}</div>
        {hoverItemId === question.id && (
          <div className="actions">
            <Button
              variant="link"
              onClick={() =>
                handleOpenModal(ONE_ON_ONE_QUESTION_EDIT_MODE, question)
              }
            >
              <FontAwesomeIcon icon={faEdit} />
              {<FormattedMessage id="UserInfo.edit" />}
            </Button>
            {showDelete && (
              <Button
                variant="link"
                onClick={() =>
                  handleOpenModal(ONE_ON_ONE_QUESTION_DELETE_MODE, question)
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} />
                {<FormattedMessage id="UserInfo.delete" />}
              </Button>
            )}
          </div>
        )}
      </td>
      <td>{question?.type?.type}</td>
      <td>{question?.active ? yesLabel : noLabel}</td>
      <td>{question?.mandatory ? yesLabel : noLabel}</td>
      <td style={{ width: "10%" }}>
        {question?.hasScore ? yesLabel : noLabel}
      </td>
      <td style={{ width: "10%" }}>{question?.createdBy}</td>
      <ConfigurationPropertiesContext.Consumer>
        {({ formatDate }) => (
          <td style={{ width: "10%" }}>{`${formatDate(
            question?.createdOn
          )}`}</td>
        )}
      </ConfigurationPropertiesContext.Consumer>

      <td style={{ width: "10%" }}>{question?.updatedBy}</td>
      <ConfigurationPropertiesContext.Consumer>
        {({ formatDate }) => (
          <td style={{ width: "10%" }}>{`${formatDate(
            question?.updatedOn
          )}`}</td>
        )}
      </ConfigurationPropertiesContext.Consumer>
    </tr>
  );
};

export default OneOnOneQuestionRow;
