import React from "react";
import { Col, Form } from "react-bootstrap";
import FAButtonAndTooltip from "./buttons/FAButtonAndTooltip";
import {
  faEdit,
  faMinusSquare,
  faPlusSquare,
} from "@fortawesome/free-regular-svg-icons";
import {
  PR_CREATE_MODE,
  PR_DELETE_MODE,
  PR_EDIT_MODE,
} from "../../constants/performanceReviewModes";

const NO_ACTIVE_PR = "No active performance review";

const PerformanceReviewsFilter = ({
  className,
  handleChange,
  selectedPerformanceReview,
  performanceReviews,
  handleOpenModal,
  noHeading,
}) => {
  return (
    <div className={`filter ${className}`}>
      <fieldset className="one-item-only">
        {!noHeading && <legend>Performance Review</legend>}
        <Form.Row className="align-items-center filter-item">
          <Form.Label column md="auto">
            {noHeading ? "Select review" : "Title"}
          </Form.Label>
          <Col xs={8}>
            <Form.Control
              as="select"
              value={
                selectedPerformanceReview
                  ? selectedPerformanceReview.id
                  : NO_ACTIVE_PR
              }
              onChange={handleChange}
            >
              {!selectedPerformanceReview && (
                <option disabled>{NO_ACTIVE_PR}</option>
              )}
              {performanceReviews.map((pr, index) => (
                <option key={index} value={pr.id} label={pr.title} />
              ))}
            </Form.Control>
          </Col>
          {handleOpenModal && (
            <>
              <Col xs="auto">
                <FAButtonAndTooltip
                  id="add-pr"
                  icon={faPlusSquare}
                  tooltipMessage="Create new performance review"
                  onClick={() => handleOpenModal(PR_CREATE_MODE)}
                />
              </Col>
              {selectedPerformanceReview && (
                <>
                  <Col xs="auto">
                    <FAButtonAndTooltip
                      id="edit-pr"
                      icon={faEdit}
                      tooltipOffset={{ left: "2px" }}
                      tooltipMessage="Edit performance review"
                      onClick={() =>
                        handleOpenModal(PR_EDIT_MODE, selectedPerformanceReview)
                      }
                    />
                  </Col>
                  <Col xs="auto">
                    <FAButtonAndTooltip
                      id="remove-pr"
                      icon={faMinusSquare}
                      tooltipType="error"
                      tooltipOffset={{ right: "1px" }}
                      tooltipMessage="Remove performance review"
                      onClick={() =>
                        handleOpenModal(
                          PR_DELETE_MODE,
                          selectedPerformanceReview
                        )
                      }
                    />
                  </Col>
                </>
              )}
            </>
          )}
        </Form.Row>
      </fieldset>
    </div>
  );
};

export default PerformanceReviewsFilter;
