import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CustomPopover from "../common/display/CustomPopover";
import ReactHtmlParser from "react-html-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import "./goals.css";
import { List, arrayMove } from "react-movable";
import GoalService from "../../services/GoalService";
import GoalTemplateService from "../../services/GoalTemplateService";
import { FormattedMessage } from "react-intl";
import { ORGANIZATIONAL_GOAL_TYPE_ID } from "../../constants/goalTypes";
const Goals = ({
  addGoalAction,
  copyGoalsAction,
  copyLastYearGoalsAction,
  goals,
  goalType,
  readOnly,
  isTemplate,
  isMyGoalsView,
}) => {
  const [currentTypeGoals, setCurrentTypeGoals] = useState([]);
  const [hoveredGoal, setHoveredGoal] = useState();

  useEffect(() => {
    setCurrentTypeGoals(
      goals.filter((goal) => goal.goalTypeId === goalType.id)
    );
  }, [goals, goalType.id]);

  const onMouseOver = (goal) => setHoveredGoal(goal);

  const onMouseLeave = () => setHoveredGoal();

  const handleListUpdate = (oldIndex, newIndex) => {
    const newGoalTypes = arrayMove(currentTypeGoals, oldIndex, newIndex);
    !isTemplate
      ? GoalService.attemptUpdateOrder(
          newGoalTypes.map((goal, index) =>
            Object.assign({ goalId: goal.id, sortIndex: index })
          )
        )
      : GoalTemplateService.attemptUpdateOrder(
          newGoalTypes.map((goal, index) =>
            Object.assign({ goalId: goal.id, sortIndex: index })
          )
        );
    setCurrentTypeGoals(newGoalTypes);
  };

  return (
    <Container className="goals-container" fluid>
      <Row>
        <Col md={8} className="goals-title">
          <FormattedMessage id={`GoalType.${goalType.id}`} /> {goalType.weight}%
        </Col>
        {!isTemplate ? (
          <Col>
            {addGoalAction && (
              <Button variant="link" onClick={addGoalAction}>
                <FontAwesomeIcon icon={faPlusCircle} />
                {<FormattedMessage id="Button.add" />}
              </Button>
            )}
            {copyGoalsAction && (
              <Button variant="link" onClick={copyGoalsAction}>
                <FontAwesomeIcon icon={faCopy} />
                {<FormattedMessage id="Button.copy" />}
              </Button>
            )}
            {copyLastYearGoalsAction && (
              <Button variant="link" onClick={copyLastYearGoalsAction}>
                <FontAwesomeIcon icon={faCopy} />
                {<FormattedMessage id="Button.copyFromLastYear" />}
              </Button>
            )}
          </Col>
        ) : (
          <Row className="ml-auto">
            <Col>
              {addGoalAction && (
                <Button
                  variant="link"
                  onClick={addGoalAction}
                  style={{ marginRight: "5px" }}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                  {<FormattedMessage id="Button.add" />}
                </Button>
              )}
              {copyGoalsAction && (
                <Button
                  variant="link"
                  onClick={copyGoalsAction}
                  style={{ marginRight: "5px" }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                  {<FormattedMessage id="Button.copy" />}
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Row>
      <Row className="table-header-row">
        <Col children={<FormattedMessage id="Goals.weight" />} />
      </Row>
      {currentTypeGoals.length > 0 && !isMyGoalsView ? (
        <List
          className="goals-list"
          values={currentTypeGoals}
          onChange={({ oldIndex, newIndex }) =>
            handleListUpdate(oldIndex, newIndex)
          }
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props }) => (
            <li {...props}>
              {
                <Row
                  key={`goal-row-${value.id}`}
                  className={`table-row ${value.active ? "" : "goal-inactive"}`}
                  onMouseOver={() => onMouseOver(value)}
                  onMouseLeave={onMouseLeave}
                >
                  <Col md={11}>
                    <Row className="goal-title">
                      <Col>{value.title}</Col>
                    </Row>
                    {value.description && (
                      <Row className="goal-desc">
                        <Col>{value.description}</Col>
                      </Row>
                    )}
                    <Row className="goal-kpi">
                      <Col>
                        <i>{"KPI: "}</i>
                        {value.kpi ? (
                          <>
                            {value.kpiDescription ? (
                              <CustomPopover
                                placement="right"
                                text={ReactHtmlParser(value.kpiDescription)}
                              >
                                <span>
                                  {value.kpi}
                                  <FontAwesomeIcon
                                    style={{ marginLeft: "5px" }}
                                    icon={faInfoCircle}
                                  />
                                </span>
                              </CustomPopover>
                            ) : (
                              value.kpi
                            )}
                          </>
                        ) : (
                          "--"
                        )}
                      </Col>
                    </Row>
                    {value.setBy && (
                      <Row className="goal-setBy">
                        <Col>
                          {<FormattedMessage id="Goals.setBy" />}
                          {`: `}
                          {goalType.id === ORGANIZATIONAL_GOAL_TYPE_ID
                            ? "Proxiad SEE"
                            : value.setBy}
                        </Col>
                      </Row>
                    )}
                    <Row className="goal-actions">
                      <Col>
                        {!readOnly &&
                          value.id === hoveredGoal?.id &&
                          value.buttons.map((button, index) => (
                            <Button
                              key={`button-goal-action-${index}`}
                              variant="link"
                              onClick={button.action}
                            >
                              <FontAwesomeIcon icon={button.icon} />
                              {button.label}
                            </Button>
                          ))}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={1}
                    className="weight-column full-height-centered"
                    children={Number(value.weight).toFixed(2)}
                  />
                </Row>
              }
            </li>
          )}
        />
      ) : currentTypeGoals.length > 0 && isMyGoalsView ? (
        currentTypeGoals.map((goal, index) => (
          <Row
            key={`goal-row-${index}`}
            className={`table-row ${goal.active ? "" : "goal-inactive"}`}
            onMouseOver={() => onMouseOver(goal)}
            onMouseLeave={onMouseLeave}
          >
            <Col md={11}>
              <Row className="goal-title">
                <Col>{goal.title}</Col>
              </Row>
              {goal.description && (
                <Row className="goal-desc">
                  <Col>{goal.description}</Col>
                </Row>
              )}
              <Row className="goal-kpi">
                <Col>
                  <i>{"KPI: "}</i>
                  {goal.kpi ? (
                    <>
                      {goal.kpiDescription ? (
                        <CustomPopover
                          placement="right"
                          text={ReactHtmlParser(goal.kpiDescription)}
                        >
                          <span>
                            {goal.kpi}
                            <FontAwesomeIcon
                              style={{ marginLeft: "5px" }}
                              icon={faInfoCircle}
                            />
                          </span>
                        </CustomPopover>
                      ) : (
                        goal.kpi
                      )}
                    </>
                  ) : (
                    "--"
                  )}
                </Col>
              </Row>
              {goal.setBy && (
                <Row className="goal-setBy">
                  <Col>
                    {<FormattedMessage id="Goals.setBy" />}
                    {`: `}
                    {goalType.id === ORGANIZATIONAL_GOAL_TYPE_ID
                      ? "Proxiad SEE"
                      : goal.setBy}
                  </Col>
                </Row>
              )}
              <Row className="goal-actions">
                <Col>
                  {!readOnly &&
                    goal.id === hoveredGoal?.id &&
                    goal.buttons.map((button, index) => (
                      <Button
                        key={`button-goal-action-${index}`}
                        variant="link"
                        onClick={button.action}
                      >
                        <FontAwesomeIcon icon={button.icon} />
                        {button.label}
                      </Button>
                    ))}
                </Col>
              </Row>
            </Col>
            <Col
              md={1}
              className="weight-column full-height-centered"
              children={Number(goal.weight).toFixed(2)}
            />
          </Row>
        ))
      ) : (
        <Col
          align="center"
          className="mt-3"
          children={
            <h4>
              <FormattedMessage id="Goals.noGoals" />
            </h4>
          }
        />
      )}
    </Container>
  );
};

export default Goals;
