import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v1/auth";

const AuthenticationService = {
  getCurrentUser: () =>
    authFetchJSON(`${BASE_URL}/current`).then((response) => response.json()),

  attemptLogIn: (credentials) =>
    fetch(`${BASE_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    }),

  getIdTokenInfo: () =>
    authFetchJSON(`${BASE_URL}/id-token-info`).then((response) =>
      response.json()
    ),

  clearAadTokens: () =>
    authFetchJSON(`${BASE_URL}/oauth2/clear-tokens`, { method: "DELETE" }),

  sendForgotPassEmail: (data) =>
    authFetchJSON(`${BASE_URL}/forgot-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }),

  updatePassword: (data) =>
    authFetchJSON(`${BASE_URL}/passwords`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }),

  getRoles: () =>
    authFetchJSON(`${BASE_URL}/roles`).then((response) => response.json()),

  attemptSavingRole: (role) =>
    authFetchJSON(`${BASE_URL}/roles`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(role),
    }),

  attemptDeletingRoleById: (roleId) =>
    authFetchJSON(`${BASE_URL}/roles/${roleId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),

  logout: () =>
    authFetchJSON(`${BASE_URL}/logout`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }),

  hasPermission: (user, permission) =>
    user &&
    user.roles &&
    user.roles.some((r) => r.permissions.some((p) => p.name === permission)),

  hasRole: (user, role) =>
    user && user.roles && user.roles.some((r) => r.name === role),
};

export default AuthenticationService;
