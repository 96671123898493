import authFetchJSON from "../components/security/fetchClient";
import { _download } from "./fileSaverService";

const BASE_URL = "/api/v1/processes";

const ProcessService = {
  getProcessesByPerformanceReviewId: (performanceReviewId) =>
    authFetchJSON(
      `${BASE_URL}/?performanceReviewId=${performanceReviewId}`
    ).then((response) => response.json()),

  attemptCreatingNewProcess: (values) =>
    authFetchJSON(BASE_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }),

  updateProcessStatus: (id, status) =>
    authFetchJSON(`${BASE_URL}/${id}?status=${status}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
    }),

  attemptSavingProcessById: (id, values) =>
    authFetchJSON(`${BASE_URL}/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }),

  attemptDeletingProcessById: (id) =>
    authFetchJSON(`${BASE_URL}/${id}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),

  getPeriod: () =>
    authFetchJSON(`${BASE_URL}/period`).then((response) => response.json()),

  getProcessAuditsByProcessId: (id) =>
    authFetchJSON(`${BASE_URL}/${id}/audit`).then((response) =>
      response.json()
    ),

  getProcessById: (id) =>
    authFetchJSON(`${BASE_URL}/${id}`).then((response) => response.json()),

  getExternalAnalyticDashboardByProcessId: (id) =>
    authFetchJSON(`${BASE_URL}/${id}/external-dashboard-url`).then((response) =>
      response.json()
    ),

  getProcessParticipantsByProcessId: (id) =>
    authFetchJSON(`${BASE_URL}/${id}/participants`).then((response) =>
      response.json()
    ),

  getProcessUsersByProcessId: (id) =>
    authFetchJSON(`${BASE_URL}/${id}/process-users`).then((response) =>
      response.json()
    ),

  getProcessStatsByProcessId: (id) =>
    authFetchJSON(`${BASE_URL}/${id}/stats`).then((response) =>
      response.json()
    ),

  addParticipantsToProcess: (id, participants) =>
    authFetchJSON(`${BASE_URL}/${id}/participants`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(participants),
    }),

  removeParticipantFromProcess: (processId, participantId) =>
    authFetchJSON(`${BASE_URL}/${processId}/participants/${participantId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    }),

  exportDashboardAsExcel: (dashboardData) =>
    _download(
      "POST",
      `${BASE_URL}/export/dashboard-participants`,
      dashboardData
    ),
};

export default ProcessService;
