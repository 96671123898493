import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import Image from "../../components/common/Image";
import { Button } from "react-bootstrap";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import { USER_DELETE_MODE, USER_EDIT_MODE } from "../../constants/userModes";
import HomeService from "../../services/HomeService";
import AuthenticationService from "../../services/AuthenticationService";
import { PERMISSION_IMPERSONATE } from "../../constants/permissions";
import { getRoleName } from "../../util/roleName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import "./user-info.css";
import { FormattedMessage } from "react-intl";
const UserInfo = ({ user, principal, handleOpenModal }) => {
  const [hoverItemId, setHoverItemId] = useState();

  return (
    <tr
      className={!user.active && "inactive"}
      onMouseOver={() => setHoverItemId(user.id)}
      onMouseLeave={() => setHoverItemId()}
    >
      <td className="profile-img">
        <LazyLoad height={50} offset={100}>
          <Image email={user.email} roundedCircle />
        </LazyLoad>
      </td>
      <td>
        <div className="employee-name">{`${user.firstName} ${user.lastName}`}</div>
        <div className="employee-position">{user.title}</div>
        <div className="employee-email">{user.email}</div>
        {hoverItemId === user.id && (
          <div className="actions">
            <Button
              variant="link"
              onClick={() => handleOpenModal(USER_EDIT_MODE, user)}
            >
              <FontAwesomeIcon icon={faEdit} />
              {<FormattedMessage id="UserInfo.edit" />}
            </Button>
            <ConfigurationPropertiesContext.Consumer>
              {({ internalUserManagement }) =>
                internalUserManagement &&
                principal.id !== user.id && (
                  <Button
                    variant="link"
                    onClick={() => handleOpenModal(USER_DELETE_MODE, user)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                    {<FormattedMessage id="UserInfo.delete" />}
                  </Button>
                )
              }
            </ConfigurationPropertiesContext.Consumer>
            <RunAsBtn user={user} principal={principal} />
          </div>
        )}
      </td>
      <td>
        {user.roles.map((role, index) => (
          <div key={index}>{getRoleName(role)}</div>
        ))}
      </td>
      {user.team ? <td>{user.team.name}</td> : <td> --</td>}

      <td>
        {user.managerName ? <div>{user.managerName}</div> : <div> --</div>}
      </td>
    </tr>
  );
};
const RunAsBtn = ({ user, principal }) => {
  const runAsConfirm = (username) =>
    HomeService.runAs(username).then(() => window.location.reload());

  if (
    AuthenticationService.hasPermission(principal, PERMISSION_IMPERSONATE) &&
    !AuthenticationService.hasPermission(user, PERMISSION_IMPERSONATE)
  ) {
    if (user.active) {
      return (
        <Button
          id={`${user.id}-impersonate`}
          disabled={!user.active}
          className="float-right"
          variant="link"
          onClick={() => runAsConfirm(user.username)}
        >
          <FontAwesomeIcon icon={faUserSecret} />
          {<FormattedMessage id="UserInfo.impersonate" />}
        </Button>
      );
    } else {
      return <React.Fragment />;
    }
  } else return <React.Fragment />;
};
export default UserInfo;
