import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const CustomPopover = ({ placement, text, children }) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement={placement}
    overlay={
      <Popover id="popover">
        <Popover.Content>{text}</Popover.Content>
      </Popover>
    }
  >
    {children}
  </OverlayTrigger>
);

export default CustomPopover;
