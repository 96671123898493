import React, { useState, useEffect } from "react";
import { Breadcrumb, Col, Container, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastr";
import ConfigurationPropertiesContext from "../../../contexts/ConfigurationPropertiesContext";
import PerformanceReviewService from "../../../services/PerformanceReviewService";
import Loading from "../../common/display/Loading";
import Filter from "../../common/Filter";
import { FormattedMessage } from "react-intl";

const Reporting = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [performanceReviews, setPerformanceReviews] = useState([]);
  const [status, setStatus] = useState("active");
  const maxReviewsNumber = 999;
  const oneKString = "1k+";

  const higherThanMaxReviewNumber = (reviewsCount) => {
    if (reviewsCount < maxReviewsNumber) {
      return reviewsCount;
    }
    return oneKString;
  };

  const [tabs, setTabs] = useState([
    {
      value: "any",
      title: <FormattedMessage id="Reviews.tabs.any" />,
      count: 0,
    },
    {
      value: "pending",
      title: <FormattedMessage id="Reviews.tabs.pending" />,
      count: 0,
    },
    {
      value: "active",
      title: <FormattedMessage id="Reviews.tabs.active" />,
      count: 0,
    },

    {
      value: "completed",
      title: <FormattedMessage id="Reviews.tabs.completed" />,
      count: 0,
    },
  ]);

  const filterClickHandler = (event) => {
    setStatus(event);
  };

  const fetchPerformanceReviews = (status) =>
    PerformanceReviewService.getPerformanceReviewsByStatus(status).then(
      (reviews) => {
        setPerformanceReviews(reviews);
        setIsLoading(false);
      }
    );

  useEffect(() => {
    fetchPerformanceReviews(status);
    PerformanceReviewService.getPerformanceReviewsStatusCount().then(
      (counts) => {
        setTabs([
          {
            value: "any",
            title: <FormattedMessage id="Reviews.tabs.any" />,
            count: higherThanMaxReviewNumber(
              counts.activeCount + counts.pendingCount + counts.completedCount
            ),
          },
          {
            value: "pending",
            title: <FormattedMessage id="Reviews.tabs.pending" />,
            count: higherThanMaxReviewNumber(counts.pendingCount),
          },
          {
            value: "active",
            title: <FormattedMessage id="Reviews.tabs.active" />,
            count: higherThanMaxReviewNumber(counts.activeCount),
          },

          {
            value: "completed",
            title: <FormattedMessage id="Reviews.tabs.completed" />,
            count: higherThanMaxReviewNumber(counts.completedCount),
          },
        ]);
      }
    );
  }, [status]);

  const allPerformanceReviews = performanceReviews.map((review, index) => (
    <tr
      key={`performance-review-row-${index}`}
      className="change-on-hover"
      onClick={(event) =>
        !(event.target instanceof HTMLButtonElement) &&
        history.push(`/training/reporting-statistics/${review.id}`)
      }
    >
      <td>
        <div className="review-title">{review.title}</div>
      </td>

      <td className="text-center">
        <ConfigurationPropertiesContext.Consumer>
          {({ formatDate }) => `${formatDate(review.createdAt)}`}
        </ConfigurationPropertiesContext.Consumer>
      </td>
      <td>
        <ConfigurationPropertiesContext.Consumer>
          {({ formatDate }) =>
            `${formatDate(review.start)} - ${formatDate(review.end)}`
          }
        </ConfigurationPropertiesContext.Consumer>
      </td>
      <td>
        <FormattedMessage id={`Review.${review.status}`} />{" "}
      </td>
    </tr>
  ));

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <ToastContainer className="toast-top-right" />
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item active>
              <FormattedMessage id="Reviews.reviews" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Filter
            value={status}
            items={tabs}
            onChange={filterClickHandler}
          ></Filter>
        </Col>
      </Row>
      <Table className="actionsTable" borderless>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>
              <FormattedMessage id="Reviews.title" />
            </th>
            <th>
              <FormattedMessage id="Reviews.createdOn" />
            </th>
            <th>
              <FormattedMessage id="Reviews.period" />
            </th>
            <th>
              <FormattedMessage id="Reviews.status" />
            </th>
          </tr>
        </thead>
        <tbody>{allPerformanceReviews}</tbody>
      </Table>
      {performanceReviews.length === 0 && (
        <h4 className="text-center">
          <FormattedMessage id="Reviews.noFound" />
        </h4>
      )}
    </Container>
  );
};

export default Reporting;
