import React, { useState } from "react";
import ConfigurationPropertiesContext from "../../../contexts/ConfigurationPropertiesContext";
import { Table, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import Image from "../../common/Image";
import "../../manager/employees.css";
import { FormattedMessage, useIntl } from "react-intl";
import FeedbackModal from "../StatusManager/FeedbackModal";
import "./TeamTrainingEmployees.css";

const TeamTrainingEmployees = ({
  employeesTrainings,
  updateTrainingStatus,
  updateTrainingFeedback,
  mode,
}) => {
  const intl = useIntl();
  const componentMode = "StatusManger";
  const [editableIndex, setEditableIndex] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const trainingStatuses = [
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Requested",
      }),
      value: "Requested",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.In.Progress",
      }),
      value: "In Progress",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Vendor.Selected",
      }),
      value: "Vendor Selected",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Provided",
      }),
      value: "Provided",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Internal.Done",
      }),
      value: "Internal Done",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Cancelled.by.Employee",
      }),
      value: "Cancelled by Employee",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Cancelled.by.P.and.C",
      }),
      value: "Cancelled by P and C",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Left.the.Company",
      }),
      value: "Left the Company",
    },
  ];

  const toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  const getLabelByValue = (value) => {
    const status = trainingStatuses.find((status) => status.value === value);
    return status ? status.label : "Label not found";
  };

  return (
    <>
      <ConfigurationPropertiesContext.Consumer>
        {() => (
          <Table className="employees" borderless>
            <thead>
              <tr>
                <th colSpan={2}>
                  <FormattedMessage id="Training.employees.name" />
                </th>
                <th className="line-manager-label">
                  <FormattedMessage id="Training.line.manager.label" />
                </th>
                <th>
                  <FormattedMessage id="Training.team.name" />
                </th>
                <th className="personal-development-label">
                  <FormattedMessage id="Training.employees.personal.development.item" />
                </th>
                <th colSpan={2}>
                  <FormattedMessage id="Training.status.label" />
                </th>
                {mode === componentMode && (
                  <th>
                    <FormattedMessage id="Training.employees.personal.development.item.comment" />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {employeesTrainings.map((employeeTraining, index) => (
                <tr key={`employee-row-${index}`}>
                  <td className="profile-img">
                    <LazyLoad height={50} offset={100}>
                      <Image email={employeeTraining.email} roundedCircle />
                    </LazyLoad>
                  </td>
                  <td>
                    <div className="employee-name">
                      {`${employeeTraining.firstName} ${employeeTraining.lastName}`}
                    </div>
                    <div className="employee-position">
                      {employeeTraining.title}
                    </div>
                    <div className="employee-email">
                      {employeeTraining.email}
                    </div>
                  </td>
                  <td>
                    {" "}
                    <div>
                      {" "}
                      {employeeTraining.managerFirstName !== "null" ? (
                        `${employeeTraining.managerFirstName} ${employeeTraining.managerLastName}`
                      ) : (
                        <FormattedMessage id="Training.line.manager.not.found.label" />
                      )}
                    </div>
                  </td>
                  <td>
                    {" "}
                    <div>
                      {" "}
                      {employeeTraining.teamName !== null ? (
                        `${employeeTraining.teamName}`
                      ) : (
                        <FormattedMessage id="Training.team.not.found.label" />
                      )}
                    </div>
                  </td>
                  <td colSpan={2}>
                    <div>{employeeTraining.label}</div>
                  </td>
                  <td style={{ width: "200px" }}>
                    {mode === componentMode ? (
                      <>
                        {editableIndex === index ? (
                          <Form.Control
                            id="team-control"
                            as="select"
                            name="role"
                            size="sm"
                            className="filter-item"
                            onChange={(event) =>
                              updateTrainingStatus(
                                employeeTraining,
                                event,
                                setEditableIndex
                              )
                            }
                            defaultValue={employeeTraining.status}
                          >
                            {trainingStatuses.map((status, idx) => (
                              <option key={idx} value={status.value}>
                                {status.label}
                              </option>
                            ))}
                          </Form.Control>
                        ) : (
                          <div
                            style={{ color: "var(--primary-light)" }}
                            onDoubleClick={() => setEditableIndex(index)}
                          >
                            <FormattedMessage
                              id={getLabelByValue(employeeTraining.status)}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <div style={{ color: "var(--primary-light)" }}>
                        <FormattedMessage
                          id={getLabelByValue(employeeTraining.status)}
                        />
                      </div>
                    )}
                  </td>
                  {mode === componentMode && (
                    <td>
                      {employeeTraining.feedback ? (
                        employeeTraining.feedback.length > 20 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip
                                style={{ backgroundColor: "white!important" }}
                              >
                                {employeeTraining.feedback}
                              </Tooltip>
                            }
                          >
                            <span
                              onDoubleClick={() => {
                                setSelectedTraining(employeeTraining);
                                toggleFeedbackModal();
                              }}
                            >
                              {employeeTraining.feedback.slice(0, 20) + "..."}
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <span
                            onDoubleClick={() => {
                              setSelectedTraining(employeeTraining);
                              toggleFeedbackModal();
                            }}
                          >
                            {employeeTraining.feedback}
                          </span>
                        )
                      ) : (
                        <div
                          onDoubleClick={() => {
                            setSelectedTraining(employeeTraining);
                            toggleFeedbackModal();
                          }}
                        >
                          <FormattedMessage
                            id={
                              "Training.employees.personal.development.item.no.comment"
                            }
                          />
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </ConfigurationPropertiesContext.Consumer>
      <FeedbackModal
        show={showFeedbackModal}
        training={selectedTraining}
        toggleModal={toggleFeedbackModal}
        updateTraining={updateTrainingFeedback}
      />
    </>
  );
};

export default TeamTrainingEmployees;
