import React from "react";
import { Bar } from "react-chartjs-2";

export const options = {
  responsive: true,
  showLine: false,
  legend: {
    display: false,
  },
  title: {
    display: false,
  },

  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 30,
      bottom: 0,
    },
  },

  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          display: true,
        },
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: {
          display: false,
          drawOnChartArea: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
          beginAtZero: true,
        },
      },
    ],
  },
  tooltips: {
    enabled: false,
  },

  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map((data) => {
          sum += data;
          return sum;
        });
        let percentage = ((value * 100) / sum).toFixed(0) + "%";
        return percentage;
      },
      display: function (context) {
        var index = context.dataIndex;
        var value = context.dataset.data[index];
        return value > 0;
      },
      align: "top",
      anchor: "end",
      font: {
        weight: "bold",
      },
    },
  },
};

const FeedbackGraph = ({ statistic }) => {
  return <Bar data={statistic} options={options} />;
};

export default FeedbackGraph;
