import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_CLOSED,
  APPRAISAL_STATUS_REVIEW,
} from "../../constants/appraisalStatus";
import UserService from "../../services/UserService";
import { PROCESS_USER_ACTION_APPRAISAL } from "../../constants/processUserAction";
import { getAppraisalAssessorByAssessorType } from "../../util/getAppraisalAssessor";
import {
  FINAL_ASSESSOR_TYPE,
  MANAGER_ASSESSOR_TYPE,
} from "../../constants/assessorTypes";
import Loading from "../common/display/Loading";
import Appraisals from "../appraisal/Appraisals";
import AuthenticationService from "../../services/AuthenticationService";
import { PERMISSION_ASSESS_EMPLOYEES } from "../../constants/permissions";

const ManagerAppraisalsContainer = ({ user }) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [employee, setEmployee] = useState();
  const [appraisals, setAppraisals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const intl = useIntl();

  const noAppraisalFound = intl.formatMessage({
    id: "Appraisal.noAppraisalsFor",
  });

  const appraisalsPlaceHolder = intl.formatMessage({
    id: "Processes.tabs.stats.appraisals",
  });

  const userNoFoundPlaceHolder = intl.formatMessage({
    id: "User.noFound",
  });
  const assessAppraisal = (appraisal) =>
    history.push(`/appraisals/${appraisal.id}/assess`);

  const interviewAppraisal = (appraisal) => {
    if (
      appraisal.status === APPRAISAL_STATUS_REVIEW &&
      getAppraisalAssessorByAssessorType(appraisal, FINAL_ASSESSOR_TYPE).draft
    ) {
      history.push(`/appraisals/${appraisal.id}/review`);
    }
  };

  const viewAppraisal = (appraisal) => {
    if (
      (appraisal.status === APPRAISAL_STATUS_ACTIVE &&
        !getAppraisalAssessorByAssessorType(appraisal, MANAGER_ASSESSOR_TYPE)
          .draft) ||
      appraisal.status === APPRAISAL_STATUS_CLOSED
    ) {
      history.push({
        pathname: `/appraisals/${appraisal.id}/view`,
        state: { managerRedirect: "managerHistory" },
      });
    }
  };

  useEffect(() => {
    UserService.getUserById(id)
      .then((employee) =>
        UserService.getProcessUsersByUserIdAndAction(
          employee.id,
          PROCESS_USER_ACTION_APPRAISAL
        ).then((processUsers) => {
          const appraisals =
            user.id === employee.managerId ||
            (AuthenticationService.hasPermission(
              employee,
              PERMISSION_ASSESS_EMPLOYEES
            ) &&
              user.teamId === employee.team?.id)
              ? processUsers.map((processUser) => createAppraisal(processUser))
              : processUsers
                  .filter(
                    (processUser) =>
                      user.id === processUser.appraisal?.managerId
                  )
                  .map((processUser) => createAppraisal(processUser));

          setEmployee(employee);
          setAppraisals(appraisals);
          setIsLoading(false);
        })
      )
      .catch(() => {
        setNotFound(true);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const createAppraisal = (processUser) => {
    Object.assign(processUser.appraisal, {
      startDate: processUser.process?.performanceReview?.start,
      endDate: processUser.process?.performanceReview?.end,
      assessors: [
        getAppraisalAssessorByAssessorType(
          processUser.appraisal,
          MANAGER_ASSESSOR_TYPE
        ),
        getAppraisalAssessorByAssessorType(
          processUser.appraisal,
          FINAL_ASSESSOR_TYPE
        ),
      ],
      assessAction: () => assessAppraisal(processUser.appraisal),
      viewAction: () => viewAppraisal(processUser.appraisal),
      interviewAction:
        processUser.appraisal.status === APPRAISAL_STATUS_REVIEW
          ? () => interviewAppraisal(processUser.appraisal)
          : undefined,
    });

    return processUser.appraisal;
  };

  const getTitle = () => {
    if (notFound) return userNoFoundPlaceHolder;
    if (appraisals.length === 0)
      return `${noAppraisalFound}  ${employee.firstName} ${employee.lastName}`;
    else
      return `${employee.firstName} ${employee.lastName} ${appraisalsPlaceHolder}`;
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container
      fluid
      style={{
        width: "66%",
        display: "flex",
        flexDirection: "column",
        marginTop: "2%",
        padding: 0,
      }}
    >
      {location.state &&
      location.state.managerRedirect &&
      appraisals.length > 0 ? (
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => history.push("/manager")}>
              <FormattedMessage id="EditUserForm.manager" />
            </Breadcrumb.Item>
            <Breadcrumb.Item
              active
            >{`${employee.firstName.toUpperCase()} ${employee.lastName.toUpperCase()} APPRAISALS`}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      ) : (
        <Row className="mb-5">
          <Col>
            <header className="text-center">
              <h4>{getTitle()}</h4>
            </header>
          </Col>
        </Row>
      )}
      {!notFound && appraisals.length > 0 && (
        <Appraisals
          style={{ textAlign: "left", width: "100%", alignSelf: "auto" }}
          appraisals={appraisals}
          employee={employee}
          manager={user}
        />
      )}
    </Container>
  );
};

export default ManagerAppraisalsContainer;
