import React from "react";
import {
  MsalProvider,
  // MsalAuthenticationTemplate,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
//import { InteractionType } from "@azure/msal-browser";
import { msalConfigTemplete, authConfig } from "./authConfig";
//import Loading from "../components/common/display/Loading";
import App from "../App";
import Lobby from "../security/Lobby";
import { LocaleProvider } from "../i18n/LocaleProvider";

function AppMsal({ appProps }) {
  const fillMsalConfigTemplete = () => {
    msalConfigTemplete.auth.clientId = appProps.clientId;
    msalConfigTemplete.auth.authority += appProps.tenantId + "/";

    authConfig.msalConfig = msalConfigTemplete;
  };

  fillMsalConfigTemplete();
  const msalInstance = new PublicClientApplication(authConfig.msalConfig);
  authConfig.msalInstance = msalInstance;
  authConfig.internalUserManagement = appProps.internalUserManagement;
  authConfig.oauth2ImplicitGrant = appProps.oauth2ImplicitGrant;

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  return (
    <MsalProvider instance={msalInstance}>
      {/*     <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest()}
        loadingComponent={Loading}
      >
        <App configProps={appProps} />
      </MsalAuthenticationTemplate> */}
      <AuthenticatedTemplate>
        <App configProps={appProps} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LocaleProvider>
          <Lobby appProps={appProps} />
        </LocaleProvider>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default AppMsal;
