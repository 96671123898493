import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import { SCHEDULED, RESCHEDULED } from "../../constants/oneOnOneRefStatus";
import {
  SCHEDULE_ONE_ON_ONE,
  RESCHEDULE_ONE_ON_ONE,
} from "../../constants/oneOnOneModes";
// import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import ErrorIcon from "../../icons/ErrorIcon";
import CustomDatePicker from "../common/form/CustomDatePicker";

const ScheduleOrRescheduleOneOnOneForm = ({
  handleClose,
  handleSubmit,
  user,
  oneOnOne,
  mode,
}) => {
  const intl = useIntl();
  // const { formatDateTime } = useContext(ConfigurationPropertiesContext);

  const initializeValues = () => {
    return {
      meetingDate: oneOnOne.meetingDate
        ? new Date(oneOnOne.meetingDate)
        : moment(new Date()).add(1, "days").hour(9).minute(0),
      reason: "",
    };
  };

  const oneOnOneValidationSchema = (intl) =>
    Yup.object({
      meetingDate: Yup.date().required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      ),
    });

  const reshceduleValidationSchema = (intl) =>
    Yup.object({
      reason: Yup.string()
        .max(50, <FormattedMessage id="SetOneOnOneForm.reason.length" />)
        .ensure()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
    });

  const getStatus = (mode) => {
    if (mode === SCHEDULE_ONE_ON_ONE) {
      return SCHEDULED;
    } else if (mode === RESCHEDULE_ONE_ON_ONE) {
      return RESCHEDULED;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initializeValues()}
      // validationSchema={oneOnOneValidationSchema(intl)}
      validationSchema={() =>
        Yup.lazy((values) => {
          if (mode === SCHEDULE_ONE_ON_ONE) {
            return oneOnOneValidationSchema(intl);
          } else {
            return oneOnOneValidationSchema(intl).concat(
              reshceduleValidationSchema(intl)
            );
          }
        })
      }
      onSubmit={(values) => {
        handleSubmit(
          {
            meetingDate: new Date(
              new Date(values?.meetingDate).getTime() -
                new Date(values?.meetingDate).getTimezoneOffset() * 60000
            ).toJSON(),
            status: getStatus(mode),
            rescheduleReason: values?.reason,
          },
          mode
        );
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>
                <FormattedMessage
                  id={`OneOnOneStatus.${getStatus(mode)}.title`}
                />
              </Modal.Title>
              <hr />
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="ScheduleOrRescheduleOneOnOneForm.meetingDate.label" />
                  </Form.Label>
                  <CustomDatePicker
                    value={formik.values.meetingDate}
                    name="meetingDate"
                    shouldCloseOnSelect
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeCaption={intl.formatMessage({
                      id: "ScheduleOrRescheduleOneOnOneForm.datepicker.time.label",
                    })}
                    minDate={new Date()}
                    minTime={new Date().setHours(9, 30)}
                    maxTime={new Date().setHours(18, 30)}
                    excludeDates={[Date.now()]}
                    prevMonthButtonDisabled
                    prevYearButtonDisabled
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span style={{ marginLeft: "1%" }}> {msg} </span>
                      </div>
                    )}
                    name="meetingDate"
                  />
                </Form.Group>
              </Row>
              {mode === RESCHEDULE_ONE_ON_ONE && (
                <>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <FormattedMessage id="ScheduleOrRescheduleOneOnOneForm.rescheduleReason.label" />
                      </Form.Label>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Control
                        className="CancelOneOnOneForm"
                        type="text"
                        name="reason"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik?.errors?.reason && (
                        <div className="validation-error-msg">
                          {" "}
                          <ErrorIcon height="16px" width="16px" />{" "}
                          <span style={{ marginLeft: "1%" }}>
                            {" "}
                            {formik?.errors?.reason}{" "}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                </>
              )}
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="PerformanceReviewForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="Users.save" />
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleOrRescheduleOneOnOneForm;
