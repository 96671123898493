import authFetchJSON from "../components/security/fetchClient";

const BASE_URL = "/api/v2/appraisals";

const AppraisalServiceV2 = {
  getAppraisalById: (appraisalId) =>
    authFetchJSON(`${BASE_URL}/${appraisalId}`).then(
      (response) => response.ok && response.json()
    ),
};

export default AppraisalServiceV2;
