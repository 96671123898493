import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import ErrorIcon from "../../icons/ErrorIcon";
import DatePickerField from "../common/DatePickerField";

const PerformanceReviewForm = ({
  handleClose,
  handleSubmit,
  performanceReview,
  period,
}) => {
  const intl = useIntl();
  const initializeValues = () => {
    if (performanceReview) {
      performanceReview.start = new Date(performanceReview.start);
      performanceReview.end = new Date(performanceReview.end);

      return performanceReview;
    } else {
      return {
        title: "",
        start: new Date(period.startDate),
        end: period.endDate ? new Date(period.endDate) : new Date(),
      };
    }
  };
  const performanceReviewValidationSchema = Yup.object({
    performanceReview: Yup.object({
      title: Yup.string().required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      ),
      start: Yup.date()
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        )
        .nullable()
        .default(undefined),
      end: Yup.date()
        .when(
          "start",
          (start, schema) =>
            start &&
            schema.min(
              start,
              intl.formatMessage({
                id: "PerformanceReviewForm.endDate.error",
              })
            )
        )
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        )
        .nullable()
        .default(undefined),
    }),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{ performanceReview: initializeValues() }}
      validationSchema={performanceReviewValidationSchema}
      onSubmit={(values) => {
        handleSubmit(values.performanceReview);
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>
                {performanceReview ? (
                  <FormattedMessage id="PerformanceReviewForm.edit" />
                ) : (
                  <FormattedMessage id="PerformanceReviewForm.new" />
                )}
              </Modal.Title>
              <hr />
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="PerformanceReviewForm.title" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="performanceReview.title"
                    value={formik.values.performanceReview.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span style={{ marginLeft: "1%" }}> {msg} </span>
                      </div>
                    )}
                    name="performanceReview.title"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="PerformanceReviewForm.startDate" />
                  </Form.Label>
                  <DatePickerField
                    name="performanceReview.start"
                    selectsStart
                    startDate={formik.values.performanceReview.start}
                    endDate={formik.values.performanceReview.end}
                    disabled={
                      performanceReview
                        ? Date.now() >= performanceReview.start
                        : false
                    }
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span style={{ marginLeft: "1%" }}> {msg} </span>
                      </div>
                    )}
                    name="performanceReview.start"
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    <FormattedMessage id="PerformanceReviewForm.endDate" />
                  </Form.Label>
                  <DatePickerField
                    name="performanceReview.end"
                    selectsEnd
                    startDate={formik.values.performanceReview.start}
                    endDate={formik.values.performanceReview.end}
                    minDate={formik.values.performanceReview.start}
                  />
                  <ErrorMessage
                    render={(msg) => (
                      <div className="validation-error-msg">
                        {" "}
                        <ErrorIcon height="16px" width="16px" />{" "}
                        <span style={{ marginLeft: "1%" }}> {msg} </span>
                      </div>
                    )}
                    name="performanceReview.end"
                  />
                </Form.Group>
              </Row>
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="PerformanceReviewForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="PerformanceReviewForm.submit" />
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default PerformanceReviewForm;
