import React from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, FieldArray } from "formik";
import { Form, Button, Modal } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { getPermissionName } from "../../../util/permissionName";
import * as permissions from "../../../constants/permissions";
import ErrorIcon from "../../../icons/ErrorIcon";

const EditRoleForm = ({ role, handleClose, handleSubmit }) => {
  const intl = useIntl();
  const editPlaceholder = intl.formatMessage({
    id: "Reviews.tabs.edit",
  });
  const newPlaceholder = intl.formatMessage({
    id: "Goals.new",
  });
  const rolePlaceholder = intl.formatMessage({
    id: "Roles.role",
  });

  const roleValidationSchema = Yup.object({
    role: Yup.object({
      name: Yup.string()
        .min(
          2,
          intl.formatMessage({
            id: "EditRoleForm.name.minLength",
          })
        )
        .required(
          intl.formatMessage({
            id: "UserAuthenticationForm.validation.error",
          })
        ),
      description: Yup.string().required(
        intl.formatMessage({
          id: "UserAuthenticationForm.validation.error",
        })
      ),
    }),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        role: role ? role : { name: "", description: "", permissions: [] },
      }}
      validationSchema={roleValidationSchema}
      onSubmit={(values) => {
        handleSubmit(values.role);
        handleClose();
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton />
          <Modal.Dialog style={{ width: "90%", maxWidth: "100%" }}>
            <Modal.Body>
              <Modal.Title>{`${
                role ? editPlaceholder : newPlaceholder
              } ${rolePlaceholder}`}</Modal.Title>
              <hr />
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="EditForm.name" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="role.name"
                  value={formik.values.role.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}> {msg} </span>
                    </div>
                  )}
                  name="role.name"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="EditForm.description" />
                </Form.Label>
                <Form.Control
                  className="text-area"
                  as="textarea"
                  name="role.description"
                  value={formik.values.role.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="validation-error-msg">
                      {" "}
                      <ErrorIcon height="16px" width="16px" />{" "}
                      <span style={{ marginLeft: "1%" }}> {msg} </span>
                    </div>
                  )}
                  name="role.description"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="EditForm.permissions" />
                </Form.Label>
                <FieldArray
                  name="role.permissions"
                  render={(arrayHelpers) => {
                    let currentRolePermissions =
                      formik.values.role.permissions.map(
                        (permission) => permission.name
                      );
                    return (
                      <div style={{ columnCount: 2 }}>
                        {Object.values(permissions)
                          .sort((firstPermission, secondPermission) => {
                            if (
                              getPermissionName(firstPermission) >
                              getPermissionName(secondPermission)
                            )
                              return 1;
                            else if (
                              getPermissionName(firstPermission) <
                              getPermissionName(secondPermission)
                            )
                              return -1;
                            else return 0;
                          })
                          .map((permission) => (
                            <Form.Check
                              id={permission}
                              type="switch"
                              label={getPermissionName(permission)}
                              value={{ id: 0, permission: permission }}
                              checked={currentRolePermissions.includes(
                                permission
                              )}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  arrayHelpers.push({
                                    id: undefined,
                                    name: permission,
                                  });
                                } else {
                                  const idx =
                                    currentRolePermissions.indexOf(permission);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                          ))}
                      </div>
                    );
                  }}
                />
              </Form.Group>
            </Modal.Body>
          </Modal.Dialog>
          <Form.Group style={{ width: "95%", textAlign: "right" }}>
            <Button variant="secondary" className="mr-1" onClick={handleClose}>
              <FormattedMessage id="EditForm.cancel" />
            </Button>
            <Button type="submit" variant="primary">
              <FormattedMessage id="EditForm.submit" />
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default EditRoleForm;
