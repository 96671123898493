import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import "./template-groups.css";
import { FormattedMessage } from "react-intl";
const TemplateGroups = ({ templates }) => {
  const [hoveredTemplate, setHoveredTemplate] = useState();

  const onMouseOver = (template) => setHoveredTemplate(template);

  const onMouseLeave = () => setHoveredTemplate();

  return (
    <Table className="template-groups" borderless>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="Goals.topic" />
          </th>
        </tr>
      </thead>
      <tbody>
        {templates.map((template, index) => (
          <tr
            key={`template-row-${index}`}
            onMouseOver={() => onMouseOver(template)}
            onMouseLeave={onMouseLeave}
          >
            <td>
              <div>{template.topic}</div>
              {template.id === hoveredTemplate?.id && (
                <div className="actions">
                  {template.buttons.map((button, index) => (
                    <Button
                      key={`button-template-group-action-${index}`}
                      variant="link"
                      onClick={button?.action}
                    >
                      <FontAwesomeIcon icon={button.icon} />
                      {button.label}
                    </Button>
                  ))}
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TemplateGroups;
