import React, { useState, useEffect, useRef, Suspense } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Badge,
  Modal,
  Container,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LazyLoad, { forceCheck } from "react-lazyload";
import { Avatar, Badge as BadgeAnt } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastr";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import {
  USER_CHANGE_PASSWORD_MODE,
  USER_CHANGE_PROFILE_PICTURE_MODE,
} from "../../constants/userModes";
import {
  PERMISSION_ASSESS_EMPLOYEES,
  PERMISSION_SELF_ASSESS,
  PERMISSION_MY_TRAINING,
} from "../../constants/permissions";
import Image from "../common/Image";
import AvatarImage from "../common/display/AvatarImage";
import UserChangePasswordForm from "../common/UserChangePasswordForm";
import UserChangeProfilePictureForm from "../common/UserChangeProfilePictureForm";
import ControlledTabs from "../common/ControlledTabs";
import Loading from "../common/display/Loading";
import AuthenticationService from "../../services/AuthenticationService";
import UserService from "../../services/UserService";
import TeamService from "../../services/TeamService";
import ManagerService from "../../services/ManagerService";

import "./my-profile.css";

const { Title, Subtitle, Text } = Card;
let toastContainer;

const tabs = [
  {
    title: <FormattedMessage id="MyProfileContainer.tabs.goals" />,
    eventKey: "/my-goals",
  },
  {
    title: <FormattedMessage id="MyProfileContainer.tabs.appraisals" />,
    eventKey: "/appraisals",
  },
  {
    title: "1:1",
    eventKey: "/oneOnOnes",
  },
];

const MyProfileContainer = ({ user, loading }) => {
  const [manager, setManager] = useState(null);
  const [usersForReview, setUsersForReview] = useState([]);
  const [directReports, setDirectReports] = useState([]);

  const [mode, setMode] = useState();
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [userProfilePicture, setUserProfilePicture] = useState();

  const sideBarProfileRef = useRef();

  const handleOpenModal = (mode) => {
    setMode(mode);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setMode();
    setShowModal(false);
  };

  const showSuccessNotification = (message) =>
    toastContainer.success(message, "", { closeButton: true });

  const showErrorNotification = (message) =>
    toastContainer.error(message, "", { closeButton: true });

  useEffect(() => {
    if (
      AuthenticationService.hasPermission(user, PERMISSION_ASSESS_EMPLOYEES)
    ) {
      const index = tabs.findIndex((item) => item.eventKey === "/manager");
      if (index === -1) {
        tabs.push({
          title: <FormattedMessage id="MyProfileContainer.tabs.manager" />,
          eventKey: "/manager",
        });
      }
    } else if (
      AuthenticationService.hasPermission(user, PERMISSION_SELF_ASSESS) &&
      user?.teamId
    ) {
      const index = tabs.findIndex((item) => item.eventKey === "/my-team");
      if (index === -1) {
        tabs.push({
          title: <FormattedMessage id="MyProfileContainer.tabs.manager" />,
          eventKey: "/my-team",
        });
      }
    }
    if (AuthenticationService.hasPermission(user, PERMISSION_MY_TRAINING)) {
      const index = tabs.findIndex(
        (item) => item.eventKey === "/training/my-training"
      );
      if (index === -1) {
        tabs.push({
          title: <FormattedMessage id="MyProfileContainer.tabs.myTraining" />,
          eventKey: "/training/my-training",
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      AuthenticationService.hasPermission(user, PERMISSION_ASSESS_EMPLOYEES)
    ) {
      Promise.all([ManagerService.getUsersByManagerId(user.id)]).then(
        ([directReports]) => {
          setDirectReports(directReports);
        }
      );
    }
    if (user.teamId) {
      Promise.all([TeamService.getUsersByTeamId(user.teamId)]).then(
        ([usersForReview]) => {
          setUsersForReview(usersForReview);
        }
      );
    }
    Promise.all([UserService.getUserById(user.managerId)]).then(([manager]) => {
      setManager(manager);
    });
    forceCheck();
  }, [user, user.managerId, user.teamId]);

  return user && !loading ? (
    <ConfigurationPropertiesContext.Consumer>
      {({ internalUserManagement }) => (
        <>
          <ToastContainer
            ref={(ref) => (toastContainer = ref)}
            className="toast-top-right"
          />
          <Container fluid>
            <Row className="header-row" />
            <Row
              className="header-card"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Col className="header-col">
                <Row className="header-title-row">
                  <Col className="header-image profile-img">
                    <LazyLoad height={50} offset={100}>
                      {internalUserManagement ? (
                        <BadgeAnt
                          style={{ marginTop: "95%", marginRight: "48%" }}
                          offset={[0, 30]}
                          count={<EditOutlined />}
                          onClick={() =>
                            handleOpenModal(USER_CHANGE_PROFILE_PICTURE_MODE)
                          }
                        >
                          <AvatarImage
                            className="profile-img"
                            email={user.email}
                            roundedCircle
                          />
                        </BadgeAnt>
                      ) : (
                        <AvatarImage
                          className="profile-img"
                          email={user.email}
                          roundedCircle
                        />
                      )}
                    </LazyLoad>
                  </Col>
                  <Col className="my-profile-col my-profile-left">
                    <Title as="h3">
                      {user.firstName} {user.lastName}{" "}
                    </Title>
                    <Subtitle as="h4">{user.title}</Subtitle>
                    <Text>{user.email}</Text>
                  </Col>
                </Row>
              </Col>
              <ControlledTabs
                className="my-profile-tabs controlled-tabs"
                defaultKey="/"
                tabs={tabs}
              />
            </Row>
          </Container>
          <Container
            fluid
            ref={sideBarProfileRef}
            style={{
              width: "34%",
              display: "flex",
              flexShrink: 1,
              float: "left",
            }}
          >
            <Card
              className="my-profile-card"
              style={{
                marginTop: "5%",
                marginLeft: "-2%",
                /*   borderRadius: "1rem", */
                boxShadow: "0px 0px 1px 1px ligthgrey",
                width: "92%",
              }}
            >
              <ListGroup className="list-group-flush">
                {manager?.fullName && (
                  <ListGroupItem>
                    <Row style={{ marginBottom: "2%" }}>
                      <Col md={4} style={{ fontWeight: 700 }}>
                        <FormattedMessage id="MyProfileContainer.title.manager" />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={2}
                        className="profile-img"
                        style={{ width: "30%", flexShrink: 0, float: "left" }}
                      >
                        {manager.email && (
                          <LazyLoad height={50} offset={100}>
                            <Image
                              style={{ width: "4.25rem" }}
                              email={manager.email}
                              roundedCircle
                              clasName="profile-img"
                            />
                          </LazyLoad>
                        )}
                      </Col>
                      <Col md={8} style={{ marginLeft: "2%" }}>
                        <Title as="h3" style={{ fontSize: "18px" }}>
                          {manager.firstName} {manager.lastName}
                        </Title>
                        <Subtitle as="h4" style={{ fontSize: "16px" }}>
                          {manager.title}
                        </Subtitle>
                      </Col>
                    </Row>
                  </ListGroupItem>
                )}
                {manager && directReports && directReports.length > 0 && (
                  <ListGroupItem>
                    <Row>
                      <Col md={12} style={{ fontWeight: 700 }}>
                        <FormattedMessage id="MyProfileContainer.title.direct" />
                      </Col>
                      <Avatar.Group
                        maxCount={10}
                        style={{ marginLeft: "4%", marginTop: "2%" }}
                      >
                        {directReports?.map((user) => (
                          <>
                            <LazyLoad height={50} offset={100}>
                              <AvatarImage
                                email={user.email}
                                name={user.fullName}
                                roundedCircle
                              />
                            </LazyLoad>
                          </>
                        ))}
                      </Avatar.Group>
                    </Row>
                  </ListGroupItem>
                )}
                {user?.teamName && (
                  <ListGroupItem>
                    <Row style={{ marginBottom: "2%" }}>
                      <Col md={4}>
                        <FormattedMessage id="MyProfileContainer.title.team" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <Title as="h3" style={{ fontSize: "18px" }}>
                          {user?.teamName}
                        </Title>
                      </Col>
                    </Row>

                    {usersForReview && (
                      <Row>
                        <Col md={8}>
                          <FormattedMessage id="MyProfileContainer.title.team.members" />
                        </Col>
                        <Suspense fallback={<Loading />}>
                          <Avatar.Group
                            maxCount={10}
                            style={{ marginLeft: "4%", marginTop: "2%" }}
                          >
                            {usersForReview?.map((user) => (
                              <>
                                <AvatarImage
                                  email={user.email}
                                  name={user.fullName}
                                  roundedCircle
                                />
                              </>
                            ))}
                          </Avatar.Group>
                        </Suspense>
                      </Row>
                    )}
                  </ListGroupItem>
                )}

                <ListGroupItem>
                  <Row>
                    <Col md={4}>
                      <FormattedMessage id="MyProfileContainer.title.roles" />
                    </Col>
                  </Row>
                  {user?.roles?.map((role) => (
                    <>
                      {" "}
                      <Badge pill variant="primary">
                        {role.name}
                      </Badge>{" "}
                    </>
                  ))}
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Container>

          {internalUserManagement && (
            <Modal
              className="sidebar-modal"
              show={showModal}
              onHide={handleCloseModal}
              centered
            >
              {mode === USER_CHANGE_PASSWORD_MODE ? (
                <UserChangePasswordForm
                  handleClose={handleCloseModal}
                  showSuccessNotification={showSuccessNotification}
                  showErrorNotification={showErrorNotification}
                />
              ) : (
                mode === USER_CHANGE_PROFILE_PICTURE_MODE && (
                  <UserChangeProfilePictureForm
                    email={user.email}
                    updateProfilePicture={setUserProfilePicture}
                    handleClose={handleCloseModal}
                    showSuccessNotification={showSuccessNotification}
                    showErrorNotification={showErrorNotification}
                  />
                )
              )}
            </Modal>
          )}
        </>
      )}
    </ConfigurationPropertiesContext.Consumer>
  ) : (
    //  )
    <>
      <Container
        fluid
        ref={sideBarProfileRef}
        style={{
          width: "34%",
          display: "flex",
          flexShrink: 1,
          float: "left",
        }}
      >
        <Card
          style={{
            marginTop: "5%",
            marginLeft: "-2%",
            /*   borderRadius: "1rem", */
            boxShadow: "0px 0px 1px 1px ligthgrey",
            width: "92%",
          }}
        >
          <ListGroup className="list-group-flush">
            {manager?.fullName && (
              <ListGroupItem>
                <Row style={{ marginBottom: "2%" }}>
                  <Col md={4}>
                    <FormattedMessage id="MyProfileContainer.title.manager" />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={2}
                    className="profile-img"
                    style={{ width: "30%", flexShrink: 0, float: "left" }}
                  >
                    {manager.email && (
                      <LazyLoad height={50} offset={100}>
                        <Image
                          style={{ width: "4.25rem" }}
                          email={manager.email}
                          roundedCircle
                          clasName="profile-img"
                        />
                      </LazyLoad>
                    )}
                  </Col>
                  <Col md={8}>
                    <Title as="h3">
                      {manager.firstName} {manager.lastName}
                    </Title>
                    <Subtitle as="h4">{manager.title}</Subtitle>
                  </Col>
                </Row>
              </ListGroupItem>
            )}
            {user?.teamName && (
              <ListGroupItem>
                <Row style={{ marginBottom: "2%" }}>
                  <Col md={2}>
                    <FormattedMessage id="MyProfileContainer.title.team" />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Title as="h3">{user?.teamName}</Title>
                  </Col>
                </Row>

                {manager && directReports && directReports.length > 0 && (
                  <Row>
                    <Col md={8}>
                      <FormattedMessage id="MyProfileContainer.title.direct" />
                    </Col>
                    <Avatar.Group
                      maxCount={10}
                      style={{ marginLeft: "4%", marginTop: "2%" }}
                    >
                      {directReports.map((user) => (
                        <>
                          <LazyLoad height={50} offset={100}>
                            <AvatarImage
                              email={user.email}
                              name={user.fullName}
                              roundedCircle
                            />
                          </LazyLoad>
                        </>
                      ))}
                    </Avatar.Group>
                  </Row>
                )}
                {usersForReview && (
                  <Row>
                    <Col md={8}>
                      <FormattedMessage id="MyProfileContainer.title.team.members" />
                    </Col>
                    <Suspense fallback={<Loading />}>
                      <Avatar.Group
                        maxCount={10}
                        style={{ marginLeft: "4%", marginTop: "2%" }}
                      >
                        {usersForReview.map((user) => (
                          <>
                            <AvatarImage
                              email={user.email}
                              name={user.fullName}
                              roundedCircle
                            />
                          </>
                        ))}
                      </Avatar.Group>
                    </Suspense>
                  </Row>
                )}
              </ListGroupItem>
            )}

            <ListGroupItem>
              <FormattedMessage id="MyProfileContainer.title.roles" />
              {user.roles.map((role) => (
                <>
                  {" "}
                  <Badge pill variant="primary">
                    {role.name}
                  </Badge>{" "}
                </>
              ))}
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    </>
  );
};

export default MyProfileContainer;
