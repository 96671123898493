import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  TEAM_CREATE_MODE,
  TEAM_DELETE_MODE,
  TEAM_EDIT_MODE,
  TEAM_SHOW_MEMBERS,
} from "../../../constants/teamModes";
import Loading from "../../common/display/Loading";
import EditTeamForm from "./EditTeamForm";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import TeamService from "../../../services/TeamService";
// import MembersList from "../MembersList";
import AddOrRemoveUserModal from "../users/AddOrRemoveUserModal";

const TeamModal = ({ showModal, handleClose, handleSubmit, mode, team }) => (
  <Modal show={showModal} onHide={handleClose} size="lg" centered>
    {(mode === TEAM_CREATE_MODE || mode === TEAM_EDIT_MODE) && (
      <EditTeamForm
        team={team}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    )}
    {mode === TEAM_SHOW_MEMBERS && (
      <TeamMembers
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        team={team}
      />
    )}
    {mode === TEAM_DELETE_MODE && (
      <DeleteConfirmation
        team={team}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    )}
  </Modal>
);

/**
 * Helper functional component for rendering a modal with selected team members
 * @param props
 * @return {*}
 * @constructor
 */
const TeamMembers = ({ team, handleClose, handleSubmit }) => {
  const [teamMembers, setTeamMembers] = useState(() => {
    TeamService.getUsersByTeamId(team.id).then((users) =>
      setTeamMembers(users)
    );
  });

  return teamMembers ? (
    <AddOrRemoveUserModal
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title={team?.name}
      members={teamMembers}
      role={team}
    />
  ) : (
    <Loading />
  );
};

export default TeamModal;
