import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppraisalService from "../../services/AppraisalService";
import PerformanceReviewService from "../../services/PerformanceReviewService";
import { getAppraisalAssessorByAssessorType } from "../../util/getAppraisalAssessor";
import {
  EMPLOYEE_ASESSOR_TYPE,
  FINAL_ASSESSOR_TYPE,
} from "../../constants/assessorTypes";
import Loading from "../common/display/Loading";
import { Col, Container, Row } from "react-bootstrap";
import Appraisals from "./Appraisals";
import { FormattedMessage } from "react-intl";
const MyAppraisalsContainer = ({ user, readOnly }) => {
  const history = useHistory();

  const [appraisals, setAppraisals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const assessAppraisal = () => history.push("/appraisal");

  const viewAppraisal = (appraisal) => {
    history.push(`/appraisals/${appraisal.id}`);
  };

  useEffect(() => {
    AppraisalService.getAppraisalsByEmployeeId(user.id)
      .then((appraisals) => {
        if (appraisals.length === 0) handleEmptyOrError();
        else {
          Promise.all(
            appraisals.map((appraisal) =>
              PerformanceReviewService.getPerformanceReviewPeriodById(
                appraisal.performanceReviewId
              )
            )
          )
            .then((periods) => {
              appraisals.forEach((appraisal, index) =>
                Object.assign(appraisal, {
                  startDate: periods[index]?.startDate,
                  endDate: periods[index]?.endDate,
                  assessors: [
                    getAppraisalAssessorByAssessorType(
                      appraisal,
                      EMPLOYEE_ASESSOR_TYPE
                    ),
                    getAppraisalAssessorByAssessorType(
                      appraisal,
                      FINAL_ASSESSOR_TYPE
                    ),
                  ],
                  assessAction: assessAppraisal,
                  viewAction: () => viewAppraisal(appraisal),
                })
              );
              setAppraisals(appraisals);
              setIsLoading(false);
            })
            .catch(handleEmptyOrError);
        }
      })
      .catch(handleEmptyOrError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleEmptyOrError = () => {
    setNotFound(true);
    setIsLoading(false);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container
      fluid
      style={{
        width: "66%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        margin: 0,
        padding: 0,
      }}
    >
      <Row className="mb-5" style={{ marginTop: "3%" }}>
        <Col>
          <header className="text-center">
            <h4>
              {notFound ? (
                <FormattedMessage id="Appraisal.noFound" />
              ) : (
                <FormattedMessage id="Appraisal.myAppraisals" />
              )}
            </h4>
          </header>
        </Col>
      </Row>
      {!notFound && <Appraisals appraisals={appraisals} employee={user} />}
    </Container>
  );
};

export default MyAppraisalsContainer;
